<!-- 新增报告 -->
<template>
  <a-modal
    :class="{ noborder: model === 'type' }"
    v-model="visibleOne"
    :title="model === 'type' ? '我要生成' : title"
    width="507px"
    height="293px"
    :body-style="
      model === 'type'
        ? { paddingTop: '56px', paddingBottom: '86px' }
        : { paddingTop: '38px', paddingLeft: '46px', paddingBottom: '98px' }
    "
    :maskClosable="false"
    :closable="model === 'type'"
    :ok-button-props="
      model === 'type'
        ? { style: { display: 'none' } }
        : {
            props: { shape: 'round' },
            domProps: {
              innerHTML: reportType === 'INTERVENTION_RECORDS' ? '本地上传记录' : '制作报告',
            },
          }
    "
    :cancel-button-props="model === 'type' ? { style: { display: 'none' } } : { props: { shape: 'round' } }"
    @ok="handleOk"
  >
    <!-- 新增报告--选择类型 -->
    <div v-if="model === 'type'" class="wrap">
      <div
        v-if="$auth('data_report_add_daily')"
        class="list"
        @click="selectItem({ nexttitle: '选择日报生成日期', type: 'date' })"
      >
        <div class="img-wrap"><img class="list-img" :src="require('@/assets/report/report/ico_rep_date.png')" /></div>
        <p class="list-title">日报</p>
      </div>

      <div
        v-if="$auth('data_report_add_weekly')"
        class="list"
        @click="selectItem({ nexttitle: '选择周报生成日期', type: 'week' })"
      >
        <div class="img-wrap"><img class="list-img" :src="require('@/assets/report/report/ico_rep_week.png')" /></div>
        <p class="list-title">周报</p>
      </div>

      <div
        v-if="$auth('data_report_add_monthly')"
        class="list"
        @click="
          selectItem({
            nexttitle: '选择月报生成日期',
            type: 'month',
          })
        "
      >
        <div class="img-wrap"><img class="list-img" :src="require('@/assets/report/report/ico_rep_month.png')" /></div>
        <p class="list-title">月报</p>
      </div>
      <!-- TODO:季报权限名称未定,临时起的-->
      <div
        v-if="$auth('add_quarterly_report')"
        class="list"
        @click="
          selectItem({
            nexttitle: '选择季报生成日期',
            type: 'quarter',
          })
        "
      >
        <div class="img-wrap"><img class="list-img" :src="require('@/assets/report/report/ico_rep_quar.png')" /></div>
        <p class="list-title">季报</p>
      </div>

      <div
        v-if="$auth('data_report_add_intervention_records')"
        class="list"
        @click="
          selectItem({
            nexttitle: '选择干预记录上传日期',
            type: 'INTERVENTION_RECORDS',
          })
        "
      >
        <div class="img-wrap"><img class="list-img" :src="require('@/assets/report/report/ico_rep_ganyu.png')" /></div>
        <p class="list-title">干预记录</p>
      </div>
    </div>

    <!-- 新增报告--选择日期 -->
    <div v-else class="time-wrap">
      <div v-if="!isDatepicker" class="time-notice">
        <div class="default">
          {{ timeLimit[reportType].name }}支持的时间跨度为：{{ timeLimit[reportType].min }}~{{
            timeLimit[reportType].max
          }}天
        </div>
      </div>
      <div class="time-content">
        <span class="time">*</span>
        <a-date-picker v-if="isDatepicker" format="YYYY-MM-DD" :disabled-date="disabledDate" @change="ondateChange" />
        <div v-else class="picker">
          <a-date-picker
            :allowClear="false"
            v-model="startValue"
            format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            placeholder="选择开始日期"
            @change="onStartChange"
            @openChange="handleOpenChange"
          />
          <span class="text">至</span>
          <a-date-picker
            :allowClear="false"
            v-model="endValue"
            format="YYYY-MM-DD"
            placeholder="选择结束日期"
            :disabled-date="disabledRange"
            :disabled="datedisabled"
            :open="endOpen"
            @change="onEndChange"
          />
        </div>
      </div>
      <!-- 干预记录本地PDF文件的上传，默认隐藏 -->
      <input id="uploadFile" type="file" name="file" @change="pdfFileChange" />
    </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import * as uploadApi from '@/api/upload'
import * as Api from '@/api/datareport'
export default {
  data() {
    return {
      // 日期选择
      // 日期相关设置
      startValue: null,
      endValue: null,
      endOpen: false,
      datedisabled: true,
      timeLimit: {
        date: {
          name: '日报',
          min: 0,
          max: 0
        },
        week: {
          name: '周报',
          min: 2,
          max: 14
        },
        month: {
          name: '月报',
          min: 15,
          max: 65
        },
        quarter: {
          name: '季报',
          min: 90,
          max: 180
        },
        INTERVENTION_RECORDS: {
          name: '干预记录',
          min: 0,
          max: 0
        }
      },
      reportNameList: {
        date: 'DAILY_NEWS',
        week: ' WEEKLY_REPORT',
        month: 'MONTHLY_REPORT',
        quarter: 'QUARTERLY_REPORT',
        INTERVENTION_RECORDS: 'INTERVENTION_RECORDS'
      },
      visibleOne: false, // 选择类型弹窗
      model: 'type', // 选择内容
      title: '', // 弹窗名称
      reportType: '', // 选择的类型
      startDate: '', // 选择的日期起始，针对时间段
      endDate: ''
    }
  },
  computed: {
    isDatepicker() {
      return ['date', 'INTERVENTION_RECORDS'].includes(this.reportType)
    }
  },
  methods: {
    showModal() {
      this.visibleOne = true
      this.clear()
    },
    selectItem(item) {
      this.title = item.nexttitle
      this.reportType = item.type
      this.model = 'time'
    },
    // 不可选择日期范围
    disabledDate(current) {
      return current > moment().endOf('day')
    },
    // 日期范围的选中计算
    handleOpenChange() {
      this.endOpen = false
    },
    onStartChange() {
      this.datedisabled = false
      this.endOpen = true
    },
    onEndChange() {
      const { startValue, endValue } = this
      if (startValue >= endValue) {
        this.startValue = endValue
        this.endValue = startValue
      }
      this.startDate = moment(this.startValue).format('YYYY-MM-DD')
      this.endDate = moment(this.endValue).format('YYYY-MM-DD')
      this.datedisabled = true
      this.endOpen = false
    },
    // 周报、月报等不可选择日期范围
    disabledRange(current) {
      const { startValue, endOpen, timeLimit, reportType } = this
      if (endOpen) {
        // 根据报告类型、开始时间可以得到报告能选择的过去、未来区间，通过比较今天的日期处于未来区间的位置，生成禁用规则
        // 计算报告类型对应的未来日期区间
        const addMinDate = moment(startValue)
          .add(timeLimit[reportType].min - 1, 'days')
          .endOf('day')
        const addMaxDate = moment(startValue)
          .add(timeLimit[reportType].max - 1, 'days')
          .endOf('day')

        if (moment().endOf('day') < addMinDate) {
          return !(
            current >
              moment(startValue)
                .subtract(timeLimit[reportType].max - 1, 'days')
                .startOf('day') &&
            current <
              moment(startValue)
                .subtract(timeLimit[reportType].min - 1, 'days')
                .endOf('day')
          )
        }

        if (moment().endOf('day') >= addMinDate && moment() < addMaxDate) {
          return !(
            (current >
              moment(startValue)
                .subtract(timeLimit[reportType].max - 1, 'days')
                .startOf('day') &&
              current <
                moment(startValue)
                  .subtract(timeLimit[reportType].min - 1, 'days')
                  .endOf('day')) ||
            (current >
              moment(startValue)
                .add(timeLimit[reportType].min - 1, 'days')
                .startOf('day') &&
              current < moment().endOf('day'))
          )
        }

        if (moment() >= addMaxDate) {
          return !(
            (current >
              moment(startValue)
                .subtract(timeLimit[reportType].max - 1, 'days')
                .startOf('day') &&
              current <
                moment(startValue)
                  .subtract(timeLimit[reportType].min - 1, 'days')
                  .endOf('day')) ||
            (current >
              moment(startValue)
                .add(timeLimit[reportType].min - 1, 'days')
                .startOf('day') &&
              current <
                moment(startValue)
                  .add(timeLimit[reportType].max - 1, 'days')
                  .endOf('day'))
          )
        }
      }
    },
    // 具体日期的选中计算
    ondateChange(date, dateString) {
      this.startDate = dateString
      this.endDate = dateString
    },
    // 获取本地pdf文件
    pdfFileChange(e) {
      // 只有上传了文件才会触发这个函数
      const file = e.target.files[0]
      const isFileType = file.type === 'application/pdf'
      if (!isFileType) {
        this.$message.error('请上传pdf文件')
        return
      }
      const formData = new FormData()
      formData.append('file', file)

      uploadApi
        .resource({
          formData
        })
        .then(res => {
          this.upload(res.data)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    upload(resource = {}) {
      const {
        startDate,
        $route: {
          params: { id }
        }
      } = this
      const param = {
        studentId: id,
        startTime: startDate,
        resource
      }
      Api.createINTERVENTION_RECORDS(param).then(res => {
        const { code } = res
        if (code === 0) {
          this.$notification.success({
            message: '提示',
            description: '干预记录上传成功'
          })
          this.$emit('refresh')
          this.visibleOne = false
        }
      })
    },
    // 查看选择日期是否有数据
    handleOk() {
      const {
        reportNameList,
        reportType,
        startDate,
        endDate,
        $route: {
          params: { id }
        },
        isDatepicker
      } = this

      if (startDate === '') {
        this.$notification.error({
          message: '提示',
          description: '请先选择日期'
        })
      } else {
        // 干预记录
        if (reportType === 'INTERVENTION_RECORDS') {
          // 触发文件上传
          const el = document.getElementById('uploadFile')
          el.click()
        } else {
          // 其它报告生成
          if (reportType !== 'quarter') {
            Api.beforeCreate({ startDate, endDate, studentId: id, type: reportNameList[reportType] }).then(res => {
              const { code, msg } = res
              if (code === 0) {
                const event = {
                  type: 'newadd',
                  toMenuName: 'steps',
                  toSubMenuName: reportType,
                  param: {}
                }
                if (isDatepicker) {
                  event.param = {
                    reportDate: startDate,
                    studentId: id
                  }
                } else {
                  event.param = {
                    startDate,
                    endDate,
                    studentId: id
                  }
                }
                this.visibleOne = false
                this.$emit('event', event)
                // 重置变量
                this.clear()
              } else if (code === 9019) {
                // 没有数据
                // '所选日期无上课训练数据，无法制作报告'
                this.$notification.error({
                  message: '提示',
                  description: msg
                })
              } else if (code === 9021) {
                // 已有报告
                // '当天已存在日报，请勿重复创建。'
                this.$notification.error({
                  message: '提示',
                  description: msg
                })
              }
            })
          } else {
            Api.createQuarterly({ startDate, endDate, id }).then(res => {
              const { code, msg } = res
              if (code === 0) {
                this.$notification.success({
                  message: '提示',
                  description: '报告生成成功'
                })
                this.$emit('refresh')
                this.visibleOne = false
              } else if (code === 9019) {
                // 没有数据
                // '所选日期无上课训练数据，无法制作报告'
                this.$notification.error({
                  message: '提示',
                  description: msg
                })
              } else if (code === 9021) {
                // 已有报告
                // '当天已存在日报，请勿重复创建。'
                this.$notification.error({
                  message: '提示',
                  description: msg
                })
              }
            })
          }
        }
      }
    },
    clear() {
      this.model = 'type'
      this.reportType = ''
      this.startValue = null
      this.endValue = null
      this.endOpen = false
      this.datedisabled = true
      this.startDate = ''
      this.endDate = ''
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  border-radius: 12px;
}
/deep/ .ant-modal-header {
  /*border-radius: 12px;*/
}
.noborder {
  /deep/.ant-modal-footer {
    border-top: none;
  }
}
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92%;
  margin: 0 auto;
  .list {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0 12px;
    .img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 66px;
      border-radius: 6px;
      border: 1px solid #3a5ff8;
      .list-img {
        width: 42px;
      }
    }
    .list-title {
      width: 66px;
      height: 20px;
      margin: 6px 0 0;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      text-align: center;
    }
  }
}
.time-wrap {
  .time-content {
    display: flex;
    align-items: center;
    .time {
      width: 8px;
      height: 9px;
      margin-right: 12px;
      color: #e52323;
    }
    .picker {
      display: flex;
      align-items: center;
      .text {
        margin: 0 10px;
      }
    }
  }
  .time-notice {
    margin-left: 22px;
    .default {
      margin: 12px 0;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #000000;
    }
  }
  #uploadFile {
    display: none;
  }
}
</style>
