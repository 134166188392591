<template>
  <div class="field-list">
    <div
      v-if="fieldList.length === 0"
      style="
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <a-spin v-if="listLoading" style="margin-top: -64px" />
    </div>
    <a-row v-else :gutter="[24, 16]" style="margin: 0">
      <a-col :span="8" v-for="(item, index) in fieldList" :key="index">
        <a-card style="width: 100%; border: none">
          <div class="field">
            <img :src="item.url" />
            {{ item.name }}
          </div>
          <div class="schedule answer-schedule">
            <div class="schedule-left">答题进度：{{ item.answerSubject }}/{{ item.totalSubject }}</div>
            <a-progress class="schedule-progress" strokeColor="#3A5FF8" :percent="item.progress" status="active">
              <template #format="percent">
                <span style="color: #3a5ff8">{{ `${item.progress}%` }}</span>
              </template>
            </a-progress>
          </div>
          <div class="schedule score">
            <div class="schedule-left">领域得分：{{ item.totalScore }}分</div>
            <a-progress class="schedule-progress" strokeColor="#FF7A55" :percent="item.successRate" status="active">
              <template #format="percent">
                <span style="color: #ff7a55">{{ `${item.successRate}%` }}</span>
              </template>
            </a-progress>
          </div>
          <template slot="actions" class="ant-card-actions">
            <a-button
              type="link"
              v-if="item.isReassessment === true && item.status === 'COMPLETED' && $auth('assessment')"
              @click="handleReassessmentsSingle({ item })"
            >
              重新评估
            </a-button>
            <a-button
              type="link"
              v-if="item.status === 'COMPLETED' && !item.isReassessment && $auth('skills_list')"
              @click="$emit('handleReport', { item })"
            >
              查看得分
            </a-button>

            <a-button
              type="link"
              v-if="item.progress == '0' && $auth('assessment')"
              @click="$emit('handleAnswer', item)"
            >
              {{ '开始评估' }}
            </a-button>

            <a-button
              type="link"
              v-if="item.progress > '0' && item.answerSubject < item.totalSubject && $auth('assessment')"
              @click="$emit('handleAnswer', item)"
            >
              {{ '继续评估' }}
            </a-button>
          </template>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as planApi from '@/api/plan'
import { mapActions } from 'vuex'
export default {
  name: 'FieldListVue',
  data() {
    return {
      listLoading: false,
      // 领域列表
      fieldList: [],
    }
  },
  created() {
    this.getFieldList()
  },

  methods: {
    ...mapActions(['changeSpinning']),

    // 重新评估-单个
    handleReassessmentsSingle({ item }) {
      const { id, name } = item
      planApi
        .saveReassessmentsSingle({
          evaluationFieldId: id,
        })
        .then((res) => {
          const { code, msg, data } = res
          if (code === 0) {
            const { evaluationField: id, totalSubject } = data
            this.$emit('handleAnswer', {
              id,
              name,
              totalSubject,
            })
          } else {
            this.$notification['error']({
              message: '提示',
              description: `${msg}`,
            })
          }
        })
        .finally(() => (this.listLoading = false))
    },

    // 领域列表
    getFieldList() {
      this.changeSpinning(true)
      this.fieldList = []
      const {
        $route: {
          params: { id },
        },
      } = this
      planApi
        .getFieldListByStudent({
          studentId: id,
        })
        .then((res) => {
          const { code, data } = res
          if (code === 0) {
            this.fieldList = data
            // 全部显示 查看报告 上边 生成计划 按钮才可点击
            const generateThePlan = data.every((item) => {
              return item.status === 'COMPLETED' && item.isReassessment === null
            })
            this.$emit('enable', generateThePlan)
          }
        })
        .finally(() => (
          this.changeSpinning(false)
        ))
    },
  },
}
</script>

<style lang="less" scoped>
/*领域列表*/
.field-list {
  min-height: 300px;

  /*标题*/
  .field {
    margin-bottom: 13px;
    line-height: 28px;
    img {
      width: 28px;
      height: 28px;
      vertical-align: bottom;
    }

    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }

  /* 答题进度*/
  .schedule {
    display: flex;

    .schedule-left {
      width: 130px;
      font-size: 14px;
      color: #000000;
    }

    .schedule-progress {
      flex: 1;

      /deep/ .ant-progress-inner {
        background-color: #fff;
      }
    }
  }

  .answer-schedule {
    margin-bottom: 8px;
  }

  /deep/ .ant-card-body {
    height: auto !important;
    padding: 16px 16px 24px 16px;
    background: #f7f8fc;
  }

  /deep/ .ant-card-actions {
    height: 35px;
    background: #f7f8fc;
    li {
      margin: 0;
      span {
        text-align: right;
        margin-right: 8px;
        cursor: default;
      }
    }
  }
}
</style>
