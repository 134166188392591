<template>
  <div>
    <date-steps ref="dateSteps" v-show="currentStepsType === 'date'" @event="handleEvent"></date-steps>
    <week-steps ref="weekSteps" v-show="currentStepsType === 'week'" @event="handleEvent"></week-steps>
    <month-steps ref="monthSteps" v-show="currentStepsType === 'month'" @event="handleEvent"></month-steps>
  </div>
</template>
<script>
import DateSteps from './dateSteps'
import WeekSteps from './weekSteps'
import MonthSteps from './monthSteps'
export default {
  components: {
    DateSteps,
    WeekSteps,
    MonthSteps
  },
  data() {
    return {
      currentStepsType: 'date'
    }
  },
  methods: {
    showStep(e) {
      this.currentStepsType = e.toSubMenuName
      switch (e.toSubMenuName) {
        case 'date':
          this.$refs.dateSteps.initData(e)
          break
        case 'week':
          this.$refs.weekSteps.initData(e)
          break
        case 'month':
          this.$refs.monthSteps.initData(e)
          break
        default:
      }
    },
    // 自定义的“冒泡”事件
    handleEvent(e) {
      this.$emit('eventsubmit', e)
    }
  }
}
</script>
<style lang="less">
.steps {
  // min-height: 50vh;
  .title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
  }
  .subtitle {
    margin: 8px 0 22px;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
  }
  .skill-item {
    padding: 28px 24px 24px;
    font-size: 14px;
    background: #f7f8fc;
    .item-title {
      display: flex;
      align-items: center;
      .des {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .tag {
        margin: 0 8px;
        padding: 4px 12px;
        font-weight: 400;
        color: #3a5ff8;
        background: rgba(58, 95, 248, 0.1);
        border-radius: 6px;
      }
    }
    .item-goal {
      margin: 8px 0 16px;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 28px;
    }

    .item-phase {
      font-weight: 400;
      .item-phase-wrap {
        display: flex;
        align-items: flex-start;
      }
      .item-phase-wrap span:nth-child(2) {
        .name {
          margin: -5px 0 8px 8px;
          color: #1a1a1a;
        }
        .goal {
          margin: 0 0 0 8px;
          color: #8c8c8c;
        }
      }
    }
    .item-phase:not(:last-child) {
      margin: 0 0 16px;
    }
  }
  .skill-item:not(:last-child) {
    margin: 0 0 16px;
  }

  .phase-item {
    padding: 28px 24px 24px;
    font-size: 14px;
    background: #f7f8fc;
    .item-title {
      display: flex;
      align-items: center;
      margin: 0 0 16px;
      .des {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .tag {
        margin: 0 8px;
        padding: 4px 12px;
        font-weight: 400;
        color: #3a5ff8;
        background: rgba(58, 95, 248, 0.1);
        border-radius: 6px;
      }
    }
    .item-mat {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      .mat-title {
        font-weight: 500;
        color: #1a1a1a;
      }
      .mat-name {
        margin: 4px;
        font-weight: 400;
        color: #8c8c8c;
      }
    }
    .item-mat:not(:last-child) {
      margin: 0 0 16px;
    }
  }
  .phase-item:not(:last-child) {
    margin: 0 0 16px;
  }

  .action-item {
    padding: 26px 16px;
    background: #f7f8fc;
    .item-opt {
      font-size: 14px;
      font-weight: 500;
      color: #1a1a1a;
    }
  }
  .action-item:not(:last-child) {
    margin: 0 0 16px;
  }

  .homework-item {
    padding: 28px 24px 24px;
    font-size: 14px;
    background: #f7f8fc;
    .item-title {
      display: flex;
      align-items: center;
    }
    .item-title span:nth-child(2) {
      display: flex;
      align-items: center;
      .des {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .tag {
        margin: 0 8px;
        padding: 4px 12px;
        font-weight: 400;
        color: #3a5ff8;
        background: rgba(58, 95, 248, 0.1);
        border-radius: 6px;
      }
    }
    .item-mat {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      margin: 16px 0 0;
      .mat-title {
        font-weight: 500;
        color: #1a1a1a;
      }
      .mat-name {
        margin: 4px;
        font-weight: 400;
        color: #8c8c8c;
      }
    }
    .item-mat:not(:last-child) {
      margin: 0 0 16px;
    }
  }
  .homework-item:not(:last-child) {
    margin: 0 0 16px;
  }

  .month-skill-phase-item {
    padding: 28px 24px 24px;
    margin: 16px 0;
    font-size: 14px;
    background: #f7f8fc;
    .item-skill-check {
      display: flex;
      align-items: flex-start;
    }
    .item-skill-check span:nth-child(2) {
      .check-wrap {
        display: flex;
        align-items: center;
        margin: -8px 0 0;
        .des {
          margin: 0;
          font-weight: 500;
          color: #1a1a1a;
        }
        .tag {
          margin: 0 8px;
          padding: 4px 12px;
          font-weight: 400;
          color: #3a5ff8;
          background: rgba(58, 95, 248, 0.1);
          border-radius: 6px;
        }
      }
      .goal {
        margin: 8px 0 16px;
        font-weight: 400;
        color: #8c8c8c;
        line-height: 28px;
      }
    }
    .item-phase-check {
      display: flex;
      align-items: flex-start;
      font-weight: 400;
      margin: 0 0 0 24px;
    }
    .item-phase-check span:nth-child(2) {
      .name {
        margin: -5px 0 8px 8px;
        color: #1a1a1a;
      }
      .goal {
        margin: 0 0 16px 8px;
        color: #8c8c8c;
      }
    }
  }

  .month-skill-item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 28px 24px 24px;
    font-size: 14px;
    background: #f7f8fc;
  }
  .month-skill-item span:nth-child(2) {
    .item-title {
      display: flex;
      align-items: center;
      margin: -8px 0 0;
      .des {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .tag {
        margin: 0 8px;
        padding: 4px 12px;
        font-weight: 400;
        color: #3a5ff8;
        background: rgba(58, 95, 248, 0.1);
        border-radius: 6px;
      }
    }
    .item-goal {
      margin: 8px 0 16px;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 28px;
    }
  }
  .month-skill-item:not(:last-child) {
    margin: 0 0 16px;
  }
}
.footer-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 75px;
}
</style>
