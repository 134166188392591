import request from '@/utils/request'
const reportApi = {
  // 日历整体
  calendar: studentId => `/data/report/students/${studentId}/calendar`,
  // 修改报告状态
  status: id => `/data/report/status/${id}`,
  // 删除报告(非季报)
  deletereport: dataReportId => `/data/report/${dataReportId}`,
  // 删除报告(季报)
  deleteQuarterlyreport: quarterlyReportId => `/quarterly-report/${quarterlyReportId}`,
  // 检查报告是否完成
  check: id => `/data/report/check/published/${id}`,

  // 选择完类型，时间后，检查是否有数据等
  beforeCreate: '/data/report/check/create',
  // 日报获取上课数据
  dailyallData: studentId => `/data/report/daily/students/${studentId}/skills`,
  // 日报获取行为记录
  dailybehaviorRecord: studentId => `/data/report/daily/students/${studentId}/behavior-records`,
  // 生成日报
  createDaily: '/data/report/daily/generate-report',
  // 查询日报
  inquireDaily: dataReportId => `/data/report/daily/${dataReportId}`,
  // 保存日报
  saveDaily: dataReportId => `/data/report/daily/${dataReportId}`,
  // 重新生成日报获取上课数据
  redailyallData: id => `/data/report/daily/regeneration/${id}/skills`,
  // 重新生成日报获取行为记录
  redailybehaviorRecord: id => `/data/report/daily/regeneration/${id}/behavior-records`,

  // 周报获取上课数据
  weeklyallData: studentId => `/data/report/weekly/students/${studentId}/skills`,
  // 周报获取行为记录
  weeklybehaviorRecord: studentId => `/data/report/weekly/students/${studentId}/behavior-records`,
  // 周报获取家庭作业
  weeklyHomework: studentId => `/data/report/weekly/students/${studentId}/homework`,
  // 生成周报
  createWeekly: '/data/report/weekly/generate-report',
  // 查询周报
  inquireWeekly: dataReportId => `/data/report/weekly/${dataReportId}`,
  // 保存周报
  saveWeekly: dataReportId => `/data/report/weekly/${dataReportId}`,
  // 重新生成周报获取上课数据
  reweeklyallData: id => `/data/report/weekly/regeneration/${id}/skills`,
  // 重新生成周报获取行为记录
  reweeklybehaviorRecord: id => `/data/report/weekly/regeneration/${id}/behavior-records`,
  // 重新生成周报获取家庭作业
  reweeklyHomework: id => `/data/report/weekly/regeneration/${id}/homework`,

  // 月报获取上课数据
  monthlyallData: studentId => `/data/report/monthly/students/${studentId}/skills`,
  // 月报获取家庭作业
  monthlyHomework: studentId => `/data/report/monthly/students/${studentId}/homework`,
  // 生成月报
  createMonthly: '/data/report/monthly/generate-report',
  // 查询月报
  inquireMonthly: dataReportId => `/data/report/monthly/${dataReportId}`,
  // 保存月报
  saveMonthly: dataReportId => `/data/report/monthly/${dataReportId}`,
  // 重新生成月报获取上课数据
  remonthlyallData: id => `/data/report/monthly/regeneration/${id}/skills`,
  // 重新生成月报获取家庭作业
  remonthlyHomework: id => `/data/report/monthly/regeneration/${id}/homework`,

  // 季报获取上课数据
  quarterlyAllData: studentId => `/quarterly-report/students/${studentId}/skills`,
  // 季报获取家庭作业
  quarterlyHomework: studentId => `/quarterly-report/students/${studentId}/homework`,
  // 生成季报
  createQuarterly: studentId => `/quarterly-report/create/student/${studentId}`,
  // 查询季报
  inquireQuarterly: dataReportId => `/quarterly-report/${dataReportId}`,
  // 撤销季报
  revokeQuarterly: dataReportId => `/quarterly-report/revoke/${dataReportId}`,
  // 发布季报
  publishQuarterly: dataReportId => `/quarterly-report/published/${dataReportId}`,
  // 保存季报
  saveQuarterly: dataReportId => `/quarterly-report/update/${dataReportId}`,
  // 重新生成季报
  reQuarterly: id => `/quarterly-report/regenerate/${id}`,

  // 上传干预记录
  createINTERVENTION_RECORDS: '/data/report/intervention-record/generate-report',

  // 导出周报
  exportWeek: id => `/data/report/weekly/export/${id}`,
  // 导出月报
  exportMonth: id => `/data/report/monthly/export/${id}`
}

// 导出周报
export function exportWeek(parameter) {
  return request({
    url: reportApi.exportWeek(parameter),
    method: 'get',
    responseType: 'arraybuffer'
  })
}

// 导出月报
export function exportMonth(parameter) {
  return request({
    url: reportApi.exportMonth(parameter),
    method: 'get',
    responseType: 'arraybuffer'
  })
}

// 日历数据
export function calendar(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.calendar(studentId),
    method: 'get',
    params: parameter
  })
}
// 改变状态
export function status(parameter) {
  const { id } = parameter
  return request({
    url: reportApi.status(id),
    method: 'PATCH',
    data: parameter
  })
}
// 删除报告(季报)
export function deleteQuarterlyreport(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.deleteQuarterlyreport(dataReportId),
    method: 'delete'
  })
}
// 删除报告(非季报)
export function deletereport(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.deletereport(dataReportId),
    method: 'delete'
  })
}
// 检查报告
export function check(parameter) {
  const { id } = parameter
  return request({
    url: reportApi.check(id),
    method: 'get'
  })
}
// 选择类型、时间后，检查数据等
export function beforeCreate(parameter) {
  return request({
    url: reportApi.beforeCreate,
    method: 'get',
    params: parameter
  })
}
// 日报获取数据
export function dailyallData(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.dailyallData(studentId),
    method: 'get',
    params: parameter
  })
}
// 日报获取行为记录
export function dailybehaviorRecord(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.dailybehaviorRecord(studentId),
    method: 'get',
    params: parameter
  })
}
// 生成日报
export function createDaily(parameter) {
  return request({
    url: reportApi.createDaily,
    method: 'post',
    data: parameter
  })
}
// 查询日报
export function inquireDaily(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.inquireDaily(dataReportId),
    method: 'get'
  })
}
// 保存日报
export function saveDaily(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.saveDaily(dataReportId),
    method: 'patch',
    data: parameter
  })
}
// 重新生成日报获取数据
export function redailyallData(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.redailyallData(dataReportId),
    method: 'get'
  })
}
// 重新生成日报获取行为记录
export function redailybehaviorRecord(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.redailybehaviorRecord(dataReportId),
    method: 'get'
  })
}
// 周报获取数据
export function weeklyallData(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.weeklyallData(studentId),
    method: 'get',
    params: parameter
  })
}
// 周报获取行为记录
export function weeklybehaviorRecord(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.weeklybehaviorRecord(studentId),
    method: 'get',
    params: parameter
  })
}
// 周报获取家庭作业
export function weeklyHomework(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.weeklyHomework(studentId),
    method: 'get',
    params: parameter
  })
}
// 生成周报
export function createWeekly(parameter) {
  return request({
    url: reportApi.createWeekly,
    method: 'post',
    data: parameter
  })
}
// 查询周报
export function inquireWeekly(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.inquireWeekly(dataReportId),
    method: 'get'
  })
}
// 保存周报
export function saveWeekly(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.saveWeekly(dataReportId),
    method: 'patch',
    data: parameter
  })
}
// 重新生成周报获取数据
export function reweeklyallData(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.reweeklyallData(dataReportId),
    method: 'get'
  })
}
// 重新生成周报获取行为记录
export function reweeklybehaviorRecord(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.reweeklybehaviorRecord(dataReportId),
    method: 'get'
  })
}
// 重新生成周报获取家庭作业
export function reweeklyHomework(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.reweeklyHomework(dataReportId),
    method: 'get'
  })
}
// 月报获取数据
export function monthlyallData(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.monthlyallData(studentId),
    method: 'get',
    params: parameter
  })
}
// 月报获取家庭作业
export function monthlyHomework(parameter) {
  const { studentId } = parameter
  return request({
    url: reportApi.monthlyHomework(studentId),
    method: 'get',
    params: parameter
  })
}
// 生成月报
export function createMonthly(parameter) {
  return request({
    url: reportApi.createMonthly,
    method: 'post',
    data: parameter
  })
}
// 查询月报
export function inquireMonthly(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.inquireMonthly(dataReportId),
    method: 'get'
  })
}
// 保存月报
export function saveMonthly(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.saveMonthly(dataReportId),
    method: 'patch',
    data: parameter
  })
}
// 重新生成月报获取数据
export function remonthlyallData(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.remonthlyallData(dataReportId),
    method: 'get'
  })
}
// 重新生成月报获取家庭作业
export function remonthlyHomework(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.remonthlyHomework(dataReportId),
    method: 'get'
  })
}
// 上传干预记录
export function createINTERVENTION_RECORDS(parameter) {
  return request({
    url: reportApi.createINTERVENTION_RECORDS,
    method: 'post',
    data: parameter
  })
}

// 生成季报
export function createQuarterly(parameter) {
  const { id } = parameter
  return request({
    url: reportApi.createQuarterly(id),
    method: 'post',
    data: parameter
  })
}
// 生成季报
export function reQuarterly(parameter) {
  return request({
    url: reportApi.reQuarterly(parameter),
    method: 'post'
  })
}
// 查询季报
export function inquireQuarterly(parameter) {
  const { dataReportId } = parameter
  return request({
    url: reportApi.inquireQuarterly(dataReportId),
    method: 'get'
  })
}
// 保存日报
export function saveQuarterly(parameter) {
  const { id } = parameter
  return request({
    url: reportApi.saveQuarterly(id),
    method: 'put',
    data: parameter
  })
}
// 撤销季报
export function revokeQuarterly(parameter) {
  const { id } = parameter
  return request({
    url: reportApi.revokeQuarterly(id),
    method: 'put',
    data: parameter
  })
}
// 发布季报
export function publishQuarterly(parameter) {
  return request({
    url: reportApi.publishQuarterly(parameter),
    method: 'put'
  })
}
