<template>
  <div class="pdf-container">
    <div id="quarter-report-1">
      <page-cover :name="dataSource.studentName" :start="dataSource.startDate" :end="dataSource.endDate"></page-cover>
      <page-first :dataSource="dataSource"></page-first>
      <page-second :dataSource="dataSource"></page-second>
      <page-third :dataSource="dataSource"></page-third>
      <page-materials
        v-for="(fast, index) in quarterlyReportMaterialList"
        :key="`fast_${index}`"
        :list="fast"
      ></page-materials>
    </div>
    <div id="quarter-report-2">
      <page-fast
        v-for="(fast1, index1) in fastStudyMaterialsList"
        :fastItem="fast1"
        :key="fast1.skillCode + index1"
        :index="index1"
        :dataSource="dataSource"
      ></page-fast>
      <!-- </div>
    <div id="quarter-report-3"> -->
      <page-rounds
        v-for="(roundInfo, index) in dataSource.quarterlyReportShortTermGoalsList"
        :key="roundInfo.skillCode"
        :index="index + 1"
        :dataSource="roundInfo"
      ></page-rounds>
      <page-end :content="dataSource.appraisal" :sign="dataSource.institutionalInformation"></page-end>
    </div>
  </div>
</template>

<script>
import { PageCover, PageFirst, PageSecond, PageThird, PageMaterials, PageFast, PageRounds, PageEnd } from './components'
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'PdfPreview',
  components: {
    PageCover,
    PageFirst,
    PageSecond,
    PageThird,
    PageMaterials,
    PageFast,
    PageRounds,
    PageEnd,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fastStudyMaterialsList() {
      const { fastStudyMaterials } = this.dataSource
      const list = []
      for (const item of fastStudyMaterials) {
        const key = list.findIndex((item1) => {
          return item1.skillName === item.skillName
        })
        if (key > -1) {
          list[key].list.push(item.materialName)
        } else {
          list.push({ ...item, ...{ list: [item.materialName] } })
        }
      }
      return list
    },
    quarterlyReportMaterialList() {
      const { quarterlyReportFieldList } = this.dataSource
      const list = []
      quarterlyReportFieldList.forEach((field) => {
        field.quarterlyReportMaterialList.forEach((material) => {
          list.push(material)
        })
      })
      const ret = []
      let page = []
      const pages = Math.ceil(list.length / 15)
      for (let i = 0; i < pages; i++) {
        page = list.slice(i * 15, (i + 1) * 15)
        ret.push(page)
      }

      return ret
    },
  },
  mounted() {
    this.$emit('exportPdfButton', false)
  },

  methods: {
    ...mapActions(['changeSpinning']),
    async exportPDF() {
      const canvas1 = await this.htmlCanvas('#quarter-report-1')
      const canvas2 = await this.htmlCanvas('#quarter-report-2')
      const PDF = new JsPDF('', 'pt', 'a4')

      const pdf1 = await this.savePDF(canvas1, PDF)
      await this.savePDF(canvas2, pdf1)

      // 导出
      PDF.save(`${this.dataSource.studentName}小朋友教学进展季报-${moment().format('YYYY.MM.DD')}` + '.pdf')
      this.$emit('exportPdfButton', false)
      this.changeSpinning(false)
    },

    htmlCanvas(dom) {
      return new Promise((resolve, reject) => {
        html2Canvas(document.querySelector(dom), {
          useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
          allowTaint: true, // 允许跨域
        })
          .then((canvas) => {
            resolve(canvas)
          })
          .catch(() => {
            reject(false)
          })
      })
    },

    savePDF(canvas, PDF) {
      return new Promise((resolve, reject) => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 根据A4纸的大小，计算出dom相应比例的尺寸
        const pageHeight = (contentWidth / 592.28) * 841.89
        console.log('pageHeight', pageHeight)
        let leftHeight = contentHeight
        let position = 0
        const imgWidth = 595.28
        // 根据a4比例计算出需要分割的实际dom位置
        const imgHeight = (592.28 / contentWidth) * contentHeight
        console.log('imgHeight', imgHeight)

        // canvas绘图生成image数据，1.0是质量参数
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        // a4大小
        // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)

            leftHeight -= pageHeight
            position -= 841.89
            // if (leftHeight > 0) {
            PDF.addPage()
            // }
          }
        }
        resolve(PDF)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.pdf-container {
  margin-left: auto;
  margin-right: auto;
  width: 1080px;
  background: #fff;
}
</style>