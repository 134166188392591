<template>
  <div style="margin-bottom: 64px">
    <div
      class="title"
      :style="{ background: COLORS_MAP[dataSource.fieldName] && COLORS_MAP[dataSource.fieldName].primary }"
    >
      <div class="cn">{{ dataSource.fieldName }}</div>
      <div v-if="dataSource.fieldNameEn" class="en">({{ dataSource.fieldNameEn }})</div>
    </div>
    <div
      class="content"
      :style="{ background: COLORS_MAP[dataSource.fieldName] && COLORS_MAP[dataSource.fieldName].secondary }"
    >
      <pre class="pre" style="font-size: 18px">{{ dataSource.introduce }}</pre>
    </div>
    <sub-title :field="dataSource.fieldName">领域得分情况</sub-title>
    <score-table :dataSource="dataSource"></score-table>
    <!-- 饼图 -->
    <div :id="'chart-wrapper-pie' + dataSource.fieldId" class="chart">
      <charts-pie
        :data="dataSource"
        :chartid="dataSource.fieldId"
        ref="ChartPie"
        :idName="'pie' + dataSource.fieldId"
      ></charts-pie>
    </div>

    <sub-title :field="dataSource.fieldName">领域计分总表</sub-title>
    <!-- 图表 -->
    <!-- {{ dataSource.evaluationFieldVOList }} -->
    <div style="padding: 0 0 24px 0; min-height: 100%; display: flex; position: relative; overflow-x: auto">
      <evaluate-table
        ref="evaluateTable"
        :id="fieldId"
        :data="dataSource"
        @example="() => {}"
        :is-report="true"
      ></evaluate-table>
    </div>
    <field-detail
      v-for="fieldDetail in dataSource.evaluationReportFieldDetailsList"
      :key="fieldDetail.fieldId"
      :field="dataSource.fieldName"
      :dataSource="fieldDetail"
    />
    <template v-if="dataSource.skillInterpret && dataSource.isDisplaySkillInterpret">
      <sub-title :field="dataSource.fieldName">领域技能解读</sub-title>
      <block-content bordered style="padding: 24px">
        <pre class="pre">{{ dataSource.skillInterpret }}</pre>
      </block-content>
    </template>
  </div>
</template>

<script>
import { COLORS_MAP } from './colors'
import { ScoreTable, Content as BlockContent, chartsPie } from '../../components'
import SubTitle from './SubTitle'
import FieldDetail from './FieldDetail'
import EvaluateTable from '@/views/home-school-service/detail/evaluation/components/Table'

export default {
  components: {
    SubTitle,
    ScoreTable,
    BlockContent,
    FieldDetail,
    chartsPie,
    EvaluateTable
  },
  props: {
    dataSource: {
      type: Object,
      default() {
        return {}
      }
    },
    fieldId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      COLORS_MAP
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 24px;
}
.cn {
  font-size: 20px;
}
.en {
  font-size: 16px;
}
.content {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  padding: 24px 16px;
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
}
</style>