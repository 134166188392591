<!-- 周报步骤 -->
<template>
  <a-card :body-style="{ padding: '26px 24px 24px', minHeight: '82vh' }">
    <!-- 步骤条 -->
    <a-steps style="padding:0 6px 42px" :current="nowStep">
      <a-step title="选择训练技能" />
      <a-step title="选择训练素材" />
      <a-step title="选择达标素材" />
      <a-step title="选择新增素材" />
      <a-step title="选择行为记录" />
      <a-step title="选择家庭作业" />
    </a-steps>
    <!-- 加载与无数据 -->
    <a-spin :spinning="spinning">
      <!-- 第一步 -->
      <div v-if="nowStep === 0" class="steps">
        <div class="title">本周训练技能</div>
        <div class="subtitle">请选择需在周报中呈现的训练技能</div>

        <a-checkbox-group style="width:100%" v-model="stepZeroform">
          <div v-for="item in stepZerosource" :key="item.id" class="skill-item">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div class="item-goal">技能目标：{{ item.goal }}</div>
            <div v-for="phase in item.dailyReportPhaseList" :key="phase.phaseId" class="item-phase">
              <a-checkbox class="item-phase-wrap" :value="phase.phaseId">
                <div class="name">第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</div>
                <div class="goal">阶段目标：{{ phase.goal }}</div>
              </a-checkbox>
            </div>
          </div>
        </a-checkbox-group>
      </div>

      <!-- 第二步 -->
      <div v-if="nowStep === 1" class="steps">
        <div class="title">本周训练素材</div>
        <div class="subtitle">请选择需在周报中呈现的训练素材</div>

        <a-checkbox-group style="width:100%" v-model="stepOneform">
          <div v-for="item in stepOnesource" :key="item.id" class="phase-item">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div v-for="itemphase in item.dailyReportPhaseList" :key="itemphase.phaseId" class="item-mat">
              <div class="mat-title">第{{ convertToChineseNumber(itemphase.phaseNumber) }}阶段训练素材：</div>
              <a-checkbox
                :value="phasemat.name"
                v-for="phasemat in itemphase.dailyReportMaterialList"
                :key="phasemat.name"
                class="mat-name"
              >
                {{ phasemat.name }}({{
                  phasemat.numberOfTrainingSessions ? `${phasemat.numberOfTrainingSessions}次` : '未练习'
                }})
              </a-checkbox>
              <span v-if="!itemphase.dailyReportMaterialList.length" class="mat-name">无训练素材</span>
            </div>
          </div>
        </a-checkbox-group>
      </div>

      <!-- 第三步 -->
      <div v-if="nowStep === 2" class="steps">
        <div class="title">本周已达标素材</div>
        <div class="subtitle">请选择需在周报中呈现的已达标素材</div>

        <a-checkbox-group style="width:100%" v-model="stepTwoform">
          <div v-for="item in stepTwosource" :key="item.id" class="phase-item">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div v-for="itemphase in item.dailyReportPhaseList" :key="itemphase.phaseId" class="item-mat">
              <div class="mat-title">第{{ convertToChineseNumber(itemphase.phaseNumber) }}阶段训练素材：</div>
              <span v-if="!itemphase.dailyReportMaterialList.length" class="mat-name">无达标素材</span>
              <a-checkbox
                :value="phasemat.name"
                v-for="phasemat in itemphase.dailyReportMaterialList"
                :key="phasemat.name"
                class="mat-name"
              >
                {{ phasemat.name }}({{
                  phasemat.numberOfTrainingSessions ? `${phasemat.numberOfTrainingSessions}次` : '未练习'
                }})
              </a-checkbox>
            </div>
          </div>
        </a-checkbox-group>
      </div>

      <!-- 第四步 -->
      <div v-if="nowStep === 3" class="steps">
        <div class="title">明日新增素材</div>
        <div class="subtitle">请选择需在周报中呈现的新训练素材</div>

        <a-checkbox-group style="width:100%" v-model="stepThreeform">
          <div v-for="item in stepThreesource" :key="item.id" class="phase-item">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div v-for="itemphase in item.dailyReportPhaseList" :key="itemphase.phaseId" class="item-mat">
              <div class="mat-title">第{{ convertToChineseNumber(itemphase.phaseNumber) }}阶段训练素材：</div>
              <a-checkbox
                :value="phasemat.name"
                v-for="phasemat in itemphase.dailyReportMaterialList"
                :key="phasemat.name"
                class="mat-name"
              >
                {{ phasemat.name }}({{
                  phasemat.numberOfTrainingSessions ? `${phasemat.numberOfTrainingSessions}次` : '未练习'
                }})
              </a-checkbox>
              <span v-if="!itemphase.dailyReportMaterialList.length" class="mat-name">计划中暂无新增素材</span>
            </div>
          </div>
        </a-checkbox-group>
      </div>

      <!-- 第五步 -->
      <div v-if="nowStep === 4" class="steps">
        <div class="title">本周行为记录</div>
        <div class="subtitle">请选择需在周报中呈现的本周行为记录的情况</div>

        <a-checkbox-group style="width:100%" v-model="stepFourform">
          <div v-for="item in stepFoursource" :key="item.id" class="action-item">
            <a-checkbox :value="item.id" class="item-opt">
              {{ item.name }}
            </a-checkbox>
          </div>
        </a-checkbox-group>
      </div>

      <!-- 第六步 -->
      <div v-if="nowStep === 5" class="steps">
        <p class="title">本周已练习作业</p>
        <p class="subtitle">请选择需在周报中呈现的家庭作业</p>

        <div v-for="item in stepFivesource" :key="item.id" class="homework-item">
          <a-checkbox
            class="item-title"
            @change="onChange"
            :checked="isChecked({ datatype: 'skill', datavalue: item.id })"
            :value="{ datatype: 'skill', datavalue: item.id }"
          >
            <div class="des">{{ item.name }}</div>
            <div class="tag">{{ item.fieldName }}</div>
          </a-checkbox>

          <div class="item-mat">
            <span class="mat-title">练习素材：</span>
            <a-checkbox
              v-for="phasemat in item.dataReportHomeworkMaterialList"
              :key="phasemat.name"
              :checked="isChecked({ datatype: 'mat', datavalue: phasemat.name })"
              :value="{ datatype: 'mat', datavalue: phasemat.name, skillid: item.id }"
              @change="onChange"
              class="mat-name"
            >
              {{ phasemat.name }}({{
                phasemat.numberOfTrainingSessions ? `${phasemat.numberOfTrainingSessions}次` : '未练习'
              }})
            </a-checkbox>
            <span v-if="!item.dataReportHomeworkMaterialList.length" class="mat-name">无练习素材</span>
          </div>
        </div>
      </div>
      <!-- 无数据 -->
      <a-empty v-if="empty" :description="description" style="margin-top: 40px" />
    </a-spin>
    <!-- 底部按钮 -->
    <div class="footer-btn-wrap">
      <a-button @click="cancelHandle" type="primary" shape="round" ghost style="width:160px;height:40px;margin:0 32px"
        >取消</a-button
      >
      <a-button
        @click="previousHandle"
        v-show="nowStep !== 0"
        type="primary"
        shape="round"
        ghost
        style="width:160px;height:40px;margin:0 32px"
        >上一步</a-button
      >
      <a-button
        @click="nextHandle"
        v-show="nowStep !== 5"
        type="primary"
        shape="round"
        :loading="spinning"
        style="width:160px;height:40px;margin:0 32px"
        >下一步</a-button
      >
      <a-button
        @click="createHandle"
        v-if="nowStep === 5"
        type="primary"
        shape="round"
        :loading="spinning"
        style="width:160px;height:40px;margin:0 32px"
        >生成报告</a-button
      >
    </div>
  </a-card>
</template>
<script>
import * as Api from '@/api/datareport'
import { convertToChineseNumber } from '@/utils/util'
import { cloneDeep } from 'lodash'

export default {
  data() {
    return {
      stepFormDecoding: ['stepZeroform', 'stepOneform', 'stepTwoform', 'stepThreeform', 'stepFourform'],
      stepSourceDecoding: [
        'stepZerosource',
        'stepOnesource',
        'stepTwosource',
        'stepThreesource',
        'stepFoursource',
        'stepFivesource'
      ],
      nowStep: 0, // 当前第几步,从0开始
      spinning: true, // 数据加载中
      empty: false, // 是否显示无数据
      description: '', // 无数据的提示
      eventParam: {}, // 传递过来的事件参数
      constStep: 5, // 当前选择总共有几步，从0开始
      maxDoneStep: -1, // 本次步骤选择中保存到第几步
      isFirstDone: false, // 是否完整走过所有步骤

      isUseHistory: false, // 1-4步是否复用上一次的数据
      isbehaviorUseHistory: false, // 行为记录是否复用上一次的数据
      ishomeworkUseHistory: false, // 家庭作业是否复用上一次的数据
      allDataSource: {}, // 全部上课数据
      stepZerosource: [], // 第一步原始数据
      stepZeroform: [], // 第一步选中
      stepOnesource: [], // 第二步原始数据
      stepOneform: [], // 第二步选中
      stepTwosource: [], // 第三步原始数据
      stepTwoform: [], // 第三步选中
      stepThreesource: [], // 第四步原始数据
      stepThreeform: [], // 第四步选中
      stepFoursource: [], // 第五步原始数据
      stepFourform: [], // 第五步选中
      stepFivesource: [], // 第六步原始数据
      stepFiveSkillform: [], // 第六步选中的技能包
      stepFiveMaterialform: [] // 第六步选中的素材
    }
  },
  created() {},
  watch: {},
  methods: {
    convertToChineseNumber,
    // 家庭作业双重选择记录，包含技能包、素材
    // 是否选中
    isChecked(obj) {
      if (obj.datatype === 'skill') {
        return this.stepFiveSkillform.includes(obj.datavalue)
      }
      if (obj.datatype === 'mat') {
        return this.stepFiveMaterialform.includes(obj.datavalue)
      }
    },
    // 改变选中状态
    onChange(e) {
      const type = e.target.value.datatype
      if (type === 'skill') {
        this.skillChecked(e)
      }
      if (type === 'mat') {
        this.matChecked(e)
      }
    },
    // 技能包id选中
    skillChecked(e) {
      // 选中技能就选中所有素材、取消就取消所有素材
      const { stepFivesource } = this
      const value = e.target.value.datavalue
      const skillform = cloneDeep(this.stepFiveSkillform)
      const matform = cloneDeep(this.stepFiveMaterialform)
      // 选中技能包
      if (e.target.checked) {
        this.stepFiveSkillform.push(value)
        stepFivesource.forEach(el => {
          if (el.id === value) {
            el.dataReportHomeworkMaterialList.forEach(mat => {
              matform.push(mat.name)
            })
          }
        })
        this.stepFiveMaterialform = [...new Set(matform)]
      } else {
        // 取消技能包
        let temp = []
        this.stepFiveSkillform = skillform.filter(id => id !== value)
        stepFivesource.forEach(el => {
          if (el.id === value) {
            temp = el.dataReportHomeworkMaterialList.map(mat => {
              return mat.name
            })
          }
        })
        this.stepFiveMaterialform = matform.filter(name => !temp.includes(name))
      }
    },
    // 素材选中
    matChecked(e) {
      // 选中一个素材就选中技能、取消所有素材就取消技能
      const { stepFivesource } = this
      const value = e.target.value.datavalue // 素材值
      const skillid = e.target.value.skillid // 技能包id
      const skillform = cloneDeep(this.stepFiveSkillform)
      const matform = cloneDeep(this.stepFiveMaterialform)
      // 选中素材
      if (e.target.checked) {
        this.stepFiveMaterialform.push(value)

        skillform.push(skillid)
        this.stepFiveSkillform = [...new Set(skillform)]
      } else {
        // 取消素材
        this.stepFiveMaterialform = matform.filter(name => name !== value)
        // 找到素材对应的技能包，检查技能包下的素材，是否选中
        const skill = stepFivesource.find(el => el.id === skillid)
        if (skill.dataReportHomeworkMaterialList.every(mat => !this.stepFiveMaterialform.includes(mat.name))) {
          this.stepFiveSkillform = skillform.filter(id => id !== skillid)
        }
      }
    },
    // 进入
    initData(e) {
      if (e.type === 'newadd') {
        this.newAddRequest(e)
      }
      if (e.type === 'regenerate') {
        this.regenerateRequest(e)
      }
    },
    // 新增报告获取数据
    newAddRequest(e) {
      // 保存基础参数，包含startdate,enddate,studentId等
      this.eventParam = e
      // 获取所有的上课数据
      Api.weeklyallData(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.allDataSource = data.attendClassDailyReportTrainingSkillList
          this.initShowData()
        }
      })
      // 获取所有的行为记录
      Api.weeklybehaviorRecord(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.stepFoursource = data
        }
      })
      // 获取所有的家庭作业
      Api.weeklyHomework(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.stepFivesource = data
        }
      })
    },
    // 重新生成获取数据
    regenerateRequest(e) {
      // 保存基础参数，包含startdate,enddate,studentId等
      this.eventParam = e
      // 获取所有的上课数据
      Api.reweeklyallData(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.isUseHistory = true
        } else {
          this.isUseHistory = false
          this.$notification.warning({
            message: '提示',
            description: '当前报告周期已产生新的训练数据，请重新选择'
          })
        }
        this.allDataSource = data.attendClassDailyReportTrainingSkillList
        this.initShowData()
      })
      // 获取所有的行为记录
      Api.reweeklybehaviorRecord(e.param).then(res => {
        const { code, data } = res
        this.stepFoursource = data
        if (code === 0) {
          this.isbehaviorUseHistory = true
        } else {
          this.isbehaviorUseHistory = false
        }
      })
      // 获取所有的家庭作业
      Api.reweeklyHomework(e.param).then(res => {
        const { code, data } = res
        this.stepFivesource = data
        if (code === 0) {
          this.ishomeworkUseHistory = true
        } else {
          this.ishomeworkUseHistory = false
        }
      })
    },
    // 每一步都是相同的逻辑，默认记录当前步骤的源数据和选中选项，先获取上一步/下一步的基础选项，再按照规则生成默认选中。
    // 项目需求是1、进入步骤选择后，新增报告默认选择全部选项，重新生成可能使用上一次报告的选项
    // 2、当本轮全部步骤都选择完毕，切换上/下一步时，使用本轮选择的选项
    //
    initShowData() {
      // 默认加载中，关闭加载中在生成默认选中选项后
      this.spinning = true
      this.initBaseOption()
      this.checkRoundAndSelect()
    },
    // 生成基础选项
    initBaseOption() {
      const { allDataSource, nowStep, stepSourceDecoding } = this
      // 第一步
      if (nowStep === 0) {
        this[stepSourceDecoding[nowStep]] = allDataSource
      }
      // 第二步
      if (nowStep === 1) {
        const skills = this.phaseIsSelected()
        // 筛选训练的素材
        skills.forEach(el => {
          el.dailyReportPhaseList.forEach(phase => {
            phase.dailyReportMaterialList = phase.dailyReportMaterialList.filter(mat => mat.isTraining)
          })
        })
        this[stepSourceDecoding[nowStep]] = skills
      }
      // 第三步
      if (nowStep === 2) {
        const skills = this.phaseIsSelected()
        // 筛选达标的素材
        skills.forEach(el => {
          el.dailyReportPhaseList.forEach(phase => {
            phase.dailyReportMaterialList = phase.dailyReportMaterialList.filter(
              mat => mat.isTraining && mat.isAchievement
            )
          })
        })
        this[stepSourceDecoding[nowStep]] = skills
      }
      // 第四步
      if (nowStep === 3) {
        // 根据第一步筛选的阶段
        const skills = this.phaseIsSelected()
        // 新增素材是isTraining、isAchievement都为false的素材
        skills.forEach(el => {
          el.dailyReportPhaseList.forEach(phase => {
            phase.dailyReportMaterialList = phase.dailyReportMaterialList.filter(
              mat => !mat.isTraining && !mat.isAchievement
            )
          })
        })

        this[stepSourceDecoding[nowStep]] = skills
      }
      // 第五步
      if (nowStep === 4) {
        if (!this[stepSourceDecoding[nowStep]].length) {
          this.empty = true
          this.description = '本周暂无行为记录'
        }
      }
      // 第六步
      if (nowStep === 5) {
        if (!this[stepSourceDecoding[nowStep]].length) {
          this.empty = true
          this.description = '本周暂无家庭作业'
        }
      }
    },
    // 筛选第一步选中的技能包、阶段
    phaseIsSelected() {
      const stepZerosourceCopy = cloneDeep(this.stepZerosource)
      const skills = stepZerosourceCopy.filter(el => {
        el.dailyReportPhaseList = el.dailyReportPhaseList.filter(phase => {
          if (this.stepZeroform.includes(phase.phaseId)) {
            return phase
          }
        })
        if (el.dailyReportPhaseList.length !== 0) {
          return el
        }
      })
      return skills
    },
    // 检查轮次,根据轮次、事件类型,生成默认选中
    checkRoundAndSelect() {
      const { isFirstDone, maxDoneStep, nowStep, eventParam } = this
      // 检查轮次，是否全部步骤都选择过并进行保存
      if (!isFirstDone) {
        // 步骤是否未操作过
        if (nowStep > maxDoneStep) {
          // 新增报告默认全选
          if (eventParam.type === 'newadd') {
            this.initAllSelect().then(() => {
              this.spinning = false
              this.saveMaxDoneStep()
            })
          }
          // 重新生成报告判断数据变化，决定默认全选还是复用上一次的
          if (eventParam.type === 'regenerate') {
            this.checkRegenerateNowstep()
          }
        } else if (nowStep <= maxDoneStep) {
          this.initNowSelect()
        }
      } else {
        this.initNowSelect()
      }
    },
    // 重新生成的默认选中判断逻辑
    checkRegenerateNowstep() {
      const { nowStep, isUseHistory, isbehaviorUseHistory, ishomeworkUseHistory } = this
      if (nowStep >= 0 && nowStep < 4) {
        if (isUseHistory) {
          this.initPreSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        } else {
          this.initAllSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        }
      }

      if (nowStep === 4) {
        if (isbehaviorUseHistory) {
          this.initPreSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        } else {
          this.$notification.warning({
            message: '提示',
            description: '当前报告周期已产生新的训练数据，请重新选择'
          })
          this.initAllSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        }
      }
      if (nowStep === 5) {
        if (ishomeworkUseHistory) {
          this.initPreSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        } else {
          this.$notification.warning({
            message: '提示',
            description: '当前报告周期已产生新的训练数据，请重新选择'
          })
          this.initAllSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        }
      }
    },
    // 默认全选
    initAllSelect() {
      const { nowStep, empty, stepSourceDecoding, stepFormDecoding } = this
      return new Promise(resolve => {
        // 第一步
        if (nowStep === 0) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            el.dailyReportPhaseList.forEach(phase => {
              tempArr.push(phase.phaseId)
            })
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第二步、// 第三步、// 第四步
        if (nowStep === 1 || nowStep === 2 || nowStep === 3) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            el.dailyReportPhaseList.forEach(phase => {
              if (phase.dailyReportMaterialList.length) {
                phase.dailyReportMaterialList.forEach(mat => {
                  tempArr.push(mat.name)
                })
              }
            })
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第五步
        if (nowStep === 4) {
          if (!empty) {
            const tempArr = []
            this[stepSourceDecoding[nowStep]].forEach(el => {
              tempArr.push(el.id)
            })
            this[stepFormDecoding[nowStep]] = tempArr
            resolve()
          } else {
            resolve()
          }
        }
        // 第六步
        if (nowStep === 5) {
          if (!empty) {
            const tempArr = []
            const matArr = []
            this.stepFivesource.forEach(el => {
              tempArr.push(el.id)
              el.dataReportHomeworkMaterialList.forEach(mat => {
                matArr.push(mat.name)
              })
            })

            this.stepFiveSkillform = tempArr
            this.stepFiveMaterialform = matArr
            resolve()
          } else {
            resolve()
          }
        }
      })
    },
    // 使用上一次报告的选中
    initPreSelect() {
      const { nowStep, empty, stepSourceDecoding, stepFormDecoding } = this
      return new Promise(resolve => {
        // 第一步
        if (nowStep === 0) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            if (el.selectedStep.includes(nowStep + 1)) {
              el.dailyReportPhaseList.forEach(phase => {
                if (phase.selectedStep.includes(nowStep + 1)) {
                  tempArr.push(phase.phaseId)
                }
              })
            }
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第二步、// 第三步、// 第四步
        if (nowStep === 1 || nowStep === 2 || nowStep === 3) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            el.dailyReportPhaseList.forEach(phase => {
              if (phase.selectedStep.includes(nowStep + 1)) {
                if (phase.dailyReportMaterialList.length) {
                  phase.dailyReportMaterialList.forEach(mat => {
                    if (mat.selectedStep.includes(nowStep + 1)) {
                      tempArr.push(mat.name)
                    }
                  })
                }
              }
            })
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第五步
        if (nowStep === 4) {
          if (!empty) {
            const tempArr = []
            this[stepSourceDecoding[nowStep]].forEach(el => {
              if (el.isSelected) {
                tempArr.push(el.id)
              }
            })
            this[stepFormDecoding[nowStep]] = tempArr
            resolve()
          } else {
            resolve()
          }
        }
        // 第六步
        if (nowStep === 5) {
          if (!empty) {
            const tempArr = []
            const matArr = []
            this.stepFivesource.forEach(el => {
              if (el.isSelected) {
                tempArr.push(el.id)
                el.dataReportHomeworkMaterialList.forEach(mat => {
                  if (mat.isSelected) {
                    matArr.push(mat.name)
                  }
                })
              }
            })

            this.stepFiveSkillform = tempArr
            this.stepFiveMaterialform = matArr
            resolve()
          } else {
            resolve()
          }
        }
      })
    },
    // 使用本轮次的选中
    initNowSelect() {
      const { nowStep, stepSourceDecoding, stepFormDecoding } = this
      // 第二步、// 第三步、// 第四步
      if (nowStep === 1 || nowStep === 2 || nowStep === 3) {
        const nowMat = []
        const preSelectedForm = cloneDeep(this[stepFormDecoding[nowStep]])
        this[stepSourceDecoding[nowStep]].forEach(el => {
          el.dailyReportPhaseList.forEach(phase => {
            if (phase.dailyReportMaterialList.length) {
              phase.dailyReportMaterialList.forEach(mat => {
                nowMat.push(mat.name)
              })
            }
          })
        })
        const tempArr = nowMat.filter(name => {
          if (preSelectedForm.includes(name)) {
            return name
          }
        })
        this[stepFormDecoding[nowStep]] = tempArr
      }
      this.spinning = false
    },
    // 记录最大完成步数
    saveMaxDoneStep() {
      const { isFirstDone, maxDoneStep, constStep, nowStep } = this
      if (!isFirstDone) {
        if (nowStep === constStep) {
          this.isFirstDone = true
          this.maxDoneStep = nowStep
        } else if (nowStep > maxDoneStep) {
          this.maxDoneStep = nowStep
        }
      }
    },
    // 取消
    cancelHandle() {
      const event = {
        type: 'cancel',
        toMenuName: 'calendar',
        toSubMenuName: '',
        param: {}
      }
      this.$emit('event', event)
      this.setAllDataDefault()
    },
    // 把数据恢复为初始值
    setAllDataDefault() {
      this.nowStep = 0
      this.spinning = true
      this.empty = false
      this.description = ''
      this.eventParam = {}
      this.constStep = 5
      this.maxDoneStep = -1
      this.isFirstDone = false

      this.isUseHistory = false
      this.isbehaviorUseHistory = false
      this.ishomeworkUseHistory = false
      this.allDataSource = {}
      this.stepZerosource = []
      this.stepZeroform = []
      this.stepOnesource = []
      this.stepOneform = []
      this.stepTwosource = []
      this.stepTwoform = []
      this.stepThreesource = []
      this.stepThreeform = []
      this.stepFoursource = []
      this.stepFourform = []
      this.stepFivesource = []
      this.stepFiveSkillform = []
      this.stepFiveMaterialform = []
    },
    // 上一步
    previousHandle() {
      if (this.nowStep > 0) {
        // 清空无数据状态
        this.empty = false
        this.description = ''
        this.nowStep -= 1
        this.preIsEmpty()
      }
    },
    // 返回上一步时，检查是否有数据
    preIsEmpty() {
      const { nowStep } = this
      // 第五步
      if (nowStep === 4) {
        if (!this.stepFoursource.length) {
          this.empty = true
          this.description = '本周暂无行为记录'
        }
      }
    },
    // 下一步
    nextHandle() {
      this.spinning = true
      this.checkStepIsAllow()
        .then(() => {
          // 清空无数据状态
          this.empty = false
          this.description = ''
          this.nowStep += 1
          this.initShowData()
        })
        .finally(() => {
          this.spinning = false
        })
    },
    // 检查第一步、第二步的选择是否符合规则
    checkStepIsAllow() {
      const { nowStep } = this
      return new Promise((resolve, reject) => {
        // 检查第一步
        if (nowStep === 0) {
          if (this.stepZeroform.length) {
            resolve()
          } else {
            this.$notification['error']({ message: '提示', description: '最少选择一个阶段' })
            reject()
          }
        }
        // 检查第二步
        if (nowStep === 1) {
          const isSelectOne = []
          const skills = cloneDeep(this.stepOnesource)
          skills.forEach(el => {
            el.dailyReportPhaseList.forEach(phase => {
              isSelectOne.push(
                JSON.stringify(phase.dailyReportMaterialList.some(mat => this.stepOneform.includes(mat.name)))
              )
            })
          })
          if (isSelectOne.includes('false')) {
            this.$notification['error']({ message: '提示', description: '每个阶段至少勾选一个素材' })
            reject()
          } else {
            resolve()
          }
        }
        // 检查第六步
        if (nowStep === 5) {
          const isSelectOne = []
          const skills = cloneDeep(this.stepFivesource)
          skills.forEach(el => {
            if (this.stepFiveSkillform.includes(el.id)) {
              if (el.dataReportHomeworkMaterialList.length) {
                isSelectOne.push(
                  JSON.stringify(
                    el.dataReportHomeworkMaterialList.some(mat => this.stepFiveMaterialform.includes(mat.name))
                  )
                )
              }
            }
          })
          if (isSelectOne.includes('false')) {
            this.$notification['error']({ message: '提示', description: '选中的技能至少需要勾选一个素材' })
            reject()
          } else {
            resolve()
          }
        }
        // 不检查
        if (nowStep === 2 || nowStep === 3 || nowStep === 4) {
          resolve()
        }
      })
    },
    // 生成报告
    createHandle() {
      this.spinning = true
      this.checkStepIsAllow()
        .then(() => {
          this.creatReport()
        })
        .catch(() => {
          this.spinning = false
        })
    },
    // 创建报告参数
    creatReport() {
      const {
        eventParam,
        eventParam: { param }
      } = this
      // 生成每一步的数据
      const firstStep = this.generateParam(0)
      const secondStep = this.generateParam(1)
      const thirdStep = this.generateParam(2)
      const fourthStep = this.generateParam(3)
      const fifthStep = this.generateParam(4)
      const sixthStep = this.generateParam(5)

      // 请求参数
      const createReport = {
        firstStep,
        secondStep,
        thirdStep,
        fourthStep,
        fifthStep,
        sixthStep,
        studentId: param.studentId,
        startTime: param.startDate,
        endTime: param.endDate
      }
      if (eventParam.type === 'regenerate') {
        // 重新生成，携带旧的报告id
        createReport.dataReportId = param.dataReportId
      }
      // 生成报告
      Api.createWeekly(createReport)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            // 携带报告id，打开报告页面
            const event = {
              type: 'edit',
              subType: 'showcalendar',
              toMenuName: 'report',
              toSubMenuName: eventParam.toSubMenuName,
              param: {
                dataReportId: data
              }
            }
            this.$emit('event', event)
            this.setAllDataDefault()
          }
        })
        .finally(() => {
          this.spinning = false
        })
    },
    // 每一步的参数
    generateParam(step) {
      // 获取对应的源数据、选中数组
      const { stepSourceDecoding, stepFormDecoding } = this
      const stepSourceClone = cloneDeep(this[stepSourceDecoding[step]])
      const stepFormClone = cloneDeep(this[stepFormDecoding[step]])
      let result = []
      if (step === 0 || step === 1 || step === 2 || step === 3) {
        // 按照接口参数格式生成新的数组
        const paramArr = []
        stepSourceClone.forEach(skill => {
          // 技能包层级的属性
          const newSkill = {
            teachingType: 'ATTEND_CLASS',
            skillId: skill.id,
            dailyReportSkillPhaseResultList: []
          }
          skill.dailyReportPhaseList.forEach((phase, phaseix) => {
            // 阶段层级的属性
            newSkill.dailyReportSkillPhaseResultList[phaseix] = {
              skillPhaseId: phase.phaseId,
              dataReportSkillPhaseMaterialList: []
            }
            phase.dailyReportMaterialList.forEach((mat, matix) => {
              // 素材层级的属性
              newSkill.dailyReportSkillPhaseResultList[phaseix].dataReportSkillPhaseMaterialList[matix] = {
                material: mat.name,
                trainingCount: mat.numberOfTrainingSessions
              }
            })
          })
          paramArr.push(newSkill)
        })
        // 根据素材选中，保留阶段、技能包
        paramArr.forEach(skill => {
          skill.dailyReportSkillPhaseResultList.forEach(phase => {
            phase.dataReportSkillPhaseMaterialList = phase.dataReportSkillPhaseMaterialList.filter(mat =>
              stepFormClone.includes(mat.material)
            )
          })
        })
        if (step === 0) {
          paramArr.forEach(skill => {
            skill.dailyReportSkillPhaseResultList = skill.dailyReportSkillPhaseResultList.filter(phase =>
              stepFormClone.includes(phase.skillPhaseId)
            )
          })
        } else {
          paramArr.forEach(skill => {
            skill.dailyReportSkillPhaseResultList = skill.dailyReportSkillPhaseResultList.filter(
              phase => phase.dataReportSkillPhaseMaterialList.length !== 0
            )
          })
        }
        // 生成最终结果
        result = paramArr.filter(skill => skill.dailyReportSkillPhaseResultList.length !== 0)
      }
      // 第五步
      if (step === 4) {
        const temp = []
        // 根据选中的行为记录id，修改数组结构
        stepSourceClone.forEach(el => {
          if (stepFormClone.includes(el.id)) {
            el.classBehaviorRecordIdSet.forEach(record => {
              temp.push({
                behaviorRecordClassId: record
              })
            })
          }
        })

        result = temp
      }
      // 第六步
      if (step === 5) {
        // 按照接口参数格式生成新的数组
        const paramArr = []
        stepSourceClone.forEach(skill => {
          // 技能包层级的属性
          const newSkill = {
            skillId: skill.id,
            dataReportHomeworkSkillPhaseMaterialResultList: []
          }
          skill.dataReportHomeworkMaterialList.forEach((mat, matix) => {
            // 素材层级的属性
            newSkill.dataReportHomeworkSkillPhaseMaterialResultList[matix] = {
              material: mat.name,
              trainingCount: mat.numberOfTrainingSessions
            }
          })
          paramArr.push(newSkill)
        })
        // 根据选中，保留素材、技能包
        const selectSkillList = paramArr.filter(skill => this.stepFiveSkillform.includes(skill.skillId))
        selectSkillList.forEach(skill => {
          skill.dataReportHomeworkSkillPhaseMaterialResultList = skill.dataReportHomeworkSkillPhaseMaterialResultList.filter(
            mat => this.stepFiveMaterialform.includes(mat.material)
          )
        })
        // 生成最终结果
        result = selectSkillList
      }
      return result
    }
  }
}
</script>
<style lang="less"></style>
