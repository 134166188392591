<!-- 日历 -->
<template>
  <div>
    <a-card :body-style="{ padding: '0px 24px 32px' }">
      <a-calendar :value="currentDate" :header-render="headerRender" @panelChange="onPanelChange">
        <div slot="dateCellRender" slot-scope="value">
          <div class="cell-top">
            <img
              v-show="getImgData(value)"
              class="top-img"
              :src="imgSrcList[getImgData(value)].url"
              alt="图片加载失败..."
            />
          </div>
          <div class="cell-bottom">
            <div
              class="item"
              v-for="(list, index) in getListData(value)"
              :key="String(index)"
              :class="{
                item: true,
                PUBLISHED: list.status === 'PUBLISHED',
                CREATED: list.status === 'CREATED',
                WITHDRAWAL: list.status === 'WITHDRAWAL',
              }"
              @click="showDetailModal(list)"
            >
              {{ reportName[list.type] }}
            </div>
          </div>
        </div>
      </a-calendar>
    </a-card>
    <!-- 报告详情 -->
    <detail-modal ref="DetailModal" @refresh="handleRefresh" @event="handleEvent"></detail-modal>
    <!-- 干预记录详情 -->
    <intervention-modal ref="InterventionModal" @refresh="handleRefresh"></intervention-modal>
    <!-- 新增报告 -->
    <add-modal ref="AddModal" @refresh="handleRefresh" @event="handleEvent"></add-modal>
  </div>
</template>

<script>
import * as Api from '@/api/datareport'
import AddModal from './addModal'
import DetailModal from './detailModal'
import InterventionModal from './interventionModal'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddModal,
    DetailModal,
    InterventionModal
  },
  data() {
    return {
      reportName: {
        DAILY_NEWS: '教学日报',
        WEEKLY_REPORT: '教学周报',
        MONTHLY_REPORT: '教学月报',
        QUARTERLY_REPORT: '教学季报',
        INTERVENTION_RECORDS: '干预记录'
      },
      colors: {
        PUBLISHED: '#34ca71',
        CREATED: '#f12a2c',
        WITHDRAWAL: '#f8a70e'
      },
      texts: {
        PUBLISHED: '已发布',
        CREATED: '待发布',
        WITHDRAWAL: '已撤回'
      },
      // 表情包按照1-10排列，0为不评价，无表情包
      imgSrcList: [
        { url: '' },
        { url: require('@/assets/report/emoji/emoji_tbkx.png') },
        { url: require('@/assets/report/emoji/emoji_hlmm.png') },
        { url: require('@/assets/report/emoji/emoji_gqka.png') },
        { url: require('@/assets/report/emoji/emoji_wbqj.png') },
        { url: require('@/assets/report/emoji/emoji_zjzl.png') },
        { url: require('@/assets/report/emoji/emoji_xtq.png') },
        { url: require('@/assets/report/emoji/emoji_ksc.png') },
        { url: require('@/assets/report/emoji/emoji_qxdl.png') },
        { url: require('@/assets/report/emoji/emoji_xkb.png') },
        { url: require('@/assets/report/emoji/emoji_bzzt.png') }
      ],
      // 报告类型
      reportType: [
        { cnName: '全部', enName: '' },
        { cnName: '教学日报', enName: 'DAILY_NEWS' },
        { cnName: '教学周报', enName: 'WEEKLY_REPORT' },
        { cnName: '教学月报', enName: 'MONTHLY_REPORT' },
        { cnName: '教学季报', enName: 'QUARTERLY_REPORT' },
        { cnName: '干预记录', enName: 'INTERVENTION_RECORDS' }
      ],
      currentReportIndex: '全部', // 当前选择的报告类型
      query: {
        studentId: null,
        workMonth: null,
        type: ''
      },
      currentDate: null, // 特殊需求，回到对应报告数据日期的月份
      calendarList: [] // 日历数据
    }
  },
  created() {
    this.firstInitData()
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus' // 学生-在园状态【true离园  false在园】
    })
  },
  watch: {
    // 监听报告类型下拉框变化，进行请求
    currentReportIndex(newval, oldval) {
      if (newval !== oldval) {
        const { reportType } = this
        let name = ''
        reportType.forEach(el => {
          if (el.cnName === newval) {
            name = el.enName
          }
        })
        this.query.type = name
        this.initData()
      }
    }
  },
  methods: {
    // 第一次进入数据
    firstInitData() {
      this.currentDate = moment(new Date())
      this.query.studentId = parseInt(this.$route.params.id)
      this.query.workMonth = moment(new Date()).format('YYYY-MM-DD')
      this.query.type = ''
      this.initData()
    },
    // 日期变化
    onPanelChange(value) {
      if (typeof value === 'object') {
        this.currentDate = value
        this.query.workMonth = moment(new Date(value)).format('YYYY-MM-DD')
      }
      this.initData()
    },
    // 按日历获取数据
    initData(e = { param: {} }) {
      // 如果携带的有报告日期，使用这个日期
      if (e.param.workMonth) {
        this.currentDate = moment(e.param.workMonth)
        this.query.workMonth = e.param.workMonth
      }
      const { query } = this
      Api.calendar(query).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.calendarList = data
        }
      })
    },
    // 日期对应的数据
    getListData(value) {
      const { calendarList } = this
      let listData = null

      calendarList.forEach(el => {
        if (el.day === moment(new Date(value)).format('YYYY-MM-DD')) {
          listData = el.dataReportCalendarVOList
        }
      })
      return listData || []
    },
    // 日期对应的表情包
    getImgData(value) {
      const { calendarList } = this
      let listData = null
      let imgSrc = 0

      calendarList.forEach(el => {
        if (el.day === moment(new Date(value)).format('YYYY-MM-DD')) {
          listData = el.dataReportCalendarVOList
        }
      })

      if (listData) {
        listData.forEach(el => {
          if (el.evaluationLevel) {
            imgSrc = el.evaluationLevel
          }
        })
      }
      return imgSrc
    },
    // 详情弹窗
    showDetailModal(list) {
      const { type } = list
      // 干预记录使用单独的页面
      if (type === 'INTERVENTION_RECORDS') {
        this.$refs.InterventionModal.showModal(list)
      } else {
        this.$refs.DetailModal.showModal(list)
      }
    },
    // 新增报告
    handleAdd() {
      this.$refs.AddModal.showModal()
    },
    // 刷新日历
    handleRefresh() {
      this.initData()
    },
    // 自定义的“冒泡”事件
    handleEvent(e) {
      this.$emit('eventsubmit', e)
    },
    headerRender({ value, type, onChange, onTypeChange }) {
      // 生成年月选项
      const start = 0
      const end = 12
      const monthOptions = []

      const current = value.clone()
      const localeData = value.localeData()
      const months = []
      for (let i = 0; i < 12; i++) {
        current.month(i)
        months.push(localeData.monthsShort(current))
      }
      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
            {months[index]}
          </a-select-option>
        )
      }
      const month = value.month()
      const year = value.year()
      const options = []
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class="year-item">
            {i}
          </a-select-option>
        )
      }

      const addBtnFlag = !this.isSchoolStatus
      const addBtn = addBtnFlag ? (
        <a-col>
          <a-button type="primary" shape="round" onClick={this.handleAdd}>
            <a-icon type="plus" />
            新增报告
          </a-button>
        </a-col>
      ) : (
        ''
      )
      // 构造页面html
      return (
        <div style={{ padding: '16px 0' }}>
          <a-row type="flex" align="middle" justify="end" gutter={[16]}>
            <a-col>
              <a-badge class="PUBLISHEDtext" color={this.colors['PUBLISHED']} text={this.texts['PUBLISHED']} />
              <a-badge
                class="WITHDRAWALtext"
                style={{ margin: '0 10px', color: this.colors['WITHDRAWAL'] }}
                color={this.colors['WITHDRAWAL']}
                text={this.texts['WITHDRAWAL']}
              />
              <a-badge class="CREATEDtext" color={this.colors['CREATED']} text={this.texts['CREATED']} />
            </a-col>
            <a-col>
              <a-select default-value="全部" v-model={this.currentReportIndex} style="width: 120px">
                <a-select-option value="全部">全部</a-select-option>
                <a-select-option value="教学日报">教学日报</a-select-option>
                <a-select-option value="教学周报">教学周报</a-select-option>
                <a-select-option value="教学月报">教学月报</a-select-option>
                <a-select-option value="干预记录">干预记录</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <h-select
                dropdownMatchSelectWidth={false}
                onChange={newYear => {
                  const now = value.clone().year(newYear)
                  onChange(now)
                }}
                value={String(year)}
                style="width:120px"
              >
                {options}
              </h-select>
            </a-col>
            <a-col>
              <h-select
                dropdownMatchSelectWidth={false}
                value={String(month)}
                onChange={selectedMonth => {
                  const newValue = value.clone()
                  newValue.month(parseInt(selectedMonth, 10))
                  onChange(newValue)
                }}
                style="width:120px"
              >
                {monthOptions}
              </h-select>
            </a-col>
            {addBtn}
          </a-row>
        </div>
      )
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-fullcalendar-cell {
  height: 162px;
  margin: 0;
  padding: 0;

  .ant-fullcalendar-date {
    height: 162px;
    margin: 0;
    padding: 0;
    .ant-fullcalendar-value {
      padding: 8px 0 0 16px;
    }
    .ant-fullcalendar-content {
      height: 162px;
      margin-top: -8%;
      .cell-top {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 71px;
        .top-img {
          width: 46px;
          margin-right: 6px;
        }
      }
      .cell-bottom {
        display: flex;
        flex-wrap: wrap-reverse;
        width: 100%;
        margin-top: 8px;
        .item {
          width: 38%;
          height: 28px;
          margin: 0 10px 4px 0;
          font-size: 12px;
          color: #ffffff;
          line-height: 28px;
          text-align: center;
          border-radius: 4px;
          &.PUBLISHED {
            background: #34ca71;
          }
          &.CREATED {
            background: #f12a2c;
          }
          &.WITHDRAWAL {
            background: #f8a70e;
          }
        }
        .item:hover {
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

.PUBLISHEDtext {
  /deep/.ant-badge-status-text {
    color: #34ca71;
  }
}
.CREATEDtext {
  /deep/.ant-badge-status-text {
    color: #f12a2c;
  }
}
.WITHDRAWALtext {
  /deep/.ant-badge-status-text {
    color: #f8a70e;
  }
}
</style>
