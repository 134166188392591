<template>
  <div>
    <div :id="idName" class="chart-pie"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartPie',
  props: {
    idName: {
      type: String,
      default: 'chartPie'
    },
    data: {
      type: Object,
      default: () => {}
    },
    chartid: {
      type: Number,
      default: null
    },
    isshow: {
      type: Boolean,
      default: () => false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      const dom = document.getElementById(this.idName)

      const wrapperWidth = document.getElementById(`chart-wrapper-pie${this.chartid}`).getBoundingClientRect().width
      dom.style.width = wrapperWidth + 'px'
      this.myChart = echarts.init(dom)

      const { score, totalScore, scoringRate, evaluationReportFieldDetailsList: list } = this.data
      const legend = [] // 图例
      const seriesInside = [] //  内环
      const seriesOutside = [] //  外环
      const insideColor = ['#5FE9D8', '#FFDA7E', '#FF8F76', '#3DA9F6', '#8975E9'] // 内环颜色
      const outsideBaseColor = ['#0FCCB6', '#FBC236', '#F05F3F', '#0089EB', '#6141F7'] // 外环基础颜色
      // 2个颜色数组有顺序,别乱动
      const outsideColor = [] // 外层颜色
      list.map((item, index) => {
        const { fieldName: name, totalScore, score } = item

        legend.push({ name })

        // 内环数据
        seriesInside.push({ name, value: totalScore })

        // 外环数据
        const seriesOutsideItem = { name, value: score, totalScore, label: { color: outsideBaseColor[index] } }
        seriesOutside.push(seriesOutsideItem)
        // 外环颜色
        outsideColor.push(outsideBaseColor[index])
        // 外环颜色加空白
        if (score < totalScore) {
          seriesOutside.push({
            name,
            value: totalScore - score,
            label: {
              show: false
            }
          })
          outsideColor.push('#fff')
        }
      })
      // console.log(seriesInside, seriesOutside)

      const option = {
        color: insideColor,
        legend: {
          data: legend,
          itemHeight: 17,
          textStyle: {
            fontSize: 12
          },
          type: 'scroll',
          bottom: '0',
          icon: 'circle'
        },
        // 中间空白文字
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: '43%',
            style: {
              text: `${score}分`,
              fill: '#3F3F3F',
              width: 30,
              height: 30,
              fontSize: 20,
              fontWeight: 600
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '50%',
            style: {
              text: `总分：${totalScore}分`,
              fill: '#3F3F3F',
              width: 30,
              height: 30,
              fontSize: 14
            }
          },
          {
            type: 'text',
            left: 'center',
            top: '55%',
            style: {
              text: `得分率：${scoringRate}%`,
              fill: '#3F3F3F',
              width: 30,
              height: 30,
              fontSize: 14
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'pie',
            selectedMode: 'single',
            radius: ['40%', '60%'],
            hoverAnimation: false, // 鼠标悬浮是否有区域弹出动画，false:无  true:有
            label: {
              show: false
            },

            data: seriesInside
          },
          {
            name: '外',
            type: 'pie',
            // radius: ['70%', '75%'],
            radius: ['62%', '62%'],
            hoverAnimation: false, // 鼠标悬浮是否有区域弹出动画，false:无  true:有
            labelLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.4)',
                with: 1
              }
            },

            label: {
              formatter: function(params) {
                const data = params.data
                return `{a|总分：${data.totalScore}}\n{hr|}\n{b|得分：${data.value}}`
              },
              rich: {
                a: {
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  fontWeight: 'normal',
                  lineHeight: 22
                }
              }
            },

            data: seriesOutside,
            itemStyle: {
              color: function(params) {
                // 自定义颜色
                return outsideColor[params.dataIndex]
              }
            }
          }
        ]
      }

      this.myChart.setOption(option, true)

      window.addEventListener('resize', this.resize)
    },
    resize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
<style lang="less" scoped>
.chart-pie {
  width: 100%;
  height: 450px;
}
</style>