import { render, staticRenderFns } from "./chartsPie.vue?vue&type=template&id=35ab36b6&scoped=true"
import script from "./chartsPie.vue?vue&type=script&lang=js"
export * from "./chartsPie.vue?vue&type=script&lang=js"
import style0 from "./chartsPie.vue?vue&type=style&index=0&id=35ab36b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ab36b6",
  null
  
)

export default component.exports