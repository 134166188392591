<template>
  <a-modal
    v-model="visible"
    title="选择技能包"
    width="716px"
    :destroyOnClose="true"
    :body-style="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', disabled: buttondisabled } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="selected = null"
  >
    <a-row :gutter="24" style="padding: 24px">
      <a-form-model class="ant-advanced-search-form">
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-model-item label="搜索">
            <a-input-search
              v-model="query.name"
              allowClear
              placeholder="请输入技能包名称"
              style="width: 100%"
              @search="initData"
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="14" :md="14" :sm="24">
          <a-form-model-item label="领域">
            <h-cascader
              v-model="field"
              :options="mainFieldsOptions"
              :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children',
              }"
              change-on-select
              allowClear
              placeholder="全部分类"
              style="width: 100%"
              @change="initData"
            />
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>

    <skill-table ref="table" @change="ontableChange($event)"></skill-table>
  </a-modal>
</template>

<script>
import * as commonApi from '@/api/common'
import SkillTable from './SkillTable'

export default {
  components: {
    SkillTable
  },
  data() {
    return {
      visible: false,
      buttondisabled: false, // 选择技能包按钮是否可操作
      query: {
        fieldId: undefined,
        mainFieldId: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined
      },
      field: [],
      mainFieldsOptions: [],
      selected: null
    }
  },
  mounted() {
    commonApi.fieldsOptions().then(res => {
      this.mainFieldsOptions = res.data.filter(v => v.level === 'MAIN')
    })
  },
  methods: {
    showModal(initquery) {
      this.visible = true
      this.buttondisabled = false
      this.query = {
        fieldId: undefined,
        mainFieldId: undefined,
        name: undefined
      }
      // 作业日期参数，添加作业包含两个日期、修改作业只有一个日期
      if (initquery.workTimestamp) {
        this.query.startTimestamp = initquery.workTimestamp
        this.query.endTimestamp = initquery.workTimestamp
      } else {
        this.query.startTimestamp = initquery.startDate
        this.query.endTimestamp = initquery.endDate
      }
      this.initData()
    },
    initData() {
      const { $nextTick, query, field, $refs } = this
      query.mainFieldId = field[0]
      query.fieldId = field[1]
      $nextTick(() => {
        $refs.table.initData(query)
      })
    },
    // 获取表格选中的数据
    ontableChange(e) {
      if (e.hasNotRelationPhase) {
        this.buttondisabled = true
      } else {
        this.buttondisabled = false
        this.selected = e
      }
    },
    // 点击选择技能弹窗的确定
    handleOk() {
      const { selected } = this
      this.$emit('submit', selected)
      this.visible = false
      this.selected = null
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
