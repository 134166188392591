<template>
  <pdf-page>
    <template #extra>
      <img src="~@/assets/report/quarter/balloon@2x.png" class="extra" alt="" />
    </template>
    <div class="main filled-wrapper">
      <div class="title" style="">练习素材情况</div>
      <a-row wrap>
        <a-col v-for="(material, index) in list" :key="`${material.skillCode}${index}`" :span="8">
          <chart-hole
            :name="[material.skillCode, splitName(material.skillName)]"
            :series="filterSeries(material)"
          ></chart-hole>
        </a-col>
      </a-row>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
import { ChartHole } from '../../components'
export default {
  components: {
    PdfPage,
    ChartHole,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    splitName(name, len = 6) {
      if (!name || name.length < 7) return name
      const source = name.split('')
      const rows = Math.ceil(source.length / 6)
      const ret = []
      let str = ''
      for (let i = 0; i < rows; i++) {
        str = source.slice(i * 6, (i + 1) * 6)
        ret.push(str.join(''))
      }
      console.log(ret.join('\n'))
      return ret.join('\n')
    },
    filterSeries(material) {
      const ret = []
      material.materialNum > 0 && ret.push({ value: material.materialNum, name: '训练中' })
      material.qualifiedMaterialNum > 0 && ret.push({ value: material.qualifiedMaterialNum, name: '已达标' })

      return ret
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 36px;
}
.text {
  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-col:not(.large) {
    margin-bottom: 5px;
  }
}
.large {
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 1px;
}
.highlight {
  color: #ff7a55;
}
.extra {
  position: absolute;
  width: 228px;
  bottom: 142px;
  left: 0;
}
.filled-wrapper {
  min-height: 1269px;
  background: rgba(255, 255, 255, 0.5);
  padding-top: 26px;
  .title {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 42px;
  }
}
</style>