import request from '@/utils/request'

const planStatisticsApi = {
  recentNumbers: '/material_statistics/phase-training-count',
  materialNumbers: '/material_statistics/material-count',
  materialStatistics: '/material_statistics/many-material-count',
  phaseMaterialOptions: '/material_statistics/phase-material',
  incrementalStatistics: '/material_statistics/incremental',
  // 近3天素材训练数据
  getStatisticsLastThreeDays: skillPhaseId =>
    `/material_statistics/homeschool/skill-phases/${skillPhaseId}/materials/last-3-days`,
  // 家校教学中，单个素材的按时按月统计
  getStatisticsBySingleMaterials: skillPhaseId =>
    `/material_statistics/homeschool/skill-phases/${skillPhaseId}/material`,
  //  查询阶段下训练过的素材
  getMaterialList: skillPhaseId => `/material_statistics/homeschool/skill-phases/${skillPhaseId}/materials/trained`,

  // 累计达标数(家校教学中，技能阶段的所有素材的达标统计)
  getStatisticsAccumulatedCompliance: skillPhaseId =>
    `/material_statistics/homeschool/skill-phases/${skillPhaseId}/materials`,
  //  家校计划训练中、已完成技能包对应领域树
  materialAtatisticsSkillPhasetree: (studentId, planId) => `/material_statistics/field/skill/phase/tree/student/${studentId}?planId=${planId}`
}

//  家校计划训练中、已完成技能包对应领域树
export function commonMaterialAtatisticsSkillPhasetree(parameter) {
  const { studentId, planId } = parameter
  return request({
    url: planStatisticsApi.materialAtatisticsSkillPhasetree(studentId, planId),
    method: 'get'
  })
}

// 累计达标数(家校教学中，技能阶段的所有素材的达标统计)
export function getStatisticsAccumulatedCompliance(parameter) {
  const { phaseId: skillPhaseId } = parameter
  return request({
    url: planStatisticsApi.getStatisticsAccumulatedCompliance(skillPhaseId),
    method: 'get',
    params: parameter
  })
}

// 单个素材训练数据(素材列表)
export function getMaterialList(parameter) {
  const { skillPhaseId } = parameter
  return request({
    url: planStatisticsApi.getMaterialList(skillPhaseId),
    method: 'get',
    params: parameter
  })
}

// 单个素材训练数据
export function getStatisticsBySingleMaterials(parameter) {
  const { skillPhaseId } = parameter
  return request({
    url: planStatisticsApi.getStatisticsBySingleMaterials(skillPhaseId),
    method: 'get',
    params: parameter
  })
}

// 近3天素材训练数据
export function getStatisticsLastThreeDays(parameter) {
  const { skillPhaseId } = parameter
  return request({
    url: planStatisticsApi.getStatisticsLastThreeDays(skillPhaseId),
    method: 'get',
    params: parameter
  })
}

export function recentNumbers(parameter) {
  return request({
    url: planStatisticsApi.recentNumbers,
    method: 'get',
    params: parameter
  })
}

export function materialNumbers(parameter) {
  return request({
    url: planStatisticsApi.materialNumbers,
    method: 'get',
    params: parameter
  })
}

export function materialStatistics(parameter) {
  return request({
    url: planStatisticsApi.materialStatistics,
    method: 'get',
    params: parameter
  })
}

export function phaseMaterialOptions(parameter) {
  return request({
    url: planStatisticsApi.phaseMaterialOptions,
    method: 'get',
    params: parameter
  })
}

export function incrementalStatistics(parameter) {
  return request({
    url: planStatisticsApi.incrementalStatistics,
    method: 'get',
    params: parameter
  })
}
