<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="400"
    title="填写计划信息"
    :destroyOnClose="true"
    :maskClosable="false"
    :bodyStyle="{ padding: 0 }"
    @cancel="resetForm"
  >
    <a-card :bordered="false" :body-style="{ padding: '30px 20px 10px 20px' }">
      <a-form-model :model="form" :rules="rules" ref="ruleForm" :labelCol="{ span: 8 }" :wrapperCol="{ span: 12 }">
        <a-form-model-item label="计划出具时间：">
          {{ time }}
        </a-form-model-item>
        <a-form-model-item label="初审时间：" prop="preliminaryReviewTime">
          <a-date-picker
            v-model="form.preliminaryReviewTime"
            type="date"
            valueFormat="YYYY-MM-DD"
            :disabled-date="disabledDate"
            placeholder="请选择日期"
            style="width: 100%;"
          />
        </a-form-model-item>
        <a-form-model-item label="复审时间：" prop="reviewTime">
          <a-date-picker
            v-model="form.reviewTime"
            type="date"
            valueFormat="YYYY-MM-DD"
            :disabled-date="disabledDate"
            placeholder="请选择日期"
            style="width: 100%;"
          />
        </a-form-model-item>
        <a-form-model-item label="计划撰写人：" prop="planWriter">
          <a-input v-model="form.planWriter" />
        </a-form-model-item>
        <a-form-model-item label="计划审核人：" prop="planReview">
          <a-input v-model="form.planReview" />
        </a-form-model-item>
      </a-form-model>
    </a-card>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
        {{ okText }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import * as Api from '@/api/field'
export default {
  props: {
    editId: {
      type: Number,
      default: null
    },
    // 编辑时,计划出具时间用这个字段
    createTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      time: moment().format('YYYY-MM-DD'),
      okText: '开始制作计划',
      form: {
        planReview: '', // 计划审核
        planWriter: '', // 计划撰写人
        preliminaryReviewTime: '', // 初审时间
        reviewTime: '', // 复审时间
        studentId: null
      },
      rules: {
        preliminaryReviewTime: [{ required: true, message: '请选择初审时间', trigger: 'change' }],
        reviewTime: [{ required: true, message: '请选择复审时间', trigger: 'change' }],
        planReview: [{ required: true, message: '请填写计划审核人', trigger: 'blur' }],
        planWriter: [{ required: true, message: '请填写计划撰写人', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },
  methods: {
    showModal({ type }) {
      this.form = {}
      const {
        userInfo: { userId, username: planWriter },
        form
      } = this
      if (type === 'add') {
        console.log(userId, planWriter)
        this.form = { ...form, planWriter }
      } else {
        this.getEditInfo()
      }

      if (type === 'again') this.okText = '开始制作计划'
      if (type === 'edit') {
        this.okText = '保存修改'
        this.time = this.createTime
      } else {
        this.time = moment().format('YYYY-MM-DD')
      }
      this.$nextTick(() => {
        this.visible = true
      })
    },
    getEditInfo() {
      if (this.editId) {
        Api.editRecordData({ id: this.editId }).then(res => {
          if (res.code === 0) {
            this.form = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    resetForm() {
      this.editId = null
      this.form = {}
    },
    handleOk() {
      const { preliminaryReviewTime, reviewTime } = this.form
      if (moment(reviewTime).isBefore(preliminaryReviewTime)) {
        this.$message.error('复审日期应大于或等于初审日期')
        return
      }

      this.$refs.ruleForm.validate(valid => {
        const { params } = this.$route
        this.form.studentId = Number(params.id)
        if (valid) {
          // 编辑
          if (this.editId) {
            Api.commitEditRecord(this.editId, this.form)
              .then(res => {
                if (res.code === 0) {
                  this.visible = false
                  this.$emit('editSuccess')
                  this.$message.success('编辑成功')
                  this.form = {}
                  this.editId = null
                }
              })
              .finally(() => {
                this.listLoading = false
              })
          } else {
            return Api.addExportPlanRecord(this.form)
              .then(res => {
                const { code, data } = res
                if (code === 0) {
                  this.visible = false
                  this.$emit('commitSuccess', data)
                  this.form = {}
                  this.editId = null
                }
              })
              .finally(() => {
                this.listLoading = false
              })
          }
        }
      })
    },
    handleCancel() {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf('day')
    }
  }
}
</script>

<style lang="less" scoped></style>
