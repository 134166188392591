<template>
  <a-card :bodyStyle="{ padding: '0 24px 24px' }">
    <div slot="title" style="line-height:32px">
      技能包各领域占比
    </div>

    <a-radio-group
      v-model="isTraining"
      @change="getData()"
      size="small"
      button-style="solid"
      style="margin-bottom:24px"
    >
      <a-radio-button :value="true">
        已训练
      </a-radio-button>
      <a-radio-button :value="false">
        已达标
      </a-radio-button>
    </a-radio-group>

    <div style="height:240px">
      <main-chart :dataSource="list"></main-chart>
    </div>
  </a-card>
</template>

<script>
import * as trainingSkillStaApi from '@/api/training-skill-statistics'
import MainChart from './SkillMainFieldChart'
export default {
  components: {
    MainChart
  },
  data() {
    return {
      isTraining: true,
      list: []
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.getData()
    },
    getData() {
      const query = this.initQuery()

      trainingSkillStaApi.fieldSkillsCount(query).then(res => {
        this.list = res.data.map(({ fieldsName: item, skillsCount: count }) => ({
          item,
          count
        }))
      })
    },
    initQuery() {
      const {
        $route: {
          params: { id: studentId }
        },
        isTraining
      } = this

      return { studentId, isTraining }
    }
  }
}
</script>

<style lang="less" scoped></style>
