<template>
  <div>
    <a-card :bodyStyle="{ padding: '0 24px' }">
      <a-tabs :active-key="current" @change="changeTabs" class="hbkx-tabs">
        <a-tab-pane v-if="$auth('home_school_student_base_info')" key="8" tab="基本信息" />
        <a-tab-pane v-if="$auth('skills_assessment')" key="1" tab="技能评估" />
        <a-tab-pane v-if="$auth('training_plan')" key="2" tab="训练计划" />
        <a-tab-pane v-if="$auth('teaching_class')" key="7" tab="上课记录" />
        <a-tab-pane v-if="$auth('plan_work')" key="3" tab="课后作业" />
        <a-tab-pane v-if="$auth('data_statistics')" key="4" tab="数据统计" />
        <!-- :v3.1隐藏-->
        <!-- <a-tab-pane key="5" tab="月度数据报告" />
        <a-tab-pane key="6" tab="干预记录" /> -->
        <a-tab-pane v-if="$auth('data_report')" key="9" tab="数据报告" />
      </a-tabs>
    </a-card>
    <a-card style="margin: 5px 0" :bodyStyle="{ padding: '16px 24px' }">
      <a-form-model layout="inline" :model="form">
        <img
          v-if="form.sex === 'MALE'"
          src="~@/assets/icons/male@2x.png"
          style="width: 40px; margin-right: 24px"
          alt=""
        />
        <img
          v-if="form.sex === 'FEMALE'"
          src="~@/assets/icons/female@2x.png"
          style="width: 40px; margin-right: 24px"
          alt=""
        />
        <a-form-model-item label="学员：" style="margin-right: 24px">
          {{ form.username }}
        </a-form-model-item>
        <a-form-model-item label="年龄：" style="margin-right: 24px">
          {{ form.age }}
        </a-form-model-item>
        <a-form-model-item label="计划撰写师：" style="margin-right: 24px">
          {{ form.planWriter || '--' }}
        </a-form-model-item>
        <a-form-model-item label="授课老师：" style="margin-right: 24px">
          {{ form.teacher || '--' }}
        </a-form-model-item>
        <a-form-model-item label="学员状态：" style="margin-right: 24px">
          {{ isSchoolStatus ? '离园' : '在园' }}
        </a-form-model-item>
      </a-form-model></a-card
    >
    <div>
      <evaluation-main v-if="current === '1'"></evaluation-main>
      <plan-main v-if="current === '2'"></plan-main>
      <homework-main v-if="current === '3'"></homework-main>
      <statistic-all-main v-if="current === '4'"></statistic-all-main>
      <monthly-data-report-main v-if="current === '5'"></monthly-data-report-main>
      <intervene-main v-if="current === '6'"></intervene-main>
      <class-record v-if="current === '7'"></class-record>
      <basic-information v-if="current === '8'"></basic-information>
      <data-report ref="dataReport" v-if="current === '9'"></data-report>
    </div>
  </div>
</template>

<script>
import EvaluationMain from './evaluation/index'
import PlanMain from './plan/index'
import HomeworkMain from './homework/index'
import StatisticAllMain from './statistic-all/index'
import monthlyDataReportMain from './monthly-datareport/index'
import interveneMain from './intervene/index'
import classRecord from './class-record/class-record'
import basicInformation from './basic-information/basic-information'
import dataReport from './datareport/index'
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'HomeSchoolDetail',
  components: {
    EvaluationMain,
    basicInformation,
    PlanMain,
    HomeworkMain,
    StatisticAllMain,
    monthlyDataReportMain,
    interveneMain,
    classRecord,
    dataReport,
  },
  data() {
    return {
      current: '1',
      form: {},
      msgTitle: '', // 弹窗标题
      msgContent: '', // 弹窗内容
    }
  },
  created() {
    this.form = JSON.parse(this.$route.query.row)
    this.checkTabsKey()
    this.judgeSchoolStatus(this.$route.query.row)
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
  },
  methods: {
    ...mapActions(['judgeSchoolStatus']),
    // 检查sessionStorage是否记录有页签值，如果有，就使用记录值，否者默认打开1（技能评估）
    checkTabsKey() {
      const key = sessionStorage.getItem('activeKey')
      if (key) {
        this.current = key
      }
      sessionStorage.removeItem('activeKey')
    },
    // 当处于报告步骤、报告页面时，切换页签，进行弹窗提示
    // 打开同一路由下，其他页签
    changeTabs(key) {
      const current = cloneDeep(this.current)
      if (current === '9') {
        const menu = this.$refs.dataReport.showMenuName
        const type = this.$refs.dataReport.eventParam.type
        if (menu === 'steps') {
          this.msgTitle = '请确认是否前往其他页面？'
          this.msgContent = '离开当前页面，系统不会保存您已选择的内容，下次需要重头开始创建数据报告。'
          this.showConfirm(key)
        }
        if (menu === 'report') {
          if (type === 'edit') {
            this.msgTitle = '请确认是否前往其他页面？'
            this.msgContent = '离开页面后当前报告中未实时保存成功的内容将丢失，是否确认离开'
            this.showConfirm(key)
          } else {
            this.current = key
          }
        }
        if (menu === 'calendar' || menu === 'export') {
          this.current = key
        }
      } else {
        this.current = key
      }
      sessionStorage.removeItem('homeSchoolIsShowTabs')
    },
    // 调用弹窗
    showConfirm(key) {
      const { msgTitle, msgContent } = this
      const confirm = this.$confirm({
        title: msgTitle,
        content: msgContent,
        onOk: () => {
          confirm.destroy()
          this.current = key
        },
        onCancel: () => {
          confirm.destroy()
        },
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('homeSchoolIsShowTabs')
    next()
  },
}
</script>

<style lang="less">
.hbkx-tabs {
  .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    margin-right: 32px;
    padding: 16px 0;
  }

  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .ant-tabs-ink-bar {
    height: 2px;
  }
}
</style>
