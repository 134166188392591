<template>
  <div v-if="chartList.length">
    <v-chart
      :forceFit="true"
      :height="height"
      :data="chartList"
      :scale="scale"
      :filter="filter"
      :padding="[0, 300, 0, 4]"
    >
      <v-tooltip :showTitle="false" dataKey="item*percent" />
      <v-axis />
      <v-legend
        :useHtml="true"
        position="right-top"
        :reactive="true"
        :containerTpl="containerTplLegend"
        :itemTpl="itemTplLegend"
        :flipPage="true"
        :offset="[16, 0]"
        :on-click="legendOnClick"
      ></v-legend>
      <v-pie position="percent" color="item" :vStyle="pieStyle" :label="null" />
      <v-coord type="theta" :radius="1" :innerRadius="0.75" />
      <v-guide v-if="ready" type="html" :position="['50%', '50%']" :html="guideHtml" />
    </v-chart>
  </div>
</template>

<script>
const DataSet = require('@antv/data-set')

const scale = [
  {
    dataKey: 'percent',
    min: 0,
    formatter: '.0%'
  }
]

export default {
  props: {
    dataSource: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    total() {
      return this.dataSource
        .filter(v => !this.uncheckedList.includes(v.item))
        .reduce((prev, curr) => prev + curr.count, 0)
    },
    guideHtml() {
      return (
        '<div class="g2-guide-html"><p class="title">' +
        '技能包' +
        '</p><p class="value">' +
        this.total +
        '个</p></div>'
      )
    },
    legendItems() {
      return this.dataSource.map(v => ({ value: v.item }))
    },
    filter() {
      return [
        {
          dataKey: 'item',
          callback: ev => {
            return !this.uncheckedList.includes(ev)
          }
        }
      ]
    }
  },
  watch: {
    dataSource: {
      handler() {
        this.chartList = []
        this.uncheckedList = []

        if (this.dataSource.length > 0) {
          const dv = new DataSet.View().source(this.dataSource)
          this.dv = dv

          dv.transform({
            type: 'percent',
            field: 'count',
            dimension: 'item',
            as: 'percent'
          })

          this.ready = false
          setTimeout(() => {
            this.chartList = dv.rows
            this.ready = true
          }, 0)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.setStyle()
  },
  methods: {
    setStyle() {
      const id = 'legend-html'
      if (document.getElementById(id)) {
        return
      }
      const styleTxt = `
        .g2-guide-html {
            vertical-align: middle;
            text-align: center;
            line-height: 1.5
        }

        .g2-guide-html .title {
            margin-bottom: 8px;
            font-size: 14px;
            color: #8c8c8c;
            font-weight: 300;
        }

        .g2-guide-html .value {
            font-size: 24px;
            color: #000;
            font-weight: bold;
        }
      `
      const style = document.createElement('style')
      style.setAttribute('id', id)
      style.innerHTML = styleTxt
      document.getElementsByTagName('head')[0].appendChild(style)
    },
    itemTplLegend(value, color, checked, index) {
      const obj = this.dv.rows[index]
      const percent = (obj.percent * 100).toFixed(2) + '%'
      checked = checked ? 'checked' : 'unChecked'
      return (
        '<tr class="g2-legend-list-item item-' +
        index +
        ' ' +
        checked +
        '" data-value="' +
        value +
        '" data-color=' +
        color +
        ' >' +
        '<td style="width:120px;"><i class="g2-legend-marker" style="width:10px;height:10px;display:inline-block;margin-right:10px;background-color:' +
        color +
        ';"></i>' +
        '<span class="g2-legend-text" style="color: #666">' +
        value +
        '</span></td>' +
        '<td style="text-align: right">' +
        percent +
        '</td>' +
        '<td style="text-align: right;color: #666;width:80px">' +
        obj.count +
        '个</td>' +
        '</tr>'
      )
    },
    legendOnClick(ev, chart) {
      console.log(ev, chart)
      const {
        item: { value, checked }
      } = ev
      const { uncheckedList } = this
      const index = uncheckedList.indexOf(value)

      if (checked && index > -1) {
        this.uncheckedList.splice(index, 1)
      } else if (!checked && index < 0) {
        this.uncheckedList.push(value)
      }
    }
  },
  data() {
    return {
      ready: false,
      chartList: [],
      uncheckedList: [],
      scale,
      height: 240,
      pieStyle: {
        stroke: '#fff',
        lineWidth: 1
      },
      labelConfig: [
        'percent',
        {
          formatter: (val, item) => {
            return item.point.item + ': ' + val
          }
        }
      ],
      containerTplLegend: `<div class="g2-legend"> <table class="g2-legend-list"></table> </div>`
    }
  }
}
</script>
