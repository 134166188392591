<template>
  <div>
    <h-statistics2 ref="statistics2" style="margin-bottom:24px" :lastThreeDaysTopRight="lastThreeDaysTopRight"></h-statistics2>
    <h-statistics1 ref="statistics1" style="margin-bottom:24px" ></h-statistics1>
    <h-statistics3 ref="statistics3"></h-statistics3>
  </div>
</template>

<script>
import HStatistics1 from './components/Statistics1'
import HStatistics2 from './components/Statistics2'
import HStatistics3 from './components/Statistics3'

export default {
  props:{
    lastThreeDaysTopRight:{
      type:String,
      default:''
    }
  },
  components: {
    HStatistics1,
    HStatistics2,
    HStatistics3
  },
  methods: {
    initData(data) {
      const { skillPhaseId, planSkillId, skillId, recordingMethod, name: materialName } = data
      const {
        $route: {
          params: { id: studentId }
        }
      } = this

      const query = {
        id: skillPhaseId,
        studentId,
        planSkillId,
        skillId,
        skillPhaseId,
        recordingMethod,
        materialName
      }

      this.$nextTick(() => {
        this.$refs.statistics1.initData({
          ...query
        })
        this.$refs.statistics2.initData({
          ...query
        })
        this.$refs.statistics3.initData({
          ...query
        })
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
