<template>
  <div>
    <date-report ref="dateReport" v-show="currentReportType === 'date'" @event="handleEvent"></date-report>
    <week-report ref="weekReport" v-show="currentReportType === 'week'" @event="handleEvent"></week-report>
    <month-report ref="monthReport" v-show="currentReportType === 'month'" @event="handleEvent"></month-report>
    <quarter-report ref="quarterReport" v-if="currentReportType === 'quarter'" @event="handleEvent"></quarter-report>
  </div>
</template>
<script>
import DateReport from './dateReport'
import WeekReport from './weekReport'
import MonthReport from './monthReport'
import QuarterReport from './quarter'
export default {
  components: {
    DateReport,
    WeekReport,
    MonthReport,
    QuarterReport,
  },
  data() {
    return {
      currentReportType: 'date',
    }
  },
  methods: {
    showReport(e) {
      this.currentReportType = e.toSubMenuName
      this.$nextTick(() => {
        switch (e.toSubMenuName) {
          case 'date':
            this.$refs.dateReport.initData(e)
            break
          case 'week':
            this.$refs.weekReport.initData(e)
            break
          case 'month':
            this.$refs.monthReport.initData(e)
            break
          case 'quarter':
            this.$refs.quarterReport.initData(e)
            break
          default:
        }
      })
    },
    // 自定义的“冒泡”事件
    handleEvent(e) {
      this.$emit('eventsubmit', e)
    },
  },
}
</script>
<style lang="less" scoped></style>
