<template>
  <a-card :bodyStyle="{ padding: '0' }">
    <div slot="title" style="font-size:20px;font-weight:500">
      近3天素材训练数据
    </div>
    <a-card
      class="main-card"
      :bordered="null"
      :headStyle="{ padding: '0 24px' }"
      :bodyStyle="{ padding: '0 24px 24px' }"
    >
      <a-row slot="title" type="flex" align="bottom">
        <a-col flex="1" style="display:flex">
          <a-statistic title="总训练天数" :value="countDate" suffix="天" style="margin-right: 40px" />
          <a-statistic title="训练素材数" :value="materialCount" suffix="个" style="margin-right: 40px" />
          <a-statistic title="达标素材数" :value="upCount" suffix="个" />
        </a-col>
        <a-col>
          <a-radio-group v-if="lastThreeDaysTopRight === 'tabs'" v-model="dateType" @change="getData()" button-style="solid">
            <a-radio-button value="day">
              日
            </a-radio-button>
            <a-radio-button value="month">
              月
            </a-radio-button>
          </a-radio-group>
          <a-range-picker v-if="lastThreeDaysTopRight === 'date'" v-model="dateRange" :allowClear="false" @change="getData()">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-col>
      </a-row>
      <a-empty v-if="empty" />
      <div style="height:480px;border:1px solid #707070" v-else>
        <main-chart :dataSource="list" :start="start" :end="end" :type="dateType" :query="query"></main-chart>
      </div>
    </a-card>
  </a-card>
</template>

<script>
import * as planStatisticsApi from '@/api/phase-material-statistics'
import moment from 'moment'
import MainChart from './Chart1'
import { mapActions } from 'vuex'

export default {
  props:{
    // tabs 日期切换 date 日历选择
    lastThreeDaysTopRight:{
      type:String,
      default:'tabs'
    }
  },
  components: {
    MainChart
  },
  data() {
    return {
      query:{},
      dateRange: [moment().subtract(181, 'days'), moment().subtract(1, 'days')],
      dateType: 'day',
      countDate: 0,
      materialCount: 0,
      upCount: 0,
      list: [],
      start: '',
      end: '',
      empty: false // 是否为空（默认否）
    }
  },
  methods: {
    ...mapActions(['changeSpinning']),
    initData(query = {}) {
      this.query = query
      this.empty = true
      this.resetData()
      this.getData()
    },
    resetData() {
      this.dateType = 'day'
      this.countDate = 0
      this.materialCount = 0
      this.upCount = 0
      this.list = []
      this.start = ''
      this.end = ''
    },

    getData() {
      const {
        query: { studentId, planSkillId, skillId, skillPhaseId },
        dateType
      } = this
      let startTimestamp = null
      let endTimestamp = null
      let isMonth = null
      if(this.lastThreeDaysTopRight === 'tabs'){
           isMonth = dateType === 'month'
           startTimestamp = moment()
            .subtract(isMonth ? 6 : 181, [dateType + 's'])
            .format('YYYY-MM-DD')
           endTimestamp = moment().format('YYYY-MM-DD')

          this.start = moment()
            .subtract(isMonth ? 6 : 181, [dateType + 's'])
            .format('YYYY-MM-DD')
          this.end = moment().format('YYYY-MM-DD')
      }

      if(this.lastThreeDaysTopRight === 'date'){
        isMonth = false
        this.start = startTimestamp = moment(this.dateRange[0]).format('YYYY-MM-DD')
        this.end = endTimestamp = moment(this.dateRange[1]).format('YYYY-MM-DD')
      }


      this.list = []
      const query = { studentId, skillPhaseId, planSkillId, skillId, isMonth, startTimestamp, endTimestamp }
      this.changeSpinning(true)
      planStatisticsApi
        .getStatisticsLastThreeDays(query)
        .then(res => {
          this.empty = false
          const { code, data } = res
          this.changeSpinning(false)
          if (code === 0) {
            const { duration, fulfilledMaterialCount, materialCount, materialStatsVOList } = data
            // 图表顶部
            this.countDate = duration
            this.materialCount = materialCount
            this.upCount = fulfilledMaterialCount
            if (!materialStatsVOList || !materialStatsVOList.length) this.empty = true

            for (const item of materialStatsVOList) {
              this.list = [...this.list, ...item]
            }
          }
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.main-card /deep/ .ant-card-head-title {
  padding-top: 0;
}
</style>
