<template>
  <div v-if="loading" class="content-loading">
    <a-spin>
      <div v-if="loadingText" class="spin-content">{{ loadingText }}</div>
    </a-spin>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return false
      }
    },
    loadingText: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.content-loading {
  text-align: center;
  padding: 32px 0;
}

.spin-content {
  padding-top: 60px;
}
</style>