<template>
  <div :class="classNames">
    <img class="pdf-page-header" src="~@/assets/report/report/pdf_logo.png" alt="" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    classNames() {
      const cls = ['pdf-page']
      if (this.page) {
        cls.push('regular')
      }
      return cls.join(' ')
    }
  }
}
</script>

<style lang="less" scoped>
.pdf-page {
  padding: 120px 32px;
  background: #fff;
  width: 1080px;
  position: relative;
  &.regular {
    height: 1527px;
  }
}
.pdf-page-header {
  width: 155px;
  right: 90px;
  position: absolute;
  top: 60px;
}
</style>