<template>
  <div>
    <div class="title">
      <div class="title-dot"></div>
      <h3 style="margin: 0">{{ title }}</h3>
    </div>
    <block-content secondary style="padding: 24px 16px; margin-top: 14px">
      <pre class="pre">{{ content }}</pre>
    </block-content>
  </div>
</template>

<script>
import BlockContent from './Content'
export default {
  components: {
    BlockContent
  },
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    content: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang='less' scoped>
.title {
  display: flex;
  align-items: center;
}
.title-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3350c7;
  margin-right: 8px;
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
}
</style>