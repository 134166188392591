import { render, staticRenderFns } from "./PageMaterials.vue?vue&type=template&id=63299474&scoped=true"
import script from "./PageMaterials.vue?vue&type=script&lang=js"
export * from "./PageMaterials.vue?vue&type=script&lang=js"
import style0 from "./PageMaterials.vue?vue&type=style&index=0&id=63299474&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63299474",
  null
  
)

export default component.exports