<!-- 家校训练服务-详情-训练计划 -->
<template>
  <a-row :gutter="24" style="overflow: hidden">
    <!--历史-->
    <template v-if="current === 'HISTORY'">
      <a-col :span="24">
        <a-card :bodyStyle="{ padding: 0 }">
          <a-button slot="extra" shape="round" @click="switchCurrent('DETAIL', query)"
            ><a-icon type="left" />返回</a-button
          >
          <history-table ref="table" @row-click="handleHistoryDetail"></history-table>
        </a-card>
      </a-col>
    </template>
    <!--统计-->
    <template v-if="['STATISTIC'].includes(current)">
      <a-col :lg="3" :md="5" :xs="7" style="padding-right: 0">
        <a-affix v-if="ready" :offset-top="24">
          <a-card title="领域" :bodyStyle="{ padding: '0 0 40px 0' }" class="aside-wrapper">
            <a-tree
              v-if="statisticFieldsOptions.length > 0"
              :tree-data="statisticFieldsOptions"
              :default-selected-keys="defaultSelectedKeys"
              default-expand-all
              @select="onSelectStatistic"
            ></a-tree>
          </a-card>
        </a-affix>
      </a-col>

      <a-col :lg="21" :md="19" :xs="17">
        <a-card :bodyStyle="{ padding: 24 }">
          <a-row type="flex" justify="space-between">
            <a-radio-group
              :value="statisticPhaseDefault"
              size="large"
              @change="changeStatisticPhase"
              class="phase-list"
            >
              <a-radio-button :value="item.planPhaseId" v-for="item in statisticPhaseList" :key="item.planPhaseId">
                {{ item.phaseNumber }}阶
              </a-radio-button>
            </a-radio-group>

            <a-button shape="round" @click="handleStatisticReturn()"><a-icon type="left" />返回</a-button>
          </a-row>
          <div style="font-size: 16px; font-weight: 600; color: #333333; margin-top: 24px">
            第{{ statisticPhaseInfo.phaseNumber }}阶段：{{ statisticPhaseInfo.phaseName }}
          </div>
        </a-card>

        <statistic-detail ref="table" :lastThreeDaysTopRight="lastThreeDaysTopRight"></statistic-detail>
      </a-col>
    </template>
    <!--学前准备-->
    <template v-if="current === 'PREPARATION'">
      <a-col :span="24">
        <a-card :bodyStyle="{ padding: 0 }">
          <a-button slot="extra" shape="round" @click="switchCurrent('DETAIL', query)"
            ><a-icon type="left" />返回</a-button
          >
          <preparation-table ref="table"></preparation-table>
        </a-card>
      </a-col>
    </template>

    <!--导出计划-->
    <template v-if="current === 'PLAN_EXPORT'">
      <export-plan-table ref="table" @switch="switchCurrent('DETAIL', query)"></export-plan-table>
    </template>

    <!--左侧菜单-->
    <template v-if="['DETAIL', 'HISTORY_DETAIL'].includes(current)">
      <a-col :lg="3" :md="5" :xs="7" style="padding-right: 0">
        <a-affix v-if="ready" :offset-top="24">
          <a-card title="领域" :bodyStyle="{ padding: '0 0 40px 0' }" class="aside-wrapper">
            <a-tree
              v-if="fieldsOptions.length > 0"
              :tree-data="fieldsOptions"
              default-expand-all
              :replaceFields="{ title: 'name', key: 'id' }"
              @select="onSelect"
            ></a-tree>
          </a-card>
        </a-affix>
      </a-col>

      <a-col :lg="21" :md="19" :xs="17">
        <a-card :bodyStyle="{ padding: '0 24px' }">
          <div slot="extra" style="color: red">
            <a-space v-if="current === 'DETAIL'" :size="8" class="buttons">
              <h-select v-model="query.status" allowClear placeholder="全部状态" @change="initData(query)">
                <a-select-option v-for="statusInfo in statusOptions" :key="statusInfo.value" style="width: 100%">
                  {{ statusInfo.label }}
                </a-select-option>
              </h-select>
              <a-button
                v-if="$auth('plans_status') && planWriteAndTeacherAuthority && !isSchoolStatus"
                :disabled="!modified"
                :loading="publishLoading"
                type="primary"
                shape="round"
                @click="handlePublish"
              >
                <a-icon v-if="!publishLoading" type="rocket" />发布
              </a-button>
              <a-button
                v-if="
                  query.status != 'COMPLETED' &&
                  planWriteAndTeacherAuthority &&
                  $auth('plans_skills_add') &&
                  !isSchoolStatus
                "
                type="primary"
                shape="round"
                @click="handleCreate"
                ><a-icon type="plus" />添加计划</a-button
              >
              <a-button
                v-if="$auth('export_plan') && isShowExportPlan"
                type="primary"
                shape="round"
                @click="handleExportPlan"
                ><a-icon type="plus" />导出计划</a-button
              >
              <a-button
                v-if="$auth('plan_history_select')"
                type="primary"
                shape="round"
                @click="switchCurrent('HISTORY', query)"
              >
                <a-icon type="history" />历史
              </a-button>
            </a-space>
            <a-button
              v-if="['HISTORY_DETAIL'].includes(current)"
              shape="round"
              @click="switchCurrent('HISTORY', query)"
            >
              <a-icon type="left" />返回
            </a-button>
          </div>
          <plan-detail
            v-if="current === 'DETAIL'"
            ref="table"
            @preparation-check="handlePreparationDetail"
            @statistic-check="handleStatisticDetail"
            @teaching-video="handleTeachingVideo"
            @publish="(e) => (modified = e)"
            @createdInitData="createdInitData"
            @changeShowExportPlan="changeShowExportPlan"
          ></plan-detail>
          <history-detail
            v-if="current === 'HISTORY_DETAIL'"
            @history-statistic-detail="historyStatisticDetail"
            ref="table"
          ></history-detail>
        </a-card>
      </a-col>

      <!--查看教学视频-->
      <Model ref="model" @playVideo="playVideo"></Model>

      <!--播放视频-->
      <Video ref="video"></Video>
    </template>
  </a-row>
</template>

<script>
import * as commonApi from '@/api/common'
import * as planStatisticsApi from '@/api/phase-material-statistics'
import { scrollTo } from '@/utils/domUtil'
import { PLAN_SKILL_STATUS } from '@/store/dictionary'
import PlanDetail from './components/Detail'
import StatisticDetail from './statistic/index'
import PreparationTable from './components/PreparationTable'
import HistoryTable from './components/HistoryTable'
import HistoryDetail from './components/HistoryDetail'
import ExportPlanTable from './components/exportPlanTable'
import Model from './dialog/Model'
import Video from '@/components/video'
import { mapGetters } from 'vuex'
export default {
  components: {
    PlanDetail,
    StatisticDetail,
    PreparationTable,
    HistoryTable,
    HistoryDetail,
    ExportPlanTable,
    Model,
    Video,
  },
  data() {
    return {
      defaultSelectedKeys: [''],
      ready: false,
      current: 'DETAIL',
      modified: false,
      publishLoading: false,
      query: {
        id: '',
        fieldId: undefined,
        mainFieldId: undefined,
        status: 'PUBLISHED',
      },
      statisticItem: {},
      fieldsOptions: [],
      statisticFieldsOptions: [], // 数据统计-左侧树状菜单
      statisticPhaseList: [], // 数据统计-顶部阶段展示
      statisticPhaseDefault: '', // 数据统计-顶部-选中阶段id
      statisticPhaseInfo: {}, // 数据统计-顶部-选中阶段信息
      statusOptions: PLAN_SKILL_STATUS,
      isData: true,
      planWriteAndTeacherAuthority: false, // 是否显示（计划撰写师、教师角色权限）
      isShowExportPlan: false, // 是否展示导出计划按钮
    }
  },
  activated() {
    if (this.ready) {
      this.ready = false
      this.initOptions()
    }

    this.$nextTick(() => {
      this.ready = true
    })
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
  },
  created() {
    const { isPlanWrite, isTeacher } = JSON.parse(this.$route.query.row)
    if (isTeacher && !isPlanWrite) {
      this.planWriteAndTeacherAuthority = false
    } else {
      this.planWriteAndTeacherAuthority = true
    }
  },
  mounted() {
    const { initData, initOptions } = this

    this.$nextTick(() => {
      this.ready = true
    })

    initOptions()
    initData(this.query)
  },
  methods: {
    initData(query = {}) {
      const { $nextTick, $refs } = this
      $nextTick(() => {
        $refs.table.initData(query)
      })
    },
    createdInitData() {
      if (this.isData) {
        this.query.status = 'CREATED'
        this.initData(this.query)
        this.isData = false
      }
    },
    initOptions() {
      commonApi.fieldsOptions().then((res) => {
        this.fieldsOptions = res.data.filter((v) => v.level === 'MAIN')
      })
    },
    // 改变导出计划按钮显示状态
    changeShowExportPlan(e) {
      this.isShowExportPlan = e
    },
    switchCurrent(val, query = {}) {
      this.current = val
      this.initData(query)
    },
    // 数据统计-树状图-点击
    onSelectStatistic(selectedKeys, info) {
      // 第三级
      const selected = selectedKeys[0]
      // 第二级
      const _fieldId = selected ? info.node.$parent.eventKey : undefined
      // 第一级
      const _mainFieldId = _fieldId ? info.node.$parent.$parent.eventKey : undefined

      // 选中第三级
      if (_mainFieldId && _fieldId && selected) {
        const { initData } = this

        // 获取相应的阶段,并设置默认选中
        for (const item of this.statisticFieldsOptions) {
          const { fieldId, children } = item
          if (fieldId === Number(_mainFieldId) && children) {
            for (const item1 of children) {
              const { fieldId, statisticsSkillList } = item1
              if (fieldId === Number(_fieldId) && statisticsSkillList) {
                for (const item2 of statisticsSkillList) {
                  const { skillId, planSkillId, phaseList } = item2
                  if (planSkillId === Number(selected)) {
                    const activePhase = phaseList.filter((item) => {
                      return item.isCurrent === true
                    })[0]
                    this.statisticPhaseDefault = activePhase.planPhaseId
                    this.statisticPhaseList = phaseList
                    this.statisticPhaseInfo = activePhase
                    this.query = {
                      ...this.query,
                      ...{
                        planSkillId,
                        skillId,
                        mainFieldId: _mainFieldId,
                        fieldId: _fieldId,
                        id: selected,
                        skillPhaseId: activePhase.planPhaseId,
                        recordingMethod: activePhase.recordingMethod,
                      },
                    }
                    initData(this.query)
                  }
                }
              }
            }
          }
        }
      }
    },
    onSelect(selectedKeys, info) {
      const { initData } = this
      const selected = selectedKeys[0]
      const parent = selected ? info.node.$parent.eventKey : undefined
      if (parent) {
        this.query.mainFieldId = parent
        this.query.fieldId = selected
      } else if (selected) {
        this.query.mainFieldId = selected
        this.query.fieldId = undefined
      } else {
        this.query.mainFieldId = undefined
        this.query.fieldId = undefined
      }
      initData(this.query)
    },

    // 历史-详情-查看训练数据
    historyStatisticDetail(item) {
      this.handleStatisticDetail({...item, type: 'date'})
      this.switchCurrent('STATISTIC', item)
      scrollTo(document.documentElement, 0, 128)
      this.lastThreeDaysTopRight = 'date'
    },

    // 历史计划-详情
    handleHistoryDetail(id) {
      this.query.id = id
      this.switchCurrent('HISTORY_DETAIL', { id })
    },
    handlePreparationDetail(item) {
      this.switchCurrent('PREPARATION', item)
    },
    // 点击-统计
    handleStatisticDetail(e) {
      const { activePhaseInfo = {}, skillId, planSkillId, mainFieldId, fieldId, type, planId = '' } = e
      this.defaultSelectedKeys = [planSkillId]

      const { recordingMethod, name: materialName } = activePhaseInfo
      const { id: studentId } = this.$route.params
      this.statisticFieldsOptions = []

      this.statisticItem = activePhaseInfo
      this.current = 'STATISTIC'
      this.lastThreeDaysTopRight = type
      
      // 数据统计-左侧树状图
      planStatisticsApi.commonMaterialAtatisticsSkillPhasetree({ studentId, planId }).then((res) => {
        const { code, data } = res
        if (code === 0) {
          // 格式化数据-显示统计树状结构
          this.statisticFieldsOptions = data.map((item, index) => {
            item.children = item.childrenFieldList
            item.key = item.fieldId
            item.title = item.fieldName
            item.children.map((item1, index1) => {
              item1.children = item1.statisticsSkillList
              item1.key = item1.fieldId
              item1.title = item1.fieldName
              item1.children.map((item2, index2) => {
                item2.key = item2.planSkillId
                item2.title = item2.skillName
                // 设置默认阶段
                if (mainFieldId === item.fieldId && fieldId === item1.fieldId && item2.planSkillId === planSkillId) {
                  const { phaseList } = item2
                  const activePhase = phaseList.filter((item) => {
                    return item.isCurrent === true
                  })[0]
                  this.statisticPhaseDefault = activePhase.planPhaseId
                  this.statisticPhaseList = phaseList
                  this.statisticPhaseInfo = activePhase
                }
                return item2
              })
              return item1
            })
            return item
          })
          if(type === 'tabs') {
            this.query = {
              ...this.query,
              ...{
                id: planSkillId,
                mainFieldId,
                fieldId,
                skillPhaseId: this.statisticPhaseDefault,
                planSkillId,
                skillId,
                recordingMethod,
                name: materialName,
              },
            }
          // console.log(this.query)
            this.switchCurrent('STATISTIC', this.query)
            scrollTo(document.documentElement, 0, 128)
          } 
        }
      })
    },
    // 数据统计-顶部素材切换
    changeStatisticPhase(e) {
      const { initData } = this
      const {
        target: { value },
      } = e
      this.statisticPhaseDefault = value
      // 取当前选中,用于展示
      const activePhase = this.statisticPhaseList.filter((item) => {
        return item.planPhaseId === value
      })[0]

      this.statisticPhaseInfo = activePhase
      const { planPhaseId } = activePhase
      this.query = {
        ...this.query,
        ...{ skillPhaseId: planPhaseId, id: value, planSkillId: value },
      }
      initData(this.query)
    },
    // 查看教学视频
    handleTeachingVideo(item) {
      this.$refs.model.showModal(item)
    },

    // 播放视频
    playVideo({ item }) {
      this.$refs.video.showModal(item)
    },

    handlePublish() {
      this.publishLoading = true
      this.$refs.table.handlePublish().finally(() => (this.publishLoading = false))
    },
    handleCreate() {
      const {
        $route: {
          params: { id: studentId },
        },
      } = this
      // 该学员是否可以添加自选技能包
      commonApi.isAddPlanSkill({ studentId }).then((res) => {
        const { code, data } = res
        const { canAddSelfPlanSkill, trainingPlanId } = data
        if (code === 0) {
          if (trainingPlanId) {
            canAddSelfPlanSkill && this.$refs.table.handleCreate()
            if (!canAddSelfPlanSkill) {
              this.$notification['error']({ message: '您添加的技能包已达上限,请先进行教学' })
            }
          } else {
            this.$notification['error']({ message: '当前学员暂无训练计划，无法添加技能包' })
          }
        }
      })
    },
    // 导出计划
    handleExportPlan() {
      this.switchCurrent('PLAN_EXPORT', {})
    },
    //  统计数据返回
    handleStatisticReturn() {
      const { status } = this.query
      this.query = { status, mainFieldId: '', fieldId: '', id: '' }
      this.switchCurrent('DETAIL', this.query)
    },
  },
}
</script>

<style lang="less" scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
}
.aside-wrapper {
  height: calc(100vh - 48px);
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow-y: auto;
  }
}

.phase-list {
  /deep/ .ant-radio-button-wrapper {
    min-width: 100px;
    padding: 0;
    text-align: center;
    font-size: 14px;
  }

  /deep/ .ant-radio-button-wrapper-checked {
    background: #e1e8ff;
  }
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}

/deep/ .ant-tree li.ant-tree-treenode-switcher-close,
/deep/ .ant-tree li.ant-tree-treenode-switcher-open {
  position: relative;
}
/deep/ .ant-tree li span.ant-tree-switcher {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(67%, 33%);
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper {
  padding: 0;
  padding-left: 40px;
  border-radius: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: @tag-color;
  color: @primary-color;
}
/deep/ .ant-tree > li:first-child {
  padding-top: 0;
}
/deep/ .ant-tree li {
  padding: 0;
}
/deep/.ant-tree li > ul > li span.ant-tree-switcher {
  left: 18px;
}
/deep/.ant-tree li > ul > li > ul {
  padding-left: 18px;
}

/deep/ .ant-tree li ul {
  padding: 0;
  .ant-tree-node-content-wrapper {
    padding-left: 58px;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    ul {
      padding-left: 18px;
    }
  }
}
</style>
