<!--  家校训练服务-详情-评估结果-评估答题 -->
<template>
  <div class="answer">
    <div class="content-container">
      <div class="field">
        <div class="field_l">当前评估领域：{{ field || '-' }}-{{ currentQuestion.field.name || '-' }}</div>
        <a-button shape="round" @click="$emit('return')"> <a-icon type="left" />返回评估领域 </a-button>
      </div>
      <div class="code">题目编号：{{ formatCode || '--' }}</div>
      <div class="title">
        <div class="title-l">题干：{{ currentQuestion.questionStem || '--' }}</div>
        <!-- 答题进度 -->
        <div class="answer-progress">
          当前答题进度：
          <span>{{ query.totalSubject - subjectList.length + 1 + subjectIndex || '--' }}</span>
          <span>/{{ query.totalSubject || '--' }}</span>
        </div>
      </div>
      <span class="example">
        <span class="example-l">示例：</span><span class="example-r">{{ currentQuestion.instance || '--' }}</span>
        <a-button type="link" @click="viewInformation">
          {{ pictureOrVideo === 'VIDEO' ? '查看视频' : pictureOrVideo === 'IMAGE' ? '查看图片' : '' }}
        </a-button>
      </span>
      <div class="description"><span class="type">多选题</span>请在以下选项中，选出符合儿童情况的所有选项</div>
      <div class="option">
        <!-- 普通选项 -->
        <a-checkbox-group v-model="currentQuestion.selectOptions" style="width: 100%">
          <div
            v-for="(item, index) in currentQuestion.optionList"
            class="option-box"
            v-if="item.score != 0"
            :key="index"
            :class="currentQuestion.selectOptions.indexOf(item.id) === -1 ? '' : 'active'"
          >
            <a-checkbox :value="item.id" :disabled="currentQuestion.choiceAll">
              <div
                class="option-description"
                :class="currentQuestion.selectOptions.indexOf(item.id) === -1 ? '' : 'hot'"
              >
                {{ item.description }}
              </div>
            </a-checkbox>
          </div>
        </a-checkbox-group>
        <!-- 最后一个都不可选 -->
        <div
          v-for="(item, index) in currentQuestion.optionList"
          :key="index"
          class="option-box"
          v-if="item.score == 0"
          :class="currentQuestion.selectOptions.indexOf(item.id) === -1 ? '' : 'active'"
        >
          <a-checkbox v-model="currentQuestion.choiceAll" icon-size="14" @change="changeNoOption(item)">
            <div class="option-description" :class="currentQuestion.choiceAll ? 'hot' : ''">
              {{ item.description }}
            </div>
          </a-checkbox>
        </div>
      </div>
    </div>
    <!-- 最后一题 -->
    <div class="submit-bottom submit-bottom-last" v-if="subjectIndex + 1 == subjectList.length">
      <div class="prev-question" @click="prevQuestion()" style="margin-right: 30px">上一题</div>
      <div class="submit" @click="submit()">评估完成</div>
    </div>
    <!-- 第一题 -->
    <div class="submit-bottom" v-else-if="subjectIndex == 0">
      <div class="next-question" @click="nextQuestion({ isSubmit: false })">下一题</div>
    </div>
    <!-- 中间题目 -->
    <div class="submit-bottom" v-else>
      <div class="prev-question" @click="prevQuestion()" style="margin-right: 30px">上一题</div>
      <div class="prev-question" @click="nextQuestion({ isSubmit: false })">下一题</div>
    </div>

    <!--播放视频-->
    <Video ref="video"></Video>

    <!-- 失败的信息提示 -->
    <a-modal
      v-model="visible"
      width="400px"
      title="信息提示"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      :bodyStyle="{ padding: 0 }"
      :ok-button-props="{
        props: { shape: 'round', loading: buttonLoading, disabled: buttonLoading }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-card style="height: 100%; overflow: auto">
        <div style="display: flex; align-content: center; align-items: center;margin-bottom: 10px;">
          <a-icon type="question-circle" style="color: #faad14; font-size: 20px; margin-right: 5px;" />
          确认要提交{{ field }}的评估结果吗？
        </div>
        <div>提交后，在下一次整体重新评估之前，无法修改评估结果</div>
      </a-card>
    </a-modal>
  </div>
</template>
<script>
import * as planApi from '@/api/plan'
import Video from '@/components/video'
import { mapActions } from 'vuex'

export default {
  props: {
    answerData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      buttonLoading: false,
      subjectIndex: 0, // 显示题目的索引
      subjectList: [], // 所有的题目
      // 当前作答题目
      currentQuestion: {
        field: {
          name: ''
        },
        code: '',
        resourceList: []
      },
      field: '',
      query: {
        evaluationFieldId: '', // 领域id
        totalSubject: '' // 题目总数
      }
    }
  },
  created() {
    const { id: evaluationFieldId, totalSubject, name: field } = this.answerData
    this.query = { evaluationFieldId, totalSubject }
    this.field = field
    this.getTitleAll()
  },

  computed: {
    // title最多显示3位code
    formatCode() {
      const { code } = this.currentQuestion
      const n = code && code.slice(8)
      const codeLengthLimit = 3
      return (Array(codeLengthLimit).join(0) + n).slice(-codeLengthLimit)
    },
    pictureOrVideo() {
      const { resourceList } = this.currentQuestion
      if (resourceList && resourceList.length !== 0) {
        const { type } = resourceList[0]
        return type
      }
      return false
    }
  },
  methods: {
    ...mapActions(['changeSpinning']),

    // 拉全部题目(已答+未答)
    getTitleAll() {
      const { evaluationFieldId } = this.query
      this.changeSpinning(true)
      planApi
        .searchAllSubject(evaluationFieldId)
        .then(res => {
          if (res.code === 0) {
            const { answerSubjectList, noAnswerSubjectList } = res.data
            // 当前索引
            this.subjectIndex = answerSubjectList.length
            // 合并所有题目
            const subjectList = [...answerSubjectList, ...noAnswerSubjectList]
            // 拼装数据
            subjectList.map(item => {
              const { optionList } = item
              // 正常选项、都不可选,排序拼装
              const notOptional = [] // 都不可选
              const __optionList = [] // 正常选项
              const __selectOptions = [] // 用户选择的答案
              optionList &&
                optionList.length &&
                optionList.forEach(item1 => {
                  const { score, isSelect, id } = item1
                  if (score === 0) {
                    notOptional.push(item1)
                    // 都不可选的标记
                    item.choiceAll = !!isSelect
                    !!isSelect && __selectOptions.push(id)
                  } else {
                    __optionList.push(item1)
                    isSelect && __selectOptions.push(id)
                  }
                })
              item.selectOptions = __selectOptions
              item.optionList = [...__optionList, ...notOptional]
              // 都不选的选项
              item.notOptional = notOptional
            })
            this.subjectList = subjectList
            this.currentQuestion = this.subjectList[this.subjectIndex]
          }
        })
        .finally(() => {
          this.changeSpinning(false)
        })
    },
    // 点击-以上都不具备
    changeNoOption(item) {
      this.currentQuestion.selectOptions = []
      if (this.currentQuestion.choiceAll) {
        this.currentQuestion.selectOptions.push(item.id)
      }
    },
    // 下一题
    nextQuestion({ isSubmit }) {
      const { evaluationFieldId } = this.query
      const { selectOptions: optionIdList, id: subjectId } = this.currentQuestion
      if (optionIdList.length === 0) {
        this.$notification['error']({ message: '提示', description: '请回答本题' })
        return
      }
      const params = { optionIdList, evaluationFieldId, subjectId }
      this.changeSpinning(true)
      planApi
        .answer(params)
        .then(res => {
          if (res.code === 0) {
            // 是否最后一题
            if (isSubmit) {
              planApi.evaluationSubmit(this.query.evaluationFieldId).then(res => {
                if (res.code === 0) {
                  this.$emit('finished')
                }
              })
            } else {
              // 切换下一题
              window.scrollTo(0, 0)
              this.subjectIndex++
              this.currentQuestion = this.subjectList[this.subjectIndex]
            }
          }
        })
        .finally(() => {
          this.changeSpinning(false)
        })
    },
    // 上一题
    prevQuestion() {
      if (this.subjectIndex === 0) return
      this.changeSpinning(true)
      window.scrollTo(0, 0)
      this.subjectIndex--
      this.currentQuestion = this.subjectList[this.subjectIndex]
      this.changeSpinning(false)
    },
    // 最后一题-提交
    submit() {
      const { selectOptions: optionIdList } = this.currentQuestion
      if (optionIdList.length === 0) {
        this.$notification['error']({ message: '提示', description: '请回答本题' })
        return
      }
      this.visible = true
    },
    handleOk(){
      this.buttonLoading = true
      this.nextQuestion({ isSubmit: true })
    },
    handleCancel(){
      this.visible = false
      this.buttonLoading = false
    },
    // 查看资料
    viewInformation() {
      const { resourceList } = this.currentQuestion
      console.log(resourceList[0])
      if (resourceList && resourceList.length !== 0) {
        const { type, url } = resourceList[0]
        if (type === 'VIDEO') {
          this.$refs.video.showModal({ teachingVideo: { url } })
        }
        if (type === 'IMAGE') {
          const urls = resourceList.map(item=> item.url)
          this.$viewerApi({
            images: urls
          })
        }
      }
    }
  },
  components: {
    Video
  }
}
</script>
<style lang="less" scoped>
.answer {
  width: 100%;
  padding: 0 20px 0 20px;
  .content-container {
    width: 100%;
    background: #ffffff;
    /*领域*/
    .field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      .field_l {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        align-self: center;
      }
    }
    /*编号*/
    .code {
      font-size: 14px;
      color: #8c8c8c;
      margin-bottom: 32px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      // 答题进度
      .answer-progress {
        display: flex;
        justify-content: center;
        /*line-height: 20px;*/
        /*padding-top: 10px;*/
        font-size: 14px;
        :first-child {
          /*color: #3350c7;*/
          font-weight: 600;
        }
        :last-child {
          /*color: #000000;*/
        }
      }
    }
    .example {
      font-size: 13px;
      margin-top: 4px;
      display: inline-block;
      color: #8c8c8c;
      .example-l {
        position: relative;
      }
      .example-r {
        line-height: 1.5;
      }
    }
    img {
      width: 100%;
      height: 132px;
      margin-top: 8px;
    }
    .description {
      margin: 10px 0 10px 0;
      font-size: 14px;
      color: #000000;
      .type {
        display: inline-block;
        width: 52px;
        line-height: 21px;
        text-align: center;
        margin-right: 5px;
        color: #3350c7;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #3a5ff8;
      }
    }
    .option {
      .option-box {
        background: #f7f8fc;
        width: 100%;
        margin-bottom: 8px;
        font-size: 14px;
        .hot {
          color: #595959;
        }

        /deep/ .ant-checkbox-wrapper {
          padding: 24px 4px;
          display: flex;
          align-items: center;
        }
      }
      .active {
        color: #595959;
      }
    }
  }
  .submit-bottom {
    width: 100%;
    height: 40px;
    padding: 0 8px;
    margin-top: 33px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    .next-question {
      border-radius: 20px;
      width: 160px;
      height: 100%;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      border: 1px solid #3a5ff8;
      color: #3a5ff8;
      cursor: pointer;
    }
    .prev-question {
      border-radius: 20px;
      width: 160px;
      height: 100%;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      border: 1px solid #3a5ff8;
      color: #3a5ff8;
      cursor: pointer;
    }
    .submit {
      border-radius: 20px;
      width: 160px;
      height: 100%;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      background: #4760c6;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .submit-bottom-last {
    display: flex;
    justify-content: center;
    .submit {
      width: 160px;
      cursor: pointer;
    }
  }
}
</style>
