<template>
  <a-card :bodyStyle="{ padding: '16px 0' }">
    <a-row type="flex">
      <a-col :span="8" class="hairline-right" style="display:flex;justify-content:center;">
        <a-row type="flex" :gutter="24" align="middle">
          <a-col>
            <div class="avatar" style="background:#F26163">
              <h-icon type="icon-shoucang" style="font-size:32px"></h-icon>
            </div>
          </a-col>
          <a-col>
            <a-statistic title="待达成技能数量" :value="total" />
            <a-space style="margin-top:4px">
              <span v-if="totalIncre < 0" class="success-color"> <a-icon type="caret-down" />{{ totalIncre }}% </span>
              <span v-if="totalIncre > 0" class="error-color"> <a-icon type="caret-up" />{{ totalIncre }}% </span>
              <span v-if="totalIncre === 0"> <a-icon type="caret-right" />{{ totalIncre }}% </span>
              <span class="disabled-color">同比上月</span>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="8" class="hairline-right" style="display:flex;justify-content:center;">
        <a-row type="flex" :gutter="24" align="middle">
          <a-col>
            <div class="avatar" style="background:#6381F7">
              <h-icon type="icon-shoucang" style="font-size:32px"></h-icon>
            </div>
          </a-col>
          <a-col>
            <a-statistic title="已训练技能数量" :value="training" />
            <a-space style="margin-top:4px">
              <span v-if="trainingIncre < 0" class="success-color">
                <a-icon type="caret-down" />{{ trainingIncre }}%
              </span>
              <span v-if="trainingIncre > 0" class="error-color"> <a-icon type="caret-up" />{{ trainingIncre }}% </span>
              <span v-if="trainingIncre === 0"> <a-icon type="caret-right" />{{ trainingIncre }}% </span>
              <span class="disabled-color">同比上月</span>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="8" style="display:flex;justify-content:center;">
        <a-row type="flex" :gutter="24" align="middle">
          <a-col>
            <div class="avatar" style="background:#51C981">
              <h-icon type="icon-shoucang" style="font-size:32px"></h-icon>
            </div>
          </a-col>
          <a-col>
            <a-statistic title="已达标技能数量" :value="upToStandard" />
            <a-space style="margin-top:4px">
              <span v-if="upToStandardIncre < 0" class="success-color">
                <a-icon type="caret-down" />{{ upToStandardIncre }}%
              </span>
              <span v-if="upToStandardIncre > 0" class="error-color">
                <a-icon type="caret-up" />{{ upToStandardIncre }}%
              </span>
              <span v-if="upToStandardIncre === 0"> <a-icon type="caret-right" />{{ upToStandardIncre }}% </span>
              <span class="disabled-color">同比上月</span>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import * as trainingSkillStaApi from '@/api/training-skill-statistics'
export default {
  data() {
    return {
      beforeTotal: 0,
      beforeTraining: 0,
      beforeUpToStandard: 0,
      total: 0,
      training: 0,
      upToStandard: 0
    }
  },
  computed: {
    totalIncre() {
      return parseInt(((this.total - this.beforeTotal) / this.total) * 100) || 0
    },
    trainingIncre() {
      return parseInt(((this.training - this.beforeTraining) / this.training) * 100) || 0
    },
    upToStandardIncre() {
      return parseInt(((this.upToStandard - this.beforeUpToStandard) / this.upToStandard) * 100) || 0
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id: studentId }
        }
      } = this
      this.query = { studentId }
      this.getData()
    },
    getData() {
      trainingSkillStaApi.skillsNumbers(this.query).then(res => {
        const { data } = res

        for (const key in data) {
          this[key] = data[key] || 0
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-statistic-content {
  font-size: 30px;
  line-height: 30px;
}
.hairline-right {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: @border-color-base;
  }
}
.avatar {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
