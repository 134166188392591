<template>
  <a-modal
    v-model="visible"
    title="确定技能包"
    width="716px"
    :destroyOnClose="true"
    :body-style="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round' } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
  >
    <a-card title="作业日期" :body-style="{ paddingTop: 0 }">
      {{ form.startDate === form.endDate ? form.startDate : `${form.startDate} —— ${form.endDate}` }}
    </a-card>
    <a-card title="选择的技能包：">
      <!-- 选择的技能包进行展示 -->
      <a-row :gutter="[16, 16]">
        <a-col v-for="(skillInfo, index) in currentList" :key="index" :span="24">
          <a-row type="flex" align="middle" class="comp-bg" style="flex-wrap: nowrap; padding: 12px">
            <a-col flex="0 1 80px" style="margin-right: 24px">
              <a-avatar :size="80" shape="square" :src="skillInfo.skill ? skillInfo.skill.coverUrl : ''"></a-avatar>
            </a-col>

            <a-col flex="0 1 100%" style="line-height: 1.5">
              <a-row type="flex" align="middle">
                <a-col class="desc">
                  <span class="heading-color">{{ skillInfo.skill ? skillInfo.skill.name : '-' }}</span>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="flex-wrap: nowrap; margin-top: 16px; font-size: 12px">
                <a-col class="desc">
                  所属分类:<span style="margin-left: 8px"
                    >{{ skillInfo.skill.field.parent.name || '-' }} >> {{ skillInfo.skill.field.name || '-' }}</span
                  >
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                <a-col flex="1" class="desc">
                  技能目标:<span style="margin-left: 8px">{{ skillInfo.skill.goal }}</span>
                </a-col>
              </a-row>
              <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                <a-col flex="1" class="desc">
                  阶段名称:<span style="margin-left: 8px">{{ skillInfo.planPhase.name }}</span>
                </a-col>
              </a-row>
              <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                <a-col flex="1" class="desc">
                  最低训练次数:<span style="margin-left: 8px">{{ skillInfo.planPhase.minimumRounds }} 次</span>
                </a-col>
              </a-row>
              <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                <a-col flex="1" class="desc">
                  素材:<span
                    style="margin-left: 8px"
                    v-for="(item, matindex) in skillInfo.form.materialList"
                    :key="matindex"
                    >{{ matindex === parseInt(skillInfo.form.materialList.length) - 1 ? item : `${item}，` }}</span
                  >
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-pagination
        style="display: flex; justify-content: flex-end; margin: 10px"
        v-model="pagination.pageIndex"
        :page-size="pagination.pageSize"
        :total="skillList.length"
        size="small"
        show-less-items
        @change="pageIndexChange"
      ></a-pagination>
    </a-card>
  </a-modal>
</template>

<script>
import * as homeworkApi from '@/api/homework'

export default {
  data() {
    return {
      visible: false,
      form: {},
      skillList: [],
      pagination: {
        pageIndex: 1,
        pageSize: 2
      }, // 分页设置
      currentList: [],
      isAjax: false // 是否在提交作业
    }
  },
  methods: {
    showModal(skill, formdata) {
      this.visible = true
      this.skillList = skill
      this.form = formdata
      this.pageIndexChange()
    },
    pageIndexChange() {
      const {
        pagination: { pageIndex, pageSize }
      } = this
      this.currentList = this.skillList.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
    },
    // 点击弹窗的确定
    handleOk() {
      if (!this.isAjax) {
        this.isAjax = true
        homeworkApi
          .addhomework(this.form)
          .then(res => {
            if (res.code === 0) {
              this.visible = false
              this.skillList = []
              this.form = {}
              this.currentList = []
              this.$notification.success({
                message: '完成',
                description: '成功添加作业'
              })
              this.$emit('submit')
            }
          })
          .then(res => {
            this.isAjax = false
          })
      } else {
        this.$notification.warning({
          message: '警告',
          description: '作业正在提交中，请勿再次提交'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
