<template>
  <div>
    <a-card :title="title" :body-style="{ padding: 0 }">
      <div slot="extra">
        <a-upload name="file" :showUploadList="showUploadList" :before-upload="beforeUpload" :customRequest="() => {}">
          <a-button type="primary" shape="round"> <a-icon :type="type" />新建干预记录</a-button>
        </a-upload>
      </div>
      <report-table ref="table"></report-table>
    </a-card>
  </div>
</template>

<script>
import ReportTable from '../monthly-datareport/components/Table'
import { addReport, publishCount } from '@/api/report'
import * as uploadApi from '@/api/upload'
export default {
  name: 'MonthlyData',
  components: {
    ReportTable
  },
  data() {
    return {
      title: '',
      showUploadList: false,
      type: 'upload',
      query: {}
    }
  },
  mounted() {
    const {
      $route: {
        params: { id }
      }
    } = this
    this.query.studentId = id
    this.query.reportType = 'SUPERVISION_RECORD'
    this.publishCount()
    this.initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData(this.query)
    },
    beforeUpload(file) {
      const isFileType = file.type === 'application/pdf'
      if (!isFileType) {
        this.$message.error('请上传pdf文件')
        return false
      }
      this.type = 'loading'
      const formData = new FormData()
      formData.append('file', file)
      return uploadApi
        .resource({
          formData,
          onUploadProgress: progressEvent => {
            this.type = 'loading'
          }
        })
        .then(res => {
          this.$message.success(`${file.name} 文件上传成功`)
          this.addReport(res.data)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          this.type = 'upload'
        })
    },
    addReport(resource = {}) {
      const params = {
        ...this.query,
        resource
      }
      addReport(params).then(res => {
        if (res.code === 0) {
          this.type = 'upload'
          this.$refs.table.initData(params)
        }
      })
    },
    publishCount() {
      publishCount(this.query).then(res => {
        if (res.code === 0) {
          this.title = `干预记录已完成${res.data}次`
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
