<template>
  <div class="sub-title">
    <div class="tri" :style="{borderTopColor:COLORS_MAP[field]&&COLORS_MAP[field].primary}"></div>
    <div class="text">
      <div class="text-main">
        <slot></slot>
      </div>
      <div class="text-border" :style="{background:COLORS_MAP[field]&&COLORS_MAP[field].secondary}"></div>
    </div>
  </div>
</template>

<script>
import { COLORS_MAP } from './colors'
export default {
  props: {
    field: {
      type: String,
      default() {
        return 'field'
      },
    },
  },
  data() {
    return {
      COLORS_MAP,
    }
  },
}
</script>

<style lang="less" scoped>
.sub-title {
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 24px;
}
.tri {
  margin-right: 6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 20px;
  border-left-width: 9px;
  border-right-width: 9px;
  border-bottom-width: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.text {
  font-size: 22px;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 30px;
  position: relative;
  z-index: 1;
}
.text-main {
  position: relative;
  z-index: 2;
}
.text-border {
  height: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>