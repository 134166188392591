<template>
  <a-card :bodyStyle="{ padding: '0 24px' }">
    <div slot="title">
      领域达标总览图
    </div>

    <div slot="extra">
      <a-range-picker
        :value="dateRange"
        :disabledDate="disabledDate"
        :mode="mode"
        format="YYYY-MM"
        @panelChange="handlePanelChange"
        :allowClear="false"
      >
        <a-icon slot="suffixIcon" type="calendar" />
      </a-range-picker>
    </div>

    <a-radio-group v-model="fieldId" @change="getData()" size="small" button-style="solid" style="margin-bottom:24px">
      <a-row type="flex" :gutter="[0, 16]">
        <a-col>
          <a-radio-button :value="null">
            全部领域
          </a-radio-button>
        </a-col>
        <a-col v-for="mainField in mainFieldOptions" :key="mainField.id">
          <a-radio-button :value="mainField.id">
            {{ mainField.name }}
          </a-radio-button>
        </a-col>
      </a-row>
    </a-radio-group>

    <div style="height:480px">
      <main-chart :dataSource="list"></main-chart>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import * as fieldApi from '@/api/field'
import * as trainingSkillStaApi from '@/api/training-skill-statistics'
import MainChart from './UpToStandardChart'
export default {
  components: {
    MainChart
  },
  data() {
    return {
      list: [],
      mainFieldOptions: [],
      fieldId: null,
      mode: ['month', 'month'],
      dateRange: [moment().subtract(5, 'months'), moment()]
    }
  },
  mounted() {
    fieldApi.mainList().then(res => {
      this.mainFieldOptions = res.data
    })
    this.initData()
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().startOf('month')
    },
    initData() {
      this.getData()
    },
    getData() {
      const query = this.initQuery()

      trainingSkillStaApi
        .fieldsTrainingCount(query)
        .then(res => (this.list = res.data.map(v => ({ ...v, countDate: moment(v.countDate).format('YYYY-MM-DD') }))))
    },
    initQuery() {
      const {
        $route: {
          params: { id: studentId }
        },
        dateRange,
        fieldId
      } = this
      const [startTimestamp, endTimestamp] = [
        dateRange[0] ? dateRange[0].startOf('month').format('YYYY-MM-DD') : '',
        dateRange[1] ? dateRange[1].endOf('month').format('YYYY-MM-DD') : ''
      ]

      return { studentId, isTraining: false, fieldId, startTimestamp, endTimestamp }
    },
    handlePanelChange(value, mode) {
      this.dateRange = value

      this.getData()
    }
  }
}
</script>

<style lang="less" scoped></style>
