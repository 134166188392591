<template>
  <pdf-page bg="cover">
    <div class="main">
      <div class="title">
        <div>{{ name }}</div>
        <div>小朋友教学进展季报</div>
      </div>
      <div style="margin-top: 18px">
        （{{ start | moment('YYYY年MM月DD日') }}—{{ end | moment('YYYY年MM月DD日') }}）
      </div>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
export default {
  components: {
    PdfPage,
  },
  props: {
    name: {
      type: String,
      default() {
        return '李乐乐'
      },
    },
    start: {
      type: [String, Number, Date],
      default() {
        return new Date()
      },
    },
    end: {
      type: [String, Number, Date],
      default() {
        return new Date()
      },
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  text-align: center;
  padding-top: 84px;
  line-height: 42px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 30px;
}
.title {
  font-size: 68px;
  line-height: 95px;
  letter-spacing: 3px;
}
</style>