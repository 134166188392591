<!-- 日报 -->
<template>
  <a-spin :spinning="spinning">
    <!-- 头部信息和评语 -->
    <div class="header">
      <a-affix :offset-top="0">
        <div class="btn">
          <div v-show="reportStatus !== ''" class="status">
            <img :src="noticeIcon[reportStatus]" />
            <span>{{ noticeText[reportStatus] }}</span>
          </div>
          <div class="button-wrap">
            <!-- 编辑类型下的按钮 -->
            <div v-show="afterRequestEventType === 'edit' || afterRequestEventType === 'reedit'">
              <a-button
                @click="btnSaveReport()"
                type="primary"
                shape="round"
                style="width:134px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-text" />
                暂存并返回
              </a-button>
              <a-button
                v-if="$auth('data_report_export_pdf')"
                @click="btnPreview()"
                type="primary"
                shape="round"
                style="min-width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-search" />
                预览PDF
              </a-button>
              <a-button
                v-if="$auth('data_report_regeneration')"
                @click="btnreGenerate()"
                type="primary"
                shape="round"
                style="width:108px;height:32px;"
              >
                <a-icon type="sync" />
                重新生成
              </a-button>
            </div>
            <!-- 查看类型下的按钮 -->
            <div v-show="afterRequestEventType === 'detail'">
              <a-button
                @click="btnBack()"
                type="primary"
                shape="round"
                style="width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="left" />
                返回
              </a-button>
              <a-button
                v-if="$auth('data_report_export_pdf')"
                @click="btnPreview()"
                type="primary"
                shape="round"
                style="min-width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-search" />
                预览PDF
              </a-button>
            </div>
            <!-- 发布、再次发布类型下的按钮，发布与再次发布流程上无任何区别 -->
            <div v-show="afterRequestEventType === 'publish'">
              <a-button
                @click="btnBack()"
                type="primary"
                shape="round"
                style="width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="left" />
                返回
              </a-button>
              <a-button
                v-if="$auth('data_report_export_pdf')"
                @click="btnPreview()"
                type="primary"
                shape="round"
                style="min-width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-search" />
                预览PDF
              </a-button>
              <a-button
                @click="btnPublish()"
                type="primary"
                shape="round"
                style="width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="rocket" />
                发布
              </a-button>
            </div>
          </div>
        </div>
      </a-affix>
      <div class="title">{{ formQuery.studentName }}小朋友的教学进展日报</div>
      <div class="date">
        {{ convertReportDate(formQuery.startTime) }}<span>({{ formQuery.week }})</span>
      </div>
      <div class="label">教师评语</div>
      <div :class="{ textarea: true, 'hightlight-border': !formQuery.comments && afterRequestEventType === 'reedit' }">
        <a-textarea
          style="min-height:169px;border: 1px solid #DFE0EB;"
          :placeholder="commentsplaceholder"
          :maxLength="1000"
          v-model="formQuery.comments"
          @keyup="isReadOnly ? '' : changeComments()"
          :read-only="isReadOnly"
        >
        </a-textarea>
        <div class="textnumber">{{ formQuery.comments ? formQuery.comments.length : 0 }}/{{ 1000 }}</div>
      </div>
    </div>

    <!-- 今日技能教学 -->
    <div class="date-skill date-item">
      <div class="tabs-name">今日技能教学</div>

      <div class="date-skill-list">
        <div class="list-item" v-for="item in formQuery.teachingTrainingDataReportSkillList" :key="item.id">
          <p class="item-name">{{ item.code }}&nbsp;&nbsp;{{ item.name }}</p>
          <p class="item-domain">{{ item.mainFieldName }} > {{ item.secondFieldName }}</p>
          <div v-for="phase in item.dataReportSkillPhaseList" :key="phase.id">
            <p class="item-phase">第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</p>
            <div class="item-goal">
              <span>记录方式：{{ recordingMethod[phase.recordingMethod] }} </span>
              <span class="goal-result"
                >{{ phase.recordingMethod === 'NUMBER_SUCCESS' ? '目标频率' : '目标正确率' }}：{{
                  phase.recordingMethod === 'NUMBER_SUCCESS'
                    ? `${phase.minutes}分${phase.seconds}秒${phase.frequency}次`
                    : `${phase.successRate}%`
                }}</span
              >
            </div>
            <div class="item-mat" v-for="mat in phase.dataReportSkillPhaseMaterialList" :key="mat.id">
              <div :class="['mat-color', checkMaterialIsGoal(phase, mat) ? 'green' : 'blue']"></div>
              <span class="mat-des" v-if="phase.recordingMethod === 'NUMBER_SUCCESS'"
                >{{ mat.material }}：训练{{ mat.trainingCount }}次，平均次数{{ mat.successRate }}次</span
              >
              <span class="mat-des" v-else
                >{{ mat.material }}：训练{{ mat.trainingCount }}次，正确率{{ mat.successRate }}%</span
              >
              <a-icon
                v-if="checkMaterialIsGoal(phase, mat)"
                type="check-circle"
                theme="twoTone"
                two-tone-color="#52BE7F"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 今日行为记录 -->
    <div class="action date-item" v-if="formQuery.dataReportBehaviorRecordList.length">
      <div class="tabs-name">今日行为记录</div>

      <div class="action-list">
        <div class="menu">
          <div
            :class="{ 'menu-default': true, 'menu-select': currentMenu === ix }"
            v-for="(item, ix) in formQuery.dataReportBehaviorRecordList"
            :key="item.id"
            @click="changeMenu(ix)"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          class="content"
          v-for="(item, ix) in formQuery.dataReportBehaviorRecordList"
          :key="`${item.name}${item.recordingMethod}`"
          v-show="currentMenu === ix"
        >
          <div class="detail">
            <div
              class="detail-item"
              v-for="(record, subix) in item.dataReportBehaviorRecordClassVOList"
              :key="String(subix)"
            >
              <div class="item-tiem">
                <div class="tiem-status"></div>
                <div class="time-text">上课时间：{{ record.classStartTime }} - {{ record.classEndTime }}</div>
              </div>
              <div class="item-dura">
                <span v-for="(dura, duraix) in record.frequencyOrDuration" :key="`${dura}${duraix}`" class="dura-tag">{{
                  computerBehaviorRecord(item.recordingMethod, dura)
                }}</span>
              </div>
            </div>
          </div>
          <div class="label">干预方式及分析</div>
          <div
            :class="{
              textarea: true,
              'hightlight-border': !item.interventionMethod && afterRequestEventType === 'reedit'
            }"
          >
            <a-textarea
              style="min-height:169px;border: 1px solid #dfe0eb;"
              :maxLength="500"
              v-model="item.interventionMethod"
              @keyup="isReadOnly ? '' : changeBehaviorRecord()"
              :read-only="isReadOnly"
            >
            </a-textarea>
            <div class="textnumber">{{ item.interventionMethod ? item.interventionMethod.length : 0 }}/{{ 500 }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 明日教学调整 -->
    <div
      class="tomorrow date-item"
      v-if="
        !(!formQuery.achievementMaterialDataReportSkillList.length && !formQuery.newMaterialDataReportSkillList.length)
      "
    >
      <div class="tabs-name">明日教学调整</div>

      <div class="tomorrow-list">
        <div class="upto" v-if="formQuery.achievementMaterialDataReportSkillList.length">
          <div class="upto-title">今日达标的教学素材</div>

          <div
            class="upto-item"
            v-for="achieve in formQuery.achievementMaterialDataReportSkillList"
            :key="achieve.type + achieve.name"
          >
            <div class="item-status"></div>
            <div class="item-text">
              {{ achieve.code }} {{ achieve.name }}：<span
                v-for="phase in achieve.dataReportSkillPhaseList"
                :key="phase.id"
              >
                <span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.material">{{
                  computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                    ? mat.material
                    : `${mat.material}、`
                }}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="newadd" v-if="formQuery.newMaterialDataReportSkillList.length">
          <div class="newadd-title">明日新增教学素材</div>
          <div
            class="newadd-item"
            v-for="newadd in formQuery.newMaterialDataReportSkillList"
            :key="newadd.type + newadd.name"
          >
            <div class="item-status"></div>
            <div class="item-text">
              {{ newadd.code }} {{ newadd.name }}：<span
                v-for="phase in newadd.dataReportSkillPhaseList"
                :key="phase.id + phase.name"
              >
                <span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.id">{{
                  computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                    ? mat.material
                    : `${mat.material}、`
                }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 今日家庭作业 -->
    <div class="homework" v-if="formQuery.homeworkDataReportSkillList.length">
      <div
        class="homework-tabs"
        v-if="(afterRequestEventType === 'detail' && formQuery.homeworkIsShow) || afterRequestEventType !== 'detail'"
      >
        <div class="tabs-name">今日家庭作业</div>
        <div
          v-if="!isReadOnly"
          class="tabs-btn"
          :title="!formQuery.homeworkIsShow ? '展示' : '隐藏后将不再报告中展示此模块'"
          @click="changeHomeworkShow"
        >
          <img
            :src="
              !formQuery.homeworkIsShow
                ? require('@/assets/report/report/ico_tab_open.png')
                : require('@/assets/report/report/ico_tab_close.png')
            "
          />
          <span>{{ !formQuery.homeworkIsShow ? '展示' : '隐藏' }}</span>
        </div>
      </div>
      <div class="homework-list" v-show="formQuery.homeworkIsShow">
        <div class="list-item" v-for="homework in formQuery.homeworkDataReportSkillList" :key="homework.id">
          <p class="item-name">{{ homework.code }}&nbsp;&nbsp;{{ homework.name }}</p>
          <p class="item-domain">技能领域：{{ homework.mainFieldName }}-{{ homework.secondFieldName }}</p>
          <div v-for="phase in homework.dataReportSkillPhaseList" :key="phase.id">
            <p class="item-phase">第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</p>
            <div class="item-goal">阶段目标：{{ phase.goal }}</div>
            <div class="item-mat">
              <div class="mat-cell"><span>最低练习次数：</span> {{ phase.minimumRounds }}次</div>
              <div>
                <span>练习素材：</span
                ><span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.id">{{
                  computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                    ? mat.material
                    : `${mat.material}、`
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部评语 -->
    <div class="footer">
      <div class="textarea">
        <a-textarea
          style="min-height:169px;border: 1px solid #dfe0eb;"
          :maxLength="1000"
          v-model="formQuery.conclusion"
          @keyup="isReadOnly ? '' : changeConclusion()"
          :read-only="isReadOnly"
        >
        </a-textarea>
        <div class="textnumber">{{ formQuery.conclusion ? formQuery.conclusion.length : 0 }}/{{ 1000 }}</div>
      </div>
    </div>
    <!-- 报告有未填写的内容 -->
    <a-modal
      v-model="visibleCheck"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '继续编辑'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="checkhandleOk"
      @cancel="checkhandleCancel"
    >
      <div class="modaltitle">报告还没有写完哦</div>
      <div class="submodaltitle">请继续完成未填写的内容</div>
    </a-modal>
    <!-- 发布弹窗 -->
    <a-modal
      v-model="visiblePublish"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '发布报告'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="publishhandleOk"
      @cancel="publishhandleCancel"
    >
      <div class="modaltitle">
        您正在发布{{ convertReportDate(formQuery.startTime) }}的日报，
        发布后的<span>报告及评价</span>将同步至家长手机端是否确认发布？
      </div>
    </a-modal>
    <!-- 返回弹窗 -->
    <a-modal
      v-model="visibleBack"
      title="报告数据已成功保存"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '40px', paddingLeft: '24px', paddingBottom: '40px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="backhandleOk"
      @cancel="backhandleCancel"
    >
      <div class="modaltitle">
        是否返回数据报告日历？
      </div>
    </a-modal>
    <!-- 重新生成 -->
    <a-modal
      v-model="visibleregenerate"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '重新生成'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="regeneratehandleOk"
      @cancel="regeneratehandleCancel"
    >
      <div class="modaltitle">
        重新生成报告将重头开始制作， 是否确认重新生成？
      </div>
    </a-modal>
    <!-- 今日评价 -->
    <a-modal
      v-model="visibleEvalution"
      width="716px"
      height="406px"
      :body-style="{ paddingTop: '34px', paddingRight: '0', paddingLeft: '32px', paddingBottom: '56px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '确认'
        }
      }"
      :cancel-button-props="{
        props: { shape: 'round' }
      }"
      @ok="evalutionhandleOk"
      @cancel="evalutionhandleCancel"
    >
      <div class="title-wrap" slot="title">
        <div class="bold">今日评价</div>
        <div class="normal">请选出最符合学生在本教学日中表现的情况</div>
      </div>
      <div class="img-wrap">
        <div
          :class="{ 'img-item': true, 'img-item-select': img.imgtype === evaluationLevel }"
          v-for="(img, ix) in reportEmoji"
          :key="String(ix)"
          @click="changeEmoji(img)"
        >
          <img v-if="img.imgurl" class="item-image" :src="img.imgurl" alt="" />
          <div v-else>{{ img.text }}</div>
        </div>
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import * as Api from '@/api/datareport'
import { convertToChineseNumber } from '@/utils/util'
import moment from 'moment'
export default {
  data() {
    return {
      spinning: true, // 默认加载
      // 实时保存的状态
      noticeIcon: {
        pending: require('@/assets/report/report/ico_pending.png'),
        success: require('@/assets/report/report/ico_success.png'),
        error: require('@/assets/report/report/ico_error.png')
      },
      noticeText: {
        pending: '正在保存中',
        success: '实时保存成功',
        error: '未检测到网络，实时保存失败'
      },
      reportStatus: '',
      // 今日评价表情包
      reportEmoji: [
        { imgtype: '1', imgurl: require('@/assets/report/emoji/emoji_tbkx.png') },
        { imgtype: '2', imgurl: require('@/assets/report/emoji/emoji_hlmm.png') },
        { imgtype: '3', imgurl: require('@/assets/report/emoji/emoji_gqka.png') },
        { imgtype: '4', imgurl: require('@/assets/report/emoji/emoji_wbqj.png') },
        { imgtype: '5', imgurl: require('@/assets/report/emoji/emoji_zjzl.png') },
        { imgtype: '6', imgurl: require('@/assets/report/emoji/emoji_xtq.png') },
        { imgtype: '7', imgurl: require('@/assets/report/emoji/emoji_ksc.png') },
        { imgtype: '8', imgurl: require('@/assets/report/emoji/emoji_qxdl.png') },
        { imgtype: '9', imgurl: require('@/assets/report/emoji/emoji_xkb.png') },
        { imgtype: '10', imgurl: require('@/assets/report/emoji/emoji_bzzt.png') },
        { imgtype: '0', imgurl: '', text: '不评价' }
      ],
      // 选中的表情
      evaluationLevel: '0',
      // 记录方式
      recordingMethod: {
        SUCCESS_RATE: '正确率(回合)',
        DURATION: '时长',
        NUMBER_SUCCESS: '频率',
        STEP_SUCCESS_RATE: '正确率(任务分析)'
      },
      // 事件参数
      eventParam: {},
      // 内部事件类型，用来区分显示按钮，（发布类型可能会在请求接口之后更改）
      afterRequestEventType: '',
      formQuery: {
        id: null, // 报告id
        studentName: '', // 学生姓名
        startTime: '', // 日期
        week: '', // 星期
        comments: '', // 头部评语
        conclusion: '', // 底部结束语
        teachingTrainingDataReportSkillList: [], // 今日技能教学
        dataReportBehaviorRecordList: [], // 行为记录
        achievementMaterialDataReportSkillList: [], // 明日调整下的达标素材
        newMaterialDataReportSkillList: [], // 明日调整下的新增素材
        homeworkDataReportSkillList: [], // 家庭作业
        homeworkIsShow: null, // 家庭作业是否显示
        mechanismName: null, // 机构名称
        createTimestamp: null // 报告创建日期
      },
      commentsplaceholder: '', // 评语占位符
      currentMenu: 0, // 行为记录的菜单切换
      commentsTime: null, // 头部输入框
      recordTime: null, // 行为记录输入框
      conclusionTime: null, // 底部输入框
      visibleCheck: false, // 报告检查
      visiblePublish: false, // 发布
      visibleBack: false, // 返回弹窗
      visibleEvalution: false, // 今日评价
      visibleregenerate: false // 重新生成
    }
  },
  computed: {
    // 是否是查看、发布，这个时候默认为只读，不可编辑
    isReadOnly() {
      const { afterRequestEventType } = this
      const typeArr = ['detail', 'publish']
      if (typeArr.includes(afterRequestEventType)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    convertToChineseNumber,
    // 日期转换
    convertReportDate(date) {
      return moment(date).format('LL')
    },
    // 查询报告数据
    initData(e) {
      // 控制按钮显示
      this.afterRequestEventType = e.type
      this.eventParam = e

      Api.inquireDaily(e.param)
        .then(res => {
          const { code, data } = res
          const newArr = []
          if (code === 0) {
            // 初始化赋值
            for (const name in data) {
              this.formQuery[name] = data[name]
            }
            //  技能教学进行拆分，一个阶段对应一个展示框
            data.teachingTrainingDataReportSkillList.forEach(skill => {
              const newskill = {
                ...skill
              }
              newskill.dataReportSkillPhaseList = []
              skill.dataReportSkillPhaseList.forEach(phase => {
                newskill.dataReportSkillPhaseList.push(phase)
                newArr.push(newskill)
              })
            })
            this.formQuery.teachingTrainingDataReportSkillList = newArr
            // 顶部评语占位字符
            this.commentsplaceholder = `示例：今天我们的${data.studentName}小朋友表现超级棒！课程中大部分时间都保持了不错的学习状态，学习效率相比之前几个教学日有了超级明显的提升。最近老师们在教学中观察到${data.studentName}的语言丰富度、语气词的使用、语言的自发性以及和自发语言与当下情景的关联性等方面都有明显的提升，老师们也会针对${data.studentName}很多和当下相关的自发语言进行发散，增加${data.studentName}自发使用语言表达的频次。在今天的音乐律动活动中，${data.studentName}也表现得非常亮眼，一直跟随音乐跳舞，用小手比出鲨鱼咬咬的动作，做得非常棒哦！${data.studentName}每天都给老师们带来惊喜！截止今天，${data.studentName}有一些教学素材已经达标了，我们会给${data.studentName}换上新的教学素材。`

            // 发布、再次发布类型下，检查报告是否完成
            if (e.type === 'publish') {
              this.checkIsAllDone()
            }
          }
        })
        .finally(() => {
          const { afterRequestEventType } = this
          const typeArr = ['detail', 'edit']
          if (typeArr.includes(afterRequestEventType)) {
            this.spinning = false
          }
        })
    },
    // 检查报告有未填写的内容
    checkIsAllDone() {
      const {
        eventParam: { param }
      } = this
      Api.check({ id: param.dataReportId })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            if (!data) {
              this.visibleCheck = true
            }
          }
        })
        .finally(() => {
          this.spinning = false
        })
    },
    // 是否继续编辑回调
    checkhandleOk() {
      this.visibleCheck = false
      this.afterRequestEventType = 'reedit'
    },
    checkhandleCancel() {
      this.visibleCheck = false
      this.backCalendar()
    },
    // 记录表情包选中
    changeEmoji(img) {
      this.evaluationLevel = img.imgtype
    },
    // 检查素材是否超过目标正确率
    checkMaterialIsGoal(phase, mat) {
      let result = false
      // 根据记录方式，判断频率与其他方式下，素材是否大于等于目标正确率
      if (phase.recordingMethod === 'NUMBER_SUCCESS') {
        result = mat.successRate >= phase.frequency
      } else {
        result = mat.successRate >= phase.successRate
      }
      return result
    },
    // 计算素材是否是数组最后一个
    computerArrayLength(arr, index) {
      if (arr.length) {
        return arr.length - 1 === index
      } else {
        return false
      }
    },
    // 切换行为记录菜单
    changeMenu(number) {
      this.currentMenu = number
    },
    // 计算行为记录的记录方式，返回对应的样式
    computerBehaviorRecord(recordingmethod, value) {
      let result = null
      if (recordingmethod === 'TIMING') {
        result = value
      }
      if (recordingmethod === 'COUNT') {
        result = `${value}`
      }
      if (recordingmethod === 'TIMING_COUNT' || recordingmethod === 'COUNTDOWN_COUNT') {
        const temp = value.split(',')
        result = `${temp[0]}，${temp[1]}`
      }
      return result
    },
    // 监听评语输入框变化
    changeComments(e) {
      if (this.commentsTime) {
        clearTimeout(this.commentsTime)
        this.commentsTime = setTimeout(() => {
          this.saveReport()
        }, 3000)
      } else {
        this.commentsTime = setTimeout(() => {
          this.saveReport()
        }, 3000)
      }
    },
    // 监听行为记录输入框变化
    changeBehaviorRecord(e) {
      if (this.recordTime) {
        clearTimeout(this.recordTime)
        this.recordTime = setTimeout(() => {
          this.saveReport()
        }, 3000)
      } else {
        this.recordTime = setTimeout(() => {
          this.saveReport()
        }, 3000)
      }
    },

    // 监听作业是否隐藏
    changeHomeworkShow(e) {
      this.formQuery.homeworkIsShow = !this.formQuery.homeworkIsShow
      this.saveReport()
    },

    // 监听底部结束语输入框变化
    changeConclusion(e) {
      if (this.conclusionTime) {
        clearTimeout(this.conclusionTime)
        this.conclusionTime = setTimeout(() => {
          this.saveReport()
        }, 3000)
      } else {
        this.conclusionTime = setTimeout(() => {
          this.saveReport()
        }, 3000)
      }
    },
    // 暂存报告,生成所有参数
    saveReport(type = '') {
      const { formQuery } = this
      // 行为记录干预方式文本输入的内容
      const dataReportBehaviorRecordSet = []
      formQuery.dataReportBehaviorRecordList.forEach(record => {
        dataReportBehaviorRecordSet.push({
          behaviorRecordClassId: record.id,
          interventionMethod: record.interventionMethod ? record.interventionMethod : ''
        })
      })
      // 请求参数
      const query = {
        dataReportId: formQuery.id,
        comments: formQuery.comments ? formQuery.comments : '',
        conclusion: formQuery.conclusion,
        homeworkIsShow: formQuery.homeworkIsShow,
        dataReportBehaviorRecordSet
      }
      this.reportStatus = 'pending'
      this.useApiRequest(query, type)
    },
    // 保存报告接口
    useApiRequest(query, type) {
      Api.saveDaily(query)
        .then(res => {
          const { data } = res
          if (data === 'SUCCESS') {
            this.clearReportStatus('success', type)
          }
        })
        .catch(() => {
          this.clearReportStatus('error', type)
        })
    },
    // 清空保存接口状态
    clearReportStatus(status, type) {
      this.reportStatus = status
      if (this.reportStatus !== 'error') {
        if (type === 'btn') {
          this.visibleBack = true
        }
      }
      // const timer = setTimeout(() => {
      //   if (this.reportStatus !== 'error') {
      //     this.reportStatus = ''
      //     clearTimeout(timer)

      //   }
      // }, 3000)
    },
    // 对应的按钮操作
    // 暂存
    btnSaveReport() {
      const type = 'btn'
      this.saveReport(type)
    },
    // 返回弹窗回调
    backhandleOk() {
      this.visibleBack = false
      this.backCalendar()
    },
    backhandleCancel() {
      this.visibleBack = false
    },
    // 直接返回
    btnBack() {
      this.backCalendar()
    },
    // 重置初始值
    setAlldataDefault() {
      this.spinning = true
      this.reportStatus = ''
      this.evaluationLevel = '1'
      this.eventParam = {}
      this.afterRequestEventType = ''
      this.currentMenu = 0
      this.commentsTime = null
      this.recordTime = null
      this.conclusionTime = null
      this.visibleCheck = false
      this.visiblePublish = false
      this.visibleBack = false
      this.visibleEvalution = false
      this.visibleregenerate = false
    },
    backCalendar() {
      // 获取类型，返回日历打开对应的报告月份
      const { eventParam, formQuery } = this
      // 提交返回事件
      const event = {
        type: 'cancel',
        toMenuName: 'calendar',
        toSubMenuName: '',
        param: {
          // 获取类型，返回日历打开对应的报告月份
          workMonth: eventParam.subType ? formQuery.startTime : null
        }
      }
      this.$emit('event', event)

      this.setAlldataDefault()
    },
    // 预览
    btnPreview() {
      // 提交返回事件
      const { formQuery } = this
      const event = {
        type: 'exportPDF',
        toMenuName: 'export',
        toSubMenuName: 'date',
        param: {
          dataReportId: formQuery.id
        }
      }
      this.$emit('event', event)
      this.setAlldataDefault()
    },
    // 重新生成
    btnreGenerate() {
      this.visibleregenerate = true
    },
    regeneratehandleOk() {
      const { formQuery } = this
      this.visibleregenerate = false
      // 提交事件
      const event = {
        type: 'regenerate',
        toMenuName: 'steps',
        toSubMenuName: 'date',
        param: {
          studentId: this.$route.params.id,
          reportDate: formQuery.startTime,
          dataReportId: formQuery.id
        }
      }
      this.$emit('event', event)
      this.setAlldataDefault()
    },
    regeneratehandleCancel() {
      this.visibleregenerate = false
    },
    // 发布
    btnPublish() {
      this.visibleEvalution = true
    },
    // 日报发布需要多一步今日评价
    evalutionhandleOk() {
      const { evaluationLevel } = this
      if (evaluationLevel !== '') {
        this.visibleEvalution = false
        this.visiblePublish = true
      }
    },
    evalutionhandleCancel() {
      this.visibleEvalution = false
    },
    publishhandleOk() {
      const {
        eventParam: { param },
        evaluationLevel
      } = this
      Api.status({ id: param.dataReportId, evaluationLevel }).then(res => {
        const { code } = res
        if (code === 0) {
          this.visiblePublish = false
          this.$notification.success({
            message: '提示',
            description: '报告发布成功'
          })
          this.backCalendar()
        }
      })
      this.setAlldataDefault()
    },
    publishhandleCancel() {
      this.visiblePublish = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-input:focus {
  border: none;
  box-shadow: none;
}
/deep/.ant-input:hover {
  border: none;
  box-shadow: none;
}
.header {
  height: 387px;
  padding: 24px;
  margin: 16px auto;
  background: #ffffff;
  .btn {
    position: relative;
    width: 100%;
    height: 44px;
    background: #ffffff;
    .status {
      position: absolute;
      top: 12px;
      left: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 19px;
        height: 19px;
        margin-right: 7px;
        border-radius: 50%;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
    .button-wrap {
      position: absolute;
      top: 6px;
      right: 0;
    }
  }
  .title {
    height: 28px;
    margin: 16px 0 10px;
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    text-align: center;
    line-height: 28px;
  }
  .date {
    height: 22px;
    margin: 0 0 24px;
    font-size: 16px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    line-height: 22px;
    span {
      margin: 0 0 0 6px;
    }
  }
  .label {
    height: 22px;
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 22px;
  }
  .textarea {
    position: relative;
    min-height: 169px;
    width: 100%;
    .textnumber {
      position: absolute;
      bottom: 16px;
      right: 16px;
      font-size: 14px;
      color: #666666;
    }
  }
  .hightlight-border {
    border: 1px solid #f12a2c;
    border-radius: 4px;
  }
}

.date-item {
  margin-bottom: 16px;
  background: #ffffff;
  .tabs-name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    background: #e1e8ff;
    cursor: pointer;
  }
}

.date-skill {
  .date-skill-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .list-item {
      width: 48.5%;
      padding: 16px;
      margin-bottom: 1%;
      border: 1px solid #dfe0eb;
      font-size: 14px;
      font-weight: 400;
      .item-name {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .item-domain {
        margin: 4px 0 8px;
        color: #3a5ff8;
      }
      .item-phase {
        margin: 0;
        color: #1a1a1a;
      }
      .item-goal {
        margin: 4px 0 16px;
        color: #757575;
        .goal-result {
          padding-left: 16px;
          color: #39a54d;
        }
      }
      .item-mat {
        display: flex;
        align-items: center;
        margin: 4px 0;
        color: #757575;
        .mat-color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
        .blue {
          background-color: #3a5ff8;
        }
        .green {
          background-color: #39a54d;
        }
        .mat-des {
          margin: 0 9px;
        }
      }
    }

    .list-item:nth-of-type(odd) {
      margin-left: 1%;
      margin-right: 1%;
    }

    .list-item:nth-of-type(even) {
      margin-right: 1%;
    }

    .list-item:first-child,
    .list-item:nth-child(2) {
      margin-top: 1%;
    }
  }
}

.action {
  .action-list {
    padding: 24px;
    .menu {
      display: flex;
      align-items: center;
      text-align: center; // 添加滚动
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      cursor: pointer;
      .menu-default {
        height: 48px;
        padding: 0 28px;
        border: 1px solid #dfe0eb;
        font-size: 14px;
        font-weight: 500;
        color: #1a1a1a;
        text-align: center;
        line-height: 48px;
      }
      .menu-select {
        background: #e1e8ff;
      }
    }
    /*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
    .menu::-webkit-scrollbar {
      width: 4px;
      height: 6px;
      background-color: rgba(0, 0, 0, 0.08);
    }
    /*定义滚动条轨道
 内阴影+圆角*/
    .menu::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0);
    }
    /*定义滑块
 内阴影+圆角*/
    .menu::-webkit-scrollbar-thumb {
      border-radius: 16px;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
      background-color: rgba(144, 147, 153, 0.3);
    }
    .content {
      padding: 0 16px;

      .detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 16px 0 0;
        .detail-item {
          width: 46%;
          margin-bottom: 24px;
          .item-tiem {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #1a1a1a;
            .tiem-status {
              width: 8px;
              height: 8px;
              background-color: #3a5ff8;
              border-radius: 50%;
            }
            .time-text {
              margin: 0 8px;
            }
          }
          .item-dura {
            display: flex;
            flex-wrap: wrap;
            margin: 14px 0 0 16px;
            .dura-tag {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px 16px;
              margin: 0 8px 8px 0;
              border-radius: 20px;
              border: 1px solid #3a5ff8;
              font-size: 12px;
              font-weight: 400;
              color: #3a5ff8;
            }
          }
        }
      }
      .label {
        height: 22px;
        margin: 0 0 16px;
        font-size: 16px;
        font-weight: 500;
        color: #1a1a1a;
        line-height: 22px;
      }
      .textarea {
        position: relative;
        min-height: 169px;
        width: 100%;
        .textnumber {
          position: absolute;
          bottom: 16px;
          right: 16px;
          font-size: 14px;
          color: #666666;
        }
      }
      .hightlight-border {
        border: 1px solid #f12a2c;
        border-radius: 4px;
      }
    }
  }
}

.tomorrow {
  .tomorrow-list {
    padding: 24px;
    .upto {
      margin: 0 0 16px;
      .upto-title {
        font-size: 16px;
        font-weight: 500;
        color: #1a1a1a;
      }
      .upto-item {
        display: flex;
        align-items: center;
        margin: 9px 0;
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        .item-status {
          width: 8px;
          height: 8px;
          margin-right: 7px;
          background: #33a648;
          border-radius: 50%;
        }
      }
    }
    .newadd {
      .newadd-title {
        font-size: 16px;
        font-weight: 500;
        color: #1a1a1a;
      }
      .newadd-item {
        display: flex;
        align-items: center;
        margin: 9px 0;
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        .item-status {
          width: 8px;
          height: 8px;
          margin-right: 7px;
          background: #3a5ff8;
          border-radius: 50%;
        }
      }
    }
  }
}

.homework {
  margin-bottom: 16px;
  background: #ffffff;
  .homework-tabs {
    position: relative;
    height: 54px;
    background: #e1e8ff;
    cursor: pointer;
    .tabs-name {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      font-size: 16px;
      font-weight: 500;
      color: #1a1a1a;
    }
    .tabs-btn {
      position: absolute;
      right: 24px;
      top: 17px;
      font-size: 14px;
      font-weight: 400;
      color: #3a5ff8;
      cursor: pointer;
      img {
        width: 20px;
        height: 10px;
        margin-right: 7px;
      }
    }
  }

  .homework-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .list-item {
      width: 48.5%;
      margin-bottom: 1%;
      padding: 16px;
      border: 1px solid #dfe0eb;
      font-size: 14px;
      font-weight: 400;
      .item-name {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .item-domain {
        margin: 4px 0 8px;
        color: #3a5ff8;
      }
      .item-phase {
        margin: 0;
        color: #1a1a1a;
      }
      .item-goal {
        margin: 4px 0 9px;
        color: #757575;
      }
      .item-mat {
        align-items: center;
        margin: 4px 0;
        color: #787878;
        font-size: 14px;
        font-weight: 400;
        .mat-cell {
          margin-right: 40px;
        }
        span {
          color: #1a1a1a;
        }
      }
    }

    .list-item:nth-of-type(odd) {
      margin-left: 1%;
      margin-right: 1%;
    }

    .list-item:nth-of-type(even) {
      margin-right: 1%;
    }

    .list-item:first-child,
    .list-item:nth-child(2) {
      margin-top: 1%;
    }
  }
}
.footer {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  margin-bottom: 16px;
  background: #ffffff;
  .textarea {
    position: relative;
    min-height: 169px;
    width: 100%;
    .textnumber {
      position: absolute;
      bottom: 16px;
      right: 16px;
      font-size: 14px;
      color: #666666;
      font-weight: 500;
    }
  }
}

/deep/ .ant-modal-content {
  border-radius: 12px;
}
/deep/ .ant-modal-header {
  border-radius: 12px 12px 0 0;
}
.modaltitle {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  span {
    color: #3a5ff8;
  }
}
.submodaltitle {
  margin: 8px 0 0;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.title-wrap {
  display: flex;
  align-items: baseline;
  .bold {
    margin-right: 8px;
    font-size: 24px;
    font-weight: 500;
    color: #333333;
  }
  .normal {
    font-size: 16px;
    color: #333333;
  }
}
.img-wrap {
  display: flex;
  flex-wrap: wrap;
  .img-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 107px;
    margin: 0 24px 24px 0;
    border-radius: 4px;
    border: 1px solid #dfe0eb;
    cursor: pointer;
    .item-image {
      width: 48px;
    }
  }
  .img-item-select {
    background: #f5f6ff;
    border: 1px solid #576fd1;
  }
}
</style>
