<!-- 家校训练服务-详情-评估结果-阶段列表-查看报告 -->
<template>
  <a-modal
    v-model="visible"
    :title="title()"
    width="700px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :footer="null"
  >
    <a-card style="height: 100%; overflow: auto">
      <div class="title title1">
        <div style="font-weight: 600">{{ data.domain.name || '--' }}</div>
        <div>
          此次得分：<span>{{ `${data.domain.score}分` || '--' }}</span>
        </div>
        <div>
          得分率：<span>{{ `${data.domain.successRate}%` || '--' }}</span>
        </div>
        <div v-show="String(data.domain.comparedToLastTimeSuccessRate) !== 'null'">
          相比上次测评{{ data.domain.comparedToLastTimeSuccessRate > 0 ? '增加' : '减少' }}：<span
            :class="{ 'reduce-color': data.domain.comparedToLastTimeSuccessRate > 0 }"
            >{{ `${data.domain.comparedToLastTimeSuccessRate}%` }}</span
          >
        </div>
      </div>
      <div class="title title2" v-for="(item, i) in data.subDomains" :key="i">
        <div style="font-weight: 600">{{ item.name || '--' }}</div>
        <div>
          此次得分：<span>{{ `${item.score}分` || '--' }}</span>
        </div>
        <div>
          得分率：<span>{{ `${item.successRate}%` || '--' }}</span>
        </div>
        <div v-show="String(data.domain.comparedToLastTimeSuccessRate) !== 'null'">
          相比上次测评{{ item.comparedToLastTimeSuccessRate > 0 ? '增加' : '减少' }}：<span
            :class="{ 'reduce-color': item.comparedToLastTimeSuccessRate > 0 }"
            >{{ `${item.comparedToLastTimeSuccessRate}%` || '--' }}</span
          >
        </div>
      </div>
    </a-card>
    <div style="padding: 10px 16px; text-align: right">
      <a-button type="primary" shape="round" @click="handleOk">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      data: {
        domain: {},
        subDomains: {}
      }
    }
  },
  methods: {
    title() {
      return `得分情况-${this.data.domain.name}`
    },

    showModal(data) {
      this.data = data
      this.visible = true
    },

    handleOk() {
      this.visible = false
      this.data = {
        domain: {},
        subDomains: {}
      }
    }
  }
}
</script>

<style lang="less" scoped>
/*/deep/ .ant-modal-body {*/
/*  height: calc(100vh - 200px - 110px);*/
/*  overflow-y: auto;*/
/*}*/
/deep/ .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
/deep/ .ant-card-body {
  padding: 40px 50px 56px 33px;
}
.title {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  margin-bottom: 8px;
  div > span {
    color: #3a5ff8;
  }

  .reduce-color {
    color: #ff7a55;
  }
}

.title1 {
  box-shadow: inset 5px 0px 0 0#3350C7;
}

.title2 {
  background: #f7f8fc;
  padding: 18px 0;
}
/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
