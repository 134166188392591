import request from '@/utils/request'
const reportApi = {
  list: '/report',
  addReport: '/report',
  remove: id => `/report/${id}`,
  publishCount: '/report/publishCount',
  release: (id, studentId) => `/report/status/${id}/${studentId}`
}
export function list(parameter) {
  return request({
    url: reportApi.list,
    method: 'get',
    params: parameter
  })
}
export function addReport(parameter) {
  return request({
    url: reportApi.addReport,
    method: 'post',
    data: parameter
  })
}
export function remove(parameter) {
  return request({
    url: reportApi.remove(parameter),
    method: 'delete'
  })
}
export function publishCount(parameter) {
  return request({
    url: reportApi.publishCount,
    method: 'get',
    params: parameter
  })
}
export function release(parameter) {
  const { id, studentId } = parameter
  delete parameter.id
  delete parameter.studentId
  return request({
    url: reportApi.release(id, studentId),
    method: 'patch',
    data: parameter
  })
}
