<template>
  <h3 class="title">
    <slot></slot>
  </h3>
</template>

<script>
export default {}
</script>

<style scoped>
.title {
  text-align: center;
  margin: 0;
  height: 54px;
  line-height: 54px;
  font-size: 16px;
  background: rgba(58, 95, 248, 0.15);
}
</style>