<template>
  <div>
    <a-table
      v-if="list.length"
      rowKey="id"
      :loading="listLoading"
      :data-source="list"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <a-table-column
        key="evaluationCompletionNum"
        title="评估完成次数"
        data-index="evaluationCompletionNum"
        align="center"
        :ellipsis="true"
        width="7%"
      />

      <a-table-column
        key="evaluationCompletionDate"
        title="评估完成时间"
        data-index="evaluationCompletionDate"
        align="center"
        :ellipsis="true"
        width="13%"
      >
        <template slot-scope="evaluationCompletionDate">
          <span v-if="evaluationCompletionDate">
            {{ evaluationCompletionDate | moment('YYYY-MM-DD') }}
          </span>
          <span v-else>-</span>
        </template>
      </a-table-column>

      <a-table-column
        key="assessorList"
        title="评估人"
        data-index="assessorList"
        align="center"
        :ellipsis="true"
        width="13%"
      >
        <template slot-scope="assessorList">
          <span>
            {{
              assessorList && assessorList.length > 0
                ? assessorList.map((assessor) => assessor.userName).join('、')
                : '-'
            }}
          </span>
        </template>
      </a-table-column>

      <a-table-column
        key="reportAuthor"
        title="报告撰写人"
        data-index="reportAuthor"
        align="center"
        :ellipsis="true"
        width="13%"
      >
        <template slot-scope="reportAuthor">
          <span>
            {{ reportAuthor || '-' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column
        key="publisher"
        title="报告发布人"
        data-index="publisher"
        align="center"
        :ellipsis="true"
        width="13%"
      >
        <template slot-scope="publisher">
          <span>
            {{ publisher || '-' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column
        key="releaseDate"
        title="发布报告时间"
        data-index="releaseDate"
        align="center"
        :ellipsis="true"
        width="13%"
      >
        <template slot-scope="releaseDate">
          <span v-if="releaseDate">
            {{ releaseDate | moment('YYYY-MM-DD') }}
          </span>
          <span v-else>-</span>
        </template>
      </a-table-column>

      <a-table-column key="status" title="状态" align="center" data-index="status" :ellipsis="true" width="10%">
        <template slot-scope="status">
          <a-badge
            :status="{ POSTED: 'success', UNPOSTED: 'warning', UNGENERATED: 'error' }[status]"
            :text="status | EvaluationReportStatusText()"
          ></a-badge>
        </template>
      </a-table-column>

      <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="15%">
        <template slot-scope="record">
          <!-- 离园 -->
          <div v-if="isSchoolStatus">
            <a-space v-if="['UNGENERATED'].includes(record.status)">-</a-space>
            <a-space v-else>
              <a-button type="link" @click="handleDetail(record, 'view')"> 查看 </a-button>
            </a-space>
          </div>
          <!-- 在园 -->
          <div v-else>
            <a-space v-if="record.status === 'POSTED'">
              <a-button type="link" @click="handleDetail(record, 'view')"> 查看 </a-button>
            </a-space>
            <a-space v-else-if="record.status === 'UNPOSTED'">
              <a-button type="link" @click="handleDetail(record, 'publish')"> 发布 </a-button>
              <a-button type="link" @click="handleDetail(record, 'edit')"> 编辑 </a-button>
              <a-button type="link" @click="handleRemove(record)"> 删除 </a-button>
            </a-space>
            <a-space v-else-if="record.status === 'UNGENERATED'">
              <a-button type="link" @click="handleDetail(record, 'edit')"> 生成报告 </a-button>
            </a-space>
          </div>
        </template>
      </a-table-column>
    </a-table>
    <a-empty v-else :description="false">
      <div slot="image">
        <img src="~@/assets/drill_assess_abnormal@2x.png" style="width: 280px" />
      </div>
    </a-empty>
  </div>
</template>

<script>
import * as evaluationReportApi from '@/api/evaluation-report'
import { mapGetters } from 'vuex'
export default {
  name: 'EvaluationReportTable',
  data() {
    return {
      listLoading: true,
      query: {},
      pagination: {},
      list: []
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus' // 学生-在园状态【true离园  false在园】
    })
  },
  methods: {
    initData(query = {}) {
      const {
        initPagination,
        getData,
        $route: {
          params: { id }
        }
      } = this

      this.query = { studentId: id }
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      this.listLoading = true
      return evaluationReportApi
        .list({
          ...query,
          pageIndex,
          pageSize
        })
        .then(res => {
          const { list, totalItems } = res.data
          this.list = list
          this.pagination.total = totalItems
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h(
              'span',
              {
                class: 'primary-color'
              },
              pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)
            ),
            h('span', ' 页'),
            h('span', ' / '),
            h(
              'span',
              {
                class: 'primary-color'
              },
              total
            ),
            h('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    // 详情
    handleDetail(record, type) {
      const { id } = record
      this.$emit('onRow', { id, type })
    },
    handleRemove(record) {
      const confirm = this.$confirm({
        title: '您正在进行评估报告删除操作',
        content: '删除的评估报告将无法找回，请确认操作。',
        onOk: () => {
          return evaluationReportApi
            .remove(record.id)
            .then(() => {
              this.$notification['success']({ message: '操作成功' })
              this.getData()
            })
            .catch(err => {
              confirm.destroy()
              this.$info({
                title: '不可删除',
                content: err.msg
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}

/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}

@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
