<template>
  <a-card :headStyle="{ padding: '0 24px' }" :bodyStyle="{ padding: '0 24px 24px' }">
    <div slot="title" style="font-size:20px;font-weight:500">
      素材累积达标数
    </div>
    <div slot="extra">
      <a-range-picker v-model="dateRange" :allowClear="false" :disabledDate="disabledDate" @change="onChange">
        <a-icon slot="suffixIcon" type="calendar" />
      </a-range-picker>
    </div>

    <a-empty v-if="empty" />
    <div style="height:480px;border:1px solid #707070" v-else>
      <main-chart :dataSource="list" :query="query"></main-chart>
    </div>
  </a-card>
</template>

<script>
import * as planStatisticsApi from '@/api/phase-material-statistics'
import moment from 'moment'
import MainChart from './Chart3'
// import { mapActions } from 'vuex'

export default {
  components: {
    MainChart
  },
  data() {
    return {
      query:{},
      dateRange: [moment().subtract(31, 'days'), moment().subtract(1, 'days')],
      list: [],
      empty: false // 是否为空（默认否）
    }
  },
  methods: {
    // ...mapActions(['changeSpinning']),

    disabledDate(current) {
      // Can not select days before today and today
      return current > moment().startOf('day')
    },
    initData(query = {}) {
      this.dateRange = [moment().subtract(31, 'days'), moment().subtract(1, 'days')]
      this.list = []
      this.query = query
      this.getData()
    },

    async getData() {
      this.empty = true
      const query = this.initQuery()
      // this.changeSpinning(true)
      planStatisticsApi.getStatisticsAccumulatedCompliance({ ...query }).then(res => {
        this.empty = false
        const { code, data } = res
        // this.changeSpinning(false)
        if (code === 0) {
          if (!data || !data.length) this.empty = true
          this.list = data
        }
      })
      // .catch(() => {
      //   this.changeSpinning(false)
      // })
    },

    initQuery() {
      const {
        query: { studentId, planSkillId, skillId, skillPhaseId: phaseId },
        // query: { studentId, phaseId, planSkillId },
        dateRange
      } = this
      const [startTimestamp, endTimestamp] = [
        dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : '',
        dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : ''
      ]

      return { studentId, phaseId, planSkillId, isMonth: false, startTimestamp, endTimestamp, skillId }
    },
    onChange(date, dateString) {
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped></style>
