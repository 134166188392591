import { render, staticRenderFns } from "./PageThird.vue?vue&type=template&id=888a5730&scoped=true"
import script from "./PageThird.vue?vue&type=script&lang=js"
export * from "./PageThird.vue?vue&type=script&lang=js"
import style0 from "./PageThird.vue?vue&type=style&index=0&id=888a5730&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "888a5730",
  null
  
)

export default component.exports