<template>
  <div class="linear-title">
    <div class="linear-bg">
      <img :src="LINEAR_TITLT_MAP[bg].url" alt="" :style="{ width: LINEAR_TITLT_MAP[bg].width + 'px' }" />
    </div>
    <div class="linear-text">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { LINEAR_TITLT_MAP } from './colors'

export default {
  props: {
    bg: {
      type: String,
      default() {
        return ''
      }
    },
    text: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      LINEAR_TITLT_MAP
    }
  }
}
</script>

<style lang="less" scoped>
.linear-title {
  text-align: center;
  position: relative;
  margin-bottom: 24px;
}
.linear-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 37px;
}
.linear-bg {
  img {
    height: 38px;
  }
}
</style>