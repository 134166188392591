<template>
  <div style="display: flex; justify-content: center">
    <div ref="chart" class="chart-pie"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartPie',
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    name: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myChart: null,
    }
  },
  watch: {
    series: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.initChart()
        })
      },
    },
  },
  methods: {
    initChart() {
      this.myChart = echarts.init(this.$refs.chart)
      const color = ['#4D55FF', '#69C7FB']
      const option = {
        color,
        tooltip: {
          formatter: (params) =>
            `<div style="width: 142px;">
                  <div style="margin-bottom: 12px;font-size: 12px;color: rgba(0,0,0,0.45);">${params.name}</div>
                  <div style="display:flex;align-items:center;">
                    <div style="width: 8px;height: 8px;background: ${
                      params.color
                    };border-radius: 50%;margin-right:8px;"></div>
                    <div>素材占比</div>
                    <div style="margin-left:auto;">${Math.round(params.percent)}%</div>
                  </div>
                </div>`,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '50%'],
            data: this.series,
            label: {
              formatter: '{name|{b}}\n{time|{c}个}',
              lineHeight: 15,
              bleedMargin: 0,
              rich: {
                time: {
                  fontSize: 12,
                  color: '#999',
                },
              },
            },
            itemStyle: {
              color: function (params) {
                // 自定义颜色
                return params.name === '已达标' ? color[1] : color[0]
              },
            },
          },
        ],
        // 中间空白文字
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: this.name.join('\n'),
              fill: '#3F3F3F',
              textAlign: 'center',
              width: 95,
              lineHeight: 18,
              letterSpacing: 0,
              fontSize: 12,
              fontWeight: 600,
            },
          },
        ],
      }

      this.myChart.setOption(option, true)
      window.addEventListener('resize', this.resize)
    },
  },
  resize() {
    if (this.myChart) {
      this.myChart.resize()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>
<style lang="less" scoped>
.chart-pie {
  width: 290px;
  height: 250px;
}
</style>