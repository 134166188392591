<template>
  <a-table
    rowKey="id"
    :customRow="customRow"
    :data-source="list"
    :pagination="pagination"
    :loading="listLoading"
    @change="handleTableChange"
  >
    <a-table-column key="record" title="图片" align="center" :ellipsis="true">
      <template slot-scope="record">
        <a-row type="flex" align="middle" justify="space-between" :gutter="24" style="flex-wrap:nowrap;">
          <a-col flex="0 0 80px">
            <a-radio :checked="selected.id === record.id"></a-radio>
          </a-col>
          <a-col flex="0 0 56px">
            <a-avatar :size="56" shape="square" :src="record.skill ? record.skill.coverUrl : ''"></a-avatar>
          </a-col>
          <a-col flex="0 0 80px"></a-col>
        </a-row>
      </template>
    </a-table-column>
    <a-table-column key="name" title="技能名称" data-index="skill.name" align="center" width="236px" />
    <a-table-column key="field" title="分类" data-index="skill.field.name" align="center" width="120px" />
    <a-table-column key="phaseCount" title="阶段" data-index="skill.phaseCount" align="center" width="120px" />
  </a-table>
</template>

<script>
import * as homeworkApi from '@/api/homework'

export default {
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [],
      selected: {}
    }
  },
  methods: {
    customRow(record) {
      return {
        on: {
          // 向上一层级回传数据
          click: () => {
            this.selected = record
            if (record.hasNotRelationPhase) {
              this.$notification.warn({
                message: '提示',
                description: '当前技能暂无教学示范，不能作为课后作业'
              })
              this.$emit('change', record)
            } else {
              this.$emit('change', record)
            }
          }
        }
      }
    },
    initData(query = {}) {
      const { initPagination, getData } = this
      this.query = query
      this.pagination = initPagination()
      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      this.listLoading = true
      return homeworkApi
        .list({ ...query, pageIndex, pageSize, studentId: this.$route.params.id })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        total: 0
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      getData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
