<template>
  <pdf-page>
    <template #extra>
      <img src="~@/assets/report/quarter/paper-plane@2x.png" class="extra" alt="" />
    </template>
    <div class="main">
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">练习</div>
        <div class="text-col large highlight">回合数最多</div>
        <div class="text-col">的短期目标是</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">（{{ index }}）</div>
      </div>
      <div class="text-row">
        <div class="text-col">“{{ dataSource.skillName }}”</div>
      </div>
      <div class="text-row">
        <div class="text-col">中的</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col large">“{{ dataSource.phaseName }}”</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">这个目标</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">练习的素材量达</div>
        <div class="text-col large highlight">{{ dataSource.materialNum }}</div>
        <div class="text-col">个</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px" v-if="dataSource.qualifiedMaterialNum > 0">
        <div class="text-col">其中</div>
      </div>
      <div class="text-row" v-if="dataSource.qualifiedMaterialNum > 0">
        <div class="text-col">达标的素材量达</div>
        <div class="text-col large highlight">{{ dataSource.qualifiedMaterialNum }}</div>
        <div class="text-col">个</div>
      </div>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
export default {
  components: {
    PdfPage,
  },
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          materialNum: 0,
          qualifiedMaterialNum: 0,
          phaseName: '',
          skillName: '',
        }
      },
    },
    index: {
      type: Number,
      default() {
        return 1
      },
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  text-align: center;
  padding: 52px 55px;
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 36px;
}
.text {
  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-col:not(.large) {
    margin-bottom: 5px;
  }
}
.large {
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 1px;
}
.highlight {
  color: #ff7a55;
}
.extra {
  position: absolute;
  width: 1080px;
  bottom: 0;
}
</style>