<template>
  <div class="pdf-container">
    <div id="report-pdf-preview-1">
      <div class="poster">
        <img src="~@/assets/report/report/pdf_poster_background.png" alt="" class="poster-bg" />
        <img src="~@/assets/report/report/pdf_poster_logo.png" alt="" class="poster-logo" />
        <div class="poster-info">
          <h1>嗨宝快学</h1>
          <h1>技能评估报告</h1>
          <div class="info-en" style="margin-top: 12px"><span>IEPABRAIN</span><span>Skills</span></div>
          <div class="info-en">Assessment Report</div>
          <div style="height: 2px; width: 100%; background: #fff; margin-top: 24px; margin-bottom: 48px" />
          <div class="descs" style="margin-bottom: 24px">
            <div class="descs-label">儿童姓名：</div>
            <div class="descs-content">{{ dataSource.username }}</div>
          </div>
          <div class="descs" style="margin-bottom: 24px">
            <div class="descs-label">评估日期：</div>
            <div class="descs-content">
              {{ dataSource.evaluationStartDate | moment('YYYY年MM月DD日') }}~{{
                dataSource.evaluationCompletionDate | moment('YYYY年MM月DD日')
              }}
            </div>
          </div>
          <div class="descs">
            <div class="descs-label">评估机构：</div>
            <div class="descs-content">{{ dataSource.mechanismName }}</div>
          </div>
        </div>
        <img src="~@/assets/report/report/pdf_logo.png" alt="" class="poster-footer" />
      </div>
      <pdf-page>
        <linear-title bg="INFORMATION" text="基本信息"></linear-title>
        <a-descriptions :column="2" bordered class="baseInfo">
          <a-descriptions-item label="儿童姓名">
            {{ dataSource.username }}
          </a-descriptions-item>
          <a-descriptions-item label="儿童性别">
            {{ dataSource.sex | GenderText() }}
          </a-descriptions-item>
          <a-descriptions-item label="出生年月">
            {{ dataSource.birthday | moment('YYYY年MM月DD日') }}
          </a-descriptions-item>
          <a-descriptions-item label="评估时间">
            {{ dataSource.evaluationStartDate | moment('YYYY年MM月DD日') }}~{{
              dataSource.evaluationCompletionDate | moment('YYYY年MM月DD日')
            }}
          </a-descriptions-item>
          <a-descriptions-item label="评估人">
            {{ dataSource.teacherList.join('、') || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="报告撰写人">
            {{ dataSource.reportAuthor || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <template v-if="dataSource.diagnosticResults">
          <h2>诊断结果</h2>
          <block-content bordered style="padding: 24px 16px">
            <pre class="pre">{{ dataSource.diagnosticResults }}</pre>
          </block-content>
        </template>

        <template v-if="dataSource.basicFamilyInformation">
          <h2>基本家庭信息</h2>
          <block-content bordered style="padding: 24px 16px">
            <pre class="pre">{{ dataSource.basicFamilyInformation }}</pre>
          </block-content>
        </template>
      </pdf-page>
      <pdf-page>
        <linear-title bg="INTRODUCE" text="评估工具介绍"></linear-title>
        <block-content>
          <pre class="pre content" style="color: #333; font-size: 20px">{{ dataSource.introduce }}</pre>
        </block-content>
      </pdf-page>
      <pdf-page :page="false">
        <linear-title bg="ANALYSE" text="各领域评估分析"></linear-title>
        <div v-for="field in dataSource.evaluationReportFieldList.slice(0, 1)" :key="field.id">
          <field :dataSource="field" :fieldId="field.id" />
        </div>
      </pdf-page>
    </div>
    <div id="report-pdf-preview-2" style="padding: 0 32px">
      <div v-for="field in dataSource.evaluationReportFieldList.slice(1, 4)" :key="field.id">
        <field :dataSource="field" :fieldId="field.id" />
      </div>
      <div style="height: 100px"></div>
    </div>
    <div id="report-pdf-preview-3" style="padding: 0 32px">
      <div v-for="field in dataSource.evaluationReportFieldList.slice(4, 7)" :key="field.id">
        <field :dataSource="field" :fieldId="field.id" />
      </div>
    </div>
    <div id="report-pdf-preview-4" style="padding: 0 32px">
      <div v-for="field in dataSource.evaluationReportFieldList.slice(7, 9)" :key="field.id">
        <field :dataSource="field" :fieldId="field.id" />
      </div>
    </div>
  </div>
</template>

<script>
import { LinearTitle, PdfPage, Field } from './components'
import { Content as BlockContent } from '../components'
export default {
  name: 'PdfPreview',
  components: {
    LinearTitle,
    PdfPage,
    BlockContent,
    Field
  },
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          teacherList: []
        }
      }
    }
  },
  mounted() {
    this.$emit('exportReportPdfButton', false)
  }
}
</script>

<style lang="less" scoped>
.baseInfo {
  /deep/ .ant-descriptions-row {
    th:nth-of-type(1) {
      width: 14%;
    }
    td:nth-of-type(1) {
      width: 34% !important;
    }
    th:nth-of-type(2) {
      width: 16% !important;
    }
    td:nth-of-type(2) {
      width: 36% !important;
    }
  }
}
.pdf-container {
  margin-left: auto;
  margin-right: auto;
  width: 1080px;
}
h2 {
  font-size: 22px;
  font-weight: 500;
  color: #3350c7;
  line-height: 30px;
  margin-top: 40px;
  margin-bottom: 16px;
}
/deep/ .ant-descriptions-view {
  border-radius: 0;
  color: #1c1c1c;
}
/deep/ .ant-descriptions-item-label {
  background: rgba(51, 80, 199, 0.12);
  font-size: 20px;
  font-weight: 500;
}
/deep/ .ant-descriptions-item-content {
  font-size: 20px;
  font-weight: 500;
}
/deep/ .ant-descriptions-bordered .ant-descriptions-row,
/deep/ .ant-descriptions-bordered .ant-descriptions-item-label,
/deep/ .ant-descriptions-bordered .ant-descriptions-item-content {
  border-color: rgba(0, 0, 0, 0.05);
}
.pdf-container,
.poster-bg,
.poster {
  width: 1080px;
}
.poster {
  position: relative;
}
.poster-logo {
  width: 363px;
  position: absolute;
  top: 375px;
  left: 19px;
}
.poster-footer {
  width: 260px;
  position: absolute;
  bottom: 63px;
  left: 50%;
  transform: translateX(-50%);
}
.poster-info {
  padding: 32px;
  position: absolute;
  top: 288px;
  left: 382px;
  width: 577px;
  height: 640px;
  background: #3453a3;
  border-radius: 6px;
  color: #ffffff;
  h1 {
    margin: 0;
    color: #ffffff;
    font-size: 82px;
    font-weight: 600;
    line-height: 100px;
  }
}
.info-en {
  font-size: 40px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 50px;
  letter-spacing: 10px;
}
.descs {
  display: flex;
  align-items: flex-start;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  .descs-label {
    white-space: nowrap;
  }
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
}
.content {
  font-size: 16px;
  font-weight: 400;
  color: #1e1e1e;
  line-height: 30px;
}
</style>
