<template>
  <a-modal
    v-model="visible"
    title="编辑技能包"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
  >
    <a-form-model ref="form" :model="form" v-bind="$formItemLayout" style="margin-top:16px">
      <a-form-model-item label="技能包名称">
        <span>{{ form.name }}</span>
      </a-form-model-item>
      <a-form-model-item
        label="训练目标"
        prop="goal"
        :rules="{
          required: true,
          message: '请填写训练目标',
          trigger: ['change', 'blur']
        }"
      >
        <div style="position:relative">
          <a-input
            v-model="form.goal"
            placeholder="请输入内容"
            :maxLength="50"
            :autoSize="{ minRows: 3, maxRows: 3 }"
            type="textarea"
          />
          <div style="position:absolute;bottom:-5px;right:1px;">
            <span style="background:#fff">{{ form.goal.length }}/50</span>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import * as planApi from '@/api/plan'

export default {
  data() {
    return {
      visible: false,
      buttonLoading: false,
      form: {
        name: '',
        goal: ''
      }
    }
  },
  methods: {
    showModal({ id, name, goal }) {
      this.visible = true
      this.form = { id, name, goal }
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { id, goal } = this.form

          this.buttonLoading = true
          planApi
            .updateSkill({ id, goal })
            .then(() => {
              this.$emit('success', goal)
              this.visible = false
            })
            .finally(() => (this.buttonLoading = false))
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
