<!-- 家校训练服务-详情-训练计划-导出计划-发布 -->

<template>
  <div class="preview-plan">
    <a-affix :offset-top="top">
      <div class="top">
        <a-button type="primary" shape="round" @click="$emit('publishSuccess')" v-if="pageFrom === 'ADD_EXPORT'">
          <a-icon type="file-done" />暂存</a-button
        >
        <a-button type="primary" shape="round" v-if="$auth('export_plan_export_pdf')" @click="handlePreview">
          <a-icon type="file-search" />预览PDF</a-button
        >
        <a-button
          type="primary"
          shape="round"
          v-if="pageFrom === 'ADD_EXPORT' && $auth('export_plan_regeneration')"
          @click="reExport()"
        >
          <a-icon type="sync" />重新生成</a-button
        >
        <a-button type="primary" shape="round" v-if="pageFrom === 'PUBLISH'" @click="showConfirmPublish">
          <a-icon type="plus" />发布</a-button
        >
        <a-button type="primary" shape="round" v-if="pageFrom !== 'ADD_EXPORT'" @click="goBack">
          <a-icon type="left" />返回</a-button
        >
      </div>
    </a-affix>
    <div class="main">
      <div class="title">
        <div class="name">{{ record.studentName }}个别化教育计划</div>
        <div class="date">{{ record.createTime }}</div>
      </div>
      <div class="module module-1">
        <div class="module-t">
          基本信息
        </div>
        <div class="module-c">
          <a-form-model ref="form" :model="form">
            <a-row>
              <a-col :span="6" class="module-c-item">
                <div>儿童姓名：</div>
                <div>{{ record.studentName || '--' }}</div>
              </a-col>
              <a-col :span="6" class="module-c-item">
                <div>儿童性别：</div>
                <div>{{ record.sex === 'FEMALE' ? '女' : record.sex === 'MALE' ? '男' : '--' }}</div>
              </a-col>
              <a-col :span="6" class="module-c-item">
                <div style="text-align: left">出生日期：</div>
                <div>{{ record.birthday || '--' }}</div>
              </a-col>
              <a-col :span="6" class="module-c-item">
                <div style="text-align: left">监护人：</div>
                <div>{{ record.guardians || '--' }}</div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6" class="module-c-item">
                <div>初审时间：</div>
                <div>{{ record.preliminaryReviewTimestamp || '--' }}</div>
              </a-col>
              <a-col :span="6" class="module-c-item">
                <div>复审时间：</div>
                <div>{{ record.reviewTime || '--' }}</div>
              </a-col>
              <a-col :span="6" class="module-c-item">
                <div style="text-align: left">计划撰写人：</div>
                <div>{{ record.planWriter || '--' }}</div>
              </a-col>
              <a-col :span="6" class="module-c-item">
                <div style="text-align: left">计划审核人：</div>
                <div>{{ record.planReview || '--' }}</div>
              </a-col>
            </a-row>
            <a-divider />
            <a-row>
              <a-col :span="24" class="module-c-item">
                <div>诊断结果：</div>
                <div>
                  {{ record.diagnosticResults || '--' }}
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" class="module-c-item">
                <div>基本家庭信息：</div>
                <div>{{ record.basicFamilyInformation || '--' }}</div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" class="module-c-item">
                <div>基本能力信息：</div>
                <div>{{ record.basicAbilityInformation || '--' }}</div>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
      <div class="module module-2">
        <div class="module-t">
          训练计划总览
        </div>
        <div class="module-c">
          <a-table :columns="columns" :data-source="trainingPlanList" bordered :pagination="false"> </a-table>
        </div>
      </div>

      <div class="module module-3">
        <div class="module-t">
          教学目标与项目计划
        </div>
        <div class="module-c">
          <a-radio-group v-model="defaultValue" class="buttons" @change="handleChange" :value="defaultValue">
            <a-radio-button :value="item.id" v-for="item in radioGroupTitle" :key="item.id">
              {{ item.fieldName }}
            </a-radio-button>
          </a-radio-group>
          <div class="field-list" v-for="item in currentLabelList" :key="item.id">
            <div class="skill">
              <div class="skill-t">
                <a-badge color="#3A5FF8" />
                <span class="skill-t-c">{{ item.code }}&nbsp{{ item.skillName }}</span>
                <span class="skill-t-r">{{ currentLabel }} > {{ item.secondaryFieldName }}</span>
              </div>
              <div class="skill-b">技能目标：{{ item.goal }}</div>
            </div>
            <div class="phase" v-for="innerItem in item.exportPlanRecordSkillPhaseList" :key="innerItem.id">
              <div class="phase-name">
                第{{ innerItem.phaseNumber | filterUpperCase }}阶段：{{ innerItem.skillPhaseName }}
              </div>
              <div class="phase-goal">阶段目标：{{ innerItem.goal }}</div>
              <div class="phase-others">
                <div class="phase-others-item">
                  <span>记录方式：</span> <span>{{ innerItem.recordingMethod | filterMethod }}</span>
                </div>
                <div class="phase-others-item">
                  <span>最低训练次数：</span> <span>{{ innerItem.minimumRounds }}次</span>
                </div>
                <!--                <div class="phase-others-item"><span>辅助方式：</span> <span>PP1 XXXXXX、PP2</span></div>-->
              </div>
              <div class="phase-standard">
                <span style="font-weight: bolder">达标标准：</span>
                <span>{{ innerItem.fulfilled }}</span>
              </div>
              <div class="phase-material">
                <span style="font-weight: bolder">训练素材：</span>
                <span v-if="innerItem.materialList">{{ innerItem.materialList.join('、') }}</span>
                <!--<span v-if="innerItem.materialList">{{ getMaterial(innerItem) }}</span>-->
              </div>
            </div>
            <a-divider />
          </div>
        </div>
      </div>
    </div>
    <!--新增导出-->
    <add-export-model ref="add" @editSuccess="commitSuccess" :edit-id="recordId" />
  </div>
</template>

<script>
import * as Api from '@/api/field'
import { mergeCellKey, downLoadBinaryFileStream } from '@/utils/util'
import addExportModel from '../dialog/add-export-model'
import { mapActions } from 'vuex'

export default {
  name: 'ExportPlanTableDetail',
  props: {
    pageFrom: {
      type: String,
      default: ''
    },
    recordId: {
      type: Number,
      default: null
    }
  },
  filters: {
    filterMethod: function(value) {
      switch (value) {
        case 'SUCCESS_RATE':
          return '正确率-回合'
        case 'DURATION':
          return '时长'
        case 'NUMBER_SUCCESS':
          return '固定时间内的次数'
        case 'STEP_SUCCESS_RATE':
          return '各个步骤的正确率'
        default:
          return '-'
      }
    },
    filterUpperCase: function(value) {
      const upperArray = [
        '一',
        '二',
        '三',
        '四',
        '五',
        '六',
        '七',
        '八',
        '九',
        '十',
        '十一',
        '十二',
        '十三',
        '十四',
        '十五'
      ]
      return upperArray[value - 1]
    }
  },
  data() {
    return {
      size: 'small',
      record: {},
      dateTime: '',
      trainingPlanList: [], // 训练计划总览
      trainingProgramOverviewList: [], // 本周训练详情总集合
      radioGroupTitle: [], // 标签集合
      defaultValue: null, // 默认标签
      currentLabel: '', // 当前标签
      currentLabelList: [], // 本周训练详情当前标签下的list
      columns: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions(['changeSpinning']),
    // 预览PDF
    handlePreview() {
      this.changeSpinning(true)
      Api.exportPlan(this.recordId)
        .then(res => {
          downLoadBinaryFileStream({
            data: res,
            type: 'application/pdf;chartset=UTF-8',
            name: `${this.record.studentName}个别化教育计划.pdf`
          })
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    },
    init() {
      // 请求参数
      const params = { id: this.recordId }
      return Api.getPlanExportRecord(params).then(res => {
        const { code } = res
        if (code === 0) {
          this.record = res.data
          this.trainingPlanList = res.data.trainingPlanDataList // 训练计划
          // 处理训练计划数据
          this.resetPlanList()
          this.trainingProgramOverviewList = res.data.trainingProgramOverviewList // 本周训练详情
          // 处理数据获取到详情标签集合
          this.getRadioGroup()
          // 设置table合并单元格
          this.setColumns()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    resetPlanList() {
      const planArray = this.trainingPlanList
      if (planArray.length > 0) {
        planArray.map(item => {
          item.target = item.code + item.skillName
        })
        this.trainingPlanList = planArray
      }
    },
    setColumns() {
      this.columns = [
        {
          title: '领域',
          dataIndex: 'fieldName',
          align: 'center',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            obj.attrs.rowSpan = mergeCellKey(row.fieldName, this.trainingPlanList, 'fieldName', index)
            return obj
          }
        },

        {
          title: '子领域',
          dataIndex: 'secondaryFieldName',
          align: 'center',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            obj.attrs.rowSpan = mergeCellKey(row.secondaryFieldName, this.trainingPlanList, 'secondaryFieldName', index)
            return obj
          }
        },
        {
          title: '长期目标',
          dataIndex: 'target',
          align: 'center',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            obj.attrs.rowSpan = mergeCellKey(row.target, this.trainingPlanList, 'target', index)
            return obj
          }
        }
      ]
    },
    getRadioGroup() {
      const radioArray = this.trainingProgramOverviewList
      radioArray.map(item => {
        this.radioGroupTitle.push({
          fieldName: item.fieldName,
          id: item.id
        })
      })
      this.defaultValue = this.radioGroupTitle[0].id

      this.handleChange({ target: { value: this.defaultValue } })
    },
    // radio change event
    handleChange(e) {
      const checkedId = e.target.value
      // 通过id获取到该标签下的数据
      const radioArray = this.trainingProgramOverviewList
      radioArray.map(item => {
        if (item.id === checkedId) {
          this.currentLabelList = item.exportPlanRecordSkillList
          this.currentLabel = item.fieldName
        }
      })
    },
    // 发布
    showConfirmPublish() {
      const _this = this
      this.$confirm({
        title: '发布后的计划将同步至家长手机端',
        content: '是否确认发布？',
        okText: ' 确认发布',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          Api.editRecordStatus({ id: _this.recordId }).then(res => {
            if (res.code === 0) {
              _this.$message.success('发布成功')
              _this.$emit('publishSuccess')
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    // 返回
    goBack() {
      this.$emit('switch')
    },
    // 重新导出
    reExport() {
      const _this = this
      this.$confirm({
        content: '重新导出计划将重头开始制作，是否确认重新导出',
        okText: '重新导出',
        cancelText: '取消',
        onOk() {
          _this.current = 'LIST'
          _this.editValue = _this.recordId
          _this.$nextTick(() => {
            _this.$refs.add.showModal({ type: 'again' })
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    commitSuccess() {
      this.record = {}
      this.trainingPlanList = [] // 训练计划总览
      this.trainingProgramOverviewList = [] // 本周训练详情总集合
      this.radioGroupTitle = [] // 标签集合
      this.defaultValue = null // 默认标签
      this.currentLabel = '' // 当前标签
      this.currentLabelList = [] // 本周训练详情当前标签下的list
      this.init()
    },
    getMaterial({ materialList }) {
      const _materialList = materialList.map(item => item.materialName) || []
      return _materialList.join('、') || '--'
    }
  },
  components: {
    addExportModel
  }
}
</script>

<style lang="less">
.preview-plan {
  .ant-table-tbody > tr > td {
    font-size: 16px;
  }
  padding: 24px 0;
  .top {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    > button {
      margin-right: 16px;
    }
  }
  .main {
    .title {
      text-align: center;
      color: #1a1a1a;
      margin-bottom: 24px;
      .name {
        font-size: 20px;
        font-weight: bolder;
        margin-bottom: 10px;
      }
      .date {
        font-size: 16px;
        color: #1a1a1a;
      }
    }
    .module {
      border-bottom: 16px solid #f7f8fc;
      .module-t {
        background: #e1e8ff;
        height: 54px;
        line-height: 54px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #1b1b1b;
      }

      .module-c {
        padding: 24px 24px 24px 24px;
        border: 1px solid #e8e8e8;
        .module-c-item {
          display: flex;
          margin-bottom: 20px;
          :first-child {
            width: 110px;
            text-align: right;
            font-weight: bolder;
          }
          :last-child {
            flex: 1;
          }
        }
      }
    }

    .module-3 {
      .ant-radio-button-wrapper {
        border-radius: 0;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        font-weight: 600;
        color: #1a1a1a;
        width: 125px;
        text-align: center;
      }

      .ant-radio-button-wrapper-checked {
        background: #e1e8ff;
        border: 1px solid #e1e8ff;
        box-shadow: none;
      }

      .buttons {
        margin-bottom: 16px;
      }

      .field-list {
        .skill {
          height: 108px;
          background: #f7f8fc;
          padding: 24px 16px;
          color: #333333;
          margin-bottom: 24px;
          .skill-t {
            font-size: 16px;
            font-weight: bolder;
            color: #333333;
            margin-bottom: 8px;
            .skill-t-r {
              margin-left: 24px;
              display: inline-block;
              font-size: 14px;
              color: #3a5ff8;
            }
          }

          .skill-b {
            font-size: 14px;
            padding-left: 15px;
          }
        }

        .phase {
          color: #333333;
          padding-left: 16px;
          > div {
            margin-bottom: 8px;
          }
          .phase-name {
            font-size: 16px;
            font-weight: bolder;
          }

          .phase-others {
            display: flex;
            .phase-others-item {
              margin-right: 64px;
              :first-child {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }
}
</style>
