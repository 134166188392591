<template>
  <a-button type="link" v-bind="$attrs" v-on="$listeners">
    <img :src="!visible ? require('@/assets/report/report/ico_tab_open.png') : require('@/assets/report/report/ico_tab_close.png')" style="width:19px;margin-right:4px;" />
    {{ visible ? '隐藏' : '显示' }}
  </a-button>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>

<style>
</style>