<!-- 家校训练服务-详情-技能评估-评估历史-列表 -->
<template>
  <a-table rowKey="id" :data-source="list" :loading="listLoading">
    <a-table-column key="status" title="序号" align="center" width="80px">
      <template slot-scope="text, record, index">
        <span>{{ index + 1 }}</span>
      </template>
    </a-table-column>
    <a-table-column key="username" title="姓名" data-index="name" align="center" :ellipsis="true" />

    <a-table-column key="cellphone" title="用户账号" data-index="cellphone" align="center" :ellipsis="true" />
    <a-table-column
      key="evaluationTimestamp"
      title="评估时间"
      data-index="evaluationTimestamp"
      align="center"
      :ellipsis="true"
    />
    <a-table-column key="actions" title="操作" align="center" :ellipsis="true">
      <template slot-scope="record">
        <a-space>
          <a-button type="link" @click="handleDetail(record)"><a-icon type="right-circle" />详情</a-button>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as studentApi from '@/api/student'
export default {
  name: 'HomeSchoolEvaluationHistory',
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: []
    }
  },
  created() {
    this.getData()
  },

  methods: {
    getData() {
      const { id } = this.$route.params
      this.listLoading = true
      studentApi
        .getEvaluationHistoryList({ studentId: id })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            this.list = data
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.getData()
    },
    handleDetail({ id }) {
      sessionStorage.setItem('homeSchoolIsShowTabs', 1)
      this.$emit('evaluationHistoryDetail', { id })
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
.refuse {
  color: #f12a2c;
  img {
    position: relative;
    top: -2px;
    left: -2px;
    width: 12px;
    margin-right: 6px;
  }
}
</style>
