import { render, staticRenderFns } from "./UpToStandard.vue?vue&type=template&id=62eafcf6&scoped=true"
import script from "./UpToStandard.vue?vue&type=script&lang=js"
export * from "./UpToStandard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62eafcf6",
  null
  
)

export default component.exports