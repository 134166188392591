<template>
  <div>
    <div :id="idName" class="lines"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'ChartLine',
  props: {
    idName: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    },
    chartid: {
      type: Number,
      default: null
    },
    isshow: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {}
  },
  watch: {
    isshow(newval, oldval) {
      if (newval) {
        this.$nextTick(() => {
          this.initChart()
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      const dom = document.getElementById(this.idName)
      const wrapperWidth = document.getElementById(`chart-wrapper-week${this.chartid}`).getBoundingClientRect().width
      dom.style.width = wrapperWidth + 'px'
      this.myChart = echarts.init(dom)
      const option = {
        color: [
          '#FF7B55',
          '#3350C7',
          '#FFAC5E',
          '#E84F4F',
          '#FFCC32',
          '#FF7B55',
          '#3350C7',
          '#FFAC5E',
          '#E84F4F',
          '#FFCC32',
          '#5672E7',
          '#7979EA',
          '#A467DE',
          '#D76AD2',
          '#E85772'
        ],
        grid: {
          top: '28px',
          right: '26px',
          bottom: '44px',
          left: '44px'
        },
        legend: {
          itemHeight: 8,
          textStyle: {
            fontSize: 10
          },
          type: 'scroll',
          bottom: '0'
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#fafafa'],
            width: 0.8,
            type: 'solid'
          }
        },
        tooltip: {},
        xAxis: [
          {
            type: 'category',
            data: this.data.xAxis,
            boundaryGap: false,
            axisPointer: {
              type: 'shadow'
            },
            axisTick: {
              show: true
            },
            position: 'top',
            axisLabel: {
              margin: 14,
              fontSize: 12
            }
          }
        ],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          },
          axisLine: {
            show: true
          }
        },
        series: this.data.series
      }
      this.myChart.setOption(option, true)
      this.myChart.resize()
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.lines {
  width: 100%;
  height: 240px;
}
</style>
