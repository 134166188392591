<template>
  <div>
    <a-card
      :headStyle="!dataSource.isAutoGenerated ? { background: '#FFF7F5' } : { background: '#f7f8fc' }"
      style="margin-bottom: 24px"
    >
      <div slot="title">
        <a-row type="flex" style="padding: 0 24px">
          <a-col flex="1">
            <a-row type="flex" align="middle">
              <a-col>{{ dataSource.skill.code }}&nbsp;&nbsp;{{ dataSource.skill.name }}</a-col>
              <a-col style="display: flex; align-items: center; margin-left: 8px">
                <a-tag
                  v-if="dataSource.skill.isBaseSkill"
                  style="margin-right: 8px"
                  :style="{
                    color: '#2795CC',
                    background: '#E1F5FF',
                  }"
                >
                  基础
                </a-tag>
                <a-tag
                  v-if="dataSource.skill.type === 'LANGUAGE'"
                  :style="{
                    color: '#EF5C26',
                    background: '#FFE9E1',
                  }"
                >
                  {{ dataSource.skill.type | SkillTypeText() }}
                </a-tag>
                <a-tag
                  v-if="!dataSource.isAutoGenerated"
                  style="height: 18px; line-height: 18px; margin-right: 8px; border: 1px solid #ff7a55"
                  :style="{
                    color: '#FF7A55',
                    background: 'transparent',
                  }"
                >
                  自选技能
                </a-tag>
              </a-col>
            </a-row>
            <a-row class="text-color">
              <a-col style="margin-top: 8px">
                <a-space align="start">
                  <span class="descriptions-label">领域：</span>
                  <span class="descriptions-content primary-color">
                    {{ dataSource.skill.field.parent.name }} > {{ dataSource.skill.field.name }}
                  </span>
                </a-space>
              </a-col>
              <a-col style="margin-top: 8px">
                <a-space align="start">
                  <span class="descriptions-label">目标：</span>
                  <p class="default-color pre">{{ dataSource.skill.goal }}</p>
                </a-space>
              </a-col>
            </a-row>
          </a-col>
          <a-col style="position: relative">
            <a-dropdown v-if="!isCompleted">
              <a-menu slot="overlay" @click="handleMenuClick">
                <a-menu-item
                  v-if="$auth('plans_replace') && !completedNegationAndAutoGenerated && planWriteAndTeacherAuthority"
                  key="replace"
                  style="text-align: center"
                  >替换</a-menu-item
                >
                <a-menu-item
                  v-if="$auth('plans_skip') && !completedNegationAndAutoGenerated && planWriteAndTeacherAuthority"
                  key="skip"
                  style="text-align: center"
                  >跳过</a-menu-item
                >
                <a-menu-item v-if="$auth('plans_update')" key="update" style="text-align: center">编辑</a-menu-item>
                <a-menu-item
                  v-if="$auth('plans_delete') && !dataSource.isAutoGenerated && planWriteAndTeacherAuthority"
                  key="remove"
                  style="text-align: center"
                  >删除</a-menu-item
                >
              </a-menu>

              <a-button type="primary" shape="round" ghost style="width: 120px" v-if="!isSchoolStatus">
                操作<a-icon type="caret-down" />
              </a-button>
            </a-dropdown>
            <div v-if="dataSource.status !== 'PUBLISHED'" class="status-badge">
              <span class="status-badge-text">{{ dataSource.status === 'CREATED' ? '未发布' : '已完成' }}</span>
              <div :class="dataSource.status === 'CREATED' ? 'error' : 'success'" class="status-badge-bg"></div>
            </div>
            <div v-else class="status-badge">
              <span class="status-badge-text">训练中</span>
              <div class="status-badge-bg normal"></div>
            </div>
          </a-col>
        </a-row>
      </div>

      <a-row type="flex" align="middle">
        <a-col flex="1" style="overflow: hidden">
          <a-tabs v-model="activePhase">
            <a-tab-pane v-for="(phase, index) in dataSource.skill.phaseList" :key="index">
              <div slot="tab">
                <a-icon
                  v-if="index < dataSource.skill.currentPhaseNumber - 1 || !enableUpgrade"
                  type="check-square"
                  :class="index !== activePhase ? 'success-color' : ''"
                  style="font-size: 16px; margin-right: 4px"
                />{{ `${index + 1}阶` }}
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-col>
        <!--        {{ activePhaseInfo }}-->
        <a-col v-if="$auth('plans_downgrade') && !isCompleted && !isSchoolStatus" style="margin: 0 16px">
          <a-button
            :disabled="dataSource.skill.currentPhaseNumber <= 1"
            type="primary"
            shape="round"
            @click="handlePhaseGrade('downgrade')"
          >
            <a-icon type="arrow-down" />降阶
          </a-button>
        </a-col>
        <a-col v-if="$auth('plans_upgrade') && !isCompleted && !isSchoolStatus">
          <a-button
            :disabled="!enableUpgrade || upgradeLoading"
            type="primary"
            shape="round"
            @click="handlePhaseGrade('upgrade')"
          >
            <a-icon :type="upgradeLoading ? 'loading' : 'arrow-up'" />升阶
          </a-button>
        </a-col>
      </a-row>
      <a-row type="flex" align="middle" style="margin-top: 32px">
        <a-col class="heading-color">
          {{ activePhaseInfo.name }}
        </a-col>
        <a-col style="margin-left: auto">
          <a-space :size="16">
            <a-button
              type="link"
              style="padding: 0"
              @click="handleEditPhase()"
              v-if="$auth('plans_phase_update') && !isCompleted && !isSchoolStatus"
              ><a-icon type="edit" />编辑</a-button
            >
            <a-button
              v-if="$auth('training_data_select')"
              type="link"
              style="padding: 0"
              @click="
                $emit('statistic-check', {
                  activePhaseInfo,
                  skillId: dataSource.skill.id,
                  fieldId: dataSource.skill.field.id,
                  mainFieldId: dataSource.skill.field.parent.id,
                  type: 'tabs'
                })
              "
            >
              <a-icon type="bar-chart" />数据
            </a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-row type="flex" align="middle" style="margin-top: 32px">
        <a-col>
          <a-space align="start">
            <span class="descriptions-label">阶段目标：</span>
            <p class="descriptions-content text-color-secondary">{{ activePhaseInfo.goal }}</p>
          </a-space>
        </a-col>
        <a-col style="margin-left: auto">
          <img
            v-if="activePhaseInfo.isQualified"
            class="descriptions-img"
            src="../../../../../assets/ico_dabiao.png"
            alt=""
          />
        </a-col>
      </a-row>
      <a-row :gutter="[24, 16]" style="margin-top: 12px">
        <a-col :lg="6" :md="12" :xs="24">
          <a-space align="start">
            <span class="descriptions-label">记录方式：</span>
            <p class="descriptions-content text-color-secondary">
              {{ activePhaseInfo.recordingMethod | RecordingMethodText() }}
            </p>
          </a-space>
        </a-col>
        <a-col :lg="6" :md="12" :xs="24">
          <a-space align="start">
            <span class="descriptions-label">最低回合数：</span>
            <p class="descriptions-content text-color-secondary">{{ activePhaseInfo.minimumRounds }}回合</p>
          </a-space>
        </a-col>
        <a-col :lg="6" :md="12" :xs="24">
          <a-space align="start">
            <span class="descriptions-label">训练方式：</span>
            <p class="descriptions-content text-color-secondary">
              {{ activePhaseInfo.trainingMethod | TrainingMethodText() }}
            </p>
          </a-space>
        </a-col>
        <a-col :lg="6" :md="12" :xs="24">
          <a-space align="start">
            <span class="descriptions-label">辅助方式：</span>
            <p class="descriptions-content text-color-secondary">
              {{ activePhaseInfo.assistMethod ? activePhaseInfo.assistMethod.join('、') : '' }}
            </p>
          </a-space>
        </a-col>
        <a-col :span="24">
          <a-space align="start" :size="56">
            <a-space align="start">
              <span class="descriptions-label">达标标准：</span>
              <p class="descriptions-content text-color-secondary">
                <a-row type="flex" align="middle">
                  <a-col>至少</a-col>
                  <a-col>{{ activePhaseInfo.materialNumber }} </a-col>
                  <a-col>个素材，每个正确率连续</a-col>
                  <a-col>{{ activePhaseInfo.day }} </a-col>
                  <a-col>天达到</a-col>
                  <template v-if="activePhaseInfo.recordingMethod !== 'NUMBER_SUCCESS'">
                    <a-col>{{ activePhaseInfo.successRate }} </a-col>
                    <a-col>%</a-col>
                  </template>
                  <template v-else>
                    <a-col>{{ activePhaseInfo.frequency }} </a-col>
                    <a-col>次，每</a-col>
                    <a-col>{{ activePhaseInfo.minutes }}分{{ activePhaseInfo.seconds }}秒</a-col>
                  </template>
                </a-row>
              </p>
            </a-space>
            <a-space align="start">
              <span class="descriptions-label">学前准备：</span>
              <a-button
                type="link"
                class="descriptions-content"
                style="padding: 0; height: auto; border: none"
                @click="$emit('preparation-check', dataSource.skill)"
                >查看</a-button
              >
            </a-space>
          </a-space>
        </a-col>
        <a-col :span="8" v-if="activePhaseInfo.recordingMethod === 'DURATION'">
          <a-space align="start">
            <span class="descriptions-label">达标时长：</span>
            <p class="descriptions-content text-color-secondary">
              {{ activePhaseInfo.minutes }}分{{ activePhaseInfo.seconds }}秒
            </p>
          </a-space>
        </a-col>
        <a-col :span="12">
          <a-space align="start">
            <span class="descriptions-label">训练素材：</span>
            <p class="descriptions-content text-color-secondary">
              {{ getMaterial(activePhaseInfo) }}
            </p>
          </a-space>
        </a-col>
        <a-col :span="12">
          <a-space align="start">
            <span class="descriptions-label">教学视频：</span>
            <a-button
              type="link"
              class="descriptions-content"
              style="padding: 0; height: auto; border: none"
              @click="$emit('teaching-video', activePhaseInfo)"
              >查看</a-button
            >
          </a-space>
        </a-col>

        <a-col :span="16">
          <a-space align="start">
            <span class="descriptions-label">注意事项：</span>
            <div v-if="activePhaseInfo.precautions.indexOf('\n')">
              <p
                v-for="(item, index) in activePhaseInfo.precautions.split('\n')"
                :key="index"
                class="descriptions-content text-color-secondary"
              >
                {{ item }}
              </p>
            </div>
            <p v-else class="descriptions-content text-color-secondary">{{ activePhaseInfo.precautions }}</p>
          </a-space>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import * as planApi from '@/api/plan'
import cloneDeep from 'lodash.clonedeep'
import { mapGetters } from 'vuex'
export default {
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          skill: {},
          planWriteAndTeacherAuthority: false, // 是否显示（计划撰写师、教师角色权限）
        }
      },
    },
  },
  data() {
    return {
      activePhase: this.dataSource.skill.currentPhaseNumber - 1 || 0,
      upgradeLoading: false,
    }
  },
  created() {
    const { isPlanWrite, isTeacher } = JSON.parse(this.$route.query.row)
    if (isTeacher && !isPlanWrite) {
      this.planWriteAndTeacherAuthority = false
    } else {
      this.planWriteAndTeacherAuthority = true
    }
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
    planSkillId() {
      return this.dataSource.id || ''
    },
    phaseList() {
      return this.dataSource.skill.phaseList || []
    },
    activePhaseInfo() {
      const tempObj = this.phaseList[this.activePhase || 0] || {}
      tempObj.planSkillId = cloneDeep(this.planSkillId)
      tempObj.skillId = cloneDeep(this.dataSource.skill.id)
      return tempObj
    },
    enableUpgrade() {
      return (
        this.dataSource.status !== 'COMPLETED' ||
        this.dataSource.skill.currentPhaseNumber < this.dataSource.skill.phaseList.length - 1
      )
    },
    // 已完成、训练中的isAutoGenerated为false
    completedNegationAndAutoGenerated() {
      return this.dataSource.status !== 'COMPLETED' && !this.dataSource.isAutoGenerated
    },
    // 是否已完成
    isCompleted() {
      return this.dataSource.status === 'COMPLETED'
    },
  },
  watch: {
    'dataSource.skill.currentPhaseNumber': {
      handler() {
        this.activePhase = this.dataSource.skill.currentPhaseNumber - 1 || 0
      },
      immediate: true,
    },
  },
  methods: {
    handleMenuClick({ key }) {
      this[key] && this[key]()
    },
    replace() {
      const {
        dataSource: { id: planId },
        $notification,
        $info,
      } = this

      this.$emit('replace', (skillId, cb) => {
        planApi
          .replaceSkill({ planId, skillId })
          .then(() => {
            $notification['success']({ message: '操作成功' })
            cb()
          })
          .catch((err) => {
            $info({
              title: '操作失败',
              content: err.msg,
            })
          })
      })
    },
    update() {
      const {
        dataSource: {
          id,
          skill: { name, goal },
        },
      } = this

      this.$emit('update', {
        skill: cloneDeep({ id, name, goal }),
        cb: (goal) => {
          this.dataSource.skill.goal = goal
        },
      })
    },
    skip() {
      const { $confirm, $notification, $info, dataSource } = this

      const confirm = $confirm({
        title: '确定跳过',
        content: '确定跳过当前技能包',
        onOk: () => {
          return planApi
            .skipSkill(dataSource.id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              this.$emit('remove')
            })
            .catch((err) => {
              confirm.destroy()
              $info({
                title: '操作失败',
                content: err.msg,
              })
            })
        },
      })
    },
    remove() {
      const { $confirm, $notification, $info, dataSource } = this

      const confirm = $confirm({
        title: '确定删除',
        content: '确定删除当前技能包',
        onOk: () => {
          return planApi
            .removeSkill(dataSource.id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              this.$emit('remove')
            })
            .catch((err) => {
              confirm.destroy()
              $info({
                title: '不可删除',
                content: err.msg,
              })
            })
        },
      })
    },
    async handlePhaseGrade(type) {
      const {
        $confirm,
        $notification,
        $info,
        dataSource: { id },
        activePhase, // 当前阶段index（从0开始）
        phaseList, // 所有阶段集合
      } = this
      // 是否达到升阶标准，接口参数
      const studentId = this.$route.params.id
      let planPhaseId = ''
      this.dataSource.skill.phaseList.forEach((el) => {
        if (el.isCurrentPhase) {
          planPhaseId = el.id
        }
      })

      let upgradeContent = ''
      try {
        this.upgradeLoading = true
        // 通过接口判断是否达到升阶标准
        const allPhaseLength = phaseList.length
        const { data } = await planApi.isQualified({ studentId, planPhaseId })
        if (data) {
          // 判断是否是最高阶
          if (allPhaseLength - 1 === activePhase) {
            upgradeContent = '当前阶段为技能的最高阶段，学员已达到升阶标准，点击升阶，该技能将全部完成。'
          } else {
            upgradeContent = '学员当前阶段已达到升阶标准，点击升阶，进入下一阶段训练。'
          }
        } else {
          if (allPhaseLength - 1 === activePhase) {
            upgradeContent =
              '当前阶段为技能的最高阶段，学员还未达到升阶标准，点击升阶，该技能将全部完成，请确认是否继续升阶？'
          } else {
            upgradeContent = '学员当前阶段还未达到升阶标准，请确认是否继续升阶？'
          }
        }
      } catch (error) {
        this.upgradeLoading = false
        throw new Error('查询技能阶段状态错误')
      }
      this.upgradeLoading = false

      const confirmMap = {
        upgrade: {
          title: '确定升阶',
          content: upgradeContent,
          onOk: () => planApi.upgradeSkill(id),
        },
        downgrade: {
          title: '确定降阶',
          content: '',
          onOk: () => planApi.downgradeSkill(id),
        },
      }

      const current = confirmMap[type]

      const confirm = $confirm({
        ...current,
        onOk: () => {
          return current
            .onOk()
            .then(() => {
              $notification['success']({ message: '操作成功' })
              const step = type === 'upgrade' ? 1 : -1
              this.dataSource.skill.currentPhaseNumber += step
              const { currentPhaseNumber, phaseCount } = this.dataSource.skill
              const max = phaseCount
              if (currentPhaseNumber > max) {
                this.dataSource.status = 'COMPLETED'
                this.dataSource.skill.currentPhaseNumber = max
              } else {
                // 未发布的状态,升阶、将阶操作,发布状态不变
                this.dataSource.status = this.dataSource.status === 'CREATED' ? 'CREATED' : 'PUBLISHED'
              }
              // 更改阶段列表的选中状态
              this.dataSource.skill.phaseList.map((el, index) => {
                el.isCurrentPhase = index + 1 === currentPhaseNumber
                return el
              })
            })
            .catch((err) => {
              confirm.destroy()
              $info({
                title: '操作失败',
                content: err.msg,
              })
            })
        },
      })
    },
    handleEditPhase() {
      const {
        activePhaseInfo: { id, planSkillId },
        $route: { params },
      } = this
      const { id: studentId } = params
      this.$router.push({
        name: 'homeSchoolPlanSkillPhaseEdit',
        params: { id },
        query: { from: 'homeSchoolDetail', activeKey: 2, planSkillId, studentId },
      })
    },
    getMaterial({ material }) {
      const materialList = material.map((item) => item.materialName) || []
      return materialList.join('、') || '--'
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-card-head {
  padding: 0;
  border-bottom-width: 1px !important;
}
/deep/ .ant-card-head-wrapper {
  align-items: flex-start;
}
.text-color {
  font-size: 14px;
}
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-tabs-bar {
  margin-bottom: 0;
}
/deep/ .ant-tabs-bar {
  border-bottom-width: 0;
}
/deep/ .ant-tabs-ink-bar {
  height: 0;
  display: none;
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 0;
  width: 80px;
  text-align: center;
  margin-bottom: 3px;
  margin-right: 16px;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
}
/deep/ .ant-tabs-nav .ant-tabs-tab-active {
  background: #e1e8ff;
  border-color: #e1e8ff;
}
/deep/ .ant-tabs-tab-arrow-show {
  box-sizing: content-box;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  top: 0px !important;
  bottom: 1px !important;
  height: auto;
}
/deep/ .ant-tabs-nav-container-scrolling {
  padding-left: 48px;
  padding-right: 48px;
}
.heading-color {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-left: 12px;
  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    width: 4px;
    height: 67%;
    background: @primary-color;
    transform: translateY(-50%);
  }
}
.status-badge {
  position: absolute;
  right: -24px;
  bottom: -16px;
}
.status-badge-text {
  position: absolute;
  right: 3px;
  bottom: 12px;
  transform: rotate(-45deg);
  z-index: 3;
  font-size: 12px;
  color: #fff;
}
.status-badge-bg {
  z-index: 1;
  position: absolute;
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  border-top: 32px solid transparent;
  border-right: 32px solid @success-color;
  border-bottom: 32px solid @success-color;
  border-left: 32px solid transparent;
  &.success {
    border-right-color: @success-color;
    border-bottom-color: @success-color;
  }
  &.error {
    border-right-color: @error-color;
    border-bottom-color: @error-color;
  }
  &.normal {
    border-right-color: @yellor-color;
    border-bottom-color: @yellor-color;
  }
}
.descriptions-label,
.descriptions-content,
p.pre {
  line-height: 2;
}
.descriptions-label {
  white-space: nowrap;
}
.descriptions-img {
  max-width: 120px;
}
.descriptions-content {
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
  text-overflow: ellipsis;
  margin: 0;
}
</style>
