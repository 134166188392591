<!-- 报告状态 -->
<template>
  <a-modal
    v-model="visible"
    width="490px"
    :body-style="{ paddingTop: '16px', paddingLeft: '24px', paddingBottom: '24px' }"
    :maskClosable="true"
    :footer="null"
  >
    <div class="name">
      <div class="name-text">{{ reportName[source.type] }}</div>
      <a-badge
        v-if="!isSchoolStatus"
        :class="{
          PUBLISHED: source.status === 'PUBLISHED',
          CREATED: source.status === 'CREATED',
          WITHDRAWAL: source.status === 'WITHDRAWAL',
        }"
        :color="colors[source.status]"
        :text="texts[source.status]"
      />
    </div>
    <div class="time" v-if="isSingleTime">{{ convertReportDate(source.startTime) }}</div>
    <div class="time" v-else>{{ convertReportDate(source.startTime) }}-{{ convertReportDate(source.endTime) }}</div>

    <div v-if="source.evaluationLevel" class="image">
      <img class="img" :src="imgSrcList[source.evaluationLevel].url" alt="图片加载失败..." />
    </div>

    <div v-if="source.creator" class="detail">
      <div class="item"><span class="bold">撰写人：</span>{{ source.creator }}</div>
      <div class="secitem"><span class="bold">首次撰写时间：</span>{{ source.createTimestamp }}</div>
    </div>
    <div v-if="source.publishedBy" class="detail">
      <div class="item"><span>发布人：</span>{{ source.publishedBy }}</div>
      <div class="secitem"><span>发布时间：</span>{{ source.publishedTimestamp }}</div>
    </div>
    <div v-if="source.withdrawalBy" class="detail">
      <div class="item"><span>撤回人：</span>{{ source.withdrawalBy }}</div>
      <div class="secitem"><span>撤回时间：</span>{{ source.withdrawalTimestamp }}</div>
    </div>
    <div class="btn" v-if="isSchoolStatus" style="justify-content: flex-end">
      <a-button
        v-if="$auth('data_report_list_detail')"
        @click="handleNext('detail')"
        type="primary"
        shape="round"
        style="width: 88px; margin: 0 12px"
      >
        查看
      </a-button>
    </div>
    <div class="btn" v-else>
      <!-- 未发布 -->
      <div v-if="source.status === 'CREATED'">
        <a-button
          v-if="$auth('data_report_delete_status_create')"
          @click="showDeteleConfirm('detele')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
          ghost
        >
          删除
        </a-button>
        <a-button
          v-if="$auth('data_report_update_status_published')"
          @click="handleNext('publish')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
        >
          发布
        </a-button>
        <a-button
          v-if="$auth('data_report_update_all_status_create')"
          @click="handleNext('edit')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
          ghost
        >
          编辑
        </a-button>
      </div>
      <!-- 已发布 -->
      <div v-if="source.status === 'PUBLISHED'">
        <a-button
          v-if="$auth('data_report_update_status_withdrawal')"
          @click="showWithdrawalConfirm('withdrawal')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
          ghost
        >
          撤回
        </a-button>
        <a-button
          v-if="$auth('data_report_list_detail')"
          @click="handleNext('detail')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
        >
          查看
        </a-button>
      </div>
      <!-- 已撤回 -->
      <div v-if="source.status === 'WITHDRAWAL'">
        <a-button
          v-if="$auth('data_report_delete_status_withdrawal')"
          @click="showDeteleConfirm('detele')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
          ghost
        >
          删除
        </a-button>
        <a-button
          v-if="$auth('data_report_update_status_regeneration_published')"
          @click="handleNext('publish')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
        >
          再次发布
        </a-button>
        <a-button
          v-if="$auth('data_report_update_all_status_withdrawal')"
          @click="handleNext('edit')"
          type="primary"
          shape="round"
          style="width: 88px; margin: 0 12px"
          ghost
        >
          编辑
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import * as Api from '@/api/datareport'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      reportName: {
        DAILY_NEWS: '教学日报',
        WEEKLY_REPORT: '教学周报',
        MONTHLY_REPORT: '教学月报',
        QUARTERLY_REPORT: '教学季报',
        INTERVENTION_RECORDS: '干预记录'
      },
      reportType: {
        DAILY_NEWS: 'date',
        WEEKLY_REPORT: 'week',
        MONTHLY_REPORT: 'month',
        QUARTERLY_REPORT: 'quarter',
        INTERVENTION_RECORDS: 'INTERVENTION_RECORDS'
      },
      colors: {
        PUBLISHED: '#34ca71',
        CREATED: '#f12a2c',
        WITHDRAWAL: '#f8a70e'
      },
      texts: {
        PUBLISHED: '已发布',
        CREATED: '待发布',
        WITHDRAWAL: '已撤回'
      },
      // 表情包按照1-10排列，0为不评价，无表情包
      imgSrcList: [
        { url: '' },
        { url: require('@/assets/report/emoji/emoji_tbkx.png') },
        { url: require('@/assets/report/emoji/emoji_hlmm.png') },
        { url: require('@/assets/report/emoji/emoji_gqka.png') },
        { url: require('@/assets/report/emoji/emoji_wbqj.png') },
        { url: require('@/assets/report/emoji/emoji_zjzl.png') },
        { url: require('@/assets/report/emoji/emoji_xtq.png') },
        { url: require('@/assets/report/emoji/emoji_ksc.png') },
        { url: require('@/assets/report/emoji/emoji_qxdl.png') },
        { url: require('@/assets/report/emoji/emoji_xkb.png') },
        { url: require('@/assets/report/emoji/emoji_bzzt.png') }
      ],
      source: {}, // 原始数据
      visible: false
    }
  },
  computed: {
    isSingleTime() {
      const { source } = this
      return ['DAILY_NEWS', 'INTERVENTION_RECORDS'].includes(source.type)
    },
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus' // 学生-在园状态【true离园  false在园】
    })
  },
  methods: {
    // 日期转换
    convertReportDate(date) {
      return moment(date).format('LL')
    },
    showModal(e) {
      this.visible = true
      this.source = e
    },
    // 删除
    showDeteleConfirm() {
      const that = this
      this.visible = false
      const confirm = this.$confirm({
        title: '您正在进行数据报告删除操作',
        content: '删除的数据报告将无法找回，请确认操作。',
        onOk() {
          confirm.destroy()
          that.handleDlete()
        },
        onCancel() {
          confirm.destroy()
        }
      })
    },
    // 撤回
    showWithdrawalConfirm() {
      const that = this
      this.visible = false
      const confirm = this.$confirm({
        title: '您正在进行数据报告撤回操作',
        content: '家长将无法查看被撤回的数据报告，撤回后您可编辑或再次发布该报告，请确认操作。',
        onOk() {
          confirm.destroy()
          that.handleWithdraw()
        },
        onCancel() {
          confirm.destroy()
        }
      })
    },
    // 删除报告
    handleDlete() {
      const { source } = this
      const param = {
        dataReportId: source.id
      }
      let api = null
      switch (source.type) {
        case 'QUARTERLY_REPORT':
          api = Api.deleteQuarterlyreport
          break
        default:
          api = Api.deletereport
          break
      }

      api(param).then(res => {
        const { code } = res
        if (code === 0) {
          this.$notification.success({
            message: '提示',
            description: `报告删除成功`
          })
          this.visible = false
          this.$emit('refresh')
        }
      })
    },
    // 撤回报告
    handleWithdraw() {
      const { source } = this
      const param = {
        id: source.id
      }
      let api = null
      switch (source.type) {
        case 'QUARTERLY_REPORT':
          api = Api.revokeQuarterly
          break
        default:
          api = Api.status
          break
      }
      api(param).then(res => {
        const { code } = res
        if (code === 0) {
          this.$notification.success({
            message: '提示',
            description: `报告撤回成功`
          })
          this.visible = false
          this.$emit('refresh')
        }
      })
    },
    // 包含发布、再次发布、查看、编辑
    handleNext(type) {
      const { source, reportType } = this
      const event = {
        type: type,
        toMenuName: 'report',
        toSubMenuName: reportType[source.type],
        param: {
          dataReportId: source.id
        }
      }
      this.visible = false
      this.$emit('event', event)
    }
  }
}
</script>

<style lang="less" scoped>
.name {
  display: flex;
  align-items: center;
  .name-text {
    margin-right: 8px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .PUBLISHED {
    /deep/.ant-badge-status-text {
      color: #34ca71;
    }
  }
  .CREATED {
    /deep/.ant-badge-status-text {
      color: #f12a2c;
    }
  }
  .WITHDRAWAL {
    /deep/.ant-badge-status-text {
      color: #f8a70e;
    }
  }
}
.time {
  margin: 6px 0 16px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #8c8c8c;
}
.image {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: -72px -20px 0;
  .img {
    width: 50px;
  }
}
.detail {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  .secitem {
    margin-left: 24px;
  }
  span {
    font-weight: 600;
  }
}
.detail:not(:last-child) {
  margin-bottom: 8px;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
</style>
