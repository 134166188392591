<template>
  <a-card :bodyStyle="{ padding: '0 24px 24px' }">
    <div slot="title">
      技能包子分类占比
    </div>

    <div slot="extra">
      <h-select v-model="fieldId" :allowClear="false" placeholder="选择领域" style="width:200px">
        <a-select-option v-for="mainField in mainFieldOptions" :key="mainField.id" :value="mainField.id">
          {{ mainField.name }}
        </a-select-option>
      </h-select>
    </div>

    <a-radio-group
      v-model="isTraining"
      @change="getData()"
      size="small"
      button-style="solid"
      style="margin-bottom:24px"
    >
      <a-radio-button :value="true">
        已训练
      </a-radio-button>
      <a-radio-button :value="false">
        已达标
      </a-radio-button>
    </a-radio-group>

    <div style="height:240px">
      <main-chart :dataSource="list"></main-chart>
    </div>
  </a-card>
</template>

<script>
import * as fieldApi from '@/api/field'
import * as trainingSkillStaApi from '@/api/training-skill-statistics'
import MainChart from './SkillMainFieldChart'
export default {
  components: {
    MainChart
  },
  data() {
    return {
      list: [],
      isTraining: true,
      mainFieldOptions: [],
      fieldId: undefined
    }
  },
  watch: {
    fieldId() {
      if (this.fieldId) {
        this.getData()
      }
    }
  },
  mounted() {
    fieldApi.mainList().then(res => {
      this.mainFieldOptions = res.data
      this.fieldId = this.mainFieldOptions[0].id
    })
  },
  methods: {
    initData() {
      this.getData()
    },
    getData() {
      const query = this.initQuery()

      trainingSkillStaApi.fieldSkillsCount(query).then(res => {
        this.list = res.data.map(({ fieldsName: item, skillsCount: count }) => ({
          item,
          count
        }))
      })
    },
    initQuery() {
      const {
        $route: {
          params: { id: studentId }
        },
        isTraining,
        fieldId
      } = this

      return { studentId, isTraining, fieldId }
    }
  }
}
</script>

<style lang="less" scoped></style>
