<template>
  <div>
    <a-card
      v-for="(phaseInfo, index) in phaseList"
      :key="index"
      :headStyle="{ padding: 0 }"
      :bodyStyle="{ padding: '0 64px 32px 64px' }"
      :style="index === phaseList.length - 1 ? { borderBottom: 'none' } : index === 0 ? { borderTopWidth: '1px' } : {}"
    >
      <div slot="title" class="custom-card-head">
        <div class="custom-card-head-title">
          <a-space>
            <a-icon type="right-circle" />
            <span>{{ phaseInfo.name }}</span>
          </a-space>
        </div>
      </div>

      <a-empty v-if="phaseInfo.trainingPreparationList.length === 0"></a-empty>
      <div v-else v-for="(preparation, pIndex) in phaseInfo.trainingPreparationList" :key="pIndex">
        <a-space direction="vertical" :size="16">
          <p v-if="preparation.introduction" class="pre">{{ preparation.introduction }}</p>

          <a-space direction="vertical">
            <div v-for="(resourceInfo, rIndex) in preparation.resourceList" :key="rIndex">
              <video v-if="resourceInfo.type === 'VIDEO'" controls width="250">
                <source :src="resourceInfo.url" type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
              <div v-if="resourceInfo.type === 'IMAGE'" style="width:250px">
                <img :src="resourceInfo.url" style="width:100%;object-fit:contain" />
              </div>
            </div>
          </a-space>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phaseList: []
    }
  },
  methods: {
    initData(data) {
      this.phaseList = data.phaseList

      return Promise.resolve()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-card-bordered {
  border-left: none;
  border-right: none;
  border-top-width: 0;
  border-radius: 0;
}
p.pre {
  line-height: 2;
}
</style>
