<!-- 家校训练服务-详情-评估结果-查看题目 -->
<template>
  <a-modal
    v-model="visible"
    :title="questionCode()"
    width="420px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :footer="null"
  >
    <a-card style="height: 100%; overflow: auto">
      <div class="skill">关联技能包：{{ data.skillVOList && data.skillVOList.join('、') }}</div>
      <a-divider />
      <div class="description"><span class="type">题目</span>{{ data.questionStem }}</div>
      <div class="optionList">
        <div
          v-for="(item, index) in data.optionList"
          :key="index"
          v-if="item.score"
          :class="item.selected ? 'active' : ''"
        >
          {{ item.description }}
          <div class="score" v-if="item.selected">{{ item.score }}分</div>
        </div>
        <div
          v-for="(item, index) in data.optionList"
          :key="index"
          v-if="!item.score"
          :class="item.selected ? 'active' : ''"
        >
          {{ item.description }}
          <div class="score" v-if="item.selected">不得分</div>
        </div>
      </div>
    </a-card>
    <div style="padding: 10px 16px; text-align: right">
      <a-button type="primary" shape="round" @click="handleOk">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    questionCode() {
      // 最多显示3位code
      const code = (this.data.code + '').substr(8)
      const codeLengthLimit = 3
      return `题目编号：${(Array(codeLengthLimit).join(0) + code).slice(-codeLengthLimit)}`
    },

    showModal() {
      this.visible = true
    },

    handleOk() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.skill {
  color: #333;
  font-weight: bolder;
}
.description {
  margin-bottom: 20px;
  color: #333;
  .type {
    display: inline-block;
    width: 46px;
    line-height: 20px;
    text-align: center;
    background: #3350c7;
    margin-right: 5px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
  }
}
.optionList {
  > div {
    border-radius: 3px;
    padding: 5px;
    border: 1px solid #dfdfdf;
    margin-bottom: 20px;
    color: #a3a3a3;
    .score {
      text-align: right;
    }
  }
  .active {
    color: #3a5ff8;
    border: 1px solid #3a5ff8;
  }
}
/*/deep/ .ant-modal-body {*/
/*  height: calc(100vh - 200px - 110px);*/
/*  overflow-y: auto;*/
/*}*/

/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
