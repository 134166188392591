<!-- 家校训练服务-详情-技能评估-评估报告-列表 -->
<template>
  <div>
    <div
      v-if="loading"
      style="
        min-height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 64px;
      "
    >
      <a-spin></a-spin>
    </div>
    <template v-else>
      <a-space v-if="needInit" align="center" direction="vertical" style="width: 100%">
        <a-space align="center" direction="vertical" :size="24">
          <h3>当前暂无评估报告~</h3>
          <a-button
            v-if="!isSchoolStatus"
            type="primary"
            shape="round"
            size="large"
            style="width: 236px; font-size: 14px"
            :loading="initReportLoading"
            @click="handleInitReport"
            >生成评估报告</a-button
          >
        </a-space>
      </a-space>
      <template v-else>
        <evaluation-report-table @onRow="onRow" />
      </template>
    </template>
  </div>
</template>

<script>
import * as evaluationReportApi from '@/api/evaluation-report'
import EvaluationReportTable from './components/Table'
import { mapGetters } from 'vuex'
export default {
  name: 'EvaluationReportList',
  components: {
    EvaluationReportTable
  },
  data() {
    return {
      needInit: false,
      loading: true,
      initReportLoading: false
    }
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus' // 学生-在园状态【true离园  false在园】
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      const { id } = this.$route.params
      console.log('get data report: ', id)
      this.loading = true
      evaluationReportApi
        .check(id)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            this.needInit = data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleInitReport() {
      const { id } = this.$route.params
      this.initReportLoading = true
      evaluationReportApi
        .init(id)
        .then(res => {
          const { code } = res
          if (code === 0) {
            this.needInit = false
          }
        })
        .finally(() => {
          this.initReportLoading = false
        })
    },
    onRow(params) {
      this.$emit('onRow', params)
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
.refuse {
  color: #f12a2c;
  img {
    position: relative;
    top: -2px;
    left: -2px;
    width: 12px;
    margin-right: 6px;
  }
}
</style>
