import request from '@/utils/request'

const homeworkApi = {
  calendar: studentId => `/homeworks/students/${studentId}/calendar`,
  homework: '/homeworks',
  skilllist: studentId => `/homeworks/students/${studentId}/plan-skills`,
  change: id => `/homeworks/${id}`,
  delete: id => `/homeworks/${id}`,
  phase: planSkillId => `/planSkill/${planSkillId}/planSkillPhases`
}
// 获取作业日历
export function calendar(parameter) {
  const { studentId } = parameter
  return request({
    url: homeworkApi.calendar(studentId),
    method: 'get',
    params: parameter
  })
}
// 获取技能包列表
export function list(parameter) {
  const { studentId } = parameter
  return request({
    url: homeworkApi.skilllist(studentId),
    method: 'get',
    params: parameter
  })
}
// 获取阶段
export function phase(parameter) {
  const { planSkillId } = parameter
  return request({
    url: homeworkApi.phase(planSkillId),
    method: 'get'
  })
}
// 添加作业
export function addhomework(parameter) {
  return request({
    url: homeworkApi.homework,
    method: 'post',
    data: parameter
  })
}
// 获取作业列表
export function gethomework(parameter) {
  return request({
    url: homeworkApi.homework,
    method: 'get',
    params: parameter
  })
}
// 修改作业
export function change(parameter) {
  const { id } = parameter
  return request({
    url: homeworkApi.change(id),
    method: 'put',
    data: parameter
  })
}
// 删除作业
export function delhomework(parameter) {
  const { id } = parameter
  return request({
    url: homeworkApi.delete(id),
    method: 'delete'
  })
}
