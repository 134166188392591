<!-- 月报步骤 -->
<template>
  <a-card :body-style="{ padding: '26px 24px 24px', minHeight: '82vh' }">
    <!-- 步骤条 -->
    <a-steps style="padding:0 6px 42px" :current="nowStep">
      <a-step title="选择达标技能" />
      <a-step title="选择训练技能" />
      <a-step title="选择达标素材" />
      <a-step title="选择待训技能" />
      <a-step title="选择家庭作业" />
    </a-steps>
    <!-- 加载与无数据 -->
    <a-spin :spinning="spinning">
      <!-- 第一步 -->
      <div v-if="nowStep === 0" class="steps">
        <div class="title">本月已达标技能</div>
        <div class="subtitle">请选择需在月报中呈现的已达标技能</div>

        <div v-for="item in stepZerosource" :key="item.id" class="month-skill-phase-item">
          <a-checkbox
            class="item-skill-check"
            :checked="isStepzeroChecked({ datatype: 'skill', datavalue: item.id })"
            :value="{ datatype: 'skill', datavalue: item.id }"
            @change="onStepzeroChange"
          >
            <div class="check-wrap">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div class="goal">技能目标：{{ item.goal }}</div>
          </a-checkbox>

          <a-checkbox
            v-for="phase in item.dailyReportPhaseList"
            :key="phase.phaseId"
            class="item-phase-check"
            :checked="isStepzeroChecked({ datatype: 'phase', datavalue: phase.phaseId })"
            :value="{ datatype: 'phase', datavalue: phase.phaseId, skillid: item.id }"
            @change="onStepzeroChange"
          >
            <div class="name">第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</div>
            <div class="goal">阶段目标：{{ phase.goal }}</div>
          </a-checkbox>
        </div>
      </div>

      <!-- 第二步 -->
      <div v-if="nowStep === 1" class="steps">
        <div class="title">本月训练技能</div>
        <div class="subtitle">请选择需在月报中呈现的正在训练技能</div>

        <a-checkbox-group style="width:100%" v-model="stepOneform">
          <a-checkbox v-for="item in stepOnesource" :key="item.name" class="month-skill-item" :value="item.id">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div class="item-goal">技能目标：{{ item.goal }}</div>
          </a-checkbox>
        </a-checkbox-group>
      </div>

      <!-- 第三步 -->
      <div v-if="nowStep === 2" class="steps">
        <div class="title">本月已达标素材</div>
        <div class="subtitle">请选择需在月报中呈现的已达标素材</div>

        <a-checkbox-group style="width:100%" v-model="stepTwoform">
          <div v-for="item in stepTwosource" :key="item.id" class="phase-item">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div v-for="itemphase in item.dailyReportPhaseList" :key="itemphase.phaseId" class="item-mat">
              <div class="mat-title">第{{ convertToChineseNumber(itemphase.phaseNumber) }}阶段达标素材：</div>
              <a-checkbox
                :value="phasemat.name"
                v-for="phasemat in itemphase.dailyReportMaterialList"
                :key="phasemat.name"
                class="mat-name"
              >
                {{ phasemat.name }}({{
                  phasemat.numberOfTrainingSessions ? `${phasemat.numberOfTrainingSessions}次` : '未练习'
                }})
              </a-checkbox>
              <span v-if="!itemphase.dailyReportMaterialList">无达标素材</span>
            </div>
            <div v-if="!item.dailyReportPhaseList" class="mat-name">无达标素材</div>
          </div>
        </a-checkbox-group>
      </div>

      <!-- 第四步 -->
      <div v-if="nowStep === 3" class="steps">
        <div class="title">即将训练的技能</div>
        <div class="subtitle">请选择需在月报中呈现的待训技能</div>
        <a-checkbox-group style="width:100%" v-model="stepThreeform">
          <a-checkbox v-for="item in stepThreesource" :key="item.id" class="month-skill-item" :value="item.id">
            <div class="item-title">
              <div class="des">{{ item.name }}</div>
              <div class="tag">{{ item.fieldName }}</div>
            </div>
            <div class="item-goal">技能目标：{{ item.goal }}</div>
          </a-checkbox>
        </a-checkbox-group>
      </div>

      <!-- 第五步 -->
      <div v-if="nowStep === 4" class="steps">
        <p class="title">本月已练习作业</p>
        <p class="subtitle">请选择需在月报中呈现的家庭作业</p>

        <div v-for="item in stepFoursource" :key="item.id" class="homework-item">
          <a-checkbox
            class="item-title"
            @change="onChange"
            :checked="isChecked({ datatype: 'skill', datavalue: item.id })"
            :value="{ datatype: 'skill', datavalue: item.id }"
          >
            <div class="des">{{ item.name }}</div>
            <div class="tag">{{ item.fieldName }}</div>
          </a-checkbox>

          <div class="item-mat">
            <span class="mat-title">练习素材：</span>
            <a-checkbox
              v-for="phasemat in item.dataReportHomeworkMaterialList"
              :key="phasemat.name"
              :checked="isChecked({ datatype: 'mat', datavalue: phasemat.name })"
              :value="{ datatype: 'mat', datavalue: phasemat.name, skillid: item.id }"
              @change="onChange"
              class="mat-name"
            >
              {{ phasemat.name }}({{
                phasemat.numberOfTrainingSessions ? `${phasemat.numberOfTrainingSessions}次` : '未练习'
              }})
            </a-checkbox>
            <span v-if="!item.dataReportHomeworkMaterialList" class="mat-name">无练习素材</span>
          </div>
        </div>
      </div>
      <!-- 无数据 -->
      <a-empty v-if="empty" :description="description" style="margin-top: 40px" />
    </a-spin>
    <!-- 底部按钮 -->
    <div class="footer-btn-wrap">
      <a-button @click="cancelHandle" type="primary" shape="round" ghost style="width:160px;height:40px;margin:0 32px"
        >取消</a-button
      >
      <a-button
        @click="previousHandle"
        v-show="nowStep !== 0"
        type="primary"
        shape="round"
        ghost
        style="width:160px;height:40px;margin:0 32px"
        >上一步</a-button
      >
      <a-button
        @click="nextHandle"
        v-show="nowStep !== 4"
        type="primary"
        shape="round"
        :loading="spinning"
        style="width:160px;height:40px;margin:0 32px"
        >下一步</a-button
      >
      <a-button
        @click="createHandle"
        v-if="nowStep === 4"
        type="primary"
        shape="round"
        :loading="spinning"
        style="width:160px;height:40px;margin:0 32px"
        >生成报告</a-button
      >
    </div>
  </a-card>
</template>
<script>
import * as Api from '@/api/datareport'
import { convertToChineseNumber } from '@/utils/util'
import { cloneDeep } from 'lodash'

export default {
  data() {
    return {
      stepFormDecoding: ['stepZeroSkillform', 'stepOneform', 'stepTwoform', 'stepThreeform', 'stepFourSkillform'],
      stepSourceDecoding: ['stepZerosource', 'stepOnesource', 'stepTwosource', 'stepThreesource', 'stepFoursource'],
      nowStep: 0, // 当前第几步,从0开始
      spinning: true, // 数据加载中
      empty: false, // 是否显示无数据
      description: '', // 无数据的提示
      eventParam: {}, // 传递过来的事件参数
      constStep: 4, // 当前选择总共有几步，从0开始
      maxDoneStep: -1, // 本次步骤选择中保存到第几步
      isFirstDone: false, // 是否完整走过所有步骤

      isUseHistory: false, // 1-3步是否复用上一次的数据
      isPlanUseHistory: false, // 第4步的训练计划是否发生变化
      ishomeworkUseHistory: false, // 家庭作业是否复用上一次的数据
      allDataSource: {}, // 一、二、三步上课数据
      stepZerosource: [], // 第一步原始数据
      stepZeroSkillform: [], // 第一步选中的技能包
      stepZeroPhaseform: [], // 第一步选中的阶段
      stepOnesource: [], // 第二步原始数据
      stepOneform: [], // 第二步选中
      stepTwosource: [], // 第三步原始数据
      stepTwoform: [], // 第三步选中
      stepThreesource: [], // 第四步原始数据
      stepThreeform: [], // 第四步选中
      stepFoursource: [], // 第五步原始数据
      stepFourSkillform: [], // 第五步选中的技能包
      stepFourMaterialform: [] // 第五步选中的素材
    }
  },
  methods: {
    convertToChineseNumber,
    // 第一步达标技能包双重选择记录，包含技能包、阶段
    // 是否选中
    isStepzeroChecked(obj) {
      if (obj.datatype === 'skill') {
        return this.stepZeroSkillform.includes(obj.datavalue)
      }
      if (obj.datatype === 'phase') {
        return this.stepZeroPhaseform.includes(obj.datavalue)
      }
    },
    // 改变选中状态
    onStepzeroChange(e) {
      const type = e.target.value.datatype
      if (type === 'skill') {
        this.StepzeroskillChecked(e)
      }
      if (type === 'phase') {
        this.StepzeroPhaseChecked(e)
      }
    },
    // 技能包id选中
    StepzeroskillChecked(e) {
      // 选中技能就选中所有素材、取消就取消所有素材
      const { stepZerosource } = this
      const value = e.target.value.datavalue
      const skillform = cloneDeep(this.stepZeroSkillform)
      const phaseform = cloneDeep(this.stepZeroPhaseform)
      // 选中技能包
      if (e.target.checked) {
        this.stepZeroSkillform.push(value)
        stepZerosource.forEach(el => {
          if (el.id === value) {
            if (el.dailyReportPhaseList.length) {
              el.dailyReportPhaseList.forEach(phase => {
                phaseform.push(phase.phaseId)
              })
            }
          }
        })
        this.stepZeroPhaseform = [...new Set(phaseform)]
      } else {
        // 取消技能包
        let temp = []
        this.stepZeroSkillform = skillform.filter(id => id !== value)
        stepZerosource.forEach(el => {
          if (el.id === value) {
            temp = el.dailyReportPhaseList.map(phase => {
              return phase.phaseId
            })
          }
        })
        this.stepZeroPhaseform = phaseform.filter(phaseid => !temp.includes(phaseid))
      }
    },
    // 阶段选中
    StepzeroPhaseChecked(e) {
      // 选中一个阶段就选中技能、取消所有阶段就取消技能包选中
      const { stepZerosource } = this
      const value = e.target.value.datavalue
      const skillid = e.target.value.skillid // 技能包id
      const skillform = cloneDeep(this.stepZeroSkillform)
      const phaseform = cloneDeep(this.stepZeroPhaseform)
      // 选中阶段
      if (e.target.checked) {
        this.stepZeroPhaseform.push(value)
        // 阶段对应的技能包id
        skillform.push(skillid)
        this.stepZeroSkillform = [...new Set(skillform)]
      } else {
        // 取消阶段
        this.stepZeroPhaseform = phaseform.filter(phaseid => phaseid !== value)
        // 找到素材对应的技能包，检查技能包下的素材，是否选中
        const skill = stepZerosource.find(el => el.id === skillid)
        // 检查技能包下的阶段，是不是在选中阶段数组里
        if (skill.dailyReportPhaseList.length) {
          if (skill.dailyReportPhaseList.every(phase => !this.stepZeroPhaseform.includes(phase.phaseId))) {
            this.stepZeroSkillform = skillform.filter(id => id !== skillid)
          }
        }
      }
    },
    // 第五步家庭作业双重选择记录，包含技能包、素材
    // 是否选中
    isChecked(obj) {
      if (obj.datatype === 'skill') {
        return this.stepFourSkillform.includes(obj.datavalue)
      }
      if (obj.datatype === 'mat') {
        return this.stepFourMaterialform.includes(obj.datavalue)
      }
    },
    // 改变选中状态
    onChange(e) {
      const type = e.target.value.datatype
      if (type === 'skill') {
        this.skillChecked(e)
      }
      if (type === 'mat') {
        this.matChecked(e)
      }
    },
    // 技能包id选中
    skillChecked(e) {
      // 选中技能就选中所有素材、取消就取消所有素材
      const { stepFoursource } = this
      const value = e.target.value.datavalue
      const skillform = cloneDeep(this.stepFourSkillform)
      const matform = cloneDeep(this.stepFourMaterialform)
      // 选中技能包
      if (e.target.checked) {
        this.stepFourSkillform.push(value)
        stepFoursource.forEach(el => {
          if (el.id === value) {
            el.dataReportHomeworkMaterialList.forEach(mat => {
              matform.push(mat.name)
            })
          }
        })
        this.stepFourMaterialform = [...new Set(matform)]
      } else {
        // 取消技能包
        let temp = []
        this.stepFourSkillform = skillform.filter(id => id !== value)
        stepFoursource.forEach(el => {
          if (el.id === value) {
            temp = el.dataReportHomeworkMaterialList.map(mat => {
              return mat.name
            })
          }
        })
        this.stepFourMaterialform = matform.filter(name => !temp.includes(name))
      }
    },
    // 素材选中
    matChecked(e) {
      // 选中一个素材就选中技能、取消所有素材就取消技能
      const { stepFoursource } = this
      const value = e.target.value.datavalue // 素材值
      const skillid = e.target.value.skillid // 技能包id
      const skillform = cloneDeep(this.stepFourSkillform)
      const matform = cloneDeep(this.stepFourMaterialform)
      // 选中素材
      if (e.target.checked) {
        this.stepFourMaterialform.push(value)

        skillform.push(skillid)
        this.stepFourSkillform = [...new Set(skillform)]
      } else {
        // 取消素材
        this.stepFourMaterialform = matform.filter(name => name !== value)
        // 找到素材对应的技能包，检查技能包下的素材，是否选中
        const skill = stepFoursource.find(el => el.id === skillid)
        if (skill.dataReportHomeworkMaterialList.every(mat => !this.stepFourMaterialform.includes(mat.name))) {
          this.stepFourSkillform = skillform.filter(id => id !== skillid)
        }
      }
    },
    // 进入
    initData(e) {
      if (e.type === 'newadd') {
        this.newAddRequest(e)
      }
      if (e.type === 'regenerate') {
        this.regenerateRequest(e)
      }
    },
    // 新增报告获取数据
    newAddRequest(e) {
      // 保存基础参数，包含startDate,endDate,studentId等
      this.eventParam = e
      // 获取所有的上课数据
      Api.monthlyallData(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.allDataSource = data.trainedMonthlyReportSkillList
          this.stepThreesource = data.untrainedMonthlyReportSkillList
          this.initShowData()
        }
      })
      // 获取所有的家庭作业
      Api.monthlyHomework(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.stepFoursource = data
        }
      })
    },
    // 重新生成获取数据
    regenerateRequest(e) {
      // 保存基础参数，包含startDate,endDate,studentId等
      this.eventParam = e
      // 获取所有的上课数据
      Api.remonthlyallData(e.param).then(res => {
        const { code, data } = res
        if (code === 0) {
          // 1-4步都使用上一次的数据
          this.isUseHistory = true
          this.isPlanUseHistory = true
        } else {
          // 判断上课数据是否复用
          if (data.classRegenerationTips !== '') {
            this.isUseHistory = false
            this.$notification.warning({
              message: '提示',
              description: data.classRegenerationTips
            })
          } else {
            this.isUseHistory = true
          }
          // 判断训练数据是否复用
          if (data.planRegenerationTips !== '') {
            this.isPlanUseHistory = false
          } else {
            this.isPlanUseHistory = true
          }
        }
        this.allDataSource = data.trainedMonthlyReportSkillList
        this.stepThreesource = data.untrainedMonthlyReportSkillList
        this.initShowData()
      })
      // 获取所有的家庭作业
      Api.remonthlyHomework(e.param).then(res => {
        const { code, data } = res
        this.stepFoursource = data
        if (code === 0) {
          this.ishomeworkUseHistory = true
        } else {
          this.ishomeworkUseHistory = false
        }
      })
    },
    // 每一步都是相同的逻辑，默认记录当前步骤的源数据和选中选项，先获取上一步/下一步的基础选项，再按照规则生成默认选中。
    // 项目需求是1、进入步骤选择后，新增报告默认选择全部选项，重新生成可能使用上一次报告的选项
    // 2、当本轮全部步骤都选择完毕，切换上/下一步时，使用本轮选择的选项
    //
    initShowData() {
      // 默认加载中，关闭加载中在生成默认选中选项后
      this.spinning = true
      this.initBaseOption()
      this.checkRoundAndSelect()
    },
    // 生成基础选项
    initBaseOption() {
      const { allDataSource, nowStep } = this
      // 第一步
      if (nowStep === 0) {
        // 使用深克隆，避免第一步把源数据的阶段清空了
        const cloneallData = cloneDeep(allDataSource)
        const QAskill = cloneallData.filter(skill => skill.isQualified)
        QAskill.forEach(skill => {
          // 根据hasQualifiedPhase判断第一步是否展示阶段
          if (skill.hasQualifiedPhase) {
            skill.dailyReportPhaseList = skill.dailyReportPhaseList.filter(phase => phase.isQualified)
          } else {
            skill.dailyReportPhaseList = []
          }
        })
        this.stepZerosource = QAskill

        if (!this.stepZerosource.length) {
          this.empty = true
          this.description = '本月暂无已达标技能'
        }
      }
      // 第二步
      if (nowStep === 1) {
        const skills = allDataSource.filter(skill => skill.isTrained)
        this.stepOnesource = skills.filter(skill => !this.stepZeroSkillform.includes(skill.id))
        if (!this.stepOnesource.length) {
          this.empty = true
          this.description = '本月暂无正在训练的技能'
        }
      }
      // 第三步
      if (nowStep === 2) {
        // 第一步、二步选中的素材,进行去重
        const skillSelected = [...new Set(this.stepZeroSkillform.concat(this.stepOneform))]
        // 根据上课数据筛选的技能包
        this.stepTwosource = allDataSource.filter(skill => skillSelected.includes(skill.id))
      }
      // 第四步
      if (nowStep === 3) {
        if (!this.stepThreesource.length) {
          this.empty = true
          this.description = '本月暂无未训练技能'
        }
      }
      // 第五步
      if (nowStep === 4) {
        if (!this.stepFoursource.length) {
          this.empty = true
          this.description = '本月暂无家庭作业'
        }
      }
    },
    // 检查轮次,根据轮次、事件类型,生成默认选中
    checkRoundAndSelect() {
      const { isFirstDone, maxDoneStep, nowStep, eventParam } = this
      // 检查轮次，是否全部步骤都选择过并进行保存
      if (!isFirstDone) {
        // 步骤是否未操作过
        if (nowStep > maxDoneStep) {
          // 新增报告默认全选
          if (eventParam.type === 'newadd') {
            this.initAllSelect().then(() => {
              this.spinning = false
              this.saveMaxDoneStep()
            })
          }
          // 重新生成报告判断数据变化，决定默认全选还是复用上一次的
          if (eventParam.type === 'regenerate') {
            this.checkRegenerateNowstep()
          }
        } else if (nowStep <= maxDoneStep) {
          this.initNowSelect()
        }
      } else {
        this.initNowSelect()
      }
    },
    // 重新生成的默认选中判断逻辑
    checkRegenerateNowstep() {
      const { nowStep, isUseHistory, ishomeworkUseHistory, isPlanUseHistory } = this
      if (nowStep >= 0 && nowStep < 3) {
        if (isUseHistory) {
          this.initPreSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        } else {
          this.initAllSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        }
      }

      if (nowStep === 3) {
        if (isPlanUseHistory) {
          this.initPreSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        } else {
          this.$notification.warning({
            message: '提示',
            description: '当前报告周期已产生新的训练数据，请重新选择'
          })
          this.initAllSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        }
      }

      if (nowStep === 4) {
        if (ishomeworkUseHistory) {
          this.initPreSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        } else {
          this.$notification.warning({
            message: '提示',
            description: '当前报告周期已产生新的训练数据，请重新选择'
          })
          this.initAllSelect().then(() => {
            this.spinning = false
            this.saveMaxDoneStep()
          })
        }
      }
    },
    // 默认全选
    initAllSelect() {
      const { nowStep, empty, stepSourceDecoding, stepFormDecoding } = this
      return new Promise(resolve => {
        // 第一步
        if (nowStep === 0) {
          if (!empty) {
            const skillArr = []
            const phaseArr = []
            this.stepZerosource.forEach(el => {
              skillArr.push(el.id)
              el.dailyReportPhaseList.forEach(phase => {
                phaseArr.push(phase.phaseId)
              })
            })

            this.stepZeroSkillform = skillArr
            this.stepZeroPhaseform = phaseArr
            resolve()
          } else {
            resolve()
          }
        }
        // 第二步// 第四步
        // if (nowStep === 1 || nowStep === 3) {
        if (nowStep === 1) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            tempArr.push(el.id)
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第三步
        if (nowStep === 2) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            if (el.dailyReportPhaseList) {
              el.dailyReportPhaseList.forEach(phase => {
                if (phase.dailyReportMaterialList) {
                  phase.dailyReportMaterialList.forEach(mat => {
                    tempArr.push(mat.name)
                  })
                }
              })
            }
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第五步
        if (nowStep === 4) {
          if (!empty) {
            const tempArr = []
            const matArr = []
            this.stepFoursource.forEach(el => {
              tempArr.push(el.id)
              el.dataReportHomeworkMaterialList.forEach(mat => {
                matArr.push(mat.name)
              })
            })

            this.stepFourSkillform = tempArr
            this.stepFourMaterialform = matArr
            resolve()
          } else {
            resolve()
          }
        }
      })
    },
    // 使用上一次报告的选中
    initPreSelect() {
      const { nowStep, empty, stepSourceDecoding, stepFormDecoding } = this
      return new Promise(resolve => {
        // 第一步
        if (nowStep === 0) {
          if (!empty) {
            const skillArr = []
            const phaseArr = []
            this.stepZerosource.forEach(el => {
              if (el.selectedStep.includes(nowStep + 1)) {
                skillArr.push(el.id)
                el.dailyReportPhaseList.forEach(phase => {
                  if (phase.selectedStep.includes(nowStep + 1)) {
                    phaseArr.push(phase.phaseId)
                  }
                })
              }
            })

            this.stepZeroSkillform = skillArr
            this.stepZeroPhaseform = phaseArr
            resolve()
          } else {
            resolve()
          }
        }
        // 第二步// 第四步
        if (nowStep === 1 || nowStep === 3) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            if (el.selectedStep.includes(nowStep + 1)) {
              tempArr.push(el.id)
            }
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第三步
        if (nowStep === 2) {
          const tempArr = []
          this[stepSourceDecoding[nowStep]].forEach(el => {
            if (el.selectedStep.includes(nowStep + 1)) {
              if (el.dailyReportPhaseList.length) {
                el.dailyReportPhaseList.forEach(phase => {
                  if (phase.selectedStep.includes(nowStep + 1)) {
                    if (phase.dailyReportMaterialList) {
                      phase.dailyReportMaterialList.forEach(mat => {
                        if (mat.selectedStep.includes(nowStep + 1)) {
                          tempArr.push(mat.name)
                        }
                      })
                    }
                  }
                })
              }
            }
          })
          this[stepFormDecoding[nowStep]] = tempArr
          resolve()
        }
        // 第五步
        if (nowStep === 4) {
          if (!empty) {
            const tempArr = []
            const matArr = []
            this.stepFoursource.forEach(el => {
              if (el.isSelected) {
                tempArr.push(el.id)
                el.dataReportHomeworkMaterialList.forEach(mat => {
                  if (mat.isSelected) {
                    matArr.push(mat.name)
                  }
                })
              }
            })

            this.stepFourSkillform = tempArr
            this.stepFourMaterialform = matArr
            resolve()
          } else {
            resolve()
          }
        }
      })
    },
    // 使用本轮次的选中
    initNowSelect() {
      const { nowStep, stepSourceDecoding, stepFormDecoding } = this
      // 第二步
      if (nowStep === 1) {
        const tempArr = []
        const preSelectedForm = cloneDeep(this[stepFormDecoding[nowStep]])
        this[stepSourceDecoding[nowStep]].forEach(el => {
          tempArr.push(el.id)
        })
        this[stepFormDecoding[nowStep]] = tempArr.filter(id => preSelectedForm.includes(id))
      }

      // 第三步
      if (nowStep === 2) {
        const nowMat = []
        const preSelectedForm = cloneDeep(this[stepFormDecoding[nowStep]])
        this[stepSourceDecoding[nowStep]].forEach(el => {
          if (el.dailyReportPhaseList) {
            el.dailyReportPhaseList.forEach(phase => {
              if (phase.dailyReportMaterialList) {
                phase.dailyReportMaterialList.forEach(mat => {
                  nowMat.push(mat.name)
                })
              }
            })
          }
        })
        const tempArr = nowMat.filter(name => preSelectedForm.includes(name))
        this[stepFormDecoding[nowStep]] = tempArr
      }
      this.spinning = false
    },
    // 记录最大完成步数
    saveMaxDoneStep() {
      const { isFirstDone, maxDoneStep, constStep, nowStep } = this
      if (!isFirstDone) {
        if (nowStep === constStep) {
          this.isFirstDone = true
          this.maxDoneStep = nowStep
        } else if (nowStep > maxDoneStep) {
          this.maxDoneStep = nowStep
        }
      }
    },
    // 取消
    cancelHandle() {
      const event = {
        type: 'cancel',
        toMenuName: 'calendar',
        toSubMenuName: '',
        param: {}
      }
      this.$emit('event', event)
      this.setAllDataDefault()
    },
    // 把数据恢复为初始值
    setAllDataDefault() {
      this.nowStep = 0
      this.spinning = true
      this.empty = false
      this.description = ''
      this.eventParam = {}
      this.constStep = 4
      this.maxDoneStep = -1
      this.isFirstDone = false

      this.isUseHistory = false
      this.ishomeworkUseHistory = false
      this.allDataSource = {}
      this.stepZerosource = []
      this.stepZeroSkillform = []
      this.stepZeroPhaseform = []
      this.stepOnesource = []
      this.stepOneform = []
      this.stepTwosource = []
      this.stepTwoform = []
      this.stepThreesource = []
      this.stepThreeform = []
      this.stepFoursource = []
      this.stepFourSkillform = []
      this.stepFourMaterialform = []
    },
    // 上一步
    previousHandle() {
      if (this.nowStep > 0) {
        // 清空无数据状态
        this.empty = false
        this.description = ''
        this.nowStep -= 1
        this.preIsEmpty()
      }
    },
    // 返回上一步时，检查是否有数据
    preIsEmpty() {
      const { nowStep } = this
      // 第一步
      if (nowStep === 0) {
        if (!this.stepZerosource.length) {
          this.empty = true
          this.description = '本月暂无已达标技能'
        }
      }
      // 第二步
      if (nowStep === 1) {
        if (!this.stepOnesource.length) {
          this.empty = true
          this.description = '本月暂无正在训练的技能'
        }
      }
      // 第四步
      if (nowStep === 3) {
        if (!this.stepThreesource.length) {
          this.empty = true
          this.description = '本月暂无未训练技能'
        }
      }
    },
    // 下一步
    nextHandle() {
      this.spinning = true
      this.checkStepIsAllow()
        .then(() => {
          // 清空无数据状态
          this.empty = false
          this.description = ''
          this.nowStep += 1
          this.initShowData()
        })
        .finally(() => {
          this.spinning = false
        })
    },
    // 检查第一步、第二步的选择是否符合规则
    checkStepIsAllow() {
      // const { nowStep, stepZeroSkillform, stepFormDecoding } = this
      const { nowStep } = this
      return new Promise((resolve, reject) => {
        // 检查第一步
        // if (nowStep === 0) {
        //   if (!stepZeroSkillform.length) {
        //     this.$notification['error']({ message: '提示', description: '至少选择一个阶段或者技能包' })
        //     reject()
        //   } else {
        //     resolve()
        //   }
        // }
        // 检查第二步
        // if (nowStep === 1) {
        //   if (!this[stepFormDecoding[nowStep]].length) {
        //     this.$notification['error']({ message: '提示', description: '至少选择一个技能包' })
        //     reject()
        //   } else {
        //     resolve()
        //   }
        // }
        // 检查第三步
        if (nowStep === 2) {
          const isSelectOne = []
          const skills = cloneDeep(this.stepTwosource)
          skills.forEach(el => {
            const temp = []
            if (el.dailyReportPhaseList) {
              el.dailyReportPhaseList.forEach(phase => {
                if (phase.dailyReportMaterialList) {
                  phase.dailyReportMaterialList.forEach(mat => {
                    temp.push(mat.name)
                  })
                }
              })
            }
            const skillisallow = true
            if (!temp.length) {
              isSelectOne.push(JSON.stringify(skillisallow))
            } else {
              isSelectOne.push(JSON.stringify(temp.some(name => this.stepTwoform.includes(name))))
            }
          })
          if (isSelectOne.includes('false')) {
            this.$notification['error']({ message: '提示', description: '拥有达标素材的技能至少勾选一个素材' })
            reject()
          } else {
            resolve()
          }
        }
        // 检查第五步
        if (nowStep === 4) {
          const isSelectOne = []
          const skills = cloneDeep(this.stepFoursource)
          skills.forEach(el => {
            if (this.stepFourSkillform.includes(el.id)) {
              if (el.dataReportHomeworkMaterialList) {
                isSelectOne.push(
                  JSON.stringify(
                    el.dataReportHomeworkMaterialList.some(mat => this.stepFourMaterialform.includes(mat.name))
                  )
                )
              }
            }
          })
          if (isSelectOne.includes('false')) {
            this.$notification['error']({ message: '提示', description: '选中的技能至少需要勾选一个素材' })
            reject()
          } else {
            resolve()
          }
        }
        // 不检查
        if ([0, 1, 3].includes(nowStep)) {
          resolve()
        }
      })
    },
    // 生成报告
    createHandle() {
      this.spinning = true
      this.checkStepIsAllow()
        .then(() => {
          this.creatReport()
        })
        .catch(() => {
          this.spinning = false
        })
    },
    // 创建报告参数
    creatReport() {
      const {
        eventParam,
        eventParam: { param }
      } = this
      // 生成每一步的数据
      const firstStep = this.generateParam(0)
      const secondStep = this.generateParam(1)
      const thirdStep = this.generateParam(2)
      const fourthStep = this.generateParam(3)
      const fifthStep = this.generateParam(4)

      // 请求参数
      const createReport = {
        firstStep,
        secondStep,
        thirdStep,
        fourthStep,
        fifthStep,
        studentId: param.studentId,
        startTime: param.startDate,
        endTime: param.endDate
      }
      if (eventParam.type === 'regenerate') {
        // 重新生成，携带旧的报告id
        createReport.dataReportId = param.dataReportId
      }
      // 生成报告
      Api.createMonthly(createReport)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            const event = {
              type: 'edit',
              subType: 'showcalendar',
              toMenuName: 'report',
              toSubMenuName: eventParam.toSubMenuName,
              param: {
                dataReportId: data
              }
            }
            this.$emit('event', event)
            this.setAllDataDefault()
          }
        })
        .finally(() => {
          // 清空加载中
          this.spinning = false
        })
    },
    // 每一步的参数
    generateParam(step) {
      // 获取对应的源数据、选中数组
      const { stepSourceDecoding, stepFormDecoding } = this
      const stepSourceClone = cloneDeep(this[stepSourceDecoding[step]])
      const stepFormClone = cloneDeep(this[stepFormDecoding[step]])
      let result = []
      // 第一步
      if (step === 0) {
        // 按照接口参数格式生成新的数组
        const paramArr = []
        stepSourceClone.forEach(skill => {
          // 技能包层级的属性
          const newSkill = {
            teachingType: 'ATTEND_CLASS',
            skillId: skill.id,
            dailyReportSkillPhaseResultList: []
          }
          if (skill.dailyReportPhaseList.length) {
            skill.dailyReportPhaseList.forEach((phase, phaseix) => {
              // 阶段层级的属性
              newSkill.dailyReportSkillPhaseResultList[phaseix] = {
                skillPhaseId: phase.phaseId,
                dataReportSkillPhaseMaterialList: []
              }
            })
          }
          paramArr.push(newSkill)
        })
        // 根据选中，保留阶段、技能包
        paramArr.forEach(skill => {
          skill.dailyReportSkillPhaseResultList = skill.dailyReportSkillPhaseResultList.filter(phase =>
            this.stepZeroPhaseform.includes(phase.skillPhaseId)
          )
        })
        // 生成最终结果
        result = paramArr.filter(skill => this.stepZeroSkillform.includes(skill.skillId))
      }
      // 第二步、第四步
      if (step === 1 || step === 3) {
        // 按照接口参数格式生成新的数组
        const paramArr = []
        stepSourceClone.forEach(skill => {
          // 技能包层级的属性
          const newSkill = {
            teachingType: 'ATTEND_CLASS',
            skillId: skill.id,
            dailyReportSkillPhaseResultList: []
          }

          paramArr.push(newSkill)
        })
        // 保留技能包
        result = paramArr.filter(skill => stepFormClone.includes(skill.skillId))
      }
      // 第三步
      if (step === 2) {
        // 按照接口参数格式生成新的数组
        const paramArr = []
        stepSourceClone.forEach(skill => {
          // 技能包层级的属性
          const newSkill = {
            teachingType: 'ATTEND_CLASS',
            skillId: skill.id,
            dailyReportSkillPhaseResultList: []
          }
          if (skill.dailyReportPhaseList) {
            skill.dailyReportPhaseList.forEach((phase, phaseix) => {
              // 阶段层级的属性
              newSkill.dailyReportSkillPhaseResultList[phaseix] = {
                skillPhaseId: phase.phaseId,
                dataReportSkillPhaseMaterialList: []
              }
              if (phase.dailyReportMaterialList) {
                phase.dailyReportMaterialList.forEach((mat, matix) => {
                  // 素材层级的属性
                  newSkill.dailyReportSkillPhaseResultList[phaseix].dataReportSkillPhaseMaterialList[matix] = {
                    material: mat.name,
                    trainingCount: mat.numberOfTrainingSessions
                  }
                })
              }
            })
          }
          paramArr.push(newSkill)
        })
        // 根据素材选中，保留阶段、技能包
        paramArr.forEach(skill => {
          if (skill.dailyReportSkillPhaseResultList) {
            skill.dailyReportSkillPhaseResultList.forEach(phase => {
              if (phase.dataReportSkillPhaseMaterialList) {
                phase.dataReportSkillPhaseMaterialList = phase.dataReportSkillPhaseMaterialList.filter(mat =>
                  stepFormClone.includes(mat.material)
                )
              }
            })
          }
        })

        paramArr.forEach(skill => {
          skill.dailyReportSkillPhaseResultList = skill.dailyReportSkillPhaseResultList.filter(
            phase => phase.dataReportSkillPhaseMaterialList.length !== 0
          )
        })
        // 生成最终结果
        result = paramArr.filter(skill => skill.dailyReportSkillPhaseResultList.length !== 0)
      }
      // 第五步

      if (step === 4) {
        // 按照接口参数格式生成新的数组
        const paramArr = []
        stepSourceClone.forEach(skill => {
          // 技能包层级的属性
          const newSkill = {
            skillId: skill.id,
            dataReportHomeworkSkillPhaseMaterialResultList: []
          }
          if (skill.dataReportHomeworkMaterialList) {
            skill.dataReportHomeworkMaterialList.forEach((mat, matix) => {
              // 素材层级的属性
              newSkill.dataReportHomeworkSkillPhaseMaterialResultList[matix] = {
                material: mat.name,
                trainingCount: mat.numberOfTrainingSessions
              }
            })
          }
          paramArr.push(newSkill)
        })
        // 根据选中，保留素材、技能包
        const selectSkillList = paramArr.filter(skill => this.stepFourSkillform.includes(skill.skillId))
        selectSkillList.forEach(skill => {
          skill.dataReportHomeworkSkillPhaseMaterialResultList = skill.dataReportHomeworkSkillPhaseMaterialResultList.filter(
            mat => this.stepFourMaterialform.includes(mat.material)
          )
        })
        // 生成最终结果
        result = selectSkillList
      }
      return result
    }
  }
}
</script>
<style lang="less"></style>
