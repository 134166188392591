<template>
  <div class="title-wrapper">
    <h3 class="title">
      <slot></slot>
    </h3>
    <div class="title-extra">
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.title-extra {
  margin-left: auto;
}
.title {
  display: flex;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  padding-left: 8px;
  border-left: 8px solid #3350c7;
  margin: 0;
}
</style>