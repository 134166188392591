<template>
  <a-modal
    v-model="visible"
    title="技能包"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="selected = null"
  >
    <a-row type="flex" :gutter="[16, 16]" style="margin:16px">
      <a-col :lg="10" :md="10" :xs="24" class="option-wrapper">
        <span>搜索：</span>
        <a-input-search v-model="name" allowClear placeholder="请输入阶段名称" style="flex:1" @search="initData" />
      </a-col>

      <a-col :lg="14" :md="14" :xs="24" class="option-wrapper">
        <span>领域：</span>
        <h-cascader
          v-model="field"
          :disabled="isBindField"
          :options="mainFieldsOptions"
          :fieldNames="{
            label: 'name',
            value: 'id',
            children: 'children'
          }"
          change-on-select
          allowClear
          placeholder="全部分类"
          style="flex:1"
          @change="initData"
        />
      </a-col>
    </a-row>

    <skill-table ref="table" :filter="filter" @change="e => (selected = e)"></skill-table>
  </a-modal>
</template>

<script>
import * as commonApi from '@/api/common'
import SkillTable from './SkillTable'

export default {
  components: {
    SkillTable
  },
  data() {
    return {
      visible: false,
      buttonLoading: false,
      isBindField: false,
      mainFieldId: undefined,
      fieldId: undefined,
      filter: [],
      name: '',
      field: [],
      mainFieldsOptions: [],
      selected: null
    }
  },
  methods: {
    showModal({ mainFieldId, fieldId }, filter) {
      this.visible = true

      this.filter = filter

      const field = []
      mainFieldId && field.push(mainFieldId)
      fieldId && field.push(fieldId)

      this.isBindField = !!mainFieldId

      this.field = field
      this.initData()
    },
    initData() {
      const { $nextTick, field, name, $refs, $route } = this
      const { id: studentId } = $route.params
      const [mainFieldId, fieldId] = [field[0], field[1]]
      $nextTick(() => {
        $refs.table.initData({ mainFieldId, fieldId, name, isAddPlan: true, studentId })
      })
    },
    handleOk() {
      const { selected } = this

      selected && this.$emit('submit', selected)
      this.visible = false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        commonApi.fieldsOptions().then(res => {
          this.mainFieldsOptions = res.data.filter(v => v.level === 'MAIN')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
