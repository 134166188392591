<template>
  <div class="content-card">
    <div v-if="title" class="content-card-header">{{ title }}</div>
    <div class="content-card-body" :class="{ nopadding }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return ''
      },
    },
    nopadding: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
}
</script>

<style lang="less" scoped>
.content-card {
  background: #fff;
  &-header {
    padding: 16px 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    background: #e1e8ff;
  }
  &-body {
    padding: 24px;
    &.nopadding {
      padding: 0;
    }
  }
}
</style>