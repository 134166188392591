import { render, staticRenderFns } from "./add-export-model.vue?vue&type=template&id=481c79cb&scoped=true"
import script from "./add-export-model.vue?vue&type=script&lang=js"
export * from "./add-export-model.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481c79cb",
  null
  
)

export default component.exports