<!-- 月报 -->
<template>
  <a-spin :spinning="spinning">
    <!-- 头部信息和评语 -->
    <div class="header">
      <a-affix :offset-top="0">
        <div class="btn">
          <div v-show="reportStatus !== ''" class="status">
            <img :src="noticeIcon[reportStatus]" />
            <span>{{ noticeText[reportStatus] }}</span>
          </div>
          <div class="button-wrap">
            <!-- 编辑类型下的按钮 -->
            <div v-show="afterRequestEventType === 'edit' || afterRequestEventType === 'reedit'">
              <a-button
                @click="btnSaveReport()"
                type="primary"
                shape="round"
                style="width:134px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-text" />
                暂存并返回
              </a-button>
              <a-button
                v-if="$auth('data_report_export_pdf')"
                @click="btnPreview()"
                type="primary"
                shape="round"
                style="min-width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-search" />
                预览PDF
              </a-button>
              <a-button
                v-if="$auth('data_report_regeneration')"
                @click="btnreGenerate()"
                type="primary"
                shape="round"
                style="width:108px;height:32px;"
              >
                <a-icon type="sync" />
                重新生成
              </a-button>
            </div>
            <!-- 查看类型下的按钮 -->
            <div v-show="afterRequestEventType === 'detail'">
              <a-button
                @click="btnBack()"
                type="primary"
                shape="round"
                style="width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="left" />
                返回
              </a-button>
              <a-button
                v-if="$auth('data_report_export_pdf')"
                @click="btnPreview()"
                type="primary"
                shape="round"
                style="min-width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-search" />
                预览PDF
              </a-button>
            </div>
            <!-- 发布、再次发布类型下的按钮，发布与再次发布流程上无任何区别 -->
            <div v-show="afterRequestEventType === 'publish'">
              <a-button
                @click="btnBack()"
                type="primary"
                shape="round"
                style="width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="left" />
                返回
              </a-button>
              <a-button
                v-if="$auth('data_report_export_pdf')"
                @click="btnPreview()"
                type="primary"
                shape="round"
                style="min-width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="file-search" />
                预览PDF
              </a-button>
              <a-button
                @click="btnPublish()"
                type="primary"
                shape="round"
                style="width:84px;height:32px;margin-right: 16px"
              >
                <a-icon type="rocket" />
                发布
              </a-button>
            </div>
          </div>
        </div>
      </a-affix>
      <div class="title">{{ formQuery.studentName }}小朋友的教学进展月报</div>
      <div class="date">{{ convertReportDate(formQuery.startTime) }}——{{ convertReportDate(formQuery.endTime) }}</div>
      <div class="label">教师评语</div>
      <div :class="{ textarea: true, 'hightlight-border': !formQuery.comments && afterRequestEventType === 'reedit' }">
        <a-textarea
          style="min-height:169px;border: 1px solid #DFE0EB;"
          :placeholder="commentsplaceholder"
          :maxLength="1000"
          v-model="formQuery.comments"
          @keyup="isReadOnly ? '' : changeContent()"
          :read-only="isReadOnly"
        >
        </a-textarea>
        <div class="textnumber">{{ formQuery.comments ? formQuery.comments.length : 0 }}/{{ 1000 }}</div>
      </div>
    </div>

    <!-- 本月教学进展 -->
    <div class="month-skill month-item">
      <div class="tabs-name">本月教学进展</div>

      <div class="month-skill-list">
        <div class="subtitle fristsubtitle">
          <div class="text">本月已经达标的项目</div>
        </div>
        <div class="first-content">
          <div class="item" v-for="item in formQuery.achievementDataReportSkillList" :key="item.skillId">
            <div class="name">{{ item.code }}&nbsp;&nbsp;{{ item.name }}</div>
            <div class="domain">{{ item.mainFieldName }} > {{ item.secondFieldName }}</div>
            <div class="phase">
              <div class="phase-item" v-for="phase in item.dataReportSkillPhaseList" :key="phase.id">
                <div class="status"></div>
                <div>第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</div>
              </div>

              <!-- <div class="phase-item" v-if="!item.dataReportSkillPhaseList.length">
                <div class="status"></div>
                <div>全阶段均已达标</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="month-skill-list" v-if="formQuery.trainingDataReportSkillList.length">
        <div class="subtitle">
          <div class="text">正在教学并且将继续进行的项目</div>
        </div>
        <div class="content">
          <div class="item" v-for="item in formQuery.trainingDataReportSkillList" :key="item.name">
            <div class="name">
              <div class="status"></div>
              <div>{{ item.code }}&nbsp;&nbsp;{{ item.name }}</div>
            </div>
            <div class="domain">{{ item.mainFieldName }} > {{ item.secondFieldName }}</div>
          </div>
        </div>
      </div>

      <div class="month-skill-list" v-if="formQuery.toBeTrainedDataReportSkillList.length">
        <div class="subtitle">
          <div class="text">即将进入教学的项目</div>
        </div>
        <div class="content">
          <div class="item" v-for="item in formQuery.toBeTrainedDataReportSkillList" :key="item.code">
            <div class="name">
              <div class="status"></div>
              <div>{{ item.code }}&nbsp;&nbsp;{{ item.name }}</div>
            </div>
            <div class="domain">{{ item.mainFieldName }} > {{ item.secondFieldName }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 本月训练详情 -->
    <div class="month month-item">
      <div class="tabs-name">本月训练详情</div>

      <div class="month-list">
        <div class="menu">
          <div
            :class="{ 'menu-default': true, 'menu-select': currentTrainMenu === ix }"
            v-for="(item, ix) in formQuery.trainingDetailDataReportSkillList"
            :key="String(item.fieldId)"
            @click="changeTrainMenu(ix)"
          >
            {{ item.fieldName }}
          </div>
        </div>

        <div
          id="chart-wrapper"
          class="content"
          v-for="(filed, filedix) in formQuery.trainingDetailDataReportSkillList"
          :key="filed.fieldName"
          v-show="filedix === currentTrainMenu"
        >
          <div class="title">本月练习累计总回合数：{{ filed.totalRounds }}回合</div>
          <div
            class="subtitle"
            v-if="
              !(!filed.teachingTrainingDataReportSkillVOList.length && !filed.dataReportMonthSecondFieldVOList.length)
            "
          >
            <div class="text">本月练习技能：</div>
          </div>

          <div class="item-wrap">
            <div
              class="item"
              v-for="skill in filed.teachingTrainingDataReportSkillVOList"
              :key="skill.code + skill.name"
            >
              <div class="name">
                <div class="status"></div>
                <div>{{ skill.code }}&nbsp;&nbsp;{{ skill.name }}</div>
                <div class="fieldname">（{{ skill.secondFieldName }}）</div>
              </div>
              <div class="goal">技能目标：{{ skill.goal }}</div>

              <div class="phase" v-for="phase in skill.dataReportSkillPhaseList" :key="phase.id">
                <span>第{{ convertToChineseNumber(phase.phaseNumber) }}阶段达标素材：</span>
                <span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.id">{{
                  computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                    ? mat.material
                    : `${mat.material}、`
                }}</span>
                <span v-if="!phase.dataReportSkillPhaseMaterialList.length">无达标素材</span>
              </div>
              <div class="phase" v-if="!skill.dataReportSkillPhaseList.length">无达标素材</div>
            </div>
          </div>

          <div class="chart-wrap" v-if="filed.dataReportMonthSecondFieldVOList.length">
            <div class="chart-item">
              <div class="item-legend">{{ filed.fieldName }}领域-达标素材累计</div>
              <div class="item-data">
                <div class="colors">
                  <div
                    :class="{ 'colors-block': true, first: colorix === 0, last: colorix === 4 }"
                    :style="{ backgroundColor: color }"
                    v-for="(color, colorix) in chartSideColors"
                    :key="String(colorix)"
                  ></div>
                </div>
                <div :id="'chart-wrapper-month' + filed.fieldId" class="chart">
                  <ChartLine
                    :chartid="filed.fieldId"
                    :isshow="filedix === currentTrainMenu"
                    ref="ChartLine"
                    :data="setChartSeries(filed.dataReportMonthSecondFieldVOList)"
                    :idName="'line' + filed.fieldId"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form">
            <div class="subtitle">
              <div class="text">本月技能教学情况</div>
              <div
                v-if="!isReadOnly"
                class="button"
                :title="!filed.skillTeachingSituation.isShow ? '展示' : '隐藏后将不再报告中展示此模块'"
                @click="changeSituationShow(filedix, filed.skillTeachingSituation.isShow)"
              >
                <img
                  :src="
                    !filed.skillTeachingSituation.isShow
                      ? require('@/assets/report/report/ico_tab_open.png')
                      : require('@/assets/report/report/ico_tab_close.png')
                  "
                />
                <span>{{ !filed.skillTeachingSituation.isShow ? '展示' : '隐藏' }}</span>
              </div>
            </div>

            <div
              v-show="filed.skillTeachingSituation.isShow"
              :class="{
                textarea: true,
                'hightlight-border': !filed.skillTeachingSituation.content && afterRequestEventType === 'reedit'
              }"
            >
              <a-textarea
                style="min-height:169px;border: 1px solid #dfe0eb;"
                :maxLength="500"
                v-model="filed.skillTeachingSituation.content"
                @keyup="isReadOnly ? '' : changeContent()"
                :read-only="isReadOnly"
              >
              </a-textarea>
              <div class="textnumber">
                {{ filed.skillTeachingSituation.content ? filed.skillTeachingSituation.content.length : 0 }}/{{ 500 }}
              </div>
            </div>
          </div>

          <div class="form">
            <div class="subtitle">
              <div class="text">下月教学重点</div>
              <div
                v-if="!isReadOnly"
                class="button"
                :title="!filed.teachingFocusOfNextMonth.isShow ? '展示' : '隐藏后将不再报告中展示此模块'"
                @click="changeNextMonthShow(filedix, filed.teachingFocusOfNextMonth.isShow)"
              >
                <img
                  :src="
                    !filed.teachingFocusOfNextMonth.isShow
                      ? require('@/assets/report/report/ico_tab_open.png')
                      : require('@/assets/report/report/ico_tab_close.png')
                  "
                />
                <span>{{ !filed.teachingFocusOfNextMonth.isShow ? '展示' : '隐藏' }}</span>
              </div>
            </div>

            <div
              v-show="filed.teachingFocusOfNextMonth.isShow"
              :class="{
                textarea: true,
                'hightlight-border': !filed.teachingFocusOfNextMonth.content && afterRequestEventType === 'reedit'
              }"
            >
              <a-textarea
                style="min-height:169px;border: 1px solid #dfe0eb;"
                :maxLength="500"
                v-model="filed.teachingFocusOfNextMonth.content"
                @keyup="isReadOnly ? '' : changeContent()"
                :read-only="isReadOnly"
              >
              </a-textarea>
              <div class="textnumber">
                {{ filed.teachingFocusOfNextMonth.content ? filed.teachingFocusOfNextMonth.content.length : 0 }}/{{
                  500
                }}
              </div>
            </div>
          </div>

          <div class="form">
            <div class="subtitle">
              <div class="text">家庭训练建议</div>
              <div
                v-if="!isReadOnly"
                class="button"
                :title="!filed.homeSchoolTrainingSuggestions.isShow ? '展示' : '隐藏后将不再报告中展示此模块'"
                @click="changeSuggestionsShow(filedix, filed.homeSchoolTrainingSuggestions.isShow)"
              >
                <img
                  :src="
                    !filed.homeSchoolTrainingSuggestions.isShow
                      ? require('@/assets/report/report/ico_tab_open.png')
                      : require('@/assets/report/report/ico_tab_close.png')
                  "
                />
                <span>{{ !filed.homeSchoolTrainingSuggestions.isShow ? '展示' : '隐藏' }}</span>
              </div>
            </div>

            <div
              v-show="filed.homeSchoolTrainingSuggestions.isShow"
              :class="{
                textarea: true,
                'hightlight-border': !filed.homeSchoolTrainingSuggestions.content && afterRequestEventType === 'reedit'
              }"
            >
              <a-textarea
                style="min-height:169px;border: 1px solid #dfe0eb;"
                :maxLength="500"
                v-model="filed.homeSchoolTrainingSuggestions.content"
                @keyup="isReadOnly ? '' : changeContent()"
                :read-only="isReadOnly"
              >
              </a-textarea>
              <div class="textnumber">
                {{
                  filed.homeSchoolTrainingSuggestions.content ? filed.homeSchoolTrainingSuggestions.content.length : 0
                }}/{{ 500 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 本月家庭作业完成情况 -->
    <div class="homework" v-if="formQuery.homeworkDataReportSkillList.length">
      <div
        class="homework-tabs"
        v-if="(afterRequestEventType === 'detail' && formQuery.homeworkIsShow) || afterRequestEventType !== 'detail'"
      >
        <div class="tabs-name">本月家庭作业完成情况</div>
        <div
          v-if="!isReadOnly"
          class="tabs-btn"
          :title="!formQuery.homeworkIsShow ? '展示' : '隐藏后将不再报告中展示此模块'"
          @click="changeHomeworkShow"
        >
          <img
            :src="
              !formQuery.homeworkIsShow
                ? require('@/assets/report/report/ico_tab_open.png')
                : require('@/assets/report/report/ico_tab_close.png')
            "
          />
          <span>{{ !formQuery.homeworkIsShow ? '展示' : '隐藏' }}</span>
        </div>
      </div>

      <div class="homework-list" v-show="formQuery.homeworkIsShow">
        <div class="list-item" v-for="homework in formQuery.homeworkDataReportSkillList" :key="homework.id">
          <div class="item-name">{{ homework.code }}&nbsp;&nbsp;{{ homework.name }}</div>
          <div class="item-domain">{{ homework.fieldName }}-{{ homework.secondFieldName }}</div>

          <div class="item-mat">
            <span>练习素材：</span
            ><span v-for="(mat, matix) in homework.dataReportHomeworkMaterialList" :key="String(matix)">{{
              computerHomeworkMaterial(homework.dataReportHomeworkMaterialList, matix, mat)
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部评语 -->
    <div class="footer">
      <div class="textarea">
        <a-textarea
          style="min-height:169px;border: 1px solid #dfe0eb;"
          :maxLength="1000"
          v-model="formQuery.conclusion"
          @keyup="isReadOnly ? '' : changeContent()"
          :read-only="isReadOnly"
        >
        </a-textarea>
        <div class="textnumber">{{ formQuery.conclusion ? formQuery.conclusion.length : 0 }}/{{ 1000 }}</div>
      </div>
    </div>
    <!-- 报告有未填写的内容 -->
    <a-modal
      v-model="visibleCheck"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '继续编辑'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="checkhandleOk"
      @cancel="checkhandleCancel"
    >
      <div class="modaltitle">报告还没有写完哦</div>
      <div class="submodaltitle">请继续完成未填写的内容</div>
    </a-modal>
    <!-- 发布弹窗 -->
    <a-modal
      v-model="visiblePublish"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '发布报告'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="publishhandleOk"
      @cancel="publishhandleCancel"
    >
      <div class="modaltitle">
        您正在发布{{ convertReportDate(formQuery.startTime) }}-{{ convertReportDate(formQuery.endtime) }}的月报，
        发布后的<span>报告</span>将同步至家长手机端是否确认发布？
      </div>
    </a-modal>
    <!-- 返回弹窗 -->
    <a-modal
      v-model="visibleBack"
      title="报告数据已成功保存"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '40px', paddingLeft: '24px', paddingBottom: '40px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="backhandleOk"
      @cancel="backhandleCancel"
    >
      <div class="modaltitle">
        是否返回数据报告日历？
      </div>
    </a-modal>
    <!-- 重新生成 -->
    <a-modal
      v-model="visibleregenerate"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '重新生成'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="regeneratehandleOk"
      @cancel="regeneratehandleCancel"
    >
      <div class="modaltitle">
        重新生成报告将重头开始制作， 是否确认重新生成？
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import * as Api from '@/api/datareport'
import { convertToChineseNumber, downLoadBinaryFileStream } from '@/utils/util'
import moment from 'moment'
import ChartLine from '../components/chartLineMonth'
import { mapActions } from 'vuex'
export default {
  components: {
    ChartLine
  },
  data() {
    return {
      spinning: true, // 默认加载
      // 实时保存的状态
      noticeIcon: {
        pending: require('@/assets/report/report/ico_pending.png'),
        success: require('@/assets/report/report/ico_success.png'),
        error: require('@/assets/report/report/ico_error.png')
      },
      noticeText: {
        pending: '正在保存中',
        success: '实时保存成功',
        error: '未检测到网络，实时保存失败'
      },
      reportStatus: '',
      chartSideColors: ['#8996FA ', '#89C5FA', '#97D88B', '#FFC172 ', '#FFE572'],
      // 事件参数
      eventParam: {},
      // 内部事件类型，用来区分显示按钮，（发布类型可能会在请求接口之后更改）
      afterRequestEventType: '',
      formQuery: {
        id: null, // 报告id
        studentName: '', // 学生姓名
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        comments: '', // 头部评语
        conclusion: '', // 底部结束语
        achievementDataReportSkillList: [], // 本月已经达标的项目
        toBeTrainedDataReportSkillList: [], // 即将进行的教学项目
        trainingDataReportSkillList: [], // 正在教学并且将继续进行的项目
        trainingDetailDataReportSkillList: [], // 本月训练详情
        homeworkDataReportSkillList: [], // 家庭作业
        homeworkIsShow: null, // 家庭作业是否显示
        mechanismName: '', // 机构名称
        createTimestamp: null // 报告创建日期
      },
      commentsplaceholder: '', // 顶部评语占位字符
      currentTrainMenu: 0, // 本月训练详情选择的技能包
      nowTimer: null, // 输入框记录计时器
      visibleCheck: false, // 报告检查
      visiblePublish: false, // 发布
      visibleBack: false, // 返回弹窗
      visibleregenerate: false // 重新生成
    }
  },
  computed: {
    // 是否是查看、发布，这个时候默认为只读，不可编辑
    isReadOnly() {
      const { afterRequestEventType } = this
      const typeArr = ['detail', 'publish']
      if (typeArr.includes(afterRequestEventType)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    convertToChineseNumber,
    ...mapActions(['changeSpinning']),
    // 日期转换
    convertReportDate(date) {
      return moment(date).format('LL')
    },
    // 查询报告数据
    initData(e) {
      this.afterRequestEventType = e.type
      this.eventParam = e
      Api.inquireMonthly(e.param)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            // 初始化赋值
            for (const name in data) {
              this.formQuery[name] = data[name]
            }
            // 顶部评语占位字符
            this.commentsplaceholder = `示例：亲爱的${data.studentName}小朋友的家长：
      你们好！
      我们本月的课程非常顺利地结束了。根据${data.studentName}的个别化教育计划，我们顺利地开展了本月的各项教学。在这个月度内，李乐乐小朋友比较适应教学节奏，我们按照计划进行了多个技能领域的教学。
      回顾这一段时间的教学，我们也取得了阶段性的教学进展：`

            // 发布、再次发布类型下，检查报告是否完成
            if (e.type === 'publish') {
              this.checkIsAllDone()
            }
          }
        })
        .finally(() => {
          const { afterRequestEventType } = this
          const typeArr = ['detail', 'edit']
          if (typeArr.includes(afterRequestEventType)) {
            this.spinning = false
          }
        })
    },
    // 检查报告有未填写的内容
    checkIsAllDone() {
      const {
        eventParam: { param }
      } = this
      Api.check({ id: param.dataReportId })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            if (!data) {
              this.visibleCheck = true
            }
          }
        })
        .finally(() => {
          this.spinning = false
        })
    },
    // 是否继续编辑回调
    checkhandleOk() {
      this.visibleCheck = false
      this.afterRequestEventType = 'reedit'
    },
    checkhandleCancel() {
      this.visibleCheck = false
      this.backCalendar()
    },

    // 根据素材生成chart的series
    setChartSeries(data) {
      const xAxis = []
      const series = []
      if (data) {
        data.forEach((skill, ix) => {
          // 一个素材对应一个series
          const seriesitem = {
            name: skill.code,
            type: 'line',
            endLabel: {
              show: true,
              formatter: '{a}'
            },
            data: []
          }
          skill.dataReportSkillPhaseMaterialTrainingVOList.forEach(rate => {
            if (ix === 0) {
              // 获取x轴日期

              xAxis.push(`${rate.trainingDate.split('-')[1]}-${rate.trainingDate.split('-')[2]}`)
            }
            // 获取y轴数据
            seriesitem.data.push(rate.successRate)
          })
          series.push(seriesitem)
        })
      }
      return { xAxis, series }
    },
    // 计算素材是否是数组最后一个
    computerArrayLength(arr, index) {
      if (arr.length) {
        return arr.length - 1 === index
      } else {
        return false
      }
    },
    // 计算作业素材是否是数组最后一个
    computerHomeworkMaterial(arr, index, item) {
      let result = ''
      if (item.numberOfTrainingSessions !== 0) {
        result = `${item.name}(${item.numberOfTrainingSessions}次)`
      } else {
        result = `${item.name}(未练习)`
      }

      if (arr.length) {
        if (arr.length - 1 !== index) {
          result = `${result}、`
        }
      }
      return result
    },
    // 切换本月训练详情选择的技能包
    changeTrainMenu(number) {
      this.currentTrainMenu = number
    },
    // 切换本月训练详情选择的技能包下的教学情况显示
    changeSituationShow(index, status) {
      this.formQuery.trainingDetailDataReportSkillList[index].skillTeachingSituation.isShow = !status
      this.saveReport()
    },
    // 切换本月训练详情选择的技能包下的下月教学重点
    changeNextMonthShow(index, status) {
      this.formQuery.trainingDetailDataReportSkillList[index].teachingFocusOfNextMonth.isShow = !status
      this.saveReport()
    },
    // 切换本月训练详情选择的技能包下的家庭训练建议显示
    changeSuggestionsShow(index, status) {
      this.formQuery.trainingDetailDataReportSkillList[index].homeSchoolTrainingSuggestions.isShow = !status
      this.saveReport()
    },

    // 监听作业是否隐藏
    changeHomeworkShow(e) {
      this.formQuery.homeworkIsShow = !this.formQuery.homeworkIsShow
      this.saveReport()
    },
    // 所有的输入框变动都用这个
    changeContent() {
      if (this.nowTimer) {
        clearTimeout(this.nowTimer)
        this.nowTimer = setTimeout(() => {
          this.saveReport()
        }, 3000)
      } else {
        this.nowTimer = setTimeout(() => {
          this.saveReport()
        }, 3000)
      }
    },
    // 暂存报告,生成所有参数
    saveReport(type = '') {
      const { formQuery } = this

      //  本月训练详情需要记录的输入框和状态
      const dataReportFieldDTOList = []
      formQuery.trainingDetailDataReportSkillList.forEach(field => {
        dataReportFieldDTOList.push({
          fieldId: field.fieldId,
          homeSchoolTrainingSuggestions: field.homeSchoolTrainingSuggestions.content
            ? field.homeSchoolTrainingSuggestions.content
            : '',
          homeSchoolTrainingSuggestionsIsShow: field.homeSchoolTrainingSuggestions.isShow,
          skillTeachingSituation: field.skillTeachingSituation.content ? field.skillTeachingSituation.content : '',
          skillTeachingSituationIsShow: field.skillTeachingSituation.isShow,
          teachingFocusOfNextMonth: field.teachingFocusOfNextMonth.content
            ? field.teachingFocusOfNextMonth.content
            : '',
          teachingFocusOfNextMonthIsShow: field.teachingFocusOfNextMonth.isShow
        })
      })

      // 请求参数
      const query = {
        dataReportId: formQuery.id,
        comments: formQuery.comments ? formQuery.comments : '',
        conclusion: formQuery.conclusion,
        homeworkIsShow: formQuery.homeworkIsShow,
        dataReportFieldDTOList
      }
      this.reportStatus = 'pending'
      this.useApiRequest(query, type)
    },
    // 保存报告接口
    useApiRequest(query, type) {
      Api.saveMonthly(query)
        .then(res => {
          const { data } = res
          if (data === 'SUCCESS') {
            this.clearReportStatus('success', type)
          }
        })
        .catch(() => {
          this.clearReportStatus('error', type)
        })
    },
    // 清空保存接口状态
    clearReportStatus(status, type) {
      this.reportStatus = status
      if (this.reportStatus !== 'error') {
        if (type === 'btn') {
          this.visibleBack = true
        }
      }
      // const timer = setTimeout(() => {
      //   if (this.reportStatus !== 'error') {
      //     this.reportStatus = ''
      //     clearTimeout(timer)
      //     if (type === 'btn') {
      //       this.visibleBack = true
      //     }
      //   }
      // }, 2000)
    },
    // 对应的按钮操作
    // 暂存并返回
    btnSaveReport() {
      const type = 'btn'
      this.saveReport(type)
    },
    // 返回弹窗回调
    backhandleOk() {
      this.visibleBack = false
      this.backCalendar()
    },
    backhandleCancel() {
      this.visibleBack = false
    },
    // 直接返回
    btnBack() {
      this.backCalendar()
    },
    // 重置初始值
    setAlldataDefault() {
      this.spinning = true
      this.reportStatus = ''
      this.eventParam = {}
      this.afterRequestEventType = ''
      this.commentsplaceholder = ''
      this.currentTrainMenu = 0
      this.nowTimer = null
      this.visibleCheck = false
      this.visiblePublish = false
      this.visibleBack = false
      this.visibleregenerate = false
    },
    backCalendar() {
      // 获取类型，返回日历打开对应的报告月份
      const { eventParam, formQuery } = this
      // 提交返回事件
      const event = {
        type: 'cancel',
        toMenuName: 'calendar',
        toSubMenuName: '',
        param: {
          // 获取类型，返回日历打开对应的报告月份
          workMonth: eventParam.subType ? formQuery.endTime : null
        }
      }
      this.$emit('event', event)

      this.setAlldataDefault()
    },
    // 预览
    btnPreview() {
      this.changeSpinning(true)
      const { id } = this.formQuery
      Api.exportMonth(id)
        .then(res => {
          downLoadBinaryFileStream({
            data: res,
            type: 'application/pdf;chartset=UTF-8',
            name: `${this.formQuery.studentName}小朋友的教学进展月报.pdf`
          })
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    },
    // 重新生成
    btnreGenerate() {
      this.visibleregenerate = true
    },
    regeneratehandleOk() {
      const { formQuery } = this
      this.visibleregenerate = false
      // 提交事件
      const event = {
        type: 'regenerate',
        toMenuName: 'steps',
        toSubMenuName: 'month',
        param: {
          studentId: this.$route.params.id,
          startDate: formQuery.startTime,
          endDate: formQuery.endTime,
          dataReportId: formQuery.id
        }
      }
      this.$emit('event', event)
      this.setAlldataDefault()
    },
    regeneratehandleCancel() {
      this.visibleregenerate = false
    },
    // 发布
    btnPublish() {
      this.visiblePublish = true
    },
    publishhandleOk() {
      const {
        eventParam: { param }
      } = this
      Api.status({ id: param.dataReportId }).then(res => {
        const { code } = res
        if (code === 0) {
          this.$notification.success({
            message: '提示',
            description: '报告发布成功'
          })
          this.visiblePublish = false
          this.backCalendar()
        }
      })
    },
    publishhandleCancel() {
      this.visiblePublish = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-input:focus {
  border: none;
  box-shadow: none;
}
/deep/.ant-input:hover {
  border: none;
  box-shadow: none;
}
.header {
  height: 387px;
  padding: 24px;
  margin: 16px auto;
  background: #ffffff;
  .btn {
    position: relative;
    width: 100%;
    height: 44px;
    background: #ffffff;
    .status {
      position: absolute;
      top: 12px;
      left: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 19px;
        height: 19px;
        margin-right: 7px;
        border-radius: 50%;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
    .button-wrap {
      position: absolute;
      top: 6px;
      right: 0;
    }
  }
  .title {
    height: 28px;
    margin: 16px 0 10px;
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    text-align: center;
    line-height: 28px;
  }
  .date {
    height: 22px;
    margin: 0 0 24px;
    font-size: 16px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    line-height: 22px;
    span {
      margin: 0 0 0 6px;
    }
  }
  .label {
    height: 22px;
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 22px;
  }
  .textarea {
    position: relative;
    min-height: 169px;
    width: 100%;
    .textnumber {
      position: absolute;
      bottom: 16px;
      right: 16px;
      font-size: 14px;
      color: #666666;
    }
  }
  .hightlight-border {
    border: 1px solid #f12a2c;
    border-radius: 4px;
  }
}

.month-item {
  margin-bottom: 16px;
  background: #ffffff;
  .tabs-name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    background: #e1e8ff;
    cursor: pointer;
  }
}

.month-skill {
  .month-skill-list {
    padding: 0 24px;
    .subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 22px;
      line-height: 22px;
      .text {
        padding-left: 8px;
        border-left: 8px solid #3350c7;
        font-size: 16px;
        font-weight: 500;
        color: #1a1a1a;
      }
    }
    .fristsubtitle {
      margin-top: 24px;
    }
    .first-content {
      padding: 16px;
      .item {
        margin-bottom: 20px;
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #1a1a1a;
        }
        .domain {
          margin: 4px 0 8px;
          font-size: 14px;
          font-weight: 400;
          color: #3a5ff8;
        }
        .phase {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          .phase-item {
            display: flex;
            align-items: center;
            width: 46%;
            margin: 0 0 2px;
            font-size: 14px;
            color: #1a1a1a;
            .status {
              width: 8px;
              height: 8px;
              margin: 0 8px 0 0;
              border-radius: 50%;
              background: #39a54d;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 16px;
      .item {
        width: 46%;
        margin-bottom: 20px;
        font-size: 14px;
        .name {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #1a1a1a;
          .status {
            width: 8px;
            height: 8px;
            margin: 0 8px 0 0;
            background: #3a5ff8;
            border-radius: 50%;
          }
        }
        .domain {
          margin: 4px 0 0 16px;
          color: #3a5ff8;
        }
      }
    }
  }
}

.month {
  .month-list {
    padding: 24px;
    .menu {
      display: flex;
      align-items: center;
      text-align: center; // 添加滚动
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      cursor: pointer;
      .menu-default {
        height: 48px;
        padding: 0 28px;
        border: 1px solid #dfe0eb;
        font-size: 14px;
        font-weight: 500;
        color: #1a1a1a;
        text-align: center;
        line-height: 48px;
      }
      .menu-select {
        background: #e1e8ff;
      }
    }
    /*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
    .menu::-webkit-scrollbar {
      width: 4px;
      height: 6px;
      background-color: rgba(0, 0, 0, 0.08);
    }
    /*定义滚动条轨道
 内阴影+圆角*/
    .menu::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      background-color: rgba(0, 0, 0, 0);
    }
    /*定义滑块
 内阴影+圆角*/
    .menu::-webkit-scrollbar-thumb {
      border-radius: 16px;
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
      background-color: rgba(144, 147, 153, 0.3);
    }
    .content {
      font-size: 14px;
      .title {
        margin: 26px 0 32px;
        font-weight: 500;
        color: #3a5ff8;
      }
      .subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 22px;
        margin: 0 0 16px;
        line-height: 22px;
        .text {
          padding-left: 8px;
          border-left: 8px solid #3350c7;
          font-size: 16px;
          font-weight: 500;
          color: #1a1a1a;
        }
      }

      .item-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          width: 46%;
          margin-bottom: 24px;
          font-size: 14px;
          font-weight: 400;
          .name {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #000000;
            .status {
              width: 8px;
              height: 8px;
              margin: 0 8px 0 0;
              background: #3a5ff8;
              border-radius: 50%;
            }
            .fieldname {
              font-size: 14px;
              font-weight: 400;
              color: #3a5ff8;
            }
          }
          .goal {
            margin: 4px 0 8px 16px;
            color: #909090;
          }
          .phase {
            margin: 0 0 2px 16px;
            color: #1a1a1a;
          }
        }
      }
      .chart-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 40px;
        .chart-item {
          width: 100%;
          height: 546px;
          background: #f8f8f8;
          .item-legend {
            margin: 32px 0 50px 0;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;
          }
          .item-data {
            position: relative;
            width: 100%;
            .colors {
              position: absolute;
              left: 16px;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 23px;
              .colors-block {
                width: 23px;
                height: 69px;
              }
              .first {
                border-radius: 3px 3px 0px 0px;
              }
              .last {
                border-radius: 0px 0px 3px 3px;
              }
            }
            .chart {
              position: absolute;
              left: 39px;
              width: 94%;
            }
          }
        }
      }

      .form {
        margin: 0 0 36px;
        .subtitle {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 22px;
          margin: 0 0 16px;
          line-height: 22px;
          .text {
            padding-left: 8px;
            border-left: 8px solid #3350c7;
            font-size: 16px;
            font-weight: 500;
            color: #1a1a1a;
          }
          .button {
            position: absolute;
            right: 0;
            top: 0;
            height: 22px;
            line-height: 22px;
            font-size: 14px;
            color: #3a5ff8;
            cursor: pointer;
            img {
              width: 20px;
              height: 10px;
              margin-right: 7px;
            }
          }
        }
        .textarea {
          position: relative;
          min-height: 169px;
          width: 100%;
          .textnumber {
            position: absolute;
            bottom: 16px;
            right: 16px;
            font-size: 14px;
            color: #666666;
            font-weight: 500;
          }
        }
        .hightlight-border {
          border: 1px solid #f12a2c;
          border-radius: 4px;
        }
      }
    }
  }
}

.homework {
  margin-bottom: 16px;
  background: #ffffff;
  .homework-tabs {
    position: relative;
    height: 54px;
    background: #e1e8ff;
    cursor: pointer;
    .tabs-name {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      font-size: 16px;
      font-weight: 500;
      color: #1a1a1a;
    }
    .tabs-btn {
      position: absolute;
      right: 24px;
      top: 17px;
      font-size: 14px;
      font-weight: 400;
      color: #3a5ff8;
      cursor: pointer;
      img {
        width: 20px;
        height: 10px;
        margin-right: 7px;
      }
    }
  }

  .homework-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .list-item {
      width: 48.5%;
      padding: 16px;
      margin-bottom: 1%;
      border: 1px solid #dfe0eb;
      font-size: 14px;
      font-weight: 400;
      .item-name {
        margin: 0;
        font-weight: 500;
        color: #1a1a1a;
      }
      .item-domain {
        margin: 4px 0 8px;
        color: #3a5ff8;
      }
      .item-mat {
        align-items: center;
        margin: 8px 0 0;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #1a1a1a;
        }
      }
    }

    .list-item:nth-of-type(odd) {
      margin-left: 1%;
      margin-right: 1%;
    }

    .list-item:nth-of-type(even) {
      margin-right: 1%;
    }

    .list-item:first-child,
    .list-item:nth-child(2) {
      margin-top: 1%;
    }
  }
}
.footer {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  margin-bottom: 16px;
  background: #ffffff;
  .textarea {
    position: relative;
    min-height: 169px;
    width: 100%;
    .textnumber {
      position: absolute;
      bottom: 16px;
      right: 16px;
      font-size: 14px;
      color: #666666;
      font-weight: 500;
    }
  }
}
/deep/ .ant-modal-content {
  border-radius: 12px;
}
/deep/ .ant-modal-header {
  border-radius: 12px;
}
.modaltitle {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  span {
    color: #3a5ff8;
  }
}
.submodaltitle {
  margin: 8px 0 0;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
</style>
