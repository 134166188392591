<template>
  <div style="margin-bottom: 32px">
    <div class="title">
      <h3 style="margin: 0">{{ title }}</h3>
      <a-button v-if="editable && list.length > 0" style="margin-left: auto" type="link" @click="showModal"
        >调整{{ module }}</a-button
      >
    </div>
    <block-content bordered style="padding: 24px 16px; margin-top: 16px">
      <template v-if="list.length === 0">
        <div style="text-align: center; padding: 32px 0">当前领域暂无{{ title }}</div>
      </template>
      <template v-else>
        <div v-for="(item, index) in selectedList" :key="item.id">{{ `${index + 1}、${item.goal}` }}</div>
      </template>
    </block-content>
    <a-modal
      v-model="visible"
      :title="title"
      width="716px"
      @ok="handleOk"
      :okButtonProps="{ props: { disabled: selected.length === 0, shape: 'round' } }"
      :cancel-button-props="{ props: { shape: 'round' } }"
    >
      <a-checkbox-group v-model="selected">
        <a-row :gutter="[16, 16]">
          <a-col v-for="(skill, index) in list" :key="skill.id" :span="24">
            <a-checkbox :value="skill.id" class="custom-checkbox" style="display: flex; align-items: flex-start">
              <a-row type="flex" align="top" style="flex-wrap: nowrap">
                <a-col>
                  {{ `${index + 1}、` }}
                </a-col>
                <a-col>
                  <a-row>
                    {{ skill.skillName }}
                  </a-row>
                  <a-row>
                    {{ skill.goal }}
                  </a-row>
                </a-col>
              </a-row>
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-modal>
  </div>
</template>

<script>
import BlockContent from './Content'
export default {
  components: {
    BlockContent
  },
  props: {
    title: {
      type: String,
      default() {
        return '已具备技能'
      }
    },
    module: {
      type: String,
      default() {
        return '技能'
      }
    },
    editable: {
      type: Boolean,
      default() {
        return false
      }
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      visible: false,
      selected: []
    }
  },
  computed: {
    selectedList() {
      return this.list.filter(item => item.isSelect)
    }
  },
  methods: {
    showModal() {
      this.selected = this.selectedList.map(item => item.id)
      this.visible = true
    },
    handleOk(e) {
      this.list.forEach(item => {
        item.isSelect = this.selected.indexOf(item.id) > -1
      })
      this.visible = false
    }
  }
}
</script>

<style lang='less' scoped>
/deep/ .ant-modal-body {
  height: 373px;
  overflow-y: auto;
}
.custom-checkbox {
  /deep/ .ant-checkbox {
    top: 0.2em;
  }
}
.title {
  display: flex;
  align-items: center;
}
.title-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3350c7;
  margin-right: 8px;
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
}
</style>