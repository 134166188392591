<template>
  <pdf-page>
    <template #extra>
      <img src="~@/assets/report/quarter/paper-plane@2x.png" class="extra" alt="" />
    </template>
    <div class="main">
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">在此期间，</div>
        <div class="text-col large">{{ dataSource.studentName }}</div>
        <div class="text-col">小朋友</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col large highlight">最快</div>
        <div class="text-col">学会的素材是</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">（{{ index + 1 }}）</div>
      </div>
      <div class="text-row">
        <div class="text-col">“{{ `${fastItem.skillCode} ${fastItem.skillName}` }}”</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px; display: flex; flex-wrap: wrap">
        <span class="text-col">中的“</span>
        <span class="text-col large highlight" v-for="(fi, fi_index) in fastItem.list" :key="fi_index"
          >{{ fi }} {{ fi_index !== fastItem.list.length - 1 ? '、' : '' }}</span
        >
        <span class="text-col">”</span>
      </div>
      <!-- <div class="text-row">
        <div class="text-col">“HR-C01 技能名”</div>
      </div>
      <div class="text-row" style="margin-bottom:21px;">
        <div class="text-col">中的“</div>
        <div class="text-col large highlight">九九九九九九九九九</div>
        <div class="text-col">”</div>
      </div> -->
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">只练习了</div>
        <div class="text-col large">{{ dataSource.fastStudyMaterialDay }}</div>
        <div class="text-col">天</div>
        <div class="text-col large">{{ dataSource.fastStudyMaterialRound }}</div>
        <div class="text-col">回合</div>
      </div>
      <div class="text-row">
        <div class="text-col">就达标了</div>
      </div>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
export default {
  components: {
    PdfPage,
  },
  props: {
    fastItem: {
      type: Object,
      default() {
        return {}
      },
    },
    dataSource: {
      type: Object,
      default() {
        return {}
      },
    },
    index: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    // material() {
    //   const { fastStudyMaterials = [] } = this.dataSource
    //   return fastStudyMaterials[this.index] || {}
    // },
  },
}
</script>

<style lang="less" scoped>
.main {
  text-align: center;
  padding-top: 52px;
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 36px;
}
.text {
  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-col:not(.large) {
    margin-bottom: 5px;
  }
}
.large {
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 1px;
}
.highlight {
  color: #ff7a55;
}
.extra {
  position: absolute;
  width: 1080px;
  bottom: 0;
}
</style>