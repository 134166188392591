<!-- 家校训练服务-详情-基本信息 -->
<template>
  <a-card :bodyStyle="{ padding: '0' }" class="basic-information">
    <a-form-model ref="form" :model="form" style="width: 1000px; margin: 0 auto">
      <a-card title="基本信息" style="width: 100%" class="basic">
        <a-row>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="姓名：">{{ data.username || '--' }} </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="性别："
              >{{ { MALE: '男', FEMALE: '女' }[data.sex] || '--' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="出生日期：">{{ data.birthday || '--' }} </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="监护人：">{{ data.guardians || '--' }} </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="联系方式："
              >{{ data.cellphone | BasicInformationPhone() }}
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="服务机构："
              >{{ (data.mechanismVO && data.mechanismVO.name) || '--' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="服务开通时间："
              >{{ data.homeSchoolOpeningTime || '--' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="计划撰写师："
              >{{ data.planWriter || '--' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item style="display: flex" label="上课教师：">{{ data.teacher || '--' }} </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="诊断结果" style="width: 100%; border: 0">
        <a-form-model-item
          label=""
          prop="diagnosticResults"
          :rules="{
            required: true,
            message: '请填写诊断结果',
            trigger: 'blur',
          }"
        >
          <div style="position: relative">
            <a-input
              v-model="form.diagnosticResults"
              placeholder="请输入内容"
              :maxLength="500"
              :autoSize="{ minRows: 6, maxRows: 6 }"
              type="textarea"
            />
            <div style="position: absolute; bottom: -5px; right: 1px">
              <span style="background: #fff"
                >{{ (form.diagnosticResults && form.diagnosticResults.length) || 0 }}/500</span
              >
            </div>
          </div>
        </a-form-model-item>
      </a-card>
      <a-card title="基本能力信息" style="width: 100%; border: 0">
        <a-form-model-item
          label=""
          prop="basicAbilityInformation"
          :rules="{
            required: true,
            message: '请填写基本能力信息',
            trigger: 'blur',
          }"
        >
          <div style="position: relative">
            <a-input
              v-model="form.basicAbilityInformation"
              placeholder="请输入内容"
              :maxLength="500"
              :autoSize="{ minRows: 6, maxRows: 6 }"
              type="textarea"
            />
            <div style="position: absolute; bottom: -5px; right: 1px">
              <span style="background: #fff"
                >{{ (form.basicAbilityInformation && form.basicAbilityInformation.length) || 0 }}/500</span
              >
            </div>
          </div>
        </a-form-model-item>
      </a-card>
      <a-card title="基本家庭信息" style="width: 100%; border: 0">
        <a-form-model-item
          label=""
          prop="basicFamilyInformation"
          :rules="{
            required: true,
            message: '请填写基本家庭信息',
            trigger: 'blur',
          }"
        >
          <div style="position: relative">
            <a-input
              v-model="form.basicFamilyInformation"
              placeholder="请输入内容"
              :maxLength="500"
              :autoSize="{ minRows: 6, maxRows: 6 }"
              type="textarea"
            />
            <div style="position: absolute; bottom: -5px; right: 1px">
              <span style="background: #fff"
                >{{ (form.basicFamilyInformation && form.basicFamilyInformation.length) || 0 }}/500</span
              >
            </div>
          </div>
        </a-form-model-item>
      </a-card>

      <a-row type="flex" justify="center" v-if="$auth('student_family_info_update')">
        <a-button type="primary" shape="round" style="width: 160px" @click="onSubmit"> 确认 </a-button>
      </a-row>
    </a-form-model>
  </a-card>
</template>

<script>
import * as Api from '@/api/student'
import { cloneDeep } from 'lodash'
export default {
  name: 'BasicInformationVue',
  data() {
    return {
      form: {
        id: '',
        diagnosticResults: '',
        basicAbilityInformation: '',
        basicFamilyInformation: '',
      },
      data: {},
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id },
        },
      } = this
      this.form.id = id
      Api.getStudentDetail({ studentId: id }).then((res) => {
        const { code, data } = res
        if (code === 0) {
          this.data = data
          const { id, diagnosticResults = '', basicAbilityInformation = '', basicFamilyInformation = '' } = data
          this.form = { id, diagnosticResults, basicAbilityInformation, basicFamilyInformation }
        }
      })
    },
    onSubmit() {
      const { formatForm, validate, $notification } = this

      validate().then((valid) => {
        if (valid) {
          this.loading = true
          try {
            const form = formatForm()
            const { id } = form
            if (id) {
              Api.studentFamilyInfo({ ...form })
                .then((res) => {
                  const { code, msg } = res
                  if (code === 0) {
                    $notification['success']({ message: '操作成功' })
                    this.close()
                  } else {
                    $notification['error']({ message: '提示', description: `${msg}` })
                  }
                })
                .finally(() => (this.loading = false))
            }
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    // 格式化
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    // 验证
    validate() {
      const {
        $refs: { form },
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.basic-information {
  background: #ffffff;
  padding-top: 20px;
  /deep/ .ant-card {
    margin-bottom: 20px;
  }
  /deep/ .ant-card-head {
    background: #e1e8ff;
    border-bottom-width: 1px;
    .ant-card-head-title {
      text-align: center;
    }
  }

  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  /deep/ .ant-card-body {
    padding: 0;
  }

  .basic {
    /deep/ .ant-card-body {
      padding: 24px;
    }
  }
}
</style>
