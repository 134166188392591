var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-calendar',{staticClass:"calendar",attrs:{"header-render":_vm.headerRender},on:{"panelChange":_vm.onPanelChange},scopedSlots:_vm._u([{key:"dateCellRender",fn:function(value){return _c('ul',{staticClass:"events",on:{"click":function($event){return _vm.showHomeworkModal(value)}}},_vm._l((_vm.getListData(value)),function(item,index){return _c('li',{key:index},[_c('a-badge',{attrs:{"color":_vm.colors[item.status],"text":item.skillName}})],1)}),0)}}])}),_c('a-modal',{attrs:{"title":"查看作业","width":"716px","destroyOnClose":true,"body-style":{ padding: 0 },"ok-button-props":_vm.isSchoolStatus && !_vm.$auth('plan_work_update')
        ? { style: { display: 'none' } }
        : {
            props: { shape: 'round' },
            domProps: {
              innerHTML: '修改',
            },
          },"cancel-button-props":{ props: { shape: 'round' } }},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},_vm._l((_vm.currentList),function(item,ix){return _c('a-row',{key:ix,staticClass:"homework-wrap",staticStyle:{"border":"1px solid #ececec","padding":"24px","flex-wrap":"nowrap"},attrs:{"type":"flex","align":"middle"}},[_c('a-col',{staticStyle:{"text-align":"center"},attrs:{"md":4,"lg":4,"xs":24}},[_c('a-avatar',{attrs:{"size":56,"shape":"square","src":item.field.url ? item.field.url : ''}})],1),_c('a-col',{attrs:{"md":16,"lg":16,"xs":24}},[_c('a-row',{attrs:{"type":"flex","align":"top"}},[_c('a-col',{attrs:{"span":12}},[_c('span',{staticStyle:{"color":"black","font-weight":"bold"}},[_vm._v("技能名称：")]),_vm._v(" "+_vm._s(item.skillName))]),_c('a-col',{attrs:{"span":12}},[_c('span',{staticStyle:{"color":"black","font-weight":"bold"}},[_vm._v("最低训练次数：")]),_vm._v(" "+_vm._s(item.minimumRounds)+" 次")])],1),_c('a-row',{staticStyle:{"margin":"10px 0"}},[_c('a-col',[_c('span',{staticStyle:{"color":"black","font-weight":"bold"}},[_vm._v("阶段名称：")]),_vm._v(" "+_vm._s(item.phaseName))])],1),_c('a-row',[_c('span',{staticStyle:{"color":"black","font-weight":"bold"}},[_vm._v("训练素材：")]),_vm._l((item.materialList),function(material,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(index === parseInt(item.materialList.length) - 1 ? material : `${material}，`))])})],2)],1),_c('a-col',{attrs:{"md":4,"lg":4,"xs":24}},[_c('a-badge',{attrs:{"color":_vm.colors[item.status],"text":_vm.texts[item.status]}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }