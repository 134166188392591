<template>
  <pdf-page>
    <template #extra>
      <img src="~@/assets/report/quarter/balloon@2x.png" class="extra" alt="" />
    </template>
    <div class="main">
      <div class="text-row" v-if="dataSource.longTermGoalsQualifiedNum">
        <div class="text-col">共有</div>
        <div class="text-col large highlight">{{ dataSource.longTermGoalsQualifiedNum }}</div>
        <div class="text-col">个长期目标达标</div>
      </div>
      <div class="text-row" style="margin-bottom: 126px">
        <div class="text-col large highlight">{{ dataSource.shortTermGoalsQualifiedNum }}</div>
        <div class="text-col">个短期目标达标</div>
      </div>
      <div class="filled-wrapper">
        <div class="title" style="margin-bottom: 12px">长期目标达标情况</div>
        <chart-pie :series="longTermGoals"></chart-pie>
      </div>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
import { ChartPie } from '../../components'
export default {
  components: {
    PdfPage,
    ChartPie,
  },
  props: {
    dataSource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    longTermGoals() {
      const {
        dataSource: { longTermGoalsQualifiedNum = 0, longTermGoalsTeachingNum = 0 },
      } = this
      const ret = []
      longTermGoalsTeachingNum > 0 && ret.push({ value: longTermGoalsTeachingNum, name: '训练中' })
      longTermGoalsQualifiedNum > 0 && ret.push({ value: longTermGoalsQualifiedNum, name: '已达标' })

      return ret
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  text-align: center;
  padding: 52px 67px;
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 36px;
}
.text {
  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-col:not(.large) {
    margin-bottom: 5px;
  }
}
.large {
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 1px;
}
.highlight {
  color: #ff7a55;
}
.extra {
  position: absolute;
  width: 228px;
  bottom: 142px;
  left: 0;
}
.filled-wrapper {
  width: 806px;
  padding: 36px 0;
  background: rgba(255, 255, 255, 0.5);
  .title {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #1a1a1a;
    line-height: 42px;
  }
}
</style>