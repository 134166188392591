<template>
  <pdf-page>
    <template #extra>
      <img src="~@/assets/report/quarter/upstairs@2x.png" class="extra" alt="" />
    </template>
    <div class="main filled-wrapper">
      <pre class="pre">{{ content }}</pre>
      <br />
      <pre class="pre" style="text-align: right">{{ sign }}</pre>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
export default {
  components: {
    PdfPage,
  },
  props: {
    content: {
      type: String,
      default() {
        return ''
      },
    },
    sign: {
      type: String,
      default() {
        return ''
      },
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  font-size: 28px;
  font-weight: 500;
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
.extra {
  position: absolute;
  width: 352px;
  bottom: 118px;
  right: 69px;
}
.filled-wrapper {
  padding: 52px 40px;
  height: 1204px;
  background: rgba(255, 255, 255, 0.6);
}
</style>