<template>
  <a-table :columns="columns" :data-source="data" bordered :pagination="false" class="score-table" />
</template>
<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default() {
        return {
          evaluationReportFieldDetailsList: []
        }
      }
    }
  },
  data() {
    return {
      //   data,
      //   columns,
    }
  },
  computed: {
    data() {
      return this.dataSource.evaluationReportFieldDetailsList || []
    },
    columns() {
      const { fieldName, totalScore, score, scoringRate, changeFromLast } = this.dataSource

      const cols = [
        {
          title: '领域',
          dataIndex: 'fieldName',
          key: 'fieldName',
          align: 'center',
          customRender: (text, record, index) => {
            return <span style="color:#333;">{text}</span>
          }
        },
        {
          title: fieldName,
          children: [
            {
              title: '总分',
              align: 'center',
              children: [
                {
                  title: totalScore,
                  dataIndex: 'totalScore',
                  key: 'totalScore',
                  align: 'center',
                  customRender: (text, record, index) => {
                    return <span style="color:#333;">{text}</span>
                  }
                }
              ]
            },
            {
              title: '得分',
              align: 'center',
              children: [
                {
                  title: <span style="color:#3350C7;">{score}分</span>,
                  dataIndex: 'score',
                  key: 'score',
                  align: 'center',
                  customRender: (text, record, index) => {
                    return <span style="color:#3350C7;">{text}分</span>
                  }
                }
              ]
            },
            {
              title: '得分率',
              align: 'center',
              children: [
                {
                  title: <span>{scoringRate}%</span>,
                  dataIndex: 'scoringRate',
                  key: 'scoringRate',
                  align: 'center',
                  customRender: (text, record, index) => {
                    return <span style="color:#333;">{text}%</span>
                  }
                }
              ]
            },
            {
              title: '较上次变化',
              align: 'center',
              children: [
                {
                  title:
                    !Number.isInteger(changeFromLast) || changeFromLast === 0 ? (
                      <span>无变化</span>
                    ) : changeFromLast > 0 ? (
                      <span class="up">增加 {changeFromLast}%</span>
                    ) : (
                      <span class="down">降低 {changeFromLast - changeFromLast * 2}%</span>
                    ),
                  dataIndex: 'changeFromLast',
                  key: 'changeFromLast',
                  align: 'center',
                  customRender: (text, record, index) => {
                    return !Number.isInteger(text) || text === 0 ? (
                      <span style="color:#333;">无变化</span>
                    ) : text > 0 ? (
                      <span class="up">增加 {text}%</span>
                    ) : (
                      <span class="down">降低 {text - text * 2}%</span>
                    )
                  }
                }
              ]
            }
          ]
        }
      ]

      return cols
    }
  }
}
</script>
<style lang="less" scoped>
.score-table {
  /deep/ .ant-table-thead > tr > th {
    background-color: rgba(58, 95, 248, 0.08);
  }
  /deep/ .ant-table-tbody > tr > td {
    font-size: 14px;
    color: inherit;
  }
}
.up {
  color: #f12a2c;
}
.down {
  color: #39a54d;
}
</style>
