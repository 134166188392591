<template>
  <div>
    <a-calendar class="calendar" :header-render="headerRender" @panelChange="onPanelChange">
      <ul class="events" slot="dateCellRender" slot-scope="value" @click="showHomeworkModal(value)">
        <li v-for="(item, index) in getListData(value)" :key="index">
          <a-badge :color="colors[item.status]" :text="item.skillName" />
        </li>
      </ul>
    </a-calendar>

    <a-modal
      v-model="visible"
      title="查看作业"
      width="716px"
      :destroyOnClose="true"
      :body-style="{ padding: 0 }"
      :ok-button-props="
        isSchoolStatus && !$auth('plan_work_update')
          ? { style: { display: 'none' } }
          : {
              props: { shape: 'round' },
              domProps: {
                innerHTML: '修改',
              },
            }
      "
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="handleOk"
    >
      <a-row
        type="flex"
        align="middle"
        v-for="(item, ix) in currentList"
        :key="ix"
        class="homework-wrap"
        style="border: 1px solid #ececec; padding: 24px; flex-wrap: nowrap"
      >
        <a-col :md="4" :lg="4" :xs="24" style="text-align: center">
          <a-avatar :size="56" shape="square" :src="item.field.url ? item.field.url : ''"></a-avatar>
        </a-col>
        <a-col :md="16" :lg="16" :xs="24">
          <a-row type="flex" align="top">
            <a-col :span="12"
              ><span style="color: black; font-weight: bold">技能名称：</span> {{ item.skillName }}</a-col
            >
            <a-col :span="12">
              <span style="color: black; font-weight: bold">最低训练次数：</span> {{ item.minimumRounds }} 次</a-col
            >
          </a-row>
          <a-row style="margin: 10px 0">
            <a-col><span style="color: black; font-weight: bold">阶段名称：</span> {{ item.phaseName }}</a-col>
          </a-row>
          <a-row
            ><span style="color: black; font-weight: bold">训练素材：</span>
            <span v-for="(material, index) in item.materialList" :key="index">
              {{ index === parseInt(item.materialList.length) - 1 ? material : `${material}，` }}</span
            ></a-row
          >
        </a-col>
        <a-col :md="4" :lg="4" :xs="24"> <a-badge :color="colors[item.status]" :text="texts[item.status]" /> </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import * as homeworkApi from '@/api/homework'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      query: {
        studentId: undefined,
        workMonth: undefined,
      },
      calendarList: [],
      visible: false, // 作业弹窗
      currentList: [],
      currentDate: '', // 选中的天
      colors: {
        COMPLETED: 'green',
        TO_BE_TRAINED: 'yellow',
        NOT_COMPLETED: 'red',
      },
      texts: {
        COMPLETED: '完成',
        TO_BE_TRAINED: '待训练',
        NOT_COMPLETED: '未完成',
      },
    }
  },
  created() {
    this.getNow()
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
    ...mapState({
      roles: (state) => state.user.roles,
    }),
  },
  methods: {
    getNow() {
      this.query.studentId = parseInt(this.$route.params.id)
      this.query.workMonth = moment(new Date()).format('YYYY-MM-DD')
      this.initData()
    },
    // 日期变化
    onPanelChange(value) {
      this.query.workMonth = moment(new Date(value)).format('YYYY-MM-DD')
      this.initData()
    },
    // 获取作业日历
    initData() {
      const { query } = this
      homeworkApi.calendar(query).then((res) => {
        if (res.code === 0) {
          this.calendarList = res.data
        }
      })
    },
    // 日期对应的技能包
    getListData(value) {
      let listData
      const currentlistData = this.calendarList.find((el) => {
        if (el.day === moment(new Date(value)).format('YYYY-MM-DD')) {
          return el
        }
      })
      // eslint-disable-next-line prefer-const
      if (currentlistData) {
        listData = currentlistData.homeworkPlanSkillVOList
      }
      return listData || []
    },
    // 作业详情弹窗
    showHomeworkModal(value) {
      this.visible = true
      this.query.workMonth = moment(new Date(value)).format('YYYY-MM-DD')
      this.currentList = this.getListData(value)
    },
    // 修改作业
    handleOk() {
      const {
        query: { workMonth, studentId },
      } = this
      // 获取点击的日历日期和当前日期，进行比较，只能修改未来的作业
      const nowDate = new Date(`${moment(new Date()).format('YYYY-MM-DD')} 00:00:00`)
      const clickDate = new Date(`${workMonth} 00:00:00`)
      if (clickDate.getTime() > nowDate.getTime()) {
        this.$emit('changecomponent', { type: 'ChangeHomework', workTimestamp: workMonth, studentId })
        this.visible = false
        this.currentList = []
      } else {
        this.$notification.error({
          message: '提示',
          description: '当前作业不能进行修改',
        })
      }
    },
    headerRender({ value, type, onChange, onTypeChange }) {
      const start = 0
      const end = 12
      const monthOptions = []

      const current = value.clone()
      const localeData = value.localeData()
      const months = []
      for (let i = 0; i < 12; i++) {
        current.month(i)
        months.push(localeData.monthsShort(current))
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
            {months[index]}
          </a-select-option>
        )
      }
      const month = value.month()

      const year = value.year()
      const options = []
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class="year-item">
            {i}
          </a-select-option>
        )
      }

      const addBtnFlag = this.roles.includes('work_add') && !this.isSchoolStatus
      const addBtn = addBtnFlag ? (
        <a-col>
          <a-button type="primary" shape="round" onClick={this.handleAdd}>
            <a-icon type="plus" />
            添加作业
          </a-button>
        </a-col>
      ) : (
        ''
      )
      return (
        <div style={{ padding: '16px 0' }}>
          <a-row type="flex" align="middle" justify="space-between" gutter={[16]}>
            <a-col style="color: #666; font-size: 12px"> 此功能为家校联动功能，需配合嗨小保使用 </a-col>
            <div style="display:flex;justify-content: end;">
              <a-col style="display:flex;align-items: center;">
                <a-badge color={this.colors['TO_BE_TRAINED']} text={this.texts['TO_BE_TRAINED']} />
                <a-badge style={{ margin: '0 10px' }} color={this.colors['COMPLETED']} text={this.texts['COMPLETED']} />
                <a-badge color={this.colors['NOT_COMPLETED']} text={this.texts['NOT_COMPLETED']} />
              </a-col>
              <a-col>
                <h-select
                  dropdownMatchSelectWidth={false}
                  onChange={(newYear) => {
                    const now = value.clone().year(newYear)
                    onChange(now)
                  }}
                  value={String(year)}
                  style="width:120px"
                >
                  {options}
                </h-select>
              </a-col>
              <a-col>
                <h-select
                  dropdownMatchSelectWidth={false}
                  value={String(month)}
                  onChange={(selectedMonth) => {
                    const newValue = value.clone()
                    newValue.month(parseInt(selectedMonth, 10))
                    onChange(newValue)
                  }}
                  style="width:120px"
                >
                  {monthOptions}
                </h-select>
              </a-col>
              {addBtn}
            </div>
          </a-row>
        </div>
      )
    },
    // 添加作业
    handleAdd() {
      this.$emit('changecomponent', { type: 'AddHomeWork' })
    },
  },
}
</script>

<style lang="less" scoped>
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events /deep/ .ant-badge-status-text {
  word-break: normal;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
