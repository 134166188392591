<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="600"
    title="查看教学视频"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :body-style="{ padding: '30px 20px 10px 20px' }">
      <a-form-model ref="form" :labelCol="{ span: 5 }" :wrapperCol="{ span: 15 }">
        <a-form-model-item label="教学视频：">
          <a
            v-if="teachingVideo && teachingVideo.url"
            style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
            @click="play()"
          >
            <a-icon type="file" style="margin-right:8px" /><span>{{ teachingVideo.name }}</span>
          </a>
          <div v-else>{{ '暂无教学视频' }}</div>
        </a-form-model-item>
        <a-form-model-item label="步骤描述：">
          <div v-if="stepsDescriptionList && stepsDescriptionList.length">
            <p
              v-for="(i, index) in stepsDescriptionList"
              :key="index"
              class="descriptions-content text-color-secondary"
            >
              {{ i }}
            </p>
          </div>
          <div v-else>{{ '暂无步骤描述' }}</div>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      teachingVideo: {
        url: '',
        name: ''
      },
      stepsDescriptionList: [],
      item: {}
    }
  },
  methods: {
    showModal(item) {
      this.item = item
      const { teachingVideo, stepsDescriptionList } = item
      this.teachingVideo = teachingVideo
      this.stepsDescriptionList = stepsDescriptionList
      this.visible = true
    },
    handleCancel() {
      this.teachingVideo = {}
      this.item = {}
      this.stepsDescriptionList = []
      this.visible = false
    },
    play() {
      this.$emit('playVideo', { item: this.item })
    }
  }
}
</script>

<style lang="less" scoped></style>
