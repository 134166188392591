<template>
  <a-modal
    v-model="visible"
    title="编辑作业"
    width="716px"
    :body-style="{ padding: '20px, 24px', backgroundColor: '#f6f7fc' }"
    :destroyOnClose="true"
    :ok-button-props="{ props: { shape: 'round' } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="onsubmitSkill"
    @cancel="cancelHandle"
  >
    <a-form-model ref="form" :model="form">
      <!-- 选择的技能包进行展示 -->
      <a-row type="flex" align="middle" class="comp-bg" style="flex-wrap:nowrap;padding:12px">
        <a-col flex="0 1 80px" style="margin-right:24px">
          <a-avatar :size="80" shape="square" :src="skillInfo.field ? skillInfo.field.url : ''"></a-avatar>
        </a-col>

        <a-col flex="0 1 100%" style="line-height:1.5">
          <a-row type="flex" align="middle">
            <a-col class="desc">
              <span class="heading-color"> {{ skillInfo.skillName ? skillInfo.skillName : '-' }}</span>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle" style="flex-wrap:nowrap;margin-top:16px;font-size:12px">
            <a-col class="desc">
              所属分类:<span style="margin-left:8px"
                >{{ skillInfo.field ? skillInfo.field.parent.name : '-' }} >>
                {{ skillInfo.field ? skillInfo.field.name : '-' }}</span
              >
            </a-col>
          </a-row>

          <a-row type="flex" align="middle" style="margin-top:8px;font-size:12px">
            <a-col flex="1" class="desc">
              技能目标:<span style="margin-left:8px">{{ skillInfo.goal ? skillInfo.goal : '-' }}</span>
            </a-col>
          </a-row>
        </a-col>
      </a-row>

      <a-form-model-item
        style="display: flex;justify-content: flex-start;align-items: center;margin: 15px 0"
        label="选择练习阶段"
        prop="planPhaseId"
        :rules="{
          required: true,
          message: '选择阶段',
          trigger: 'change'
        }"
      >
        <a-button shape="round" @click="showPhaseModal()">
          {{ planPhase.name ? planPhase.name : '选择阶段' }}
        </a-button>
      </a-form-model-item>

      <a-form-model-item
        label="最低训练次数"
        prop="minimumRounds"
        style="display: flex;justify-content: flex-start;align-items: center"
      >
        <div>
          <span style="color: red">{{ planPhase.minimumRounds ? planPhase.minimumRounds : '--' }}</span
          >次
        </div>
      </a-form-model-item>
      <a-form-model-item
        style="width: 100%; display: flex;justify-content: flex-start;align-items: center"
        label="训练素材"
        prop="skillMaterial"
        :rules="{
          required: true,
          message: '请选择训练素材',
          trigger: 'blur'
        }"
      >
        <div class="checkbox">
          <a-checkbox-group v-model="form.materialList" style="width:100%">
            <a-row :gutter="[10, 20]">
              <a-col v-for="(item, index) in materialOption" :key="index" :span="8">
                <a-checkbox v-show="!isSelfmaterial(item)" :value="item">
                  {{ item }}
                </a-checkbox>
                <a-popover v-show="isSelfmaterial(item)">
                  <template slot="content">
                    <p @click="changeMaterial(item)">编辑</p>
                    <a-popconfirm
                      title="你确定要删除吗?"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="deleteMaterial(item)"
                    >
                      删除
                    </a-popconfirm>
                  </template>
                  <a-checkbox :value="item">
                    {{ item }}
                  </a-checkbox>
                </a-popover>
              </a-col>
            </a-row>
          </a-checkbox-group>
          <a-row type="flex" align="middle" style="margin-top:8px;font-size:12px">
            <a-col :span="12" style="margin-right: 10px">
              <a-input v-model="newMaterial" :style="{ width: 100, marginLeft: 10 }" />
            </a-col>
            <a-col :span="8">
              <a-button type="link" @click="onaddskillMaterial()">添加素材</a-button>
            </a-col>
          </a-row>
        </div>
      </a-form-model-item>
      <!-- 修改自定义素材 -->
      <a-modal
        v-model="Materialvisible"
        title="修改自定义素材"
        width="716px"
        :destroyOnClose="true"
        :ok-button-props="{ props: { shape: 'round' } }"
        :cancel-button-props="{ props: { shape: 'round' } }"
        @ok="handlechangeMaterial"
      >
        <a-form-model-item label="原素材" style="display: flex;justify-content: flex-start;align-items: center">
          <div>{{ beforeMaterial }}</div>
        </a-form-model-item>
        <a-form-model-item label="新素材" style="display: flex;justify-content: flex-start;align-items: center">
          <a-input v-model="afterMaterial" :style="{ width: 100, marginLeft: 10 }" />
        </a-form-model-item>
      </a-modal>
      <!-- 选择阶段弹窗 -->
      <a-modal
        v-model="Phasevisible"
        title="选择技能包阶段"
        width="716px"
        :destroyOnClose="true"
        :body-style="{ padding: 0 }"
        :ok-button-props="{ props: { shape: 'round' } }"
        :cancel-button-props="{ props: { shape: 'round' } }"
        @ok="handleOk"
        @cancel="selected = null"
      >
        <a-table rowKey="id" :customRow="customRow" :pagination="pagination" :data-source="Phaselist">
          <a-table-column key="record" title="图片" align="center" :ellipsis="true">
            <template slot-scope="record">
              <a-row type="flex" align="middle" justify="space-between" :gutter="24" style="flex-wrap:nowrap;">
                <a-col flex="0 0 80px">
                  <a-radio :checked="selected.id === record.id"></a-radio>
                </a-col>

                <a-col flex="0 0 56px">
                  <a-avatar :size="56" shape="square" :src="record.url"></a-avatar>
                </a-col>

                <a-col flex="0 0 80px"></a-col>
              </a-row>
            </template>
          </a-table-column>
          <a-table-column key="phaseNumber" title="阶段数" data-index="phaseNumber" align="center" width="20%">
            <template slot-scope="phaseNumber">
              <span>
                {{ `第${convertToChineseNumber(phaseNumber)}阶段` }}
              </span>
            </template>
          </a-table-column>
          <a-table-column key="name" title="阶段名称" data-index="name" align="center" width="40%" />
        </a-table>
      </a-modal>
    </a-form-model>
  </a-modal>
</template>

<script>
import { convertToChineseNumber } from '@/utils/util'
import * as homeworkApi from '@/api/homework'
import cloneDeep from 'lodash.clonedeep'

export default {
  data() {
    return {
      visible: false,
      skillInfo: {},
      form: {
        materialList: [],
        planPhaseId: undefined,
        planSkillId: undefined
      },
      Phasevisible: false, // 阶段选择弹窗
      Phaselist: [], // 阶段列表
      pagination: {
        pageSize: 4
      }, // 分页设置
      planPhase: {}, // 选中的阶段
      selected: {},
      newMaterial: undefined,
      materiallist: [], // 原始素材
      Materialvisible: false,
      beforeMaterial: '',
      afterMaterial: '',
      isFirstset: true // 是否是第一次设置信息
    }
  },
  computed: {
    materialOption() {
      if (this.visible && this.isFirstset) {
        return Array.from(
          new Set(this.materiallist.concat(this.skillInfo.selfMaterialList ? this.skillInfo.selfMaterialList : []))
        )
      } else {
        return Array.from(new Set(this.materiallist))
      }
    },
    systemMaterialList() {
      const tempdata = []
      this.form.materialList.forEach(el => {
        if (this.materiallist.includes(el)) {
          if (!tempdata.includes(el)) {
            tempdata.push(el)
          }
        }
      })
      return tempdata || []
    },
    selfMaterialList() {
      const tempdata = []
      this.form.materialList.forEach(el => {
        if (!this.materiallist.includes(el)) {
          if (!tempdata.includes(el)) {
            tempdata.push(el)
          }
        }
      })
      return tempdata || []
    }
  },
  methods: {
    convertToChineseNumber,
    // 编辑作业
    showModal(skill) {
      this.visible = true
      this.skillInfo = skill
      this.form.planSkillId = skill.planSkillId
      this.form.planPhaseId = skill.planPhaseId
      this.form.materialList = skill.materialList
      this.planPhase.id = skill.planPhaseId
      this.initData(skill)
    },
    initData(skill) {
      homeworkApi.phase({ planSkillId: skill.planSkillId }).then(res => {
        if (res.code === 0) {
          this.Phaselist = res.data
          this.planPhase = res.data.find(el => {
            if (el.id === skill.planPhaseId) {
              return el
            }
          })
          this.materiallist = this.planPhase.materialList
        }
      })
    },
    // 选择阶段弹窗
    showPhaseModal() {
      this.Phasevisible = true
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selected = record
          }
        }
      }
    },
    // 确定阶段
    handleOk() {
      if (this.selected.id) {
        this.isFirstset = false
        this.Phasevisible = false
        this.planPhase = this.selected
        this.form.planPhaseId = this.selected.id
        this.form.materialList = []
        this.materiallist = this.selected.materialList
      } else {
        this.$notification.error({
          message: '提示',
          description: '请选择阶段'
        })
      }
    },
    checkMaterialNameReg(mat) {
      let regResult = false
      if (/^[a-zA-Z]+$/.test(mat)) {
        regResult = true
      }
      if (/^[0-9]*$/.test(mat)) {
        regResult = true
      }
      if (/^[\u4E00-\u9FA5]+$/.test(mat)) {
        regResult = true
      }
      return regResult
    },
    checkMaterial(mat) {
      const { materialOption } = this
      let tempBoolean = true
      if (!mat) {
        this.$notification.error({
          message: '错误',
          description: '不能添加空素材'
        })
        tempBoolean = false
      } else if (/[\s*]/.test(mat)) {
        this.$notification.error({
          message: '错误',
          description: '素材名不能包含空格'
        })
        tempBoolean = false
      } else if (mat.length > 9) {
        this.$notification.error({
          message: '错误',
          description: '素材最多为9个字'
        })
        tempBoolean = false
      } else if (materialOption.includes(mat)) {
        this.$notification.error({
          message: '错误',
          description: '不能添加重复素材'
        })
        tempBoolean = false
      }
      // else if (!this.checkMaterialNameReg(mat)) {
      //   this.$notification.error({
      //     message: '错误',
      //     description: '请用纯文字、纯数字或者纯字母创建素材'
      //   })
      //   tempBoolean = false
      // }
      return tempBoolean
    },
    // 添加素材
    onaddskillMaterial() {
      const { newMaterial, materialOption } = this
      if (this.checkMaterial(newMaterial)) {
        newMaterial && materialOption.push(newMaterial)
        this.form.materialList.push(newMaterial)
      }
      this.newMaterial = undefined
    },
    // 验证是否是自定义素材
    isSelfmaterial(item) {
      return !this.materiallist.includes(item)
    },
    // 编辑自定义素材
    changeMaterial(item) {
      this.beforeMaterial = item
      this.Materialvisible = true
    },
    handlechangeMaterial() {
      const { beforeMaterial, afterMaterial, materialOption } = this
      if (this.checkMaterial(afterMaterial)) {
        const formIndex = this.form.materialList.findIndex(el => el === beforeMaterial)
        const optIndex = materialOption.findIndex(el => el === beforeMaterial)
        afterMaterial && materialOption.splice(optIndex, 1, afterMaterial)
        this.form.materialList.splice(formIndex, 1, afterMaterial)
      }
      this.beforeMaterial = ''
      this.afterMaterial = ''
      this.Materialvisible = false
    },
    // 删除自定义素材
    deleteMaterial(item) {
      const optIndex = this.materialOption.findIndex(el => el === item)
      this.materialOption.splice(optIndex, 1)
      if (this.form.materialList.includes(item)) {
        const formIndex = this.form.materialList.findIndex(el => el === item)
        this.form.materialList.splice(formIndex, 1)
      }
      this.$forceUpdate()
    },
    // 修改作业
    onsubmitSkill() {
      this.visible = false
      const { skillInfo, planPhase, form, selfMaterialList, systemMaterialList } = this
      // 设置编辑作业回传
      skillInfo.materialList = form.materialList
      skillInfo.phaseName = planPhase.name
      skillInfo.minimumRounds = planPhase.minimumRounds
      const skill = cloneDeep(skillInfo)
      // 设置提交的信息
      form.selfMaterialList = selfMaterialList
      form.systemMaterialList = systemMaterialList
      form.id = skillInfo.id
      homeworkApi.change(form).then(res => {
        if (res.code === 0) {
          this.$emit('editsubmit', skill)
          this.$notification.success({
            message: '成功',
            description: '作业修改成功'
          })
        }
      })
    },
    cancelHandle() {
      this.visible = false
      const { skillInfo } = this
      const skill = cloneDeep(skillInfo)
      this.$emit('editsubmit', skill)
    }
  }
}
</script>

<style lang="less" scoped>
.checkbox {
  width: 500px;
  margin: 0 20px;
  padding: 20px;
  border: 1px solid #f6f7fc;
  background-color: #ffffff;
  border-radius: 15px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
