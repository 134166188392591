<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="600"
    title="播放视频"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    @ok="handleCancel"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :body-style="{ padding: '30px 20px 10px 20px', textAlign: 'center' }">
      <video
        controls
        controlsList="nodownload"
        disablePictureInPicture
        :src="teachingVideo"
        style="max-width: 100%;max-height: 100%"
      ></video>
    </a-card>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      teachingVideo: ''
    }
  },
  methods: {
    showModal(item) {
      console.log(item)
      this.visible = true
      this.teachingVideo = item.teachingVideo.url
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped></style>
