<template>
  <div>
    <date-export ref="dateExport" v-show="currentExportType === 'date'" @event="handleEvent"></date-export>
  </div>
</template>
<script>
import DateExport from './dateExport'
export default {
  components: {
    DateExport
  },
  data() {
    return {
      currentExportType: 'date'
    }
  },
  methods: {
    showExport(e) {
      this.currentExportType = e.toSubMenuName
      switch (e.toSubMenuName) {
        case 'date':
          this.$refs.dateExport.initData(e)
          break
        default:
      }
    },
    // 自定义的“冒泡”事件
    handleEvent(e) {
      this.$emit('eventsubmit', e)
    }
  }
}
</script>
<style lang="less"></style>
