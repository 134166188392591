<!-- 日报 -->
<template>
  <a-spin :spinning="spinning">
    <div class="preview">
      <a-affix :offset-top="0">
        <div class="header-btn">
          <div class="btn-wrap">
            <a-button
              v-if="!isexportPDF"
              type="primary"
              shape="round"
              style="width: 134px; height: 32px; margin-right: 16px"
              @click="exportPDF()"
            >
              <a-icon type="file-pdf" />
              导出PDF</a-button
            >
            <a-button
              v-if="isexportPDF"
              @click="btnBack()"
              type="primary"
              shape="round"
              style="width: 84px; height: 32px; margin-right: 16px"
            >
              <a-icon type="left" />
              返回
            </a-button>
          </div>
        </div>
      </a-affix>
      <!-- 默认pdf页面 -->
      <div id="content" class="content">
        <div class="logo">
          <img class="image" src="~@/assets/report/report/pdf_logo.png" alt="加载错误..." />
        </div>
        <!-- 头部信息和评语 -->
        <div class="header pdfitemclass">
          <div class="title">{{ formQuery.studentName }}小朋友的教学进展日报</div>
          <div class="date">
            {{ convertReportDate(formQuery.startTime) }}<span>({{ formQuery.week }})</span>
          </div>
          <div class="label">教师评语</div>
          <div class="comments">{{ formQuery.comments }}</div>
        </div>

        <!-- 今日技能教学 -->
        <div class="tabs-name pdfitemclass">今日技能教学</div>
        <div
          class="list-item pdfitemclass"
          v-for="skill in formQuery.teachingTrainingDataReportSkillList"
          :key="skill.id"
        >
          <div class="title">
            {{ skill.code }}&nbsp;&nbsp;{{ skill.name
            }}<span class="field">{{ skill.mainFieldName }} > {{ skill.secondFieldName }}</span>
          </div>

          <div class="phase" v-for="phase in skill.dataReportSkillPhaseList" :key="phase.id">
            <div class="wrap">
              <div class="phase-title">阶段</div>
              <div class="phase-info">
                <div class="info-name">第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</div>
                <div class="info-des">
                  <span>记录方式：{{ recordingMethod[phase.recordingMethod] }} </span>
                  <span class="goal-result"
                    >{{ phase.recordingMethod === 'NUMBER_SUCCESS' ? '目标频率' : '目标正确率' }}：{{
                      phase.recordingMethod === 'NUMBER_SUCCESS'
                        ? `${phase.minutes}分${phase.seconds}秒${phase.frequency}次`
                        : `${phase.successRate}%`
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="phase-mat">
              <div class="mat-title">练习素材</div>
              <div class="mat-list">
                <div class="list-item" v-for="mat in phase.dataReportSkillPhaseMaterialList" :key="mat.id">
                  <span v-if="phase.recordingMethod === 'NUMBER_SUCCESS'"
                    >{{ mat.material }}：训练{{ mat.trainingCount }}次，平均次数{{ mat.successRate }}次</span
                  >
                  <span v-else>{{ mat.material }}：训练{{ mat.trainingCount }}次，正确率{{ mat.successRate }}%</span>
                  <a-icon
                    v-if="checkMaterialIsGoal(phase, mat)"
                    type="check-circle"
                    theme="twoTone"
                    two-tone-color="#52BE7F"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 今日行为记录 -->
        <div class="action pdfitemclass" v-if="formQuery.dataReportBehaviorRecordList.length">
          <div class="tabs-name">今日行为记录</div>
          <div class="menu">
            <div class="menu-item menu-o">问题行为</div>
            <div class="menu-item menu-t">上课时间</div>
            <div class="menu-item menu-e">行为记录</div>
          </div>
        </div>

        <div
          class="action-list-item pdfitemclass"
          v-for="item in formQuery.dataReportBehaviorRecordList"
          :key="item.id"
        >
          <div class="item-l">{{ item.name }}</div>
          <div class="item-r">
            <div
              class="record"
              v-for="(record, subix) in item.dataReportBehaviorRecordClassVOList"
              :key="String(subix)"
            >
              <div class="record-t">{{ record.classStartTime }} - {{ record.classEndTime }}</div>
              <div class="record-i">
                <span class="dura" v-for="(dura, duraix) in record.frequencyOrDuration" :key="`${dura}-${duraix}`">{{
                  computerArrayLength(record.frequencyOrDuration, duraix)
                    ? computerBehaviorRecord(item.recordingMethod, dura)
                    : `${computerBehaviorRecord(item.recordingMethod, dura)}、`
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="action-text pdfitemclass"
          v-for="(item, ix) in formQuery.dataReportBehaviorRecordList"
          :key="`${item.name}${ix}`"
        >
          <div class="text-name" v-if="item.interventionMethod">{{ item.name }}干预方式及分析</div>
          <div class="text-bold" v-if="item.interventionMethod">{{ item.interventionMethod }}</div>
        </div>
        <!-- 明日教学调整 -->
        <div
          class="tabs-name pdfitemclass"
          v-if="
            !(
              !formQuery.achievementMaterialDataReportSkillList.length &&
              !formQuery.newMaterialDataReportSkillList.length
            )
          "
        >
          明日教学调整
        </div>

        <div class="tomorrw-title pdfitemclass" v-if="formQuery.achievementMaterialDataReportSkillList.length">
          今日达标的教学素材
        </div>

        <div
          class="tomorow-item pdfitemclass"
          v-for="achieve in formQuery.achievementMaterialDataReportSkillList"
          :key="achieve.id"
        >
          <div class="item-name">
            {{ achieve.code }} {{ achieve.name
            }}<span class="field">{{ achieve.mainFieldName }}>{{ achieve.secondFieldName }} </span>
          </div>
          <div class="item-mat">
            <div class="mat-cont">练习素材</div>
            <div class="mat-name">
              <span v-for="phase in achieve.dataReportSkillPhaseList" :key="phase.id">
                <span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.material">{{
                  computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                    ? mat.material
                    : `${mat.material}、`
                }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="tomorrw-title pdfitemclass" v-if="formQuery.newMaterialDataReportSkillList.length">
          明日新增的教学素材
        </div>
        <div
          class="tomorow-item pdfitemclass"
          v-for="newadd in formQuery.newMaterialDataReportSkillList"
          :key="newadd.id"
        >
          <div class="item-name">
            {{ newadd.code }} {{ newadd.name
            }}<span class="field"> {{ newadd.mainFieldName }}>{{ newadd.secondFieldName }}</span>
          </div>
          <div class="item-mat">
            <div class="mat-cont">练习素材</div>
            <div class="mat-name">
              <span v-for="phase in newadd.dataReportSkillPhaseList" :key="phase.id">
                <span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.id">{{
                  computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                    ? mat.material
                    : `${mat.material}、`
                }}</span></span
              >
            </div>
          </div>
        </div>

        <!-- 今日家庭作业 -->
        <div
          class="tabs-name pdfitemclass"
          v-if="formQuery.homeworkIsShow && formQuery.homeworkDataReportSkillList.length"
        >
          今日家庭作业
        </div>

        <div
          v-if="formQuery.homeworkIsShow && formQuery.homeworkDataReportSkillList.length"
          class="homework-list-item pdfitemclass"
          v-for="homework in formQuery.homeworkDataReportSkillList"
          :key="homework.id"
        >
          <div class="title">
            {{ homework.code }}&nbsp;&nbsp;{{ homework.name
            }}<span class="field">{{ homework.mainFieldName }} > {{ homework.secondFieldName }}</span>
          </div>

          <div class="phase" v-for="phase in homework.dataReportSkillPhaseList" :key="phase.id">
            <div class="phase-title">阶段</div>

            <div class="wrap">
              <div class="phase-info">
                <div class="info-name">第{{ convertToChineseNumber(phase.phaseNumber) }}阶段：{{ phase.name }}</div>
                <div class="info-des">阶段目标：{{ phase.goal }}</div>
              </div>

              <div class="phase-mat">
                <span class="nmb">最低练习次数：{{ phase.minimumRounds }}次</span>
                <span
                  >练习素材：<span v-for="(mat, matix) in phase.dataReportSkillPhaseMaterialList" :key="mat.id">{{
                    computerArrayLength(phase.dataReportSkillPhaseMaterialList, matix)
                      ? mat.material
                      : `${mat.material}、`
                  }}</span></span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 底部评语 -->
        <div class="footer pdfitemclass">
          <!-- <div class="label">结束语</div> -->
          <div class="conclusion">{{ formQuery.conclusion }}</div>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
import * as Api from '@/api/datareport'
import { convertToChineseNumber } from '@/utils/util'
import moment from 'moment'
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  data() {
    return {
      spinning: false, // 默认加载
      // 记录方式
      recordingMethod: {
        SUCCESS_RATE: '正确率（回合）',
        DURATION: '时长',
        NUMBER_SUCCESS: '频率',
        STEP_SUCCESS_RATE: '正确率（任务分析）'
      },
      // 事件参数
      eventParam: {},
      formQuery: {
        id: null, // 报告id
        studentName: '', // 学生姓名
        startTime: '', // 日期
        week: '', // 星期
        comments: '', // 头部评语
        conclusion: '', // 底部结束语
        teachingTrainingDataReportSkillList: [], // 今日技能教学
        dataReportBehaviorRecordList: [], // 行为记录
        achievementMaterialDataReportSkillList: [], // 明日调整下的达标素材
        newMaterialDataReportSkillList: [], // 明日调整下的新增素材
        homeworkDataReportSkillList: [], // 家庭作业
        homeworkIsShow: null, // 家庭作业是否显示
        mechanismName: null, // 机构名称
        createTimestamp: null, // 报告创建日期
        firstPageHasSkill: 0
      },
      isexportPDF: false // 是否已经导出PDF
    }
  },
  methods: {
    exportPDF() {
      const { formQuery } = this
      const vm = this
      const A4_WIDTH = 592.28
      const A4_HEIGHT = 841.89
      this.spinning = true
      vm.$nextTick(() => {
        // dom的id。
        document.getElementById('content').style.border = 'none'
        const target = document.getElementById('content')
        const pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT
        // 获取分割dom，此处为class类名为item的dom
        const lableListID = document.getElementsByClassName('pdfitemclass')
        // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
        for (let i = 0; i < lableListID.length; i++) {
          const multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight)
          if (this.isSplit(lableListID, i, multiple * pageHeight)) {
            const divParent = lableListID[i].parentNode // 获取该div的父节点
            const newNode = document.createElement('div')
            newNode.className = 'emptyDiv'
            newNode.style.background = '#ffffff'
            const _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight)
            newNode.style.height = _H + 140 + 'px'
            newNode.style.width = '100%'
            const next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            console.log(next)
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(newNode, next)
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(newNode)
            }
          }
        }
        // 传入title和dom标签，此处是 #content
        // 异步函数，导出成功后处理交互
        this.savePDF(`${formQuery.studentName}小朋友的教学进展日报`, '#content')
          .then(() => {
            this.spinning = false
            this.$message.success('PDF生成成功')
          })
          .catch(() => {
            this.spinning = false
            this.$message.error('PDF生成失败')
          })
          .finally(() => {
            this.isexportPDF = true
          })
      })
    },
    isSplit(nodes, index, pageHeight) {
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true
      }
      return false
    },
    savePDF(title, dom) {
      return new Promise((resolve, reject) => {
        html2Canvas(document.querySelector(dom), {
          useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
          allowTaint: true // 允许跨域
        })
          .then(function(canvas) {
            const contentWidth = canvas.width
            const contentHeight = canvas.height
            // 根据A4纸的大小，计算出dom相应比例的尺寸
            const pageHeight = (contentWidth / 592.28) * 841.89
            let leftHeight = contentHeight
            let position = 0
            const imgWidth = 595.28
            // 根据a4比例计算出需要分割的实际dom位置
            const imgHeight = (592.28 / contentWidth) * contentHeight
            // canvas绘图生成image数据，1.0是质量参数
            const pageData = canvas.toDataURL('image/jpeg', 1.0)
            // a4大小
            const PDF = new JsPDF('', 'pt', 'a4')
            // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
            if (leftHeight < pageHeight) {
              PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            } else {
              while (leftHeight > 0) {
                PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                leftHeight -= pageHeight
                position -= 841.89
                if (leftHeight > 0) {
                  PDF.addPage()
                }
              }
            }
            // 导出
            PDF.save(title + '.pdf')
            resolve(true)
          })
          .catch(() => {
            reject(false)
          })
      })
    },
    convertToChineseNumber,
    // 日期转换
    convertReportDate(date) {
      return moment(date).format('LL')
    },
    // 查询报告数据
    initData(e) {
      // 控制按钮显示
      this.afterRequestEventType = e.type
      this.eventParam = e

      Api.inquireDaily(e.param)
        .then(res => {
          const { code, data } = res
          const newArr = []
          if (code === 0) {
            // 初始化赋值
            for (const name in data) {
              this.formQuery[name] = data[name]
            }
            //  技能教学进行拆分，一个阶段对应一个展示框
            data.teachingTrainingDataReportSkillList.forEach(skill => {
              const newskill = {
                ...skill
              }
              newskill.dataReportSkillPhaseList = []
              skill.dataReportSkillPhaseList.forEach(phase => {
                newskill.dataReportSkillPhaseList.push(phase)
                newArr.push(newskill)
              })
            })
            this.formQuery.teachingTrainingDataReportSkillList = newArr
          }
        })
        .finally(() => {
          this.spinning = false
        })
    },

    // 检查素材是否超过目标正确率
    checkMaterialIsGoal(phase, mat) {
      let result = false
      // 根据记录方式，判断频率与其他方式下，素材是否大于等于目标率
      if (phase.recordingMethod === 'NUMBER_SUCCESS') {
        result = mat.successRate >= phase.frequency
      } else {
        result = mat.successRate >= phase.successRate
      }
      return result
    },
    // 计算素材是否是数组最后一个
    computerArrayLength(arr, index) {
      if (arr.length) {
        return arr.length - 1 === index
      } else {
        return false
      }
    },
    // 计算行为记录的记录方式，返回对应的样式
    computerBehaviorRecord(recordingmethod, value) {
      let result = null
      if (recordingmethod === 'TIMING') {
        result = value
      }
      if (recordingmethod === 'COUNT') {
        result = `${value}`
      }
      if (recordingmethod === 'TIMING_COUNT' || recordingmethod === 'COUNTDOWN_COUNT') {
        const temp = value.split(',')
        result = `${temp[0]}，${temp[1]}`
      }
      return result
    },

    // 对应的按钮操作
    // 返回
    btnBack() {
      this.backCalendar()
    },
    // 重置初始值
    setAlldataDefault() {
      this.spinning = true
      this.eventParam = {}
      this.isexportPDF = false
    },
    backCalendar() {
      this.setAlldataDefault()
      // 提交返回事件
      const event = {
        type: 'cancel',
        toMenuName: 'calendar',
        toSubMenuName: '',
        param: {}
      }
      this.$emit('event', event)
    }
  }
}
</script>
<style lang="less" scoped>
.preview {
  overflow-x: auto;
  padding: 24px 0;
  margin: 16px 0 0;
  background: #ffffff;

  .header-btn {
    position: relative;
    width: 100%;
    height: 44px;
    margin: 0 0 24px;
    background: #ffffff;
    .btn-wrap {
      position: absolute;
      top: 6px;
      right: 0;
    }
  }

  .content {
    width: 1080px;
    padding: 60px 90px 116px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #ffffff;
    .logo {
      display: flex;
      justify-content: flex-end;
      .image {
        height: 42px;
      }
    }

    .header {
      .title {
        margin: 40px 0 8px;
        height: 42px;
        font-size: 30px;
        font-weight: 500;
        color: #000000;
        line-height: 42px;
        text-align: center;
      }
      .date {
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #1a1a1a;
        line-height: 28px;
        text-align: center;
      }
      .label {
        height: 50px;
        margin: 40px 0 12px;
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        line-height: 50px;
      }
      .comments {
        min-height: 138px;
        padding: 24px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 20px;
        font-weight: 400;
        color: #1e1e1e;
        white-space: pre-line; // 通过css设置，识别评语的\n换行，实现换行
      }
    }

    .tabs-name {
      height: 62px;
      margin: 40px 0 16px;
      font-size: 30px;
      font-weight: 500;
      color: #1e1e1e;
      text-align: center;
      line-height: 62px;
      cursor: pointer;
    }

    .list-item {
      .title {
        padding: 22px 40px;
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        background: rgba(58, 95, 248, 0.08);
        border: 1px solid rgba(0, 0, 0, 0.05);
        .field {
          margin-left: 8px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .phase {
        .wrap {
          display: flex;

          .phase-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            min-height: 88px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            background: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, 0.05);
          }
          .phase-info {
            width: 80%;
            min-height: 88px;
            padding: 16px 20px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            .info-name {
              font-size: 18px;
              font-weight: 500;
              color: #333333;
            }
            .info-des {
              margin: 8px 0 0;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              .goal-result {
                margin: 0 0 0 12px;
              }
            }
          }
        }

        .phase-mat {
          display: flex;
          .mat-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            min-height: 64px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            background: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, 0.05);
          }
          .mat-list {
            width: 80%;
            min-height: 64px;
            padding: 16px 20px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            .list-item {
              font-size: 18px;
              font-weight: 400;
              color: #333333;
              span {
                margin: 0 8px 0 0;
              }
            }
          }
        }
      }
    }

    .action {
      .menu {
        display: flex;
        align-items: center;
        background: rgba(58, 95, 248, 0.08);
        .menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 34px;
          font-size: 20px;
          font-weight: 500;
          color: #1a1a1a;
          border: 1px solid rgba(0, 0, 0, 0.05);
        }
        .menu-o {
          width: 20%;
        }
        .menu-t {
          width: 20%;
        }
        .menu-e {
          width: 60%;
        }
      }
    }
    .action-list-item {
      display: flex;
      width: 100%;
      .item-l {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        min-height: 56px;
        font-size: 18px;
        font-weight: 500;
        color: #1a1a1a;
        background: rgba(0, 0, 0, 0.02);
        border: 1px solid rgba(0, 0, 0, 0.05);
      }
      .item-r {
        width: 80%;
        .record {
          display: flex;
          // align-items: center;
          width: 100%;
          .record-t {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            padding: 16px 18px;
            font-size: 18px;
            font-weight: 500;
            color: #1a1a1a;
            border: 1px solid rgba(0, 0, 0, 0.05);
          }
          .record-i {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 75%;
            padding: 16px 18px;
            font-size: 18px;
            font-weight: 400;
            color: #1a1a1a;
            border: 1px solid rgba(0, 0, 0, 0.05);
            .dura {
              padding: 10px 14px;
            }
          }
        }
      }
    }
    .action-text {
      margin: 40px 0 0;
      .text-name {
        height: 60px;
        background: rgba(58, 95, 248, 0.08);
        border: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        text-align: center;
        line-height: 60px;
      }
      .text-bold {
        padding: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #1e1e1e;
        line-height: 22px;
      }
    }

    .tomorrw-title {
      height: 60px;
      background: rgba(58, 95, 248, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.05);
      font-size: 20px;
      font-weight: 500;
      color: #1e1e1e;
      text-align: center;
      line-height: 60px;
    }
    .tomorow-item {
      .item-name {
        padding: 16px 24px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        background: rgba(0, 0, 0, 0.02);
        border: 1px solid rgba(0, 0, 0, 0.05);
        .field {
          margin: 0 0 0 8px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .item-mat {
        display: flex;
        // align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.05);
        .mat-cont {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 14px 38px;
          width: 20%;
          min-height: 54px;
          background: rgba(0, 0, 0, 0.02);
          border: 1px solid rgba(0, 0, 0, 0.05);
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }
        .mat-name {
          width: 80%;
          padding: 16px 24px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
      }
    }

    .homework-list-item {
      .title {
        padding: 22px 40px;
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        background: rgba(58, 95, 248, 0.08);
        border: 1px solid rgba(0, 0, 0, 0.05);
        .field {
          margin-left: 8px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .phase {
        display: flex;
        // align-items: center;
        .phase-title {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
          min-height: 88px;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          background: rgba(0, 0, 0, 0.02);
          border: 1px solid rgba(0, 0, 0, 0.05);
        }
        .wrap {
          width: 80%;
          .phase-info {
            padding: 16px 20px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            .info-name {
              font-size: 18px;
              font-weight: 500;
              color: #333333;
            }
            .info-des {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 22px;
            }
          }

          .phase-mat {
            display: flex;
            align-items: center;
            padding: 16px 24px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            .nmb {
              margin: 0 8px 0 0;
            }
          }
        }
      }
    }

    .footer {
      .label {
        height: 50px;
        margin: 40px 0 12px;
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        line-height: 50px;
      }
      .conclusion {
        min-height: 138px;
        padding: 24px;
        margin: 52px 0 0;
        border: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 20px;
        font-weight: 400;
        color: #1e1e1e;
        white-space: pre-line; // 通过css设置，识别结束语的\n换行，实现换行
      }
    }
  }
}
</style>
