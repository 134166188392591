 const COLORS_MAP = {
    习惯常规: {
        primary: '#EF7261',
        secondary: '#FEF4F3'
    },
    观察模仿: {
        primary: '#FAA77F',
        secondary: '#FFF8F5'
    },
    概念逻辑: {
        primary: '#ABE4F8',
        secondary: '#F3FBFE'
    },
    社交沟通: {
        primary: '#FFD281',
        secondary: '#FFF9EC'
    },
    生活自理: {
        primary: '#798DFF',
        secondary: '#F1F3FF'
    },
    游戏互动: {
        primary: '#FFAE9E',
        secondary: '#FFF3F1'
    },
    情绪心理: {
        primary: '#8DBDFF',
        secondary: '#EEF5FF'
    },
    学业技能: {
        primary: '#B37BF2',
        secondary: '#F9F5FE'
    },
    生命安全: {
        primary: '#CCEC8C',
        secondary: '#F5FBE8'
    },
 }

//  35个子领域
const CHILD_FIELD_MAP = {
    '抽象概念': require('@/assets/pdf/chouxianggainian@3x.png'),
    '动作模仿': require('@/assets/pdf/dongzuomofang@3x.png'),
    '独立穿衣': require('@/assets/pdf/dulichuanyi@3x.png'),
    '独立工作': require('@/assets/pdf/duligongzuo@3x.png'),
    '独立进餐': require('@/assets/pdf/dulijincan@3x.png'),
    '独立如厕': require('@/assets/pdf/duliruce@3x.png'),
    '对话': require('@/assets/pdf/duihua@3x.png'),
    '仿说': require('@/assets/pdf/fangshuo@3x.png'),
    '个人卫生': require('@/assets/pdf/gerenweisheng@3x.png'),
    '功能游戏': require('@/assets/pdf/gongnengyouxi@3x.png'),
    '建构游戏': require('@/assets/pdf/jiangouyouxi@3x.png'),
    '关系理解': require('@/assets/pdf/guanxilijie@3x.png'),
    '观察学习': require('@/assets/pdf/guanchaxuexi@3x.png'),
    '互动游戏': require('@/assets/pdf/hudongyouxi@3x.png'),
    '假想游戏': require('@/assets/pdf/jiaxiangyouxi@3x.png'),
    '居家安全': require('@/assets/pdf/jujiaanquan@3x.png'),
    '具象概念': require('@/assets/pdf/juxianggainian@3x.png'),
    '逻辑思维': require('@/assets/pdf/luojisiwei@3x.png'),
    '评述性语言': require('@/assets/pdf/pingshuxingyuyan@3x.png'),
    '强化物管理': require('@/assets/pdf/qianghuawuguanli@3x.png'),
    '情绪表达': require('@/assets/pdf/qingxubiaoda@3x.png'),
    '情绪管理': require('@/assets/pdf/qingxuguanli@3x.png'),
    '情绪理解': require('@/assets/pdf/qingxulijie@3x.png'),
    '日常收纳': require('@/assets/pdf/richangshouna@3x.png'),
    '社交发起': require('@/assets/pdf/shejiaofaqi@3x.png'),
    '社交规则': require('@/assets/pdf/shejiaoguize@3x.png'),
    '社交回应': require('@/assets/pdf/shejiaohuiying@3x.png'),
    '时间': require('@/assets/pdf/shijian@3x.png'),
    '书写': require('@/assets/pdf/shuxie@3x.png'),
    '数学': require('@/assets/pdf/shuxue@3x.png'),
    '外出安全': require('@/assets/pdf/waichuanquan@3x.png'),
    '心理理论': require('@/assets/pdf/xinlililun@3x.png'),
    '阅读': require('@/assets/pdf/yuedu@3x.png'),
    '运动类游戏': require('@/assets/pdf/yundongleiyouxi@3x.png'),
    '指令控制': require('@/assets/pdf/zhilingkongzhi@3x.png'),

}

const LINEAR_TITLT_MAP = {
    'ANALYSE': {
        url: require('@/assets/pdf/analysis@2x.png'),
        width: 342
    },
    'INFORMATION': {
        url: require('@/assets/pdf/information@2x.png'),
        width: 445
        
    },
    'INTRODUCE': {
        url: require('@/assets/pdf/introduction@2x.png'),
        width: 521
    } 
}


export { COLORS_MAP,CHILD_FIELD_MAP, LINEAR_TITLT_MAP }