<template>
  <pdf-page>
    <template #extra>
      <img src="~@/assets/report/quarter/time@2x.png" class="extra" alt="" />
    </template>
    <div class="main">
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">从{{ dataSource.startDate | moment('YYYY年') }}</div>
        <div class="text-col large">{{ dataSource.startDate | moment('MM月DD日') }}</div>
        <div class="text-col">——</div>
        <div class="text-col">{{ dataSource.endDate | moment('YYYY年') }}</div>
        <div class="text-col large">{{ dataSource.endDate | moment('MM月DD日') }}</div>
      </div>
      <div class="text-row" style="margin-bottom: 21px">
        <div class="text-col">小朋友经历了{{ dataSource.teachingDay }}个教学日</div>
      </div>
      <div class="text-row" style="margin-bottom: 33px">
        <div class="text-col">学习目标横跨</div>
        <div class="text-col large">{{ dataSource.fieldNum }}</div>
        <div class="text-col">个领域，</div>
        <div class="text-col large">{{ dataSource.subFieldNum }}</div>
        <div class="text-col">个子领域</div>
      </div>
      <div class="text-row">
        <div class="text-col">学习长期目标</div>
        <div class="text-col large highlight">{{ dataSource.longTermGoalsNum }}</div>
        <div class="text-col">个</div>
      </div>
      <div class="text-row">
        <div class="text-col">含括短期目标</div>
        <div class="text-col large highlight">{{ dataSource.shortTermGoalsNum }}</div>
        <div class="text-col">个</div>
      </div>
    </div>
  </pdf-page>
</template>

<script>
import PdfPage from './PdfPage'
export default {
  components: {
    PdfPage,
  },
  props: {
    dataSource: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="less" scoped>
.main {
  text-align: center;
  padding-top: 52px;
  line-height: 50px;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 36px;
}
.text {
  &-row {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &-col:not(.large) {
    margin-bottom: 5px;
  }
}
.large {
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 1px;
}
.highlight {
  color: #ff7a55;
}
.extra {
  position: absolute;
  width: 910px;
  bottom: 228px;
  left: 50%;
  transform: translateX(-50%);
}
</style>