<template>
  <a-card :bodyStyle="{ padding: '0 24px 24px' }">
    <div :slot="currentComponent !== 'MainCalendar' ? 'extra' : ''">
      <a-button shape="round" @click="goback"><a-icon type="left" />返回</a-button>
    </div>
    <MainCalendar
      @changecomponent="changeComponent($event)"
      v-show="currentComponent === 'MainCalendar'"
    ></MainCalendar>
    <AddHomeWork v-show="currentComponent === 'AddHomeWork'" @changecomponent="changeComponent($event)"></AddHomeWork>
    <ChangeHomework
      :temp-data="tempData"
      :all-data="allData"
      v-show="currentComponent === 'ChangeHomework'"
      @changecomponent="changeComponent($event)"
    ></ChangeHomework>
  </a-card>
</template>

<script>
import MainCalendar from './components/Calendar'
import AddHomeWork from './components/AddHomeWork'
import ChangeHomework from './components/ChangeHomework'
import * as homeworkApi from '@/api/homework'

export default {
  components: {
    MainCalendar,
    AddHomeWork,
    ChangeHomework
  },
  data() {
    return {
      tempData: {},
      allData: {},
      currentComponent: 'MainCalendar' // 当前展示的页面
    }
  },
  methods: {
    changeComponent(data) {
      this.tempData = data
      this.currentComponent = data.type
      if (data.type === 'ChangeHomework') {
        homeworkApi
          .gethomework({ studentId: data.studentId, workTimestamp: data.workTimestamp, pageIndex: 1, pageSize: 2 })
          .then(res => {
            if (res.code === 0) {
              this.allData = res.data
            }
          })
      }
    },
    goback() {
      this.currentComponent = 'MainCalendar'
    }
  }
}
</script>

<style lang="less" scoped></style>
