import request from '@/utils/request'

const evaluationReportApi = {
  check: id => `/evaluations/report/create/student/${id}`,
  init: id => `/evaluations/report/create/student/${id}`,
  list: id => `/evaluations/report/student/${id}`,
  update: () => `/evaluations/report/update`,
  detail: id => `/evaluations/report/evaluation-report/${id}`,
  isComplete: id => `/evaluations/report/evaluation-report/${id}/skill-interpret/is-complete`,
  publish: id => `/evaluations/report/publish/evaluation-report/${id}`,
  remove: id => `/evaluations/report/delete/evaluation-report/${id}`,
}

export function check(parameter) {
  return request({
    url: evaluationReportApi.check(parameter),
    method: 'get'
  })
}

export function init(parameter) {
  return request({
    url: evaluationReportApi.init(parameter),
    method: 'post'
  })
}

export function list(parameter) {
  const { studentId } = parameter
  delete parameter.studentId
  return request({
    url: evaluationReportApi.list(studentId),
    method: 'get',
    params: parameter
  })
}

export function detail(parameter) {
  return request({
    url: evaluationReportApi.detail(parameter),
    method: 'get'
  })
}

export function update(parameter) {
  return request({
    url: evaluationReportApi.update(),
    method: 'post',
    data: parameter
  })
}

export function isComplete(parameter) {
  return request({
    url: evaluationReportApi.isComplete(parameter),
    method: 'get',
  })
}

export function publish(parameter) {
  return request({
    url: evaluationReportApi.publish(parameter),
    method: 'post',
  })
}

export function remove(parameter) {
  return request({
    url: evaluationReportApi.remove(parameter),
    method: 'put',
  })
}
