<template>
  <div>
    <v-chart :forceFit="true" :height="height" :data="dataSource" :scale="scale" :padding="padding">
      <v-tooltip crosshairs="y" :htmlContent="htmlContent" />
      <v-legend
        :custom="legendCustom"
        :textStyle="{ fontSize: '14' }"
        :allow-all-canceled="legendAllowAllCanceled"
        :items="legendItems"
        :on-click="legendOnClick"
      />
      <v-axis data-key="incremental" :title="title" />
      <v-axis data-key="total" :grid="axisGrid" :label="axisLabel" :title="title2" />
      <v-bar position="countDate*incremental" />
      <v-line position="countDate*total" color="rgb(47, 194, 91)" :size="gemoSize" />
      <!-- <v-point shape="circle" position="countDate*total" color="rgb(47, 194, 91)" :size="gemoSize" /> -->
    </v-chart>
  </div>
</template>

<script>
const scale = [
  {
    dataKey: 'countDate',
    type: 'timeCat',
    mask: 'MM-DD'
  },
  {
    dataKey: 'total',
    min: 0
  },
  {
    dataKey: 'incremental',
    min: 0
  }
]

export default {
  props: {
    dataSource: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      scale,
      padding: [48, 96, 96, 96],
      height: 480,

      axisLabel: {
        textStyle: {
          //   fill: 'rgb(47, 194, 91)'
        }
      },
      axisGrid: null,
      title: {
        autoRotate: false,
        position: 'end',
        textStyle: {
          fill: '#000000'
        },
        text: '增量'
      },
      title2: {
        autoRotate: false,
        position: 'end',
        textStyle: {
          fill: '#000000'
        },
        text: '总量'
      },

      gemoSize: 2,

      legendCustom: true,
      legendAllowAllCanceled: true,
      legendItems: [
        { value: '增量', marker: { symbol: 'square', fill: '#3182bd', radius: 5 } },
        {
          value: '总量',
          marker: {
            symbol: 'hyphen',
            stroke: 'rgb(47, 194, 91)',
            radius: 5,
            lineWidth: 3
          }
        }
      ],
      legendOnClick: (ev, chart) => {
        const item = ev.item
        const value = item.value
        const checked = ev.checked
        const geoms = chart.getAllGeoms()
        for (let i = 0; i < geoms.length; i++) {
          const geom = geoms[i]
          if (geom.getYScale().field === value) {
            if (checked) {
              geom.show()
            } else {
              geom.hide()
            }
          }
        }
      },

      htmlContent: (title, items) => {
        var html = '<div class="g2-tooltip">'
        var titleDom = '<div class="g2-tooltip-title" style="margin-bottom: 4px;">' + title + '</div>'
        var listDom = '<ul class="g2-tooltip-list">'
        for (var i = 0; i < items.length; i++) {
          var item = items[i]

          var itemDom =
            '<li data-index={index}>' +
            '<div>' +
            '<span style="background-color:' +
            item.color +
            ';width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>' +
            { incremental: '增量', total: '总量' }[item.name] +
            '<span class="g2-tooltip-value">' +
            item.value +
            '</span>' +
            '</div>' +
            '</li>'
          listDom += itemDom
        }
        listDom += '</ul>'

        return html + titleDom + listDom + '</div>'
      }
    }
  },
  mounted() {
    // this.setStyle()
  },
  methods: {
    setStyle() {
      const id = 'legend-html'
      if (document.getElementById(id)) {
        return
      }
      const styleTxt = `
          .g2-tooltip {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 3px;
            color: rgb(87, 87, 87);
            font-size: 12px;
            line-height: 20px;
            padding: 10px 10px 6px 10px;
            box-shadow: 0px 0px 10px #aeaeae;
            pointer-events: none;
          }

          .g2-tooltip-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
          }
          .g2-tooltip-value {
            margin-left: 30px;
            display: inline;
            float: right;
          }
          .g2-tooltip-tail {
            background-color: #f9f9f9;
            color: #909090;
            font-size: 14px;
            padding-bottom: 10px;
            margin-top: 10px;
            list-style-type: none;
            text-align: center;
            padding-top: 10px;
          }
          .g2-tooltip-story {
            color: #9b9b9b;
            font-size: 12px;
            padding-bottom: 10px;
            margin-top: 10px;
            list-style-type: none;
            padding-top: 10px;
          }
      `
      const style = document.createElement('style')
      style.setAttribute('id', id)
      style.innerHTML = styleTxt
      document.getElementsByTagName('head')[0].appendChild(style)
    }
  }
}
</script>
