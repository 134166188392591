<template>
  <div>
    <div
      v-if="mainFieldList.length === 0"
      style="
        margin-top: 50px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <a-spin v-if="listLoading" />
      <a-empty v-else description="还没有完成评估哦"></a-empty>
    </div>

    <a-row
      v-else
      v-for="(mainField, mainFieldIndex) in mainFieldList"
      :key="mainFieldIndex"
      type="flex"
      class="hairline--right hairline--bottom"
      :class="{ 'hairline--bottom': mainFieldIndex === mainFieldList.length - 1 }"
      style="flex-wrap: nowrap; font-size: 12px; font-weight: 500; margin-bottom: 14px"
    >
      <a-col style="width: 26px; min-width: 26px; display: flex; flex-direction: column">
        <div v-if="mainFieldIndex === 0" class="hairline--top hairline--left" style="height: 48px"></div>
        <div
          v-if="mainField.fieldName === '习惯常规'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(249, 105, 86, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '观察模仿'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(255, 145, 91, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '概念逻辑'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(83, 230, 240, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '社交沟通'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(255, 194, 84, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '生活自理'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(81, 102, 216, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '游戏互动'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(255, 168, 151, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '情绪心理'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(109, 147, 255, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '学业技能'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(133, 83, 188, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
        <div
          v-if="mainField.fieldName === '生命安全'"
          class="align-center hairline--top hairline--left"
          style="
            flex: 1;
            padding: 10px;
            background: rgba(180, 219, 102, 0.9);
            color: #ffffff;
            line-height: 20px;
            font-size: 18px;
          "
        >
          {{ mainField.fieldName }}
        </div>
      </a-col>
      <a-col v-for="level in levelMax" :key="level" class="hairline--top">
        <div v-if="mainFieldIndex === 0" style="line-height: 48px; display: flex">
          <div :style="level === 1 ? 'width:32px' : ''"></div>
          <div
            v-if="level === 1"
            class="hairline--top hairline--left"
            style="text-align: center; flex: 1; background: #acdc7a; color: #ffffff"
          >
            <span style="display: inline-block; transform: scale(0.8)">
              {{ `LEVEL${level}` }}
            </span>
          </div>
          <div
            v-else-if="level === 2"
            class="hairline--top hairline--left"
            style="text-align: center; flex: 1; background: #7abfdc; color: #ffffff"
          >
            <span style="display: inline-block; transform: scale(0.8)">
              {{ `LEVEL${level}` }}
            </span>
          </div>
          <div
            v-else-if="level === 3"
            class="hairline--top hairline--left"
            style="text-align: center; flex: 1; background: #7a91dc; color: #ffffff"
          >
            <span style="display: inline-block; transform: scale(0.8)">
              {{ `LEVEL${level}` }}
            </span>
          </div>
          <div v-if="level < levelMax" class="hairline--left" style="width: 4px"></div>
        </div>
        <a-row
          v-for="(field, fieldIndex) in mainField.children || 1"
          :key="fieldIndex"
          type="flex"
          style="flex-wrap: nowrap"
        >
          <a-col
            v-if="level === 1"
            class="align-center hairline--left"
            :class="[mainFieldIndex === 0 || fieldIndex !== 0 ? 'hairline--top' : '']"
            style="width: 32px; padding: 8px; background: #ffffff"
          >
            {{ field.fieldName || '-' }}
          </a-col>

          <a-col v-for="subjectIndex in getSubjectMax(level)" :key="subjectIndex" style="width: 24px">
            <div
              style="font-size: 10px"
              :class="[
                'questionCode',
                mainFieldIndex === 0 || fieldIndex !== 0 ? 'hairline--top' : '',
                questionCode(field, level - 1, subjectIndex - 1) ? 'hbkx-pointer questionCodeBg' : 'questionCodeNoBg',
                {
                  'hairline--left': isHairlineleft(
                    questionCode(field, level - 1, subjectIndex - 2),
                    questionCode(field, level - 1, subjectIndex - 1),
                    subjectIndex,
                    getSubjectMax(level)
                  ),
                },
              ]"
              @click="handleQuestionItem(field, level - 1, subjectIndex - 1)"
            >
              {{ questionCode(field, level - 1, subjectIndex - 1) }}
              <span v-if="!questionCode(field, level - 1, subjectIndex - 1)" style="opacity: 0">-</span>
            </div>
            <div
              v-for="optionIndex in optionMax"
              :key="optionIndex"
              :class="[
                `${renderOption(field, level - 1, subjectIndex - 1, optionIndex - 1)}`,
                `${renderOptionStandard(field, level - 1, subjectIndex - 1, optionIndex - 1)}`,
                { 'hairline--top': questionCode(field, level - 1, subjectIndex - 1) },
                {
                  'hairline--left': isHairlineleft(
                    questionCode(field, level - 1, subjectIndex - 2),
                    questionCode(field, level - 1, subjectIndex - 1),
                    subjectIndex,
                    getSubjectMax(level)
                  ),
                },
              ]"
              style="height: 24px; line-height: 24px"
            >
              <!--<h-icon class="empty-color" type="icon-empty"></h-icon>-->
              <a-icon
                class="standard-color"
                type="check-square"
                :style="{ fontSize: '16px', opacity: '0.6' }"
                title="当前选项关联的阶段已达标"
              ></a-icon>
            </div>
          </a-col>
          <a-col v-if="level < levelMax" class="hairline--left" style="width: 4px"></a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as evaluationApi from '@/api/evaluation'
import { cloneDeep } from 'lodash'
export default {
  props: {
    historyId: {
      type: Number,
      default: 0
    },
    isReport: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      listLoading: false,
      mainFieldList: [],
      levelMax: 0,
      // subjectMax: 0,
      optionMax: 0,
      maxLevel1: 0,
      maxLevel2: 0,
      maxLevel3: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 评估报告
      if (this.isReport) {
        this.initDataReport()
      } else {
        // 评估历史
        this.initData()
      }
    })
  },
  methods: {
    getSubjectMax(level) {
      let temp = 0
      if (level === 1) temp = this.maxLevel1
      if (level === 2) temp = this.maxLevel2
      if (level === 3) temp = this.maxLevel3
      return temp
    },
    // 判断请求接口
    judgeRequest() {
      // 是否 评估历史
      const requestType = !!sessionStorage.getItem('homeSchoolIsShowTabs')
      const {
        $route: {
          params: { id }
        }
      } = this

      return requestType ? evaluationApi.historyDetail(this.historyId) : evaluationApi.detailNew(id)
    },

    // 点击题目code
    handleQuestionItem(field, levelIndex, subjectIndex) {
      this.$emit('detail', { field, levelIndex, subjectIndex })
    },
    // 题目code
    questionCode(field, levelIndex, subjectIndex) {
      const { levelList } = field
      if (
        !levelList ||
        !levelList[levelIndex] ||
        !levelList[levelIndex].subjectList ||
        !levelList[levelIndex].subjectList[subjectIndex]
      ) {
        return ''
      } else {
        // 最多显示3位code
        const code = (levelList[levelIndex].subjectList[subjectIndex].code + '').substr(8)
        const codeLengthLimit = 3
        return (Array(codeLengthLimit).join(0) + code).slice(-codeLengthLimit)
      }
    },

    // 渲染4种涂色
    renderOption(field, levelIndex, subjectIndex, optionIndex) {
      const { levelList } = field
      if (
        !levelList ||
        !levelList[levelIndex] ||
        !levelList[levelIndex].subjectList ||
        !levelList[levelIndex].subjectList[subjectIndex]
      ) {
        return 'empty'
      } else {
        const { optionList } = levelList[levelIndex].subjectList[subjectIndex]

        // 不存在
        if (!optionList) {
          return 'empty'
        }
        if (optionList.length === 0) {
          return ''
        }

        // 总分
        const totalScore = optionList.reduce((prev, curr) => prev + Number(curr.score), 0)

        // 最后一回合(最大)选项
        const maxEvaluationNumber = cloneDeep(optionList).sort((a, b) => {
          return b.evaluationNumber - a.evaluationNumber
        })[0]

        // 最后一回合,得0分
        if (
          maxEvaluationNumber.optionNumber === maxEvaluationNumber.score &&
          maxEvaluationNumber.optionNumber === 0 &&
          maxEvaluationNumber.score === 0
        ) {
          return ''
        }

        // 全部是0分
        if (totalScore === 0) {
          return ''
        }
        // 大于0分
        if (totalScore > 0) {
          // 取有分数的集合
          const optionScoreList = optionList.filter(item => {
            return item.score > 0
          })
          const { score, evaluationNumber } = optionScoreList[0]
          if (score === 4) {
            return `active${evaluationNumber}`
          }

          if (score === 2) {
            // 选项-从大到小重新排序
            const tempList = cloneDeep(optionList).sort((a, b) => {
              return b.optionNumber - a.optionNumber
            })
            const temp = [0, 0, 0, 0]
            for (const item of tempList) {
              const { optionNumber, evaluationNumber, score } = item
              if (optionNumber === 2) {
                temp[0] = {
                  evaluationNumber: score === 2 ? evaluationNumber : ''
                }
                temp[1] = {
                  evaluationNumber: score === 2 ? evaluationNumber : ''
                }
              }
              if (optionNumber === 1) {
                temp[2] = {
                  evaluationNumber: score === 2 ? evaluationNumber : ''
                }
                temp[3] = {
                  evaluationNumber: score === 2 ? evaluationNumber : ''
                }
              }
            }
            const type = temp[optionIndex].evaluationNumber
            return type ? `active${type}` : ''
          }

          // 当前格的选项
          const optionCell = optionList.filter(item => {
            return item.optionNumber === 4 - optionIndex
          })

          if (score === 1 && optionCell.length) {
            const { evaluationNumber } = optionCell[0]
            return `active${evaluationNumber}`
          }
        }
      }
    },
    // 达标小对勾
    renderOptionStandard(field, levelIndex, subjectIndex, optionIndex) {
      const { levelList } = field
      if (
        !levelList ||
        !levelList[levelIndex] ||
        !levelList[levelIndex].subjectList ||
        !levelList[levelIndex].subjectList[subjectIndex]
      ) {
        return 'empty'
      } else {
        const { fulfilledOptionList } = levelList[levelIndex].subjectList[subjectIndex]
        if (!fulfilledOptionList) {
          return 'empty'
        }
        if (fulfilledOptionList.length === 0) {
          return ''
        }
        const { score } = fulfilledOptionList[0]
        if (!score) {
          return ''
        }
        if (score === 4) {
          return 'standard'
        }
        if (score === 2) {
          if (fulfilledOptionList.length === 2) {
            return 'standard'
          }
          const { optionNumber } = fulfilledOptionList[0]
          let numbers = []
          if (optionNumber === 1) {
            numbers = [2, 3]
          } else if (optionNumber === 2) {
            numbers = [0, 1]
          }
          if (numbers.includes(optionIndex)) {
            return 'standard'
          }
          // 保留
          // if (optionIndex < serialNumber * 2 && optionIndex > (serialNumber - 1) * 2) {
          //   return 'active'
          // }
        }
        if (score === 1) {
          // 保留
          // const numbers = optionList.map(v => v.serialNumber - 1)
          const numbers = []
          fulfilledOptionList.forEach(v => {
            if (v.optionNumber === 1) {
              numbers.push(3)
            } else if (v.optionNumber === 2) {
              numbers.push(2)
            } else if (v.optionNumber === 3) {
              numbers.push(1)
            } else if (v.optionNumber === 4) {
              numbers.push(0)
            }
          })
          if (numbers.includes(optionIndex)) {
            return 'standard'
          }
        }
      }
    },
    // 题目左侧的竖线
    isHairlineleft(preQuesId, quesId, index, quesMaxNumber) {
      // 第一个 或者 有题目id
      if (index === 1 || quesId) {
        return true
      } else {
        if (!quesId && preQuesId) {
          return true
        }

        if (!quesId && !preQuesId) {
          return false
        }
      }
    },
    // 初始化-评估结果、评估历史
    initData() {
      this.listLoading = true
      this.judgeRequest()
        .then(res => {
          this.init(res.data)
        })
        .finally(() => (this.listLoading = false))
    },

    // 初始化-评估结果、评估历史
    initDataReport() {
      const { data } = this
      this.maxLevel1 = 0
      this.maxLevel2 = 0
      this.maxLevel3 = 0
      this.init(data)
    },
    init(data) {
      const {
        evaluationFieldVOList: mainFieldList,
        levelTotal: levelMax,
        // levelQuestionCountMax: levelSubjectMax,
        optionTotal: optionMax,
        evaluationFieldSerialNumberVOList // 图例
      } = data

      if (!mainFieldList) return
      mainFieldList.forEach(v => v.children.length === 0 && v.children.push({}))
      mainFieldList.map(item => {
        item.children &&
          item.children.length &&
          item.children.map(item1 => {
            item1.levelList &&
              item1.levelList.length &&
              item1.levelList.map(item2 => {
                if (item2.level === 'LEVEL1') {
                  this.maxLevel1 = item2.subjectList.length > this.maxLevel1 ? item2.subjectList.length : this.maxLevel1
                  if (this.isReport && this.maxLevel1 === 0) {
                    this.maxLevel1 = 2
                  }
                }
                if (item2.level === 'LEVEL2') {
                  this.maxLevel2 = item2.subjectList.length > this.maxLevel2 ? item2.subjectList.length : this.maxLevel2
                  if (this.isReport && this.maxLevel2 === 0) {
                    this.maxLevel2 = 2
                  }
                }
                if (item2.level === 'LEVEL3') {
                  this.maxLevel3 = item2.subjectList.length > this.maxLevel3 ? item2.subjectList.length : this.maxLevel3
                  if (this.isReport && this.maxLevel3 === 0) {
                    this.maxLevel3 = 2
                  }
                }
              })
          })
      })
      // console.log(mainFieldList)
      this.mainFieldList = mainFieldList
      this.levelMax = levelMax
      // this.subjectMax = levelSubjectMax
      this.optionMax = optionMax
      // this.$emit('enable')
      // 图例数据
      this.$emit('example', { evaluationFieldSerialNumberVOList })
    }
  }
}
</script>

<style lang="less" scoped>
@import './hairline.less';

.question-code {
  opacity: 0;
}
.hbkx-pointer {
  cursor: pointer;
}
.hbkx-pointer:hover {
  background-color: #3a5ff8 !important;
  color: #fff;
}

/deep/ .ant-row,
/deep/ .ant-col,
div {
  box-sizing: border-box !important;
  text-align: center;
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}
.questionCode {
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}
.questionCodeBg {
  background: #fbfbfb;
}

.questionCodeNoBg {
  background: #fff;
}

.active1 {
  background: #fffa75;
}
.active2 {
  background: #ffb475;
}
.active3 {
  background: #ff7575;
}
.active4 {
  background: #da75ff;
}
.empty .empty-color,
.standard .standard-color {
  display: inline-block;
  position: relative;
  top: 2px;
}
.empty-color {
  display: none;
  color: @primary-color-2;
}

.standard-color {
  display: none;
  color: @success-color;
}
</style>
