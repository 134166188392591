<template>
  <a-form-model ref="form" v-bind="$formItemLayout" style="padding-top: 30px">
    <a-form-model-item label="作业日期">
      <span>{{ tempData.workTimestamp }}</span>
    </a-form-model-item>
    <a-form-model-item label="当前作业">
      <a-card
        style="margin: 10px 0"
        :body-style="{ padding: 0 }"
        v-for="(skillInfo, index) in homeworkList"
        :key="index"
        :title="skillInfo.skillName"
      >
        <!-- 编辑删除菜单 -->
        <p slot="extra">
          <a-button type="primary" size="small" @click="editHomework(index)" style="margin-right: 20px">
            编辑
          </a-button>
          <a-popconfirm
            title="你确定要删除这个技能包吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteHomework(index)"
          >
            <a-button type="danger" size="small"> 删除 </a-button>
          </a-popconfirm>
        </p>
        <a-row type="flex" align="middle" class="comp-bg" style="flex-wrap: nowrap; padding: 12px">
          <a-col flex="0 1 80px" style="margin-right: 24px">
            <a-avatar :size="80" shape="square" :src="skillInfo.field.url ? skillInfo.field.url : ''"></a-avatar>
          </a-col>

          <a-col flex="0 1 100%" style="line-height: 1.5">
            <a-row type="flex" align="middle" style="flex-wrap: nowrap; margin-top: 16px; font-size: 12px">
              <a-col class="desc">
                所属分类:<span style="margin-left: 8px"
                  >{{ skillInfo.field.name ? skillInfo.field.parent.name : '-' }} >>
                  {{ skillInfo.field.name ? skillInfo.field.name : '-' }}</span
                >
              </a-col>
            </a-row>

            <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
              <a-col flex="1" class="desc">
                技能目标:<span style="margin-left: 8px">{{ skillInfo.goal || '-' }}</span>
              </a-col>
            </a-row>
            <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
              <a-col flex="1" class="desc">
                阶段名称:<span style="margin-left: 8px">{{ skillInfo.phaseName }}</span>
              </a-col>
            </a-row>
            <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
              <a-col flex="1" class="desc">
                最低训练次数:<span style="margin-left: 8px">{{ skillInfo.minimumRounds }} 次</span>
              </a-col>
            </a-row>
            <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
              <a-col flex="1" class="desc">
                素材:<span
                  style="margin-left: 8px"
                  v-for="(item, matindex) in skillInfo.materialList"
                  :key="matindex"
                  >{{ matindex === parseInt(skillInfo.materialList.length) - 1 ? item : `${item}，` }}</span
                >
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
      <span v-show="this.homeworkList.length === 0">当前日期没有作业</span>
      <a-pagination
        v-show="this.homeworkList.length > 0"
        style="display: flex; justify-content: flex-end; margin: 10px"
        v-model="pagination.pageIndex"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        size="small"
        show-less-items
        @change="pageIndexChange"
      ></a-pagination>
    </a-form-model-item>
    <a-form-model-item
      label="练习技能"
      prop="skillList"
      :rules="{
        required: true,
        message: '选择技能',
        trigger: 'change',
      }"
    >
      <!-- 选择技能包按钮 -->
      <a-button shape="round" @click="showSkillModal"> <a-icon type="thunderbolt" theme="filled" />选择技能 </a-button>
      <!-- 选择的技能包进行展示 -->
      <a-row v-if="skillList.length > 0" style="margin-top: 10px" :gutter="[16, 16]">
        <a-col v-for="(skillInfo, index) in skillList" :key="index" :span="24">
          <a-card :body-style="{ padding: 0 }" :title="skillInfo.skill.name ? skillInfo.skill.name : ''">
            <!-- 编辑删除菜单 -->
            <p slot="extra">
              <a-button type="primary" size="small" @click="editSkill(index)" style="margin-right: 20px">
                编辑
              </a-button>
              <a-popconfirm
                title="你确定要删除这个技能包吗?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="deleteSkill(index)"
              >
                <a-button type="danger" size="small"> 删除 </a-button>
              </a-popconfirm>
            </p>
            <a-row type="flex" align="middle" class="comp-bg" style="flex-wrap: nowrap; padding: 12px">
              <a-col flex="0 1 80px" style="margin-right: 24px">
                <a-avatar :size="80" shape="square" :src="skillInfo.skill ? skillInfo.skill.coverUrl : ''"></a-avatar>
              </a-col>

              <a-col flex="0 1 100%" style="line-height: 1.5">
                <a-row type="flex" align="middle" style="flex-wrap: nowrap; margin-top: 16px; font-size: 12px">
                  <a-col class="desc">
                    所属分类:<span style="margin-left: 8px"
                      >{{ skillInfo.skill ? skillInfo.skill.field.parent.name : '-' }} >>
                      {{ skillInfo.skill ? skillInfo.skill.field.name : '-' }}</span
                    >
                  </a-col>
                </a-row>

                <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                  <a-col flex="1" class="desc">
                    技能目标:<span style="margin-left: 8px">{{ skillInfo.skill ? skillInfo.skill.goal : '-' }}</span>
                  </a-col>
                </a-row>
                <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                  <a-col flex="1" class="desc">
                    阶段名称:<span style="margin-left: 8px">{{ skillInfo.planPhase.name }}</span>
                  </a-col>
                </a-row>
                <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                  <a-col flex="1" class="desc">
                    最低训练次数:<span style="margin-left: 8px">{{ skillInfo.planPhase.minimumRounds }} 次</span>
                  </a-col>
                </a-row>
                <a-row type="flex" align="middle" style="margin-top: 8px; font-size: 12px">
                  <a-col flex="1" class="desc">
                    素材:<span
                      style="margin-left: 8px"
                      v-for="(item, matindex) in skillInfo.form.materialList"
                      :key="matindex"
                      >{{ item }}</span
                    >
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <!-- 设置技能包的信息 -->
      <skill-base-select ref="skillBaseselect" @submit="handleSkillBase"></skill-base-select>
    </a-form-model-item>
    <!-- 添加所有已选择的技能包,最后弹窗展示 -->
    <a-form-model-item label="提交">
      <a-button shape="round" type="primary" size="large" style="width: 50%" @click="showAllselected">提交</a-button>
    </a-form-model-item>
    <allselect-modal ref="allselectModal" @submit="addhomeworkDone"></allselect-modal>
    <!-- 选择技能包弹窗 -->
    <skill-modal ref="skillModal" @submit="handleSkillChange"></skill-modal>
    <!-- 再次编辑技能包的信息 -->
    <edit-skill-modal ref="editSkillmodal" @submit="handleEditSkillModal"></edit-skill-modal>
    <!-- 编辑作业 -->
    <edit-homework ref="EditHomework" @editsubmit="handleEditHomework"></edit-homework>
  </a-form-model>
</template>

<script>
import SkillModal from './SkillModal'
import SkillBaseSelect from './SkillBaseSelect'
import EditSkillModal from './EditSkillModal'
import AllselectModal from './AllselectModal'
import EditHomework from './EditHomework'
import * as homeworkApi from '@/api/homework'

export default {
  props: {
    tempData: {
      type: Object,
      default() {
        return {}
      }
    },
    allData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    SkillModal,
    SkillBaseSelect,
    AllselectModal,
    EditSkillModal,
    EditHomework
  },
  data() {
    return {
      skillList: [],
      pagination: {
        pageIndex: 1,
        pageSize: 2,
        total: 0
      },
      homeworkList: []
    }
  },
  methods: {
    // 获取当日作业
    pageIndexChange() {
      const {
        tempData: { studentId, workTimestamp },
        pagination: { pageIndex, pageSize }
      } = this
      homeworkApi.gethomework({ studentId, workTimestamp, pageIndex, pageSize }).then(res => {
        const {
          code,
          data: { list, totalItems }
        } = res
        if (code === 0) {
          this.homeworkList = list
          this.pagination.total = totalItems
        }
      })
    },
    // 编辑作业
    editHomework(index) {
      const skill = this.homeworkList[index]
      this.$refs.EditHomework.showModal(skill)
    },
    handleEditHomework(skill) {
      this.pageIndexChange()
    },
    // 删除作业
    deleteHomework(index) {
      const id = this.homeworkList[index].id
      homeworkApi.delhomework({ id }).then(res => {
        if (res.code === 0) {
          this.$notification.success({
            message: '完成',
            description: '删除作业成功'
          })
          this.pagination.pageIndex = 1
          this.pageIndexChange()
          // this.homeworkList.splice(index, 1)
        }
      })
    },
    // 选择技能包弹窗
    showSkillModal() {
      const query = {}
      query.workTimestamp = this.tempData.workTimestamp
      this.$refs.skillModal.showModal(query)
    },
    // 选择技能包后的回调
    handleSkillChange(skill) {
      const { skillList } = this

      if (skill) {
        if (!skillList.some(s => s.id === skill.id)) {
          this.$refs.skillBaseselect.showBase(skill)
        } else {
          this.$notification.error({
            message: '提示',
            description: '请不要重复选择技能包'
          })
        }
      }
    },
    // 设置技能包的基本信息后的回调
    handleSkillBase(skill) {
      const { skillList } = this

      if (skill) {
        if (skillList.some(s => s.id === skill.id)) {
          const deleteindex = skillList.findIndex(item => {
            return (item.id = skill.id)
          })
          skillList.splice(deleteindex, skill)
          this.skillList = skillList
        } else {
          this.skillList.push(skill)
        }
      }
    },
    // 再次编辑技能包
    editSkill(index) {
      const skill = this.skillList[index]
      this.$refs.editSkillmodal.showModal(skill)
    },
    // 删除技能包
    deleteSkill(index) {
      this.skillList.splice(index, 1)
    },
    handleEditSkillModal(skill) {
      const { skillList } = this

      if (skill) {
        if (skillList.some(s => s.id === skill.id)) {
          // eslint-disable-next-line no-return-assign
          const deleteindex = skillList.findIndex(item => {
            return (item.id = skill.id)
          })
          skillList.splice(deleteindex, skill)
          this.skillList = skillList
        }
      }
    },
    // 提交作业
    showAllselected() {
      const { skillList, tempData } = this
      const studentId = tempData.studentId
      const startDate = tempData.workTimestamp
      const endDate = tempData.workTimestamp
      const homeworkSkillList = skillList.map(item => {
        return item.form
      })
      if (skillList.length !== 0) {
        this.$refs.allselectModal.showModal(skillList, { studentId, startDate, endDate, homeworkSkillList })
      } else {
        this.$notification.error({
          message: '提示',
          description: '请添加作业'
        })
      }
    },
    // 提交作业完成
    addhomeworkDone() {
      this.$emit('changecomponent', { type: 'MainCalendar' })
    }
  },
  watch: {
    allData: {
      handler(newval) {
        this.pagination.total = newval.totalItems
        this.homeworkList = newval.list
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped></style>
