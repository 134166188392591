<template>
  <div :class="{ 'pdf-page-container': true, repeat }" :style="{ backgroundImage: 'url(' + bgUrl + ')' }">
    <slot name="extra"></slot>
    <img class="pdf-page-footer" src="~@/assets/report/report/pdf_logo.png" alt="" />
    <div class="pdf-page-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Common from '@/assets/report/quarter/background@2x.png'
import Cover from '@/assets/report/quarter/cover@2x.png'
export default {
  props: {
    repeat: {
      type: Boolean,
      default: () => false
    },
    bg: {
      type: String,
      default: () => 'common'
    }
  },
  data() {
    return {
      common: Common,
      cover: Cover
    }
  },
  computed: {
    bgUrl() {
      return this[this.bg]
    }
  }
}
</script>

<style lang="less" scoped>
.pdf {
  &-page {
    &-container {
      width: 1080px;
      height: 1535px;
      position: relative;
      background-position: center center;
      background-size: 1080px 1535px;
      background-repeat: no-repeat;
      &.repeat {
        height: auto;
        min-height: 1535px;
        background-repeat: repeat;
      }
    }
    &-main {
      z-index: 2;
      padding-top: 100px;
      padding-left: 70px;
      padding-right: 70px;
      padding-bottom: 158px;
      color: #3350c7;
    }
    &-footer {
      width: 229px;
      position: absolute;
      bottom: 56px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>