import request from '@/utils/request'

const evaluationApi = {
  detail: id => `/evaluations/v2/${id}`,
  detailNew: id => `/evaluations/students/${id}`,
  historyDetail: evaluationId => `/evaluations/${evaluationId}`
}

export function historyDetail(parameter) {
  return request({
    url: evaluationApi.historyDetail(parameter),
    method: 'get'
  })
}

export function detailNew(parameter) {
  return request({
    url: evaluationApi.detailNew(parameter),
    method: 'get'
  })
}

export function detail(parameter) {
  return request({
    url: evaluationApi.detail(parameter),
    method: 'get'
  })
}
