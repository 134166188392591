import { render, staticRenderFns } from "./PdfPage.vue?vue&type=template&id=880f3222&scoped=true"
import script from "./PdfPage.vue?vue&type=script&lang=js"
export * from "./PdfPage.vue?vue&type=script&lang=js"
import style0 from "./PdfPage.vue?vue&type=style&index=0&id=880f3222&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "880f3222",
  null
  
)

export default component.exports