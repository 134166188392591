<template>
  <div class="content" :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    secondary: {
      type: Boolean,
      default() {
        return false
      },
    },
    bordered: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {
    className() {
      const { bordered, secondary } = this
      const classes = []
      bordered && classes.push('bordered')
      secondary && classes.push('secondary')
      return classes.join(' ')
    },
  },
}
</script>

<style lang='less' scoped>
.content {
  background-color: #fff;
}
.secondary {
  background: rgba(51, 80, 199, 0.08);
}
.bordered {
  border: 1px solid #DFE0EB;
}
</style>