<!-- 家校训练服务-详情-上课记录 -->
<template>
  <a-row :gutter="24" style="overflow: hidden" class="class-record">
    <template v-if="current === 1">
      <!--左侧-->
      <a-col :lg="4" :md="7" :xs="9" class="left-panel">
        <a-affix v-if="ready" :offset-top="24">
          <a-card :bodyStyle="{ padding: '0 0 40px 0' }" class="aside-wrapper">
            <div slot="title">
              <span>上课日期</span>
              <a-button
                type="primary"
                size="small"
                style="margin-left: 8px"
                @click="handleAttendClass"
                v-if="!isSchoolStatus && $auth('teaching_class_record')"
                >去上课</a-button
              >
            </div>
            <a-tree
              v-if="dateList.length > 0"
              :tree-data="dateList"
              default-expand-all
              :default-selected-keys="dateDefault"
              :replaceFields="{ title: 'name', key: 'id' }"
              @select="onSelectDate"
            ></a-tree>
            <!-- 无数据 -->
            <a-empty v-else style="margin-top: 40px" />
          </a-card>
        </a-affix>
      </a-col>

      <!--右侧-->
      <a-col :lg="20" :md="17" :xs="15" class="right-panel">
        <!-- 无数据 -->
        <a-card v-if="empty" :bodyStyle="{ padding: '0' }" :bordered="false">
          <a-empty style="margin-top: 40px; height: calc(100vh - 238px)" />
        </a-card>
        <div v-else>
          <!--顶部-->
          <div class="head">
            <div></div>
            <div class="head-right">
              <div class="teacher">本次上课老师：{{ (classRecordSingle && classRecordSingle.teacher) || '--' }}</div>
              <a-button style="margin-left: 24px" v-if="[2, 3].includes(current)" @click="back()"
                ><a-icon type="left" />返回</a-button
              >
            </div>
          </div>
          <a-card :bodyStyle="{ padding: '0' }" :bordered="false">
            <!--内容-->
            <end-of-crouse
              :isEditBehaviorRecord="false"
              ref="endOfCrouse"
              @initData="getEndOfCrouse"
              @change="changeCurrent"
            ></end-of-crouse>
          </a-card>
        </div>
      </a-col>
    </template>
    <!--编辑素材、记录详情-->
    <template v-if="current === 2 || current === 3">
      <a-col :lg="4" :md="7" :xs="9" class="left-panel">
        <a-card title="训练技能" :bodyStyle="{ padding: '0 0 40px 0' }" class="aside-wrapper">
          <a-tree
            v-if="classRecordSingleDetail.skillList.length > 0"
            :tree-data="classRecordSingleDetail.skillList"
            default-expand-all
            :default-selected-keys="skillDefault"
            :replaceFields="{ title: 'name', key: 'id' }"
            @select="onSelectSkill"
          ></a-tree>
        </a-card>
      </a-col>
      <!--记录详情-->
      <a-col :lg="20" :md="17" :xs="15" class="right-panel" v-if="current === 3">
        <class-record-detail
          :show-time="true"
          ref="classRecordDetail"
          @back="back()"
          @changeCurrentTime="changeCurrentTime"
        ></class-record-detail>
      </a-col>

      <!--编辑素材-->
      <a-col :lg="20" :md="17" :xs="15" class="right-panel" v-if="current === 2">
        <div class="head">
          <div>
            <div style="font-size: 16px; font-weight: 600; color: #333333">编辑阶段素材</div>
          </div>
          <div class="head-right">
            <div class="teacher">本次上课老师：{{ (classRecordSingle && classRecordSingle.teacher) || '--' }}</div>
            <a-button style="margin-left: 24px" @click="back()"><a-icon type="left" />返回</a-button>
          </div>
        </div>

        <div style="background: #fff; padding: 0 24px">
          <div style="margin-bottom: 16px">
            <span style="font-size: 16px; font-weight: 600; color: #333333; margin-right: 32px">{{
              phaseDetail && phaseDetail.skillName
            }}</span>
            <span
              >第{{ convertToChineseNumber(phaseDetail.phaseNumber) }}阶段：
              {{ phaseDetail.name }}
            </span>
          </div>

          <div style="margin-bottom: 24px">
            <span style="font-size: 14px; font-weight: 600; color: #333333">素材说明：</span>
            {{ phaseDetail && phaseDetail.materialDescription }}
          </div>
          <!--编辑素材-->
          <basis-form
            style="margin-bottom: 40px"
            ref="editMaterial"
            type="plan"
            :mainFieldId="NaN"
            :fieldId="NaN"
            origin="trainingCompleted"
            :only-material="true"
            @initData="initEditMaterial"
          />
          <div style="width: 320px; height: 44px">
            <a-button :loading="loading" type="primary" block shape="round" :disabled="loading" @click="onSubmit"
              >保存</a-button
            >
          </div>
        </div>
      </a-col>
    </template>
    <!--去上课-->
    <attend-class ref="attendClass"></attend-class>
  </a-row>
</template>
<script>
import * as Api from '@/api/serviceManager'
import * as planApi from '@/api/plan'
import BasisForm from '@/views/skill-management/skill/phase/components/BasisForm'
import AttendClass from '@/views/home-school-service/components/attend-class'
import endOfCrouse from '../../components/end-of-crouse'
import classRecordDetail from './class-record-detail'
import moment from 'moment'
import { convertToChineseNumber } from '@/utils/util'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    endOfCrouse,
    BasisForm,
    classRecordDetail,
    AttendClass,
  },
  data() {
    return {
      current: -1, // 1结束课程  2编辑素材 3记录详情
      dateDefault: [''], // 上课日期-默认选中  teachingClassId
      skillDefault: [''],
      dateList: [], // 上课日期-树状菜单
      ready: false,
      loading: false,
      isSkillDefault: true, // 是否设置 技能阶段默认值 true 设置第1个 false 不需要(编辑阶段素材、记录详情)
      phaseDetail: {}, // 编辑阶段素材、记录详情
      classRecordSingle: {}, // 单个上课记录
      // 记录详情
      classRecordSingleDetail: {
        skillName: '',
        date: {
          year: '',
          month: '',
          day: '',
        }, // 日期
        dateTimeList: [], // 时间列表
        currentTime: '', // 选中的时间
        skillList: [], // 左侧树状
        recordDetailsPhase: {}, // 右侧记录详情
      },
      recordDetailsPhaseList: [],
      empty: false,
      editMaterialDetail: {}, // 编辑素材的详情信息
    }
  },
  created() {
    this.current = 1
    this.initData({})
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
  },
  methods: {
    ...mapActions(['changeSpinning']),

    // 记录详情-右上角时间-切换
    changeCurrentTime(selected) {
      this.dateDefault = [selected]
      this.isSkillDefault = true
      this.classRecordSingleDetail.currentTime = selected
      // this.initData({})
      this.getSkillPhaseTreeList().then(() => {
        this.$refs.classRecordDetail.initData(this.classRecordSingleDetail)
      })
    },
    // 选择技能
    onSelectSkill(selectedKeys, info) {
      if (this.current === 2) {
        this.$refs.editMaterial.materialDicts = []
        this.$refs.editMaterial.materialsAdded = []
      }

      // 第三级
      const selected = selectedKeys[0]
      // 第二级
      const _fieldId = selected ? info.node.$parent.eventKey : undefined
      // 第一级
      const _mainFieldId = _fieldId ? info.node.$parent.$parent.eventKey : undefined
      // 选中第三级
      if (_mainFieldId && _fieldId && selected) {
        // 设置选中
        this.skillDefault = [selected]
        // 编辑素材
        if (this.current === 2) {
          this.changeSpinning(true)

          const { id: studentId } = this.$route.params
          const { recordDetailsPhase } = this.recordDetailsPhaseList.filter((item) => {
            return item.skillId === selected
          })[0]
          const { name: skillName } = info.node.dataRef
          const { planPhaseId, phaseName, phaseNumber } = recordDetailsPhase
          planApi
            .phaseDetail(planPhaseId)
            .then((res) => {
              const { materialDescription } = res.data
              this.phaseDetail = {
                ...this.phaseDetail,
                ...{ phaseNumber, name: phaseName, skillName, materialDescription, id: planPhaseId },
              }
              this.editMaterialDetail = res.data
              this.$refs.editMaterial.planinitData(res.data, studentId)
              this.$forceUpdate()
              this.changeSpinning(false)
            })
            .catch(() => {
              this.changeSpinning(false)
            })
        }
        // 记录详情
        if (this.current === 3) {
          const { skillName, recordDetailsPhase } = this.recordDetailsPhaseList.filter((item) => {
            return item.skillId === selected
          })[0]
          this.classRecordSingleDetail = { ...this.classRecordSingleDetail, ...{ skillName, recordDetailsPhase } }
          this.$refs.classRecordDetail.initData(this.classRecordSingleDetail)
          this.changeSpinning(false)
        }
        // this.initData({})
      }
    },

    // 选择时间
    onSelectDate(selectedKeys, info) {
      if (this.current === 1) {
        this.$refs.endOfCrouse.descript = ''
      }

      // 第三级
      const selected = selectedKeys[0]
      // 第二级
      const _fieldId = selected ? info.node.$parent.eventKey : undefined
      // 第一级
      const _mainFieldId = _fieldId ? info.node.$parent.$parent.eventKey : undefined
      // 选中第三级
      if (_mainFieldId && _fieldId && selected) {
        // 记录详情-右上角-年月日、时间段
        const { id, children } = info.node.$parent.dataRef
        this.classRecordSingleDetail.date = {
          year: _mainFieldId.split('-')[0],
          month: _mainFieldId.split('-')[1],
          day: id.split('-')[1],
        }
        this.classRecordSingleDetail.dateTimeList = children
        // this.classRecordSingleDetail.currentTime = children[0].id
        this.classRecordSingleDetail.currentTime = selected
        // 设置选中
        this.dateDefault = [selected]
        this.getEndOfCrouse()
      }
    },
    // 上课日期列表
    initData() {
      const { id: studentId } = this.$route.params
      // 报告详情
      if (this.current === 1) {
        this.getDateListTreeList()
      }
      // 编辑素材
      if (this.current === 2) {
        this.changeSpinning(true)
        this.getSkillPhaseTreeList()
          .then(() => {
            const { id } = this.phaseDetail
            this.changeSpinning(true)
            planApi
              .phaseDetail(id)
              .then((res) => {
                const { materialDescription } = res.data
                this.phaseDetail = {
                  ...this.phaseDetail,
                  ...{ materialDescription },
                }
                this.editMaterialDetail = res.data
                this.$refs.editMaterial.planinitData(res.data, studentId)
                this.$forceUpdate()
                this.changeSpinning(false)
              })
              .catch(() => {
                this.changeSpinning(false)
              })
          })
          .catch(() => {
            this.changeSpinning(false)
          })
      }
      // 记录详情
      if (this.current === 3) {
        this.changeSpinning(true)
        this.getSkillPhaseTreeList().then(() => {
          this.changeSpinning(false)
          const { teacher } = this.classRecordSingle
          this.$refs.classRecordDetail.initData({ ...this.classRecordSingleDetail, ...{ teacherName: teacher } })
        })
      }
    },

    // 时间-树状图数据
    getDateListTreeList() {
      this.changeSpinning(true)
      const { id: studentId } = this.$route.params
      Api.getStudentTeachingDateList({ studentId })
        .then((res) => {
          const { code, data } = res
          this.changeSpinning(false)
          if (code === 0) {
            const list = []
            data.map((item, index) => {
              const { teachingYearMonth, teachingMonthDayList } = item
              const _item = {
                id: teachingYearMonth,
                name: moment(teachingYearMonth).format('YYYY年MM月'),
                children: [],
              }
              //
              teachingMonthDayList.map((item1, index1) => {
                const { teachingMonthDay, teachingMonthDayList } = item1
                const _item1 = {
                  id: teachingMonthDay,
                  name: moment(`${teachingYearMonth.split('-')[0]}-${teachingMonthDay}`).format('MM月DD日'),
                  children: [],
                }
                teachingMonthDayList.map((item2, index2) => {
                  const { startTime: name, teachingClassId: id } = item2
                  if (!this.dateDefault[0] && index === 0 && index1 === 0 && index2 === 0) {
                    this.dateDefault = [id]
                  }
                  _item1.children.push({ id, name })
                })
                // 记录详情-右上角-年月日、时间段
                if (index === 0 && index1 === 0) {
                  this.classRecordSingleDetail.date = {
                    year: teachingYearMonth.split('-')[0],
                    month: teachingYearMonth.split('-')[1],
                    day: teachingMonthDay.split('-')[1],
                  }
                  this.classRecordSingleDetail.dateTimeList = _item1.children
                  this.classRecordSingleDetail.currentTime = _item1.children[0].id
                }
                _item.children.push(_item1)
              })
              list.push(_item)
            })

            if (list.length > 0) {
              this.getEndOfCrouse()
            } else {
              this.empty = true
            }

            this.dateList = list

            this.ready = true
          }
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    },

    // 编辑素材阶段、记录详情-技能树状图
    getSkillPhaseTreeList() {
      const teachingId = this.dateDefault[0]
      this.classRecordSingleDetail.skillList = []
      this.recordDetailsPhaseList = []
      const params = { teachingId }
      return new Promise((resolve, reject) => {
        Api.classRecordDetail(params)
          .then((res) => {
            const { code, data } = res
            if (code === 0) {
              const { recordDetailsParentFieldList, teacherName } = data
              const list = []
              recordDetailsParentFieldList.map((item, index) => {
                const { fieldId, fieldName, recordDetailsFieldList } = item
                const _item = {
                  id: fieldId,
                  name: fieldName,
                  children: [],
                }
                //
                recordDetailsFieldList.map((item1, index1) => {
                  const { fieldId, fieldName, recordDetailsSkillList } = item1
                  const _item1 = {
                    id: fieldId,
                    name: fieldName,
                    children: [],
                  }
                  recordDetailsSkillList.map((item2, index2) => {
                    const { skillName: name, skillId: id, recordDetailsPhase } = item2
                    // 需要默认选中第1个
                    if (this.isSkillDefault) {
                      if (index === 0 && index1 === 0 && index2 === 0) {
                        this.skillDefault = [id]
                        this.classRecordSingleDetail.skillName = name
                        this.classRecordSingleDetail.recordDetailsPhase = recordDetailsPhase
                      }
                    } else {
                      // 不需要默认选中第1个
                      const _id = this.skillDefault[0]
                      if (id === _id) {
                        this.classRecordSingleDetail.skillName = name
                        this.classRecordSingleDetail.recordDetailsPhase = recordDetailsPhase
                      }
                    }

                    _item1.children.push({ id, name })
                    this.recordDetailsPhaseList.push(item2)
                  })
                  _item.children.push(_item1)
                })
                list.push(_item)
              })
              this.classRecordSingleDetail.skillList = list

              this.classRecordSingleDetail = { ...this.classRecordSingleDetail, ...{ teacherName } }
              resolve()
            }
          })
          .catch(() => {
            reject()
          })
      })
    },

    // 获取报告
    getEndOfCrouse() {
      this.changeSpinning(true)
      const teachingId = this.dateDefault[0]
      const params = { teachingId }
      Api.getTeachingReport(params)
        .then((res) => {
          const { code, data } = res
          this.changeSpinning(false)
          if (code === 0) {
            this.classRecordSingle = data
            this.$refs.endOfCrouse.initData({ data, teachingClassId: teachingId })
          }
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    },
    // 切换显示
    changeCurrent({ item, current }) {
      if (item) {
        this.phaseDetail = item
      }
      this.current = current
      // 查看详情、预测将来某一天会要求记住滚动条位置
      if (current === 3 || current === 2) {
        this.skillDefault = [item.skillId]
        this.isSkillDefault = false
      }
      this.initData()
      this.$forceUpdate()
    },
    back() {
      // this.phaseDetail = {}
      this.classRecordSingle = {}
      this.ready = false
      this.isSkillDefault = true
      this.changeCurrent({ current: 1 })
    },
    onSubmit() {
      this.changeSpinning(true)
      const {
        phaseDetail: { skillId, id },
        $notification,
        editMaterialDetail: {
          trainingAssistanceMode1 = null,
          trainingAssistanceMode2 = null,
          trainingAssistanceMode3 = null,
        },
        $refs: { editMaterial },
      } = this

      editMaterial
        .validate()
        .then((res) => {
          const valid = res.every((item) => item === true)
          this.changeSpinning(false)
          if (valid) {
            this.loading = true
            try {
              const { planformatForm } = editMaterial
              const form = planformatForm()

              form.skillId = skillId
              form.id = id
              this.changeSpinning(true)
              const params = {
                ...form,
                ...{ trainingAssistanceMode1, trainingAssistanceMode2, trainingAssistanceMode3 },
              }
              planApi
                .updatePhase(params)
                .then((res) => {
                  $notification['success']({ message: '操作成功' })
                  // this.back()
                  this.loading = false
                  this.changeSpinning(false)
                })
                .catch(() => {
                  this.changeSpinning(false)
                  this.loading = false
                })
            } catch (error) {
              console.log(error)
              $notification['error']({ message: '提示', description: '脚本错误' })
              this.changeSpinning(false)
              this.loading = false
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 去上课
    handleAttendClass() {
      const row = JSON.parse(this.$route.query.row)
      this.$refs.attendClass.handleGoToClass(row)
    },
    initEditMaterial() {
      const {
        $route: {
          params: { id: studentId },
        },
        editMaterialDetail,
      } = this
      this.$refs.editMaterial.planinitData(editMaterialDetail, studentId)
    },
    convertToChineseNumber,
  },
}
</script>

<style lang="less" scoped>
.aside-wrapper {
  height: calc(100vh - 185px);
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow-y: auto;
  }
}

.class-record {
  .left-panel {
    padding-right: 0 !important;
    .item {
      cursor: pointer;
      padding: 0 0 0 24px;
      line-height: 40px;
      border-bottom: 0;
    }

    .active {
      font-size: 14px;
      color: #3a5ff8;
      background: #e1e8ff;
    }
  }

  .right-panel {
    /*顶部*/
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 24px;
      background: #fff;
      .head-right {
        display: flex;
        align-items: center;
        /*padding: 0 24px;*/
        .teacher {
          font-size: 14px;
          font-weight: 600;
          color: #1a1a1a;
        }
      }
    }
  }
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
   内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
   内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
