<template>
  <a-card :bodyStyle="{ padding: '12px', minHeight: 'calc(100vh - 263px)' }">
    <!-- {{ type }} -->
    <a-space slot="extra" :size="10" v-if="[1, 2, 5].includes(type)">
      <!--达标-小对勾-->
      <div class="example" v-show="type == 2">
        <div class="example-l" style="width: 16px; height: 16px; line-height: 18px">
          <a-icon
            class="standard-color"
            type="check-square"
            :style="{ fontSize: '16px', opacity: '0.6' }"
            title="当前选项关联的阶段已达标"
          ></a-icon>
        </div>
        <div class="example-r">当前选项关联的阶段已达标</div>
      </div>
      <!--4种颜色图例-->
      <div
        class="example"
        v-for="(item, index) in evaluationFieldSerialNumberVOList"
        :key="index"
        v-if="[5, 2].includes(type)"
      >
        <div class="example-l" :class="`example-l-${item.serialNumber}`"></div>
        <div class="example-r">
          <p>评估{{ item.serialNumber }}：{{ item.completedTimestamp || '--' }}</p>
          <p>评估人：{{ item.planWriter.length ? item.planWriter.join('、') : '--' }}</p>
        </div>
      </div>
      <!--评估历史详情-返回-->
      <a-button shape="round" @click="historyDetailReturn" v-show="type == 5"><a-icon type="left" />返回</a-button>

      <!--操作按钮-->
      <a-button type="link" v-if="type == 1 && $auth('evaluations_result')" @click="type = 2">
        <img src="~@/assets/icons/cxpgjg@2x.png" style="width: 19px" alt="" />
        查看评估结果
      </a-button>
      <a-button shape="round" v-if="type == 1" @click="type = 6" class="custom-button">
        <img src="~@/assets/icons/evaluation-report@2x.png" />
        评估报告</a-button
      >
      <a-button type="primary" shape="round" @click="handleReturnField" v-if="type == 2">
        <a-icon type="rollback" /> 返回领域列表</a-button
      >
      <a-button
        v-if="$auth('plans_create') && planWriteAndTeacherAuthority && [1, 2].includes(type) && !isSchoolStatus"
        :loading="buttonLoading"
        :disabled="!enableCreate || isReassess"
        type="primary"
        shape="round"
        @click="handleCreate"
      >
        <a-icon v-if="!buttonLoading" type="plus" />生成计划
      </a-button>

      <a-button
        shape="round"
        @click="handleHistory"
        v-if="$auth('assessment_old') && [2].includes(type)"
        class="custom-button"
      >
        <img src="~@/assets/icons/pgls@2x.png" />
        评估历史</a-button
      >
      <a-button
        :loading="reassessButtonLoading"
        shape="round"
        @click="handleReassess"
        v-if="type == 1 && isReassess && !isSchoolStatus && $auth('assessment_again')"
        class="custom-button"
      >
        <img src="~@/assets/icons/cxpg@2x.png" />
        重新评估
      </a-button>
    </a-space>

    <!--领域列表-->
    <field-list
      ref="fieldList"
      v-if="type === 1"
      @handleReport="handleReport"
      @handleAnswer="handleAnswer"
      @enable="changeEnable"
    />

    <!--评估结果-->
    <div style="padding: 0 24px 24px 24px; min-height: 100%; display: flex; overflow-x: auto" v-if="type === 2">
      <evaluate-table ref="table" @example="example" @detail="evaluateDialogDetail"> </evaluate-table>
      <div style="flex: 0 0 24px"></div>
    </div>

    <!--答题-->
    <answer :answerData="answerData" v-if="type === 3" @finished="finishedAnswer" @return="type = 1" />

    <!-- 评估结果-单个题目-详情-->
    <evaluate-dialog ref="evaluateDialog" :data="evaluateQuestionDetail"></evaluate-dialog>

    <!--评估历史-列表-->
    <template v-if="type === 4">
      <a-row type="flex" justify="end">
        <a-button shape="round" @click="type = 2"><a-icon type="left" />返回</a-button>
      </a-row>
      <evaluation-history @evaluationHistoryDetail="evaluationHistoryDetail"></evaluation-history>
    </template>

    <!--评估历史-详情-->
    <div style="padding: 0 24px 24px 24px; min-height: 100%; display: flex; position: relative" v-if="type === 5">
      <evaluate-table
        ref="table"
        :history-id="evaluationHistoryDetailId"
        @enable="enableCreate = true"
        @example="example"
      ></evaluate-table>
      <div style="flex: 0 0 24px"></div>
    </div>

    <!--评估报告-列表-->
    <template v-if="type === 6">
      <div style="padding: 12px">
        <a-row type="flex" align="middle" justify="space-between" style="margin-bottom: 18px">
          <h3 style="margin: 0">评估报告列表</h3>
          <a-button @click="type = 1" class="back-btn"><a-icon type="left" />返回领域列表</a-button>
        </a-row>
        <evaluation-report-list @onRow="handleEvaluationReportDetail" />
      </div>
    </template>

    <!--评估报告-详情-->
    <template v-if="type === 7">
      <div style="margin: -12px">
        <a-affix :offset-top="0">
          <div style="padding: 24px; background: #fff">
            <a-row v-if="!evReportIsPdf" type="flex" align="middle">
              <a-space v-if="evReportSaved" align="center" style="color: #666666">
                <a-icon type="check-circle" />
                <span>{{ evReportSavedTime | moment('HH:mm:ss') }} 保存成功</span>
              </a-space>
              <a-space
                v-if="(evReportSaving || evReportToPublish) && !evReportSaved"
                align="center"
                style="color: #666666"
              >
                <a-icon type="clock-circle" />
                <span>正在保存中......</span>
              </a-space>
              <a-space v-if="evReportFailed" align="center" style="color: #666666">
                <a-icon type="exclamation-circle" />
                <span>未检测到网络，保存失败</span>
              </a-space>
              <a-space align="center" style="margin-left: auto" v-if="isSchoolStatus">
                <a-button type="primary" shape="round" @click="handlePdfPreview">
                  <a-icon type="file-search" />预览PDF
                </a-button>
                <a-button :loading="evReportSaving" type="primary" shape="round" @click="handleReportSave">
                  <a-icon v-if="!evReportSaving" type="file-text" />保存并返回
                </a-button>
              </a-space>

              <a-space align="center" style="margin-left: auto" v-else>
                <template v-if="['view', 'publish'].includes(evaluationReportDetailViewType)">
                  <a-button type="primary" shape="round" style="width: 98px" @click="type = 6"> 返回 </a-button>
                  <a-button type="primary" shape="round" @click="handlePdfPreview">
                    <a-icon type="file-search" />预览PDF
                  </a-button>
                  <a-button
                    v-if="evaluationReportDetailViewType === 'publish'"
                    type="primary"
                    shape="round"
                    style="width: 98px"
                    @click="publish"
                  >
                    发布
                  </a-button>
                </template>
                <template v-if="evaluationReportDetailViewType === 'edit'">
                  <a-button :loading="evReportSaving" type="primary" shape="round" @click="handleReportSave">
                    <a-icon v-if="!evReportSaving" type="file-text" />保存并返回
                  </a-button>
                  <a-button type="primary" shape="round" @click="handlePdfPreview">
                    <a-icon type="file-search" />预览PDF
                  </a-button>
                  <a-button
                    :loading="evReportToPublish"
                    type="primary"
                    shape="round"
                    style="width: 98px"
                    @click="toPublish"
                  >
                    发布
                  </a-button>
                </template>
              </a-space>
            </a-row>
            <a-row v-else type="flex" justify="end">
              <a-space>
                <a-button type="primary" shape="round" style="width: 98px" @click="handlePdfBack"> 返回详情 </a-button>
                <a-select
                  style="width: 160px"
                  placeholder="请选择并导出报告"
                  :disabled="exportReportPdfButton"
                  @change="handleExportReportPdf"
                >
                  <a-select-option :value="exportItem.type" v-for="exportItem in exportList" :key="exportItem.type">
                    {{ exportItem.name }}
                  </a-select-option>
                </a-select>
              </a-space>
            </a-row>
          </div>
        </a-affix>
        <evaluation-report-detail
          ref="evReportDetailRef"
          :report-id="evaluationReportDetailId"
          :view-type="evaluationReportDetailViewType"
          :is-pdf="evReportIsPdf"
          @exportReportPdfButton="
            (flag) => {
              exportReportPdfButton = flag
            }
          "
        ></evaluation-report-detail>
        <!-- 返回弹窗 -->
        <a-modal
          v-model="reportBackVisible"
          title="报告数据已成功保存"
          width="424px"
          height="240px"
          :body-style="{ paddingTop: '40px', paddingLeft: '24px', paddingBottom: '40px', paddingRight: '24px' }"
          :maskClosable="false"
          :closable="false"
          :ok-button-props="{
            props: { shape: 'round' },
          }"
          :cancel-button-props="{ props: { shape: 'round' } }"
          @ok="
            type = 6
            reportBackVisible = false
          "
          @cancel="reportBackVisible = false"
        >
          <div class="modaltitle">是否返回报告列表页？</div>
        </a-modal>
      </div>
    </template>

    <!--阶段列表-查看报告-->
    <report-dialog ref="reportDialog"></report-dialog>
  </a-card>
</template>

<script>
import { Modal } from 'ant-design-vue'
import * as planApi from '@/api/plan'
import * as evaluationReportApi from '@/api/evaluation-report'
import EvaluateTable from './components/Table'
import evaluateDialog from './components/evaluate-dialog'
import reportDialog from './components/report-dialog'
import fieldList from './components/field-list'
import answer from './components/answer'
import evaluationHistory from './components/evaluationHistory'
import EvaluationReportList from './report'
import EvaluationReportDetail from './report/detail'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      type: 1, // 1 领域列表  2 评估结果(四种颜色) 3答题 4评估历史-列表 5评估历史-详情 6评估报告-列表 7评估报告-详情
      enableCreate: false, // 操作按钮是否可用
      buttonLoading: false, // 生成计划按钮
      isReassess: false, // 重新评估按钮
      reassessButtonLoading: false, // 重新评估按钮按钮loading
      evaluationFieldSerialNumberVOList: [], // 图例列表
      planWriteAndTeacherAuthority: false, // 是否显示（计划撰写师、教师角色权限）
      evaluateQuestionDetail: {}, // 评估结果-单个题目-详情
      answerData: {}, // 答题数据
      evaluationHistoryDetailId: '', // 评估历史点详情的id
      evaluationReportDetailId: '', // 评估报告 详情id
      evaluationReportDetailViewType: '', // 评估报告 查看方式 view | edit | publish
      evReportIsPdf: false, // 评估报告 是否为pdf预览
      evReportSaving: false,
      evReportSaved: false,
      evReportFailed: false,
      evReportToPublish: false,
      reportBackVisible: false,
      evReportSavedTime: '',
      evReportPublishLoading: false,
      exportReportPdfButton: true,
      exportList: [
        {
          type: 1,
          id: '#report-pdf-preview-1',
          name: '第一部分',
        },
        {
          type: 2,
          id: '#report-pdf-preview-2',
          name: '第二部分',
        },
        {
          type: 3,
          id: '#report-pdf-preview-3',
          name: '第三部分',
        },
        {
          type: 4,
          id: '#report-pdf-preview-4',
          name: '第四部分',
        },
      ],
    }
  },
  created() {
    this.initData()
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus', // 学生-在园状态【true离园  false在园】
    }),
  },
  methods: {
    ...mapActions(['changeSpinning']),

    // 返回领域列表
    handleReturnField() {
      this.type = 1
      this.evaluationFieldSerialNumberVOList = []
    },
    // 结束答题
    finishedAnswer() {
      this.type = 1
      this.answerData = {}
    },
    // 开始答题
    handleAnswer(item) {
      this.answerData = {}
      setTimeout(() => {
        this.answerData = item
        this.type = 3
      })
    },
    // 查看报告
    handleReport({ item }) {
      const { id: evaluationFieldId } = item
      planApi
        .getFieldScore({
          evaluationFieldId,
        })
        .then((res) => {
          const { code, data } = res
          if (code === 0) {
            console.log(data)
            this.$refs.reportDialog.showModal(data)
          }
        })
    },
    // 初始化数据
    initData() {
      const { isTeacher, isPlanWrite } = JSON.parse(this.$route.query.row)
      if (isTeacher && !isPlanWrite) {
        this.planWriteAndTeacherAuthority = false
      } else {
        this.planWriteAndTeacherAuthority = true
      }

      this.isReassessments()
    },
    // 该学员是否可以重新评估
    isReassessments() {
      const {
        $route: {
          params: { id },
        },
      } = this
      planApi
        .reassessments({
          studentId: id,
        })
        .then((res) => {
          const { code, data } = res
          if (code === 0) {
            this.isReassess = data
          }
        })
    },

    // 重新评估
    handleReassess() {
      const confirm = this.$confirm({
        title: '信息提示',
        content: `确认要进行重新评估吗？`,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          const {
            $route: {
              params: { id }
            }
          } = this
          this.reassessButtonLoading = true
          planApi
            .saveReassessmentsAll({
              studentId: id,
            })
            .then((res) => {
              const { code, msg } = res
              if (code === 0) {
                this.isReassessments()
                this.$refs.fieldList.getFieldList()
              } else {
                this.$notification['error']({
                  message: '提示',
                  description: `${msg}`,
                })
              }
            })
            .finally(() => {
              confirm.destroy()
              this.reassessButtonLoading = false
            })
        },
        cancel: () => {
          confirm.destroy()
        },
      })
    },

    // 评估结果-单个题目-详情
    evaluateDialogDetail({ field, levelIndex, subjectIndex }) {
      const { levelList } = field
      if (
        levelList &&
        levelList[levelIndex] &&
        levelList[levelIndex].subjectList &&
        levelList[levelIndex].subjectList[subjectIndex]
      ) {
        const { id: subjectId } = levelList[levelIndex].subjectList[subjectIndex]
        const {
          $route: {
            params: { id },
          },
        } = this
        planApi
          .getLastAnswerQuestionDetail({
            subjectId,
            studentId: id,
          })
          .then((res) => {
            const { code, data } = res
            if (code === 0) {
              data.skillVOList = data.skillVOList.map((item) => {
                return item.name
              })
              this.evaluateQuestionDetail = data
              this.$refs.evaluateDialog.showModal()
            }
          })
      }
    },
    // 生成计划
    handleCreate() {
      const {
        $route: {
          params: { id },
        },
        $notification,
      } = this

      this.buttonLoading = true
      planApi
        .create(id)
        .then(() => {
          $notification['success']({
            message: '操作成功',
          })
          this.isReassessments()
        })
        .finally(() => (this.buttonLoading = false))
    },
    // 评估历史
    handleHistory() {
      this.type = 4
    },
    // 评估历史详情
    evaluationHistoryDetail({ id }) {
      this.evaluationFieldSerialNumberVOList = []
      this.evaluationHistoryDetailId = id
      this.type = 5
    },
    // 图例数据
    example({ evaluationFieldSerialNumberVOList }) {
      this.evaluationFieldSerialNumberVOList = evaluationFieldSerialNumberVOList
    },
    changeEnable(flag) {
      this.enableCreate = flag
    },

    // 评估历史-详情-返回
    historyDetailReturn() {
      this.type = 4
      sessionStorage.removeItem('homeSchoolIsShowTabs')
    },

    handleEvaluationReportDetail(params) {
      const { type: viewType, id } = params
      const action = (type) => {
        this.evaluationReportDetailId = id
        this.evaluationReportDetailViewType = type
        this.evReportSaving = false
        this.evReportSaved = false
        this.evReportFailed = false
        this.evReportSavedTime = ''
        this.evReportToPublish = false
        this.type = 7
      }
      if (viewType === 'publish') {
        evaluationReportApi
          .isComplete(id)
          .then((res) => {
            const { code, data } = res
            if (code === 0 && data) {
              action(viewType)
            } else {
              const confirm = Modal.confirm({
                title: `报告还没有写完呢`,
                content: `请继续完成未填写的内容`,
                okText: '继续编辑',
                onOk: () => {
                  confirm.destroy()
                  action('edit')
                },
                onCancel: () => {
                  confirm.destroy()
                },
              })
            }
          })
          .finally(() => {
            this.evReportToPublish = false
          })
      } else {
        action(viewType)
      }
    },
    handleReportSave() {
      this.evReportSaving = true
      this.evReportSaved = false
      this.evReportFailed = false
      this.$refs.evReportDetailRef
        .save()
        .then(() => {
          this.reportBackVisible = true
          this.evReportSavedTime = new Date()
          this.evReportSaved = true
        })
        .catch(() => {
          this.evReportFailed = true
        })
        .finally(() => {
          this.evReportSaving = false
        })
    },
    async toPublish() {
      this.evReportToPublish = true
      this.evReportSaved = false
      this.evReportFailed = false
      try {
        await this.$refs.evReportDetailRef.save()
        this.evReportSavedTime = new Date()
        this.evReportSaved = true
      } catch (error) {
        console.log('保存失败: ', error)
        this.evReportFailed = true
        this.evReportToPublish = false
        return
      }
      evaluationReportApi
        .isComplete(this.evaluationReportDetailId)
        .then((res) => {
          const { code, data } = res
          if (code === 0 && data) {
            this.evaluationReportDetailViewType = 'publish'
          } else {
            this.$info({
              title: '报告还没有写完呢',
              content: '请继续完成未填写的内容',
              okText: '继续编辑',
            })
          }
        })
        .finally(() => {
          this.evReportToPublish = false
        })
    },
    publish() {
      this.evReportPublishLoading = false
      this.$confirm({
        title: `您正在发布${this.$refs.evReportDetailRef.detail.username}的评估报告`,
        content: '发布后的报告无法撤回，是否确认发布？',
        okText: '确认发布',
        okButtonProps: {
          loading: this.evReportPublishLoading,
        },
        onOk: () => {
          this.evReportPublishLoading = true
          return new Promise((resolve, reject) => {
            evaluationReportApi
              .publish(this.evaluationReportDetailId)
              .then((res) => {
                const { code } = res
                if (code === 0) {
                  resolve()
                  this.$notification['success']({ message: '操作成功' })
                  this.type = 6
                } else {
                  reject()
                }
              })
              .catch(() => {
                reject()
              })
              .finally(() => {
                this.evReportPublishLoading = false
              })
          })
        },
      })
    },
    async handlePdfPreview() {
      await this.$refs.evReportDetailRef.save()
      this.evReportIsPdf = true
      window.scrollTo({
        top: 0,
      })
    },
    handlePdfBack() {
      this.evReportIsPdf = false
      this.exportReportPdfButton = true
      window.scrollTo({
        top: 0,
      })
    },
    // 导出报告的PDF
    handleExportReportPdf(type) {
      const exportItem = this.exportList.filter((item) => item.type === type)[0]

      this.exportReportPdfButton = true
      const {
        changeSpinning,
        $refs: { evReportDetailRef },
      } = this
      changeSpinning(true)
      setTimeout(() => {
        evReportDetailRef.exportPDF(exportItem)
      })
    },
  },
  components: {
    EvaluateTable,
    evaluateDialog,
    reportDialog,
    answer,
    fieldList,
    evaluationHistory,
    EvaluationReportList,
    EvaluationReportDetail,
  },
}
</script>

<style lang="less" scoped>
/* 图例*/
.example {
  display: flex;
  margin-right: 10px;

  .example-l {
    align-self: center;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 0px solid #979797;
  }

  .example-l-1 {
    background: #fffa75;
  }

  .example-l-2 {
    background: #ffb475;
  }

  .example-l-3 {
    background: #ff7575;
  }

  .example-l-4 {
    background: #da75ff;
  }

  .example-r {
    font-size: 10px;
    color: #434343;

    p {
      margin-bottom: 0;
    }
  }

  .standard-color {
    color: @success-color;
  }
}

.custom-button {
  color: #3a5ff8;
  border: 1px solid #3a5ff8;
  img {
    width: 14px;
    height: 14px;
    position: relative;
    top: -2px;
    margin-right: 8px;
  }
}

.modaltitle {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  span {
    color: #3a5ff8;
  }
}

.back-btn:not(:hover) {
  color: #979797;
}
</style>
