<template>
  <div>
    <div v-if="chartDv.length">
      <v-chart :forceFit="true" height="408" :data="chartDv" :scale="scale" :padding="padding">
        <v-tooltip crosshairs="y" :htmlContent="htmlContent"> </v-tooltip>
        <v-legend :textStyle="{ fontSize: '14' }"></v-legend>
        <v-line
          :position="`${isFrequency ? 'countDate*frequency' : 'countDate*successRate'}`"
          color="materialName"
          :size="2"
        ></v-line>
        <v-point
          :position="`${isFrequency ? 'countDate*frequency' : 'countDate*successRate'}`"
          :size="2"
          :style="style"
        >
        </v-point>
        <!--非频率，自定义Y轴显示格式-->
        <v-axis v-if="!isFrequency" dataKey="successRate" :label="labelFormater"></v-axis>
        <v-axis dataKey="countDate"></v-axis>
      </v-chart>
      <v-plugin v-if="type === 'day'">
        <v-slider
          width="auto"
          :height="26"
          :padding="[20, 40, 20, 40]"
          container="viser-slider-2"
          :start="startText"
          :end="endText"
          :data="dataSource"
          xAxis="countDate"
          :yAxis="`${this.isFrequency ? 'frequency' : 'successRate'}`"
          :scales="scalesld"
          :on-change="slideChange"
        />
      </v-plugin>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Array,
      default() {
        return []
      }
    },
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    query: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    dataSource: {
      handler() {
        if (this.dataSource.length > 0) {
          this.chartDv = []

          this.$nextTick(() => {
            const list = this.getData()
            this.chartDv = list
          })
        }
      },
      immediate: true
    },
    start: {
      handler() {
        this.startText = this.start
      },
      immediate: true
    },
    end: {
      handler() {
        this.endText = this.end
      },
      immediate: true
    },
    query: {
      handler() {
        this.isFrequency = this.query.recordingMethod === 'NUMBER_SUCCESS'
      },
      immediate: true
    }
  },
  computed: {
    scale() {
      return this.type === 'day'
        ? [
            { dataKey: 'countDate', mask: 'MM-DD' },
            { dataKey: this.isFrequency ? 'frequency' : 'successRate', min: 0 }
          ]
        : [
            { dataKey: 'countDate', mask: 'YYYY-MM' },
            { dataKey: this.isFrequency ? 'frequency' : 'successRate', min: 0 }
          ]
    }
  },
  mounted() {
    // this.setStyle()
  },
  methods: {
    setStyle() {
      const id = 'legend-html'
      if (document.getElementById(id)) {
        return
      }
      const styleTxt = `
          .g2-tooltip {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 3px;
            color: rgb(87, 87, 87);
            font-size: 12px;
            line-height: 20px;
            padding: 10px 10px 6px 10px;
            box-shadow: 0px 0px 10px #aeaeae;
            pointer-events: none;
          }

          .g2-tooltip-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
          }
          .g2-tooltip-value {
            margin-left: 30px;
            display: inline;
            float: right;
          }
          .g2-tooltip-tail {
            background-color: #f9f9f9;
            color: #909090;
            font-size: 14px;
            padding-bottom: 10px;
            margin-top: 10px;
            list-style-type: none;
            text-align: center;
            padding-top: 10px;
          }
          .g2-tooltip-story {
            color: #9b9b9b;
            font-size: 12px;
            padding-bottom: 10px;
            margin-top: 10px;
            list-style-type: none;
            padding-top: 10px;
          }
      `
      const style = document.createElement('style')
      style.setAttribute('id', id)
      style.innerHTML = styleTxt
      document.getElementsByTagName('head')[0].appendChild(style)
    },
    getData() {
      const { startText, endText, type, dataSource } = this
      const list =
        type === 'day'
          ? dataSource.filter(v => {
              return v.countDate >= startText && v.countDate <= endText
            })
          : dataSource
          
      return list
    },
    slideChange(opts) {
      this.startText = opts.startText
      this.endText = opts.endText
      const list = this.getData()
      this.chartDv = list
    }
  },
  data() {
    return {
      isFrequency: false, // 是否频率
      chartDv: [],
      startText: this.start,
      endText: this.end,
      labelFormater: {
        formatter: function formatter(text) {
          return text + '%'
        }
      },

      style: {
        lineWidth: 2
      },
      padding: [48, 48, 96, 48],

      scalesld: {
        countDate: {
          type: 'time',
          mask: 'YYYY-MM-DD'
        }
      },

      htmlContent: (title, items) => {
        var html = '<div class="g2-tooltip">'
        var titleDom = '<div class="g2-tooltip-title" style="margin-bottom: 4px;">' + title + '</div>'
        var listDom = '<ul class="g2-tooltip-list">'
        for (var i = 0; i < items.length; i++) {
          var item = items[i]
          var origin = item.point._origin

          var itemDom =
            '<li data-index={index}>' +
            '<div>' +
            '<span style="background-color:' +
            item.color +
            ';width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>' +
            item.name +
            // '<span class="g2-tooltip-value">' +
            // item.value +
            // '</span>' +
            '</div>' +
            '<div>' +
            '<span style="width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>' +
            '回合数' +
            '<span class="g2-tooltip-value">' +
            origin.rounds +
            '</span>' +
            '</div>' +
            '<div>' +
            '<span style="width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>' +
            `${this.isFrequency ? '次数' : '正确率'}` +
            '<span class="g2-tooltip-value">' +
            `${this.isFrequency ? origin.frequency : origin.successRate} ${this.isFrequency ? '' : '%'}` +
            '</span>' +
            '</div>' +
            '<div>' +
            '<span style="width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>' +
            '时长' +
            '<span class="g2-tooltip-value">' +
            origin.duration +
            '</span>' +
            '</div>' +
            '</li>'
          listDom += itemDom
        }
        listDom += '</ul>'

        return html + titleDom + listDom + '</div>'
      }
    }
  }
}
</script>
