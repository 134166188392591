import request from '@/utils/request'

const planStatisticsApi = {
  fieldSkillsCount: '/skills_statistics/field-skills-count',
  fieldsTrainingCount: '/skills_statistics/fields-training-count',
  skillsNumbers: '/skills_statistics/skills-all-count'
}

export function fieldSkillsCount(parameter) {
  return request({
    url: planStatisticsApi.fieldSkillsCount,
    method: 'get',
    params: parameter
  })
}

export function fieldsTrainingCount(parameter) {
  return request({
    url: planStatisticsApi.fieldsTrainingCount,
    method: 'get',
    params: parameter
  })
}

export function skillsNumbers(parameter) {
  return request({
    url: planStatisticsApi.skillsNumbers,
    method: 'get',
    params: parameter
  })
}
