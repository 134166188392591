<template>
  <div class="quater-report">
    <a-affix :offset-top="0">
      <div style="padding: 24px; background: #fff">
        <a-row v-if="!isPdf" type="flex" align="middle">
          <a-space v-if="saved" align="center" style="color: #666666">
            <a-icon type="check-circle" />
            <span>{{ savedTime | moment('HH:mm:ss') }} 保存成功</span>
          </a-space>
          <a-space v-if="saving" align="center" style="color: #666666">
            <a-icon type="clock-circle" />
            <span>正在保存中......</span>
          </a-space>
          <a-space v-if="failed" align="center" style="color: #666666">
            <a-icon type="exclamation-circle" />
            <span>未检测到网络，保存失败</span>
          </a-space>
          <a-space align="center" style="margin-left: auto">
            <template v-if="['detail', 'publish'].includes(viewMode)">
              <a-button type="primary" shape="round" style="width: 98px" @click="backCalendar">返回</a-button>
              <a-button type="primary" shape="round" @click="isPdf = true"
                ><a-icon type="file-search" />预览PDF</a-button
              >
              <a-button
                v-if="viewMode === 'publish'"
                type="primary"
                shape="round"
                style="width: 98px"
                @click="handlePublish"
                >发布</a-button
              >
            </template>
            <template v-if="viewMode === 'edit'">
              <a-button :loading="saving" type="primary" shape="round" @click="handleSave()">
                <a-icon v-if="!saving" type="file-text" />保存并返回
              </a-button>
              <a-button type="primary" shape="round" @click="isPdf = true"
                ><a-icon type="file-search" />预览PDF</a-button
              >
              <a-button
                type="primary"
                v-if="$auth('data_report_regeneration')"
                shape="round"
                style="width: 98px"
                @click="visibleRegenerate = true"
                >重新生成</a-button
              >
            </template>
          </a-space>
        </a-row>
        <a-row v-else type="flex" justify="end">
          <a-space>
            <a-button
              type="primary"
              shape="round"
              style="width: 98px"
              @click="
                () => {
                  isPdf = false
                  exportPdfBtn = true
                }
              "
              >返回详情</a-button
            >
            <a-button
              v-if="$auth('data_report_export_pdf')"
              type="primary"
              shape="round"
              :disabled="exportPdfBtn"
              @click="handleExportPdf"
            >
              <a-icon type="file-search" /> 导出PDF
            </a-button>
          </a-space>
        </a-row>
      </div>
    </a-affix>
    <a-spin v-if="loading" style="width: 100%; padding: 48px 0"></a-spin>
    <template v-if="!loading">
      <template v-if="!isPdf">
        <content-card nopadding>
          <div style="padding: 0 24px 24px 24px">
            <div style="text-align: center; font-size: 20px; font-weight: 500">
              {{ detail.studentName }}小朋友的教学进展季报
            </div>
            <div style="text-align: center; margin-top: 10px">
              {{ detail.startDate | moment('YYYY年MM月DD日') }}—{{ detail.endDate | moment('YYYY年MM月DD日') }}
            </div>
            <div
              class="colorful-container"
              :style="{
                padding: '34px 64px',
                borderRadius: '10px',
                marginTop: '36px',
                backgroundImage: `url(${bg})`,
              }"
            >
              <div style="position: relative; z-index: 2">
                <div style="font-size: 16px; line-height: 35px; font-weight: 500; color: #e75200; margin-bottom: 8px">
                  {{ detail.startDate | moment('YYYY年MM月DD日') }}-{{ detail.endDate | moment('YYYY年MM月DD日')
                  }}{{ `，${detail.studentName}小朋友经历了${detail.teachingDay}个教学日` }}
                </div>
                <div style="font-weight: 500; line-height: 30px">
                  {{ `学习目标横跨${detail.fieldNum}个领域，${detail.subFieldNum}个子领域；` }}
                </div>
                <div style="font-weight: 500; line-height: 30px">
                  {{ `学习长期目标${detail.longTermGoalsNum}个，含括短期目标${detail.shortTermGoalsNum}个；` }}
                </div>
                <div
                  style="font-weight: 500; line-height: 30px"
                  v-if="detail.longTermGoalsQualifiedNum > 0 && detail.shortTermGoalsQualifiedNum > 0"
                >
                  {{
                    `共有${detail.longTermGoalsQualifiedNum}个长期目标达标，${detail.shortTermGoalsQualifiedNum}个短期目标达标；`
                  }}
                </div>
              </div>
              <img :src="fg" class="colorful-fg" alt="" />
            </div>
          </div>
        </content-card>
        <content-card style="margin-top: 16px" title="长期目标达标情况">
          <chart-pie :series="longTermGoals"></chart-pie>
        </content-card>
        <content-card style="margin-top: 16px" title="练习素材情况">
          <div style="padding: 16px; background: #f6f8ff">
            <pre class="pre" v-html="materialDesc"></pre>
          </div>
          <a-row wrap>
            <a-col
              v-for="(material, index) in quarterlyReportMaterialList"
              :key="`${material.skillCode}${index}`"
              :xl="6"
              :sm="8"
            >
              <chart-hole
                :name="[material.skillCode, splitName(material.skillName)]"
                :series="filterSeries(material)"
              ></chart-hole>
            </a-col>
          </a-row>
        </content-card>
        <content-card style="margin-top: 16px">
          <template v-if="viewMode === 'edit'">
            <div style="position: relative">
              <a-textarea
                v-model="detail.appraisal"
                class="block-textarea"
                style="min-height: 109px"
                :max-length="500"
                :autoSize="false"
              ></a-textarea>
              <div class="textnumber">{{ detail.appraisal.length || 0 }}/500</div>
            </div>
            <div style="position: relative; margin-top: 24px">
              <a-textarea
                v-model="detail.institutionalInformation"
                class="block-textarea"
                style="text-align: right"
                :showCount="true"
                :max-length="500"
                :autoSize="false"
              ></a-textarea>
              <!-- <div class="textnumber">{{ detail.institutionalInformation.length || 0 }}/500</div> -->
            </div>
          </template>
          <template v-else>
            <p class="pre">{{ detail.appraisal }}</p>
            <br />
            <p class="pre" style="text-align: right">{{ detail.institutionalInformation }}</p>
          </template>
        </content-card>
      </template>
      <div v-else style="background: #fff; overflow-x: auto">
        <pdf-preview
          :dataSource="detail"
          ref="pdfPreview"
          @exportPdfButton="
            (flag) => {
              exportPdfBtn = flag
            }
          "
        ></pdf-preview>
      </div>
    </template>

    <!-- 返回弹窗 -->
    <a-modal
      v-model="visibleBack"
      title="报告数据已成功保存"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '40px', paddingLeft: '24px', paddingBottom: '40px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="
        visibleBack = false
        backCalendar()
      "
      @cancel="visibleBack = false"
    >
      <div class="modaltitle">是否返回报告列表页？</div>
    </a-modal>

    <!-- 重新生成 -->
    <a-modal
      v-model="visibleRegenerate"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round', disabled: regenerating },
        domProps: {
          innerHTML: regenerating ? '生成中……' : '重新生成',
        },
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="doRegenerate"
      @cancel="
        regenerating = false
        visibleRegenerate = false
      "
    >
      <div class="modaltitle">重新生成报告将重头开始制作， 是否确认重新生成？</div>
    </a-modal>

    <!-- 报告有未填写的内容 -->
    <a-modal
      v-model="visibleCheck"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '继续编辑',
        },
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="
        visibleCheck = false
        viewMode = 'edit'
        eventParam.type = 'edit'
      "
      @cancel="visibleCheck = false"
    >
      <div class="modaltitle">报告还没有写完哦</div>
      <div class="submodaltitle">请继续完成未填写的内容</div>
    </a-modal>
  </div>
</template>

<script>
import Bg from './assets/bg.png'
import Fg from './assets/fg.png'
import * as Api from '@/api/datareport'
import { ContentCard, ChartPie, ChartHole } from './components'
import PdfPreview from './pdf'
import { mapActions } from 'vuex'

export default {
  components: {
    ContentCard,
    ChartPie,
    ChartHole,
    PdfPreview,
  },
  data() {
    return {
      bg: Bg,
      fg: Fg,
      loading: true,
      detail: {
        appraisal: '', // 达标素材数
        endDate: undefined,
        fastStudyMaterialDay: 0,
        fastStudyMaterialRound: 0,
        fastStudyMaterials: [],
        fieldNum: 0,
        id: 0,
        institutionalInformation: '',
        longTermGoalsNum: 0,
        longTermGoalsQualifiedNum: 0,
        longTermGoalsTeachingNum: 0,
        materialNum: 0, // 练习素材数
        qualifiedMaterialNum: 0, // 达标素材数
        quarterlyReportMaterialList: [],
        quarterlyReportShortTermGoalsList: [],
        reportAuthor: '',
        shortTermGoalsNum: 0,
        shortTermGoalsQualifiedNum: 0,
        startDate: undefined,
        status: '',
        studentName: '',
        subFieldNum: 0,
        teachingDay: 0,
        totalRound: 0,
      },
      viewMode: 'detail', // 评估报告 查看方式 detail | edit | publish
      isPdf: false, // 评估报告 是否为pdf预览
      saving: false,
      saved: false,
      failed: false,
      regenerating: false,
      visibleRegenerate: false,
      visibleCheck: false,
      visibleBack: false,
      savedTime: '',
      publishLoading: false,
      exportPdfBtn: true,
    }
  },
  computed: {
    longTermGoals() {
      const {
        detail: { longTermGoalsQualifiedNum = 0, longTermGoalsTeachingNum = 0 },
      } = this
      const ret = []
      longTermGoalsQualifiedNum > 0 && ret.push({ value: longTermGoalsQualifiedNum, name: '已达标' })
      longTermGoalsTeachingNum > 0 && ret.push({ value: longTermGoalsTeachingNum, name: '训练中' })
      return ret
    },
    materialDesc() {
      const {
        studentName,
        totalRound = 0,
        materialNum = 0,
        qualifiedMaterialNum = 0,
        fastStudyMaterials = [],
        fastStudyMaterialDay = 0,
        fastStudyMaterialRound = 0,
        quarterlyReportShortTermGoalsList = [],
      } = this.detail

      const strs = []

      if (qualifiedMaterialNum > 0) {
        strs.push(
          `在这期间，${studentName}小朋友总共练习的回合数达到${totalRound}回合；练习的素材量达${materialNum}个，其中达标的素材量为${qualifiedMaterialNum}个；`
        )
        strs.push(
          `<span style="font-weight: bolder;">最快学会的素材是：</span>\n${fastStudyMaterials
            .map((material) => `“${material.skillCode} ${material.skillName}”中的“${material.materialName}”`)
            .join('；\n')}；\n只练习了${fastStudyMaterialDay}天${fastStudyMaterialRound}回合就达标了。`
        )
        strs.push(
          `<br/><span style="font-weight: bolder;">练习回合数最多的短期目标是：</span>\n${quarterlyReportShortTermGoalsList
            .map(
              (goal) =>
                `“${goal.skillCode}${goal.skillName}”中的“${goal.phaseName}”，这个目标练习的素材量达${goal.materialNum}个，其中达标的素材量为${goal.qualifiedMaterialNum}个`
            )
            .join('；\n')}`
        )
      } else {
        strs.push(
          `在这期间，${studentName}小朋友总共练习的回合数达到${totalRound}回合；练习的素材量达${materialNum}个；`
        )
        strs.push(
          `<br/><span style="font-weight: bolder;">练习回合数最多的短期目标是：</span>\n${quarterlyReportShortTermGoalsList
            .map(
              (goal) =>
                `“${goal.skillCode}${goal.skillName}”中的“${goal.phaseName}”，这个目标练习的素材量达${goal.materialNum}个`
            )
            .join('；\n')}`
        )
      }

      return strs.join('\n')
    },
    quarterlyReportMaterialList() {
      const { quarterlyReportFieldList } = this.detail
      const list = []
      quarterlyReportFieldList.forEach((field) => {
        field.quarterlyReportMaterialList.forEach((material) => {
          list.push(material)
        })
      })

      return list
    },
  },
  methods: {
    ...mapActions(['changeSpinning']),

    initData(e) {
      // 控制按钮显示
      this.viewMode = e.type
      this.eventParam = e
      this.loading = true

      Api.inquireQuarterly(e.param)
        .then((res) => {
          const { code, data } = res
          // const newArr = []
          if (code === 0) {
            this.detail = data
            // // 初始化赋值
            // for (const name in data) {
            //   this.formQuery[name] = data[name]
            // }
            // //  技能教学进行拆分，一个阶段对应一个展示框
            // data.teachingTrainingDataReportSkillList.forEach(skill => {
            //   const newskill = {
            //     ...skill
            //   }
            //   newskill.dataReportSkillPhaseList = []
            //   skill.dataReportSkillPhaseList.forEach(phase => {
            //     newskill.dataReportSkillPhaseList.push(phase)
            //     newArr.push(newskill)
            //   })
            // })
            // this.formQuery.teachingTrainingDataReportSkillList = newArr
            // // 顶部评语占位字符
            // this.commentsplaceholder = `示例：今天我们的${data.studentName}小朋友表现超级棒！课程中大部分时间都保持了不错的学习状态，学习效率相比之前几个教学日有了超级明显的提升。最近老师们在教学中观察到${data.studentName}的语言丰富度、语气词的使用、语言的自发性以及和自发语言与当下情景的关联性等方面都有明显的提升，老师们也会针对${data.studentName}很多和当下相关的自发语言进行发散，增加${data.studentName}自发使用语言表达的频次。在今天的音乐律动活动中，${data.studentName}也表现得非常亮眼，一直跟随音乐跳舞，用小手比出鲨鱼咬咬的动作，做得非常棒哦！${data.studentName}每天都给老师们带来惊喜！截止今天，${data.studentName}有一些教学素材已经达标了，我们会给${data.studentName}换上新的教学素材。`
            // // 发布、再次发布类型下，检查报告是否完成
            // if (e.type === 'publish') {
            //   this.checkIsAllDone()
            // }
          }
        })
        .finally(() => {
          // const { afterRequestEventType } = this
          // const typeArr = ['detail', 'edit']
          // if (typeArr.includes(afterRequestEventType)) {
          //   this.spinning = false
          // }
          this.loading = false
        })
    },
    splitName(name, len = 6) {
      if (!name || name.length < 7) return name
      const source = name.split('')
      const rows = Math.ceil(source.length / 6)
      const ret = []
      let str = ''
      for (let i = 0; i < rows; i++) {
        str = source.slice(i * 6, (i + 1) * 6)
        ret.push(str.join(''))
      }
      console.log(ret.join('\n'))
      return ret.join('\n')
    },
    filterSeries(material) {
      const ret = []
      material.materialNum > 0 && ret.push({ value: material.materialNum, name: '训练中' })
      material.qualifiedMaterialNum > 0 && ret.push({ value: material.qualifiedMaterialNum, name: '已达标' })

      return ret
    },
    backCalendar() {
      // 获取类型，返回日历打开对应的报告月份
      const { eventParam, formQuery } = this
      // 提交返回事件
      const event = {
        type: 'cancel',
        toMenuName: 'calendar',
        toSubMenuName: '',
        param: {
          // 获取类型，返回日历打开对应的报告月份
          workMonth: eventParam.subType ? formQuery.startTime : null,
        },
      }
      this.$emit('event', event)
    },
    handleSave(notBack = false) {
      this.saving = true
      this.saved = false
      this.failed = false
      const { id, appraisal, institutionalInformation } = this.detail
      return new Promise((resolve, reject) => {
        Api.saveQuarterly({ id, appraisal, institutionalInformation })
          .then(() => {
            if (notBack !== true) {
              this.visibleBack = true
            }
            this.savedTime = new Date()
            this.saved = true
            resolve()
          })
          .catch(() => {
            this.failed = true
            reject()
          })
          .finally(() => {
            this.saving = false
          })
      })
    },
    doRegenerate() {
      this.regenerating = true
      Api.reQuarterly(this.eventParam.param.dataReportId)
        .then((res) => {
          const { code, data } = res
          if (code === 0) {
            this.eventParam.param.dataReportId = data.id
            this.detail = data
            this.visibleRegenerate = false
            this.$notification['success']({ message: '操作成功', description: `已重新生成教学进度季报` })
          }
        })
        .finally(() => {
          this.regenerating = false
        })
    },
    handlePublish() {
      this.publishLoading = false
      if (!this.detail.institutionalInformation || !this.detail.appraisal) {
        this.visibleCheck = true
        return
      }
      this.$confirm({
        title: `您正在发布${this.detail.studentName}的教学季报`,
        content: '是否确认发布？',
        okText: '确认发布',
        okButtonProps: {
          loading: this.publishLoading,
        },
        onOk: () => {
          this.publishLoading = true
          return new Promise((resolve, reject) => {
            Api.publishQuarterly(this.eventParam.param.dataReportId)
              .then((res) => {
                const { code } = res
                if (code === 0) {
                  resolve()
                  this.$notification['success']({ message: '操作成功' })
                  this.backCalendar()
                } else {
                  reject()
                }
              })
              .catch(() => {
                reject()
              })
              .finally(() => {
                this.publishLoading = false
              })
          })
        },
      })
    },
    handleExportPdf() {
      this.changeSpinning(true)
      this.exportPdfBtn = false
      this.$refs.pdfPreview.exportPDF()
    },
  },
}
</script>

<style lang="less" scoped>
.quater-report {
  color: #1a1a1a;
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
.block-textarea.ant-input {
  border-radius: 0;
  padding: 16px;
}
.textnumber {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 14px;
  color: #666666;
}
.modaltitle {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  span {
    color: #3a5ff8;
  }
}
.colorful-container {
  position: relative;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.colorful-fg {
  position: absolute;
  width: 414px;
  height: 132px;
  bottom: 32px;
}
@media (min-width: 1920px) {
  .colorful-fg {
    left: 874px;
  }
}
@media (max-width: 1919px) and (min-width: 1440px) {
  .colorful-fg {
    left: 658px;
  }
}
@media (max-width: 1439px) {
  .colorful-fg {
    right: 25px;
  }
}
</style>