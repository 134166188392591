<template>
  <div>
    <a-table
      rowKey="id"
      :data-source="list"
      :pagination="pagination"
      :loading="list_loading"
      @change="handleTableChange"
    >
      <a-table-column key="createTimestamp" title="时间" data-index="createTimestamp" align="center" width="120px">
        <template slot-scope="createTimestamp">
          <span>
            {{ createTimestamp | moment('YYYY-MM-DD') }}
          </span>
        </template>
      </a-table-column>
      <a-table-column key="name" title="文件" align="center" data-index="name" width="200px" />
      <a-table-column key="actions" title="操作" align="center" width="200px">
        <template slot-scope="record">
          <a-space v-if="record.status === 'CREATED'">
            <a-button type="link" size="small" @click="handleRelease(record)"><a-icon type="rocket" />发布</a-button>
            <a-button
              v-if="$auth('service_delete')"
              type="link"
              size="small"
              @click="handleRemove(record)"
              class="error-color"
              ><a-icon type="delete" />删除</a-button
            >
          </a-space>
          <a-space v-else>
            <a-button type="link" size="small">已发布</a-button>
            <a-button type="link" size="small" @click="see(record)"><a-icon type="eye" />查看</a-button>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { list, remove, release } from '@/api/report'
export default {
  data() {
    return {
      pagination: {},
      list_loading: false,
      list: [],
      query: {},
      resource: {}
    }
  },
  methods: {
    handleRelease(record) {
      const params = {
        id: record.id,
        studentId: this.query.studentId
      }
      release(params).then(res => {
        this.$message.success('发布成功')
        this.getData()
      })
    },
    handleRemove(record) {
      this.$confirm({
        title: '删除',
        content: '您确定要删除吗',
        onOk: () => {
          remove(record.id).then(res => {
            if (res.code === 0) {
              this.$message.success('删除成功')
              this.getData()
            }
          })
        }
      })
    },
    see(record) {
      window.open(record.url)
    },
    initData(params = {}) {
      if (!params.resource) {
        this.query = params
      } else {
        this.query.studentId = params.studentId
        this.query.reportType = params.reportType
        this.resource = params.resource
      }
      const { initPagination, getData } = this
      this.pagination = initPagination()
      getData()
    },
    getData() {
      this.list_loading = true
      const params = {
        ...this.query,
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.current
      }
      list(params).then(res => {
        if (res.code === 0) {
          this.pagination.total = res.data.totalItems
          this.list = res.data.list
          this.list_loading = false
        }
      })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      getData()
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
/deep/ .ant-btn {
  font-size: 12px;
}
</style>
