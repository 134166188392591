<template>
  <div>
    <div class="icon-title">
      <img :src="CHILD_FIELD_MAP[dataSource.fieldName]" style="width: 30px" alt="" />
      <div class="icon-title-text">
        <span>{{ dataSource.fieldName }}</span>
        <span v-if="dataSource.fieldNameEn" style="margin-left: 4px">{{ dataSource.fieldNameEn }}</span>
      </div>
    </div>
    <div class="content" :style="{ background: COLORS_MAP[field] && COLORS_MAP[field].secondary }">
      <pre class="pre" style="font-size: 18px">{{ dataSource.introduce }}</pre>
    </div>
    <block-content v-if="acquiredSkillList.length > 0" bordered style="padding: 24px; margin-bottom: 40px">
      <div class="content-title">已具备技能</div>
      <div v-for="(item, index) in acquiredSkillList" :key="item.id" style="font-size: 18px">
        {{ `${index + 1}、${item.goal}` }}
      </div>
    </block-content>
    <block-content v-if="developmentGoalsList.length > 0" bordered style="padding: 24px">
      <div class="content-title">发展目标</div>
      <div v-for="(item, index) in developmentGoalsList" :key="item.id" style="font-size: 18px">
        {{ `${index + 1}、${item.goal}` }}
      </div>
    </block-content>
  </div>
</template>

<script>
import { COLORS_MAP, CHILD_FIELD_MAP } from './colors'
import { Content as BlockContent } from '../../components'
import SubTitle from './SubTitle'
export default {
  components: {
    SubTitle,
    BlockContent
  },
  props: {
    field: {
      type: String,
      default() {
        return ''
      }
    },
    dataSource: {
      type: Object,
      default() {
        return {
          acquiredSkillList: [],
          developmentGoalsList: []
        }
      }
    }
  },
  data() {
    return {
      COLORS_MAP,
      CHILD_FIELD_MAP
    }
  },
  computed: {
    acquiredSkillList() {
      return this.dataSource.acquiredSkillList.filter(skill => skill.isSelect)
    },
    developmentGoalsList() {
      return this.dataSource.developmentGoalsList.filter(skill => skill.isSelect)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  padding: 24px 16px;
  margin-bottom: 48px;
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}
.icon-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 40px;
  img {
    margin-right: 10px;
  }
}
.icon-title-text {
  font-size: 18px;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 22px;
}
.content-title {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #1a1a1a;
  line-height: 28px;
}
</style>