<template>
  <div>
    <calendar v-show="showMenuName === 'calendar'" ref="calendar" @eventsubmit="changeMenu"></calendar>
    <steps v-show="showMenuName === 'steps'" ref="steps" @eventsubmit="changeMenu"></steps>
    <report v-if="showMenuName === 'report'" ref="report" @eventsubmit="changeMenu"></report>
    <export v-show="showMenuName === 'export'" ref="export" @eventsubmit="changeMenu"></export>
  </div>
</template>

<script>
import Calendar from './calendar/index'
import Steps from './steps/index'
import Report from './report/index'
import Export from './export/index'
export default {
  components: {
    Calendar,
    Steps,
    Report,
    Export
  },
  data() {
    return {
      showMenuName: 'calendar',
      eventParam: {}
    }
  },
  methods: {
    /**
     * 根据事件对象，切换展示页面
     * event:｛｝
     * @type 事件类型，分为newadd（新增）、detail(查看)、edit(编辑)、publish(发布)、regenerate(重新生成),cancel（返回报告日历）、export(导出pdf)
     * @toMenuName 对应要显示的下个模块
     * @toSubMenuName 对应日、周、月等类型
     * @param 携带的详细数据，获取初始数据时使用
     */
    changeMenu(event) {
      this.showMenuName = event.toMenuName

      switch (event.toMenuName) {
        case 'steps':
          this.$refs.steps.showStep(event)
          break
        case 'report':
          this.eventParam = event
          this.$nextTick(()=>{
            this.$refs.report.showReport(event)
          })
          break
        case 'calendar':
          this.$refs.calendar.initData(event)
          break
        case 'export':
          this.$refs.export.showExport(event)
          break
        default:
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
