<template>
  <div>
    <div v-if="list.length === 0" style="padding:64px 0;text-align: center;">
      <a-empty v-if="!listLoading"></a-empty>
    </div>
    <detail-item
      :id="planInfo.id"
      v-for="(planInfo, index) in list"
      :key="planInfo.id"
      :dataSource="planInfo"
      @remove="handleRemove(index)"
      @replace="handleReplace"
      @update="handleUpdate"
      @preparation-check="e => $emit('preparation-check', e)"
      @statistic-check="e => $emit('statistic-check', { ...e, planSkillId: planInfo.id })"
      @teaching-video="e => $emit('teaching-video', e)"
      style="margin-bottom:24px"
    >
    </detail-item>

    <skill-modal ref="modal" @submit="handleSkillSelected"></skill-modal>
    <skill-edit-modal ref="editModal" @success="handleSkillUpdated"></skill-edit-modal>
  </div>
</template>

<script>
import * as planApi from '@/api/plan'
import DetailItem from './DetailItem'
import SkillModal from './SkillModal'
import SkillEditModal from './SkillEditModal'
import { mapActions } from 'vuex'

export default {
  components: {
    DetailItem,
    SkillModal,
    SkillEditModal
  },
  data() {
    return {
      query: {},
      listLoading: true,
      list: []
    }
  },
  watch: {
    listLoading(newval) {
      if (!newval) {
        const timer = setTimeout(() => {
          this.$nextTick(() => {
            const planSkillId = sessionStorage.getItem('planSkillId')
            if (planSkillId) {
              const el = document.getElementById(planSkillId)
              el.scrollIntoView(false)
            }
            sessionStorage.removeItem('planSkillId')
            clearTimeout(timer)
          })
        }, 500)
      }
    }
  },
  computed: {
    skillIds() {
      return this.list.map(v => v.skill.id)
    },
    needPublish() {
      return this.list.some(v => v.status === 'CREATED')
    }
  },
  activated() {
    this.getData()
  },
  methods: {
    ...mapActions(['changeSpinning']),
    initData(query = {}) {
      const { getData } = this

      this.query = query

      return getData()
    },
    getData() {
      const {
        $route: {
          params: { id: studentId }
        },
        query
      } = this
      // 判断使用什么参数，默认 使用子分类id进行查询
      if (query.mainFieldId && query.fieldId === undefined) {
        // 使用领域id作为参数进行查询
        query.fieldId = query.mainFieldId
      }
      this.changeSpinning(true)
      return planApi
        .list({ ...query, studentId })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            if (data.length === 0) {
              this.$emit('createdInitData')
            } else {
              // 在status为PUBLISHED，且有数据的情况下，显示导出计划按钮
              if (query.status === 'PUBLISHED') {
                this.$emit('changeShowExportPlan', true)
              }
            }

            this.list = data
            this.$emit('publish', this.needPublish)
          }
        })
        .finally(() => {
          this.listLoading = false
          this.changeSpinning(false)
        })
    },
    showModal(type) {
      const { query, skillIds } = this
      this.skill2Use = type

      this.$nextTick(() => {
        this.$refs.modal.showModal(query, skillIds)
      })
    },
    handleRemove(index) {
      this.list.splice(index, 1)
    },
    handlePublish(cb) {
      const {
        $route: {
          params: { id }
        },
        $notification
      } = this

      return planApi.publishSkill(id).then(() => {
        $notification['success']({ message: '操作成功' })
        this.getData()
        cb()
      })
    },
    handleCreate() {
      this.showModal('create')
    },
    handleReplace(cb) {
      this.replaceCallback = cb
      this.showModal('replace')
    },
    handleUpdate({ skill, cb }) {
      this.updateCallback = cb
      this.$refs.editModal.showModal(skill)
    },
    handleSkillUpdated(goal) {
      this.updateCallback(goal)
    },
    handleSkillSelected(e) {
      const { skill2Use } = this

      this[skill2Use] && this[skill2Use](e)
    },
    create(selected) {
      if (selected) {
        const {
          $route: {
            params: { id: studentId }
          },
          $notification
        } = this
        const { id: skillId } = selected

        planApi
          .createSkill({ studentId, skillId })
          .then(() => {
            $notification['success']({ message: '操作成功' })
            this.getData()
          })
          .catch(err => {
            $notification['error']({ message: err.msg })
          })
      }
    },
    replace(selected) {
      if (selected) {
        this.replaceCallback(selected.id, () => {
          this.getData()
          this.replaceCallback = null
        })
      } else {
        this.replaceCallback = null
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
