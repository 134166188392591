<template>
  <div>
    <total-card></total-card>

    <a-row :gutter="24" style="margin-top:24px">
      <a-col :span="12">
        <skill-main-field-card></skill-main-field-card>
      </a-col>
      <a-col :span="12">
        <skill-field-card></skill-field-card>
      </a-col>
    </a-row>

    <training-card style="margin-top:24px"></training-card>
    <up-to-standard-card style="margin-top:24px"></up-to-standard-card>
  </div>
</template>

<script>
import TotalCard from './components/Total'
import SkillMainFieldCard from './components/SkillMainField'
import SkillFieldCard from './components/SkillField'
import TrainingCard from './components/Training'
import UpToStandardCard from './components/UpToStandard'
export default {
  components: {
    TotalCard,
    SkillMainFieldCard,
    SkillFieldCard,
    TrainingCard,
    UpToStandardCard
  }
}
</script>

<style lang="less" scoped></style>
