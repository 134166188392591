<template>
  <content-loading :loading="loading" loadingText="正在生成评估报告,请稍后……" class="ev-report-detail">
    <template v-if="!isPdf">
      <h2 style="text-align: center; margin-top: 24px; margin-bottom: 36px">
        {{ `${detail.username}嗨宝快学技能评估报告` }}
      </h2>
      <block-title>基本信息</block-title>
      <block-content style="padding: 24px 24px 8px 24px">
        <a-descriptions>
          <a-descriptions-item label="儿童姓名">{{ detail.username }}</a-descriptions-item>
          <a-descriptions-item label="儿童性别">{{ detail.sex | GenderText() }}</a-descriptions-item>
          <a-descriptions-item label="出生日期">{{ detail.birthday | moment('YYYY-MM-DD') }}</a-descriptions-item>
          <a-descriptions-item label="评估时间" style="padding-bottom: 0"
            >{{ detail.evaluationStartDate }}~{{ detail.evaluationCompletionDate }}</a-descriptions-item
          >
          <a-descriptions-item label="评估人" style="padding-bottom: 0">{{
            detail.teacherList.join('、') || '-'
          }}</a-descriptions-item>
          <a-descriptions-item label="报告撰写人" style="padding-bottom: 0">{{
            detail.reportAuthor || '-'
          }}</a-descriptions-item>
        </a-descriptions>
      </block-content>
      <a-divider style="margin: 0" />
      <block-content style="padding: 24px 24px 8px 24px" class="custom-descriptions">
        <a-descriptions :column="1">
          <a-descriptions-item v-if="detail.diagnosticResults" label="诊断结果">
            <div style="white-space: pre-wrap">
              {{ detail.diagnosticResults || '-' }}
            </div></a-descriptions-item
          >
          <a-descriptions-item v-if="detail.basicFamilyInformation" label="基本家庭信息" style="padding-bottom: 0">
            <div style="white-space: pre-wrap">
              {{ detail.basicFamilyInformation || '-' }}
            </div></a-descriptions-item
          >
        </a-descriptions>
      </block-content>
      <div class="block-divider" />
      <block-title>评估工具介绍</block-title>
      <block-content style="padding: 24px">
        <pre class="pre">{{ detail.introduce }}</pre>
      </block-content>
      <div class="block-divider" />
      <block-title>各领域评估分析</block-title>
      <block-content style="padding: 24px">
        <a-radio-group class="report-field-redio" v-model="activeField" button-style="solid">
          <a-radio-button :value="item.value" v-for="item in fieldsOptions" :key="item.value">
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
        <block-content secondary style="padding: 24px 16px; margin-top: 14px">
          <pre class="pre">{{ activeFieldDetail.introduce }}</pre>
        </block-content>
        <sub-title style="margin-top: 32px">领域得分情况</sub-title>
        <a-row :gutter="[0, 0]">
          <a-col :lg="12" :md="24" :sm="24" style="padding-top: 30px">
            <score-table :dataSource="activeFieldDetail"></score-table>
          </a-col>
          <a-col :lg="12" :md="24" :sm="24">
            <div :id="'chart-wrapper-pie' + activeFieldDetail.fieldId" class="chart">
              <!-- 饼图 -->
              <charts-pie
                :isshow="activeField === activeFieldDetail.fieldId"
                :data="activeFieldDetail"
                :chartid="activeFieldDetail.fieldId"
                ref="ChartPie"
                :idName="'pie' + activeFieldDetail.fieldId"
              >
              </charts-pie>
            </div>
          </a-col>
        </a-row>
        <sub-title style="margin-top: 32px">领域计分总表</sub-title>
        <div style="padding: 0 24px 24px 24px; min-height: 100%; display: flex; position: relative; overflow-x: auto">
          <evaluate-table
            :isshow="activeField === activeFieldDetail.fieldId"
            ref="evaluateTable"
            :data="activeFieldDetail"
            @example="() => {}"
            :is-report="true"
            @detail="evaluateDialogDetail"
          ></evaluate-table>
        </div>
        <div v-for="fieldDetail in activeFieldDetail.evaluationReportFieldDetailsList" :key="fieldDetail.fieldId">
          <field-introduce
            :title="`${fieldDetail.fieldName} ${fieldDetail.fieldNameEn}`"
            :content="fieldDetail.introduce"
            style="margin-bottom: 32px"
          ></field-introduce>
          <edit-module
            title="已具备技能"
            module="技能"
            :editable="isEditMode"
            :list="fieldDetail.acquiredSkillList"
          ></edit-module>
          <edit-module
            title="发展目标"
            module="目标"
            :editable="isEditMode"
            :list="fieldDetail.developmentGoalsList"
          ></edit-module>
        </div>
        <template v-if="isEditMode || activeFieldDetail.isDisplaySkillInterpret">
          <sub-title>
            领域技能解读
            <template #extra>
              <template v-if="isEditMode">
                <a-tooltip v-if="activeFieldDetail.isDisplaySkillInterpret" placement="left">
                  <template slot="title"> 隐藏后将不再报告中展示此模块 </template>
                  <visible-switcher
                    :visible="activeFieldDetail.isDisplaySkillInterpret"
                    @click="activeFieldDetail.isDisplaySkillInterpret = !activeFieldDetail.isDisplaySkillInterpret"
                  ></visible-switcher>
                </a-tooltip>
                <visible-switcher
                  v-else
                  :visible="activeFieldDetail.isDisplaySkillInterpret"
                  @click="activeFieldDetail.isDisplaySkillInterpret = !activeFieldDetail.isDisplaySkillInterpret"
                ></visible-switcher>
              </template>
            </template>
          </sub-title>
          <template v-if="activeFieldDetail.isDisplaySkillInterpret">
            <a-textarea
              v-if="isEditMode"
              v-model="activeFieldDetail.skillInterpret"
              :placeholder="placeholder"
              class="block-textarea"
              :class="
                !activeFieldDetail.skillInterpret && !everySkillInterpretIsEmpty ? 'border-waring' : 'border-normal'
              "
              style="min-height: 169px"
              :maxLength="1000"
              :autoSize="false"
            ></a-textarea>
            <block-content v-else bordered style="padding: 24px">
              <pre class="pre" v-if="activeFieldDetail.skillInterpret">{{ activeFieldDetail.skillInterpret }}</pre>
              <div v-else style="text-align: center; padding: 32px 0">当前领域暂无技能解读</div>
            </block-content>
          </template>
        </template>
      </block-content>
    </template>
    <pdf-preview
      v-else
      :dataSource="detail"
      id="report-pdf-preview"
      @exportReportPdfButton="
        (flag) => {
          $emit('exportReportPdfButton', flag)
        }
      "
    ></pdf-preview>

    <!-- 评估结果-单个题目-详情-->
    <evaluate-dialog ref="evaluateDialog" :data="evaluateQuestionDetail"></evaluate-dialog>
  </content-loading>
</template>

<script>
import * as planApi from '@/api/plan'
import { ContentLoading } from '@/components'
import cloneDeep from 'lodash.clonedeep'
import html2Canvas from 'html2canvas'
import evaluateDialog from '@/views/home-school-service/detail/evaluation/components/evaluate-dialog.vue'
import JsPDF from 'jspdf'
import moment from 'moment'
import { convertToChineseNumber } from '@/utils/util'
import { mapActions } from 'vuex'
import {
  Title as BlockTitle,
  Content as BlockContent,
  SubTitle,
  ScoreTable,
  FieldIntroduce,
  EditModule,
  VisibleSwitcher,
  chartsPie
} from './components'
import PdfPreview from './pdf'
import EvaluateTable from '@/views/home-school-service/detail/evaluation/components/Table'

import * as evaluationReportApi from '@/api/evaluation-report'
export default {
  name: 'EvaluationReportDetail',
  components: {
    ContentLoading,
    BlockTitle,
    BlockContent,
    SubTitle,
    ScoreTable,
    FieldIntroduce,
    EditModule,
    VisibleSwitcher,
    PdfPreview,
    chartsPie,
    EvaluateTable,
    evaluateDialog
  },
  props: {
    reportId: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    viewType: {
      type: String,
      default() {
        return ''
      }
    },
    isPdf: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      placeholder: `1、具体领域表现分析：儿童在此领域的总体技能表现、优势项和弱势项分析；\n2、分项目表现分析：儿童在各个评估分项目中的具体表现，在评估中表现出的亮点，已经后续需要关注的发展问题，有针对性地对具体项目进行记录和分析；\n3、阶段干预建议：根据儿童在此领域的技能表现，综合优弱势项，给出相应的训练建议，指出下一阶段的发展方向。`,
      loading: true,
      detail: {
        teacherList: []
      },
      activeField: '',
      pdfVisible: false,
      evaluateQuestionDetail: {} // 评估结果-单个题目-详情
    }
  },
  computed: {
    activeFieldDetail() {
      if (!this.activeField) return { evaluationReportFieldDetailsList: [] }
      return this.detail.evaluationReportFieldList.find(field => field.fieldId === this.activeField)
    },
    fieldsOptions() {
      if (!this.detail.evaluationReportFieldList || this.detail.evaluationReportFieldList.length === 0) return []
      return this.detail.evaluationReportFieldList.map(field => ({ label: field.fieldName, value: field.fieldId }))
    },
    isEditMode() {
      return this.viewType === 'edit'
    },
    everySkillInterpretIsEmpty() {
      return this.detail.evaluationReportFieldList.every(field => {
        return field.skillInterpret
      })
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions(['changeSpinning']),

    getData() {
      this.loading = true
      const tempLevel = [`LEVEL1`, `LEVEL2`, `LEVEL3`]

      evaluationReportApi
        .detail(this.reportId)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            const _data = cloneDeep(data)
            // 处理领域计分总表的数据（涂色的小格子）,LEVEL在空的时候填充一条数据
            _data.evaluationReportFieldList = _data.evaluationReportFieldList.map((item, index) => {
              const { fieldName, evaluationReportFieldDetailsList } = item
              const children = []
              evaluationReportFieldDetailsList.map((item1, index1) => {
                const { fieldName, evaluationLevelList } = item1
                const levelList = []
                tempLevel.map((item2, index2) => {
                  const levelItem = evaluationLevelList.filter((item3, index3) => {
                    return item3.level === item2
                  })
                  if (levelItem.length) {
                    levelList.push(levelItem[0])
                  } else {
                    levelList.push({ level: item2, subjectList: [] })
                  }
                })
                children.push({ fieldName, children: [], levelList })
              })
              return {
                ...item,
                ...{ evaluationFieldVOList: [{ fieldName, children, levelList: null }], levelTotal: 3, optionTotal: 4 }
              }
            })
            this.detail = _data
            if (_data.evaluationReportFieldList && _data.evaluationReportFieldList.length > 0) {
              this.activeField = _data.evaluationReportFieldList[0].fieldId
            } else {
              this.activeField = ''
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save() {
      const {
        detail: { evaluationReportFieldList },
        reportId
      } = this
      return evaluationReportApi.update({
        id: reportId,
        evaluationReportFieldDList: evaluationReportFieldList.map(field => ({
          id: field.id,
          isDisplaySkillInterpret: field.isDisplaySkillInterpret,
          skillInterpret: field.skillInterpret,
          evaluationReportFieldDetailsList: field.evaluationReportFieldDetailsList.map(fieldDetail => ({
            acquiredSkillList: fieldDetail.acquiredSkillList.filter(skill => skill.isSelect).map(skill => skill.id),
            developmentGoalsList: fieldDetail.developmentGoalsList
              .filter(skill => skill.isSelect)
              .map(skill => skill.id),
            id: fieldDetail.id
          }))
        }))
      })
    },

    async exportPDF({ type, id }) {
      const canvas1 = await this.htmlCanvas(id)

      const PDF = new JsPDF('', 'pt', 'a4')

      // await this.savePDF(canvas1, PDF)
      await this.savePDF_NEW(canvas1, PDF)
      console.log(16)
      // 导出
      PDF.save(
        `${this.detail.username}嗨宝快学技能评估报告-第${this.convertToChineseNumber(type)}部分-${moment().format(
          'YYYY.MM.DD'
        )}` + '.pdf'
      )
      this.$emit('exportReportPdfButton', false)
      this.changeSpinning(false)
    },

    htmlCanvas(dom) {
      return new Promise((resolve, reject) => {
        html2Canvas(document.querySelector(dom), {
          useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
          allowTaint: true // 允许跨域
        })
          .then(canvas => {
            resolve(canvas)
          })
          .catch(() => {
            reject(false)
          })
      })
    },

    savePDF(canvas, PDF) {
      return new Promise((resolve, reject) => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 根据A4纸的大小，计算出dom相应比例的尺寸
        const pageHeight = (contentWidth / 592.28) * 841.89
        console.log('pageHeight', pageHeight)
        let leftHeight = contentHeight
        let position = 0
        const imgWidth = 595.28
        // 根据a4比例计算出需要分割的实际dom位置
        const imgHeight = (592.28 / contentWidth) * contentHeight
        console.log('imgHeight', imgHeight)

        // canvas绘图生成image数据，1.0是质量参数
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        // a4大小
        // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          console.log(leftHeight)
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)

            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        resolve(PDF)
      })
    },

    savePDF_NEW(canvas, PDF) {
      return new Promise((resolve, reject) => {
        const _quality = 1
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        console.log(`contentWidth::${contentWidth},,contentHeight::${contentHeight}`)
        const a4Width = 595.28
        const a4Height = 841.89

        // 根据A4纸的大小，计算出dom相应比例的尺寸
        const pageHeight = Math.floor((contentWidth / a4Width) * a4Height)

        let leftHeight = contentHeight
        let position = 0

        // canvas绘图生成image数据，1.0是质量参数
        const pageData = canvas.toDataURL('image/jpeg', _quality)
        // eslint-disable-next-line no-unused-vars
        let index = 1
        const canvas1 = document.createElement('canvas')
        let height = 0
        const canvas2d = canvas.getContext('2d')
        PDF.setDisplayMode('fullwidth', 'continuous', 'FullScreen')
        const createImpl = canvas => {
          console.log(1,leftHeight)
          console.log(2,pageHeight)
          if (leftHeight > 0) {
            index++
            console.log(33,index)
            let checkCount = 0
            if (leftHeight > pageHeight) {
              let lunchCount = 0
              let i = position + pageHeight
              for (i = position + pageHeight; i >= position; i-=3) {
                let isWrite = true
                lunchCount++
                const cs = canvas2d.getImageData(0, i, contentWidth, 1).data
                if (cs.findIndex(item => item !== 0xff) !== -1) isWrite = false

                if (isWrite) {
                  checkCount++
                  if (checkCount >= 10) {
                    break
                  }
                } else {
                  checkCount = 0
                }
              }
              console.log(`for each #${lunchCount}`)
              height = Math.round(i - position) || Math.min(leftHeight, pageHeight)
              if (height <= 0) {
                height = pageHeight
              }
            } else {
              height = leftHeight
            }
            canvas1.width = canvas.width
            canvas1.height = height
            const ctx = canvas1.getContext('2d')
            ctx.drawImage(canvas, 0, position, canvas.width, height, 0, 0, canvas.width, height)
            if (position !== 0) {
              PDF.addPage()
            }
            PDF.addImage(canvas1.toDataURL('image/jpeg', _quality), 'JPEG', 0, 0, a4Width, a4Width / canvas1.width * height)
            leftHeight -= height
            position += height
            if (leftHeight > 0) {
              setTimeout(createImpl, 100, canvas)
            } else {
              resolve(PDF)
            }
          } else {
            console.log(666,`${leftHeight} 小于等于 0`)
          }
        }
        // a4大小
        // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', a4Width, a4Width / contentWidth * leftHeight)
          resolve(PDF)
        } else {
          try {
            setTimeout(createImpl, 100, canvas)
          } catch (e) {
            console.log(110,e)
          }
        }
      })
    },

    // 评估结果-单个题目-详情
    evaluateDialogDetail({ field, levelIndex, subjectIndex }) {
      const { levelList } = field
      if (
        levelList &&
        levelList[levelIndex] &&
        levelList[levelIndex].subjectList &&
        levelList[levelIndex].subjectList[subjectIndex]
      ) {
        const { id: subjectId } = levelList[levelIndex].subjectList[subjectIndex]
        const {
          $route: {
            params: { id }
          }
        } = this
        planApi
          .getLastAnswerQuestionDetail({
            subjectId,
            studentId: id
          })
          .then(res => {
            const { code, data } = res
            if (code === 0) {
              data.skillVOList = data.skillVOList.map(item => {
                return item.name
              })
              this.evaluateQuestionDetail = data
              this.$refs.evaluateDialog.showModal()
            }
          })
      }
    },
    convertToChineseNumber
  },

  watch: {
    activeField(newval, oldval) {
      if (newval) {
        setTimeout(() => {
          this.$refs.ChartPie.initChart()
          this.$refs.evaluateTable.initDataReport()
        }, 300)
      }
    },
    isPdf(newval) {
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.custom-descriptions {
  /deep/ .ant-descriptions-item {
    display: flex;
  }
}
.pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
}
.ev-report-detail {
  overflow-x: auto;
  /deep/ .ant-descriptions-item-label {
    font-weight: 600;
  }
  .report-field-redio {
    /deep/ .ant-radio-button-wrapper {
      border-radius: 0;
      font-weight: 600;
      height: 48px;
      line-height: 48px;
      // padding: 0 36px;
    }
  }
  /deep/
    .ant-radio-group-solid.report-field-redio
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: inherit;
  }
}
.block-divider {
  height: 16px;
  background-color: #f7f8fc;
}
.block-textarea.ant-input {
  border-radius: 0;
  padding: 24px 16px;
}

.border-waring {
  border: 1px solid red;
}

.border-normal {
  border: 1px solid #dfe0eb;
}
</style>
