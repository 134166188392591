<!-- 家校训练服务-详情-训练计划-导出计划 -->
<template>
  <div style="padding-left: 12px; padding-right: 12px">
    <!--列表-->
    <a-card :bodyStyle="{ padding: 0 }" title="导出计划" v-if="current === 'LIST'">
      <div slot="extra">
        <a-button
          shape="round"
          type="primary"
          @click="handleAddExport()"
          v-if="$auth('export_plan_add') && !isSchoolStatus"
          style="margin-right: 16px"
          ><a-icon type="plus" />新增导出</a-button
        >
        <a-button shape="round" @click="$emit('switch')"><a-icon type="left" />返回</a-button>
      </div>
      <a-table rowKey="id" :data-source="list" :pagination="false" :loading="listLoading">
        <a-table-column key="createTime" title="导出时间" align="center" data-index="createTime" :ellipsis="true">
          <span>
            {{ createTime | moment('YYYY-MM-DD') }}
          </span>
        </a-table-column>
        <a-table-column key="publishedTime" title="发布时间" align="center" data-index="publishedTime" :ellipsis="true">
          <template slot-scope="publishedTime">
            <span>
              {{ publishedTime | moment('YYYY-MM-DD') }}
            </span>
          </template>
        </a-table-column>

        <a-table-column key="writer" title="撰写人" align="center" data-index="writer" :ellipsis="true" />
        <a-table-column key="publishedBy" title="发布人" align="center" data-index="publishedBy" :ellipsis="true" />
        <a-table-column key="id" title="报告状态" align="center" data-index="status" :ellipsis="true">
          <template slot-scope="status">
            <span>
              {{ status | filterStatus }}
            </span>
          </template>
        </a-table-column>

        <a-table-column key="actions" title="操作" align="center" :ellipsis="true">
          <template slot-scope="record">
            <!-- 离园 -->
            <div v-if="isSchoolStatus">
              <a-button
                type="link"
                size="small"
                v-if="record.status === 'PUBLISHED' && $auth('export_plan_select')"
                @click="goPreview(record)"
                ><a-icon type="eye" />查看</a-button
              >
            </div>
            <!-- 在园 -->
            <div v-else>
              <a-button
                type="link"
                size="small"
                v-if="
                  (record.status == 'CREATED' && $auth('export_plan_update_status_published')) ||
                  (record.status == 'WITHDRAWAL' && $auth('export_plan_update_status_regeneration_published'))
                "
                @click="handleRelease(record)"
                ><a-icon type="rocket" />{{ record.status == 'WITHDRAWAL' ? '重新发布' : '发布' }}
              </a-button>
              <a-button
                type="link"
                size="small"
                v-if="
                  (record.status == 'CREATED' && $auth('export_plan_update_all_status_create')) ||
                  (record.status == 'WITHDRAWAL' && $auth('export_plan_update_all_status_withdrawal'))
                "
                @click="showEditVisible(record)"
                ><a-icon type="edit" />编辑</a-button
              >
              <a-button
                type="link"
                size="small"
                v-if="
                  (record.status == 'CREATED' && $auth('export_plan_delete_status_create')) ||
                  (record.status == 'WITHDRAWAL' && $auth('export_plan_delete_status_withdrawal'))
                "
                @click="showDeleteConfirm(record)"
                ><a-icon type="delete" />删除</a-button
              >
              <a-button
                type="link"
                size="small"
                v-if="record.status === 'PUBLISHED' && $auth('export_plan_select')"
                @click="goPreview(record)"
                ><a-icon type="eye" />查看</a-button
              >
              <a-button
                type="link"
                size="small"
                v-if="record.status == 'PUBLISHED' && $auth('export_plan_update_status_withdrawal')"
                @click="showWithdrawConfirm(record)"
                ><a-icon type="right-circle" />撤回</a-button
              >
            </div>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
    <!--发布-->
    <a-card :bodyStyle="{ padding: 0 }" v-if="current === 'PREVIEW'">
      <plan-preview
        @switch="current = 'LIST'"
        :page-from="previewFrom"
        :record-id="recordId"
        @publishSuccess="publishSuccess"
      ></plan-preview>
    </a-card>
    <!--新增导出-->
    <add-export-model
      ref="add"
      @commitSuccess="goPlanPreview"
      @editSuccess="getData"
      :edit-id="editValue"
      :create-time="createTime"
    />
  </div>
</template>

<script>
import * as Api from '@/api/field'
import planPreview from './exportPlanTableDetail'
import addExportModel from '../dialog/add-export-model'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      current: 'LIST',
      // query: {},
      // listLoading: false,
      list: [],
      editValue: null, // 编辑数据
      previewFrom: '', // 进入到详情页的来源
      recordId: null, // 报告id
      createTime: null // 创建时间（编辑时用）
    }
  },
  filters: {
    filterStatus: function(value) {
      switch (value) {
        case 'CREATED':
          return '待发布'
        case 'PUBLISHED':
          return '已发布'
        case 'WITHDRAWAL':
          return '已撤回'
        default:
          return '-'
      }
    }
  },
  computed: {
    ...mapGetters({
      isSchoolStatus: 'getSchoolStatus' // 学生-在园状态【true离园  false在园】
    })
  },
  methods: {
    // 新增导出
    handleAddExport() {
      this.editValue = null
      this.$nextTick(() => {
        this.$refs.add.showModal({ type: 'add' })
      })
    },
    // 编辑导出
    showEditVisible({ id: recordId, createTime }) {
      this.editValue = recordId
      this.createTime = createTime
      this.$nextTick(() => {
        this.$refs.add.showModal({ type: 'edit' })
      })
    },
    // 发布
    handleRelease({ id: recordId }) {
      this.current = 'PREVIEW'
      this.previewFrom = 'PUBLISH'
      this.recordId = recordId
    },
    // 新增导出成功后进入详情
    goPlanPreview(recordId) {
      this.recordId = recordId
      this.current = 'PREVIEW'
      this.previewFrom = 'ADD_EXPORT'
    },
    // 发布成功后进入列表页
    publishSuccess() {
      this.current = 'LIST'
      this.$nextTick(function() {
        this.getData()
      })
    },

    // 点击查看进入详情
    goPreview({ id: recordId, createTime }) {
      this.current = 'PREVIEW'
      this.previewFrom = 'VIEW_DETAIL'
      this.recordId = recordId
      this.createTime = createTime
    },
    // 删除
    showDeleteConfirm({ id }) {
      const _this = this
      this.$confirm({
        title: '您正在进行数据报告删除操作',
        content: '删除的报告将无法找回，请确认操作',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          Api.deletePlanRecord({ id }).then(res => {
            if (res.code === 0) {
              _this.$message.success('删除成功')
              _this.getData()
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    // 撤回
    showWithdrawConfirm({ id }) {
      const _this = this
      this.$confirm({
        title: '您正在进行数据报告撤回操作',
        content: '家长将无法查看被撤回的数据报告，撤回后您可编辑或再次发布该报告，请确认操作。',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          Api.editRecordStatus({ id }).then(res => {
            if (res.code === 0) {
              _this.$message.success('撤回成功')
              _this.getData()
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    // 初始化
    initData(query = {}) {
      const { getData } = this

      this.query = query

      return getData()
    },
    getData() {
      const { params } = this.$route
      return Api.getPlanExportList({ ...params })
        .then(res => {
          const { code } = res
          if (code === 0) {
            this.list = res.data
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleDetail({ id }) {
      this.$emit('row-click', id)
    }
  },
  components: {
    planPreview,
    addExportModel
  }
}
</script>

<style lang="less" scoped></style>
