<template>
  <div>
    <a-table rowKey="id" :data-source="list" :pagination="false" :loading="listLoading">
      <a-table-column key="status" title="序号" align="center" width="80px">
        <template slot-scope="text, record, index">
          <span>{{ index + 1 }}</span>
        </template>
      </a-table-column>

      <a-table-column
        key="createTimestamp"
        title="生成日期"
        align="center"
        data-index="createTimestamp"
        :ellipsis="true"
      >
        <template slot-scope="createTimestamp">
          <span>
            {{ createTimestamp | moment('YYYY-MM-DD') }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="planWriter" title="计划编辑教师" align="center" data-index="planWriter" :ellipsis="true" />

      <a-table-column key="skillCount" title="训练技能数量" align="center" data-index="skillCount" :ellipsis="true" />

      <a-table-column key="actions" title="操作" align="center" :ellipsis="true">
        <template slot-scope="record">
          <a-button type="link" size="small" @click="handleDetail(record)"><a-icon type="right-circle" />详情</a-button>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import * as Api from '@/api/field'

export default {
  data() {
    return {
      query: {},
      listLoading: false,
      list: []
    }
  },
  methods: {
    initData(query = {}) {
      const { getData } = this

      this.query = query

      return getData()
    },
    getData() {
      const { params } = this.$route
      this.listLoading = true
      return Api.getPlanHistoryList({ ...params })
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            this.list = data.list
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleDetail({ id }) {
      this.$emit('row-click', id)
    }
  }
}
</script>

<style lang="less" scoped></style>
