<template>
  <a-card :bodyStyle="{ padding: '0' }">
    <div slot="title" style="font-size:20px;font-weight:500">单个素材训练数据</div>
    <a-card
      class="main-card"
      :bordered="null"
      :headStyle="{ padding: '0 24px' }"
      :bodyStyle="{ padding: '0 24px 24px' }"
    >
      <a-row slot="title" type="flex" align="bottom">
        <a-col flex="1" style="display:flex;align-items: flex-start" v-cloak class="title-left">
          <div v-if="teachingClassMaterialCountVO.isQualified" style="margin-top: 5px;margin-right: 10px;">
            <img style="width: 35px" src="@/assets/ico_dabiao_star.png" alt="" />
          </div>
          <a-statistic title="素材" :value="materialName || '--'" style="margin-right: 40px" />
          <div class="ant-statistic" style="margin-right: 40px;">
            <div class="ant-statistic-title">累计回合数</div>
            <div class="ant-statistic-content">
              <div class="content-item">
                机构训练：<span class="suffix">{{ teachingClassMaterialCountVO.rounds }}</span>
              </div>
              <div class="content-item">
                课后作业：<span class="suffix">{{ homeworkMaterialCountVO.rounds }}</span>
              </div>
            </div>
          </div>
          <div class="ant-statistic" style="margin-right: 40px;">
            <div class="ant-statistic-title">
              {{
                query && query.recordingMethod && query.recordingMethod == 'NUMBER_SUCCESS'
                  ? '近三天平均次数'
                  : '近三天正确率'
              }}
            </div>
            <div class="ant-statistic-content">
              <div class="content-item">
                机构训练：<span class="suffix"
                  >{{
                    query && query.recordingMethod && query.recordingMethod == 'NUMBER_SUCCESS'
                      ? teachingClassMaterialCountVO.frequency
                      : teachingClassMaterialCountVO.successRate
                  }}{{
                    query &&
                    query.recordingMethod &&
                    query.recordingMethod != 'NUMBER_SUCCESS' &&
                    teachingClassMaterialCountVO.successRate >= 0
                      ? '%'
                      : ''
                  }}</span
                >
              </div>
              <div class="content-item">
                课后作业：<span class="suffix">
                  {{
                    query && query.recordingMethod && query.recordingMethod == 'NUMBER_SUCCESS'
                      ? homeworkMaterialCountVO.frequency
                      : homeworkMaterialCountVO.successRate
                  }}{{
                    query &&
                    query.recordingMethod &&
                    query.recordingMethod != 'NUMBER_SUCCESS' &&
                    homeworkMaterialCountVO.successRate >= 0
                      ? '%'
                      : ''
                  }}
                </span>
              </div>
            </div>
          </div>
        </a-col>
        <a-col>
          <a-space :size="24">
            <h-select v-model="materialName" placeholder="训练素材" style="width:200px">
              <a-select-option v-for="(material, index) in materialOptions" :key="index" :value="material.materialName">
                {{ material.materialName }}
              </a-select-option>
            </h-select>
            <a-radio-group
              v-if="lastThreeDaysTopRight === 'tabs'"
              v-model="dateType"
              @change="getData()"
              size="small"
              button-style="solid"
              :disabled="!materialOptions.length"
            >
              <a-radio-button value="day">
                日
              </a-radio-button>
              <a-radio-button value="month">
                月
              </a-radio-button>
            </a-radio-group>
            <a-range-picker v-if="lastThreeDaysTopRight === 'date'" v-model="dateRange" :allowClear="false" @change="changeDate()">
              <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </a-space>
        </a-col>
      </a-row>
      <a-empty v-if="empty" />
      <div style="height:480px;border:1px solid #707070" v-else>
        <main-chart :dataSource="list" :start="start" :end="end" :type="dateType" :query="query"></main-chart>
      </div>
    </a-card>
  </a-card>
</template>

<script>
import * as planStatisticsApi from '@/api/phase-material-statistics'
import moment from 'moment'
import MainChart from './Chart2'
// import { mapActions } from 'vuex'

export default {
  components: {
    MainChart
  },
  props:{
    // tabs 日期切换 date 日历选择
    lastThreeDaysTopRight:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      dateRange: [moment().subtract(181, 'days'), moment().subtract(1, 'days')],
      query:{},
      dateType: 'day',
      materialName: ' ',
      countDate: 0,
      homeworkMaterialCountVO: {},
      teachingClassMaterialCountVO: {},
      materialOptions: [], // 素材下拉列表
      list: [],
      start: '',
      end: '',
      empty: false // 是否为空（默认否）
    }
  },
  watch: {
    materialName() {
      if (this.materialName) {
        this.getData()
      }
    }
  },
  methods: {
    changeDate(){
      this.materialName = ''
      this.initData(this.query)
    },
    // ...mapActions(['changeSpinning']),
    initData(query = {}) {
      this.resetData()
      this.query = query
      const query_ = this.initQuery()
      planStatisticsApi.getMaterialList(query_).then(res => {
        const { code, data } = res
        if (code === 0) {
          if (!data || !data.length) {
            this.empty = true
            return
          }
          this.materialOptions = data
          this.materialName = this.materialOptions[0].materialName
        }
      })
    },
    async getData() {
      this.empty = true
      const query = this.initQuery()
      const { materialName } = this
      this.list = []
      this.homeworkMaterialCountVO = {}
      this.teachingClassMaterialCountVO = {}
      // this.changeSpinning(true)
      planStatisticsApi.getStatisticsBySingleMaterials({ ...query, materialName }).then(res => {
        this.empty = false
        // this.changeSpinning(false)
        const {
          homeworkMaterialCountVO,
          teachingClassMaterialCountVO,
          homeworkMaterialCountVOList,
          teachingClassMaterialCountVOList
        } = res.data
        
        if (!homeworkMaterialCountVOList.length && !teachingClassMaterialCountVOList.length) this.empty = true

        // 顶部信息
        this.homeworkMaterialCountVO =
          homeworkMaterialCountVOList.length > 0
            ? homeworkMaterialCountVO
            : {
                frequency: '--',
                rounds: '--',
                successRate: '--'
              }
        this.teachingClassMaterialCountVO =
          teachingClassMaterialCountVOList.length > 0
            ? teachingClassMaterialCountVO
            : {
                frequency: '--',
                rounds: '--',
                successRate: '--'
              }
        // 折线图数据
        for (const v of homeworkMaterialCountVOList) {
          this.list.push({
            ...v,
            materialName: `${v.materialName}(作业)`,
            countDate: moment(v.countDate).format('YYYY-MM-DD')
          })
        }
        for (const v of teachingClassMaterialCountVOList) {
          this.list.push({
            ...v,
            materialName: `${v.materialName}(教学)`,
            countDate: moment(v.countDate).format('YYYY-MM-DD')
          })
        }
      })
      // .catch(() => {
      //   this.changeSpinning(false)
      // })
    },
    initQuery() {
      const {
        query: { studentId, planSkillId, skillId, skillPhaseId },
        dateType
      } = this
      let startTimestamp = null
      let endTimestamp = null
      let isMonth = null
      if(this.lastThreeDaysTopRight === 'tabs'){
        isMonth = dateType === 'month'
        this.isMonth = isMonth
        startTimestamp = moment()
          .subtract(isMonth ? 6 : 181, [dateType + 's'])
          .format('YYYY-MM-DD')
        endTimestamp = moment().format('YYYY-MM-DD')

        this.start = moment()
          .subtract(isMonth ? 6 : 181, [dateType + 's'])
          .format('YYYY-MM-DD')
        this.end = moment().format('YYYY-MM-DD')
      }

      if(this.lastThreeDaysTopRight === 'date'){
        isMonth = false
        this.start = startTimestamp = moment(this.dateRange[0]).format('YYYY-MM-DD')
        this.end = endTimestamp = moment(this.dateRange[1]).format('YYYY-MM-DD')
      }

      return { studentId, skillPhaseId, planSkillId, skillId, isMonth, startTimestamp, endTimestamp }
    },
    resetData() {
      this.materialOptions = []
      this.materialName = ''
      this.dateType = 'day'
      this.countDate = 0
      this.homeworkMaterialCountVO = {}
      this.teachingClassMaterialCountVO = {}
      this.list = []
      this.start = ''
      this.end = ''
    }
  }
}
</script>

<style lang="less" scoped>
.main-card {
  /deep/ .ant-card-head-title {
    padding-top: 0;
  }
  /deep/ .ant-statistic-content-value {
    display: inline-block;
    line-height: 42px;
    text-align: center;
    color: #3a5ff8;
  }

  .title-left {
    .content-item {
      font-size: 16px;
      line-height: normal;
      .suffix {
        color: #3a5ff8;
      }
    }
  }
}
</style>
