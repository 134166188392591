<template>
  <div>
    <a-collapse default-active-key="1" :bordered="false" expandIconPosition="right" v-if="list">
      <a-collapse-panel v-for="i in list" :key="i.id" :style="customStyle">
        <div slot="header">
          <a-row type="flex" align="top">
            <a-col flex="1">
              <div class="heading-color">{{ i.name }}</div>
              <div style="position:relative">
                <div style="margin-top:8px">
                  <a-space>
                    <span>阶段 :</span>
                    <p class="pre">
                      共
                      <span class="error-color" style="margin:0">{{ i.historyPlanSkillPhaseListVOList.length }}</span>
                      阶段， 已完成
                      <span class="error-color" style="margin:0">{{ i.completedPhases }}</span>
                      阶段
                    </p>
                  </a-space>
                </div>
                <div style="margin-top:8px; ">
                  <span>目标 :</span>
                  {{ i.goal }}
                </div>
                <div
                  class="header-icon success-color"
                  v-if="i.completedPhases === i.historyPlanSkillPhaseListVOList.length"
                >
                  <h-icon type="icon-yiwancheng"></h-icon>
                </div>
              </div>
            </a-col>
            <a-col style="white-space:nowrap"><a>展开阶段列表</a></a-col>
          </a-row>
        </div>

        <div
          class="list-item"
          :class="[index + 1 == i.historyPlanSkillPhaseListVOList.length ? 'last' : '']"
          v-for="(item, index) in i.historyPlanSkillPhaseListVOList"
          :key="index"
        >
          <div style="font-size:16px">
            阶段：{{ item.name }}
            <a-button @click="handleHistoryStatisticDetail({ i, item })" type="link">查看训练数据</a-button>
          </div>
          <div style="margin-top:16px;font-size:16px">
            <span>目标 :</span>
            {{ item.goal }}
          </div>
          {{ item.status }}
          <div class="list-item-icon success-color" v-if="index < i.completedPhases">
            <h-icon type="icon-yiwancheng"></h-icon>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <a-empty v-else />
    <a-pagination
      v-show="list.length"
      style="display: flex;justify-content: flex-end;margin: 10px"
      :page-size-options="['10', '20', '50', '100']"
      v-model="pagination.pageIndex"
      :page-size="pagination.pageSize"
      :total="pagination.total"
      size="small"
      show-less-items
      @change="pageIndexChange"
    ></a-pagination>
  </div>
</template>

<script>
import * as Api from '@/api/field'
export default {
  data() {
    return {
      customStyle: 'margin-bottom:24px',
      query: {},
      listLoading: false,
      list: [],
      pagination: {
        pageIndex: 1,
        pageSize: 20,
        total: 0
      }
    }
  },
  methods: {
    pageIndexChange() {
      this.getData()
    },
    initData(query = {}) {
      const { getData } = this

      this.query = query

      return getData()
    },
    handleHistoryStatisticDetail({ i, item }) {
      const { id: planId } = this.query
      const { id: planSkillId, mainFieldId, fieldId, skillId } = i
      const { id, recordingMethod } = item
      this.$emit('history-statistic-detail', { id: planSkillId, planSkillId, mainFieldId, fieldId, skillId, recordingMethod, skillPhaseId:id, phaseId:id, planId })
    },
    getData() {
      const { id: planId, fieldId, mainFieldId } = this.query
      const { pageIndex, pageSize } = this.pagination
      this.listLoading = true
      return Api.getPlanHistoryDetail({ planId, pageIndex, pageSize, fieldId, mainFieldId })
        .then(res => {
          const { code, data } = res
          const { list, pageIndex, pageSize, totalItems: total } = data
          if (code === 0) {
            this.list = list
            this.pagination = { pageIndex, pageSize, total }
          }
        })
        .finally(() => {
          this.listLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-collapse {
  background-color: rgba(0, 0, 0, 0) !important;
}
/deep/ .ant-collapse-header {
  background-color: @comp-bg-color;
  border: 1px solid @border-color-base;
  border-top-left-radius: @border-radius-base!important;
  border-top-right-radius: @border-radius-base!important;
}
/deep/ .ant-collapse-extra {
  margin-left: auto;
}
/deep/ .ant-collapse-arrow {
  color: @primary-color!important;
}
/deep/ .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  border-left: 1px solid @border-color-base;
  border-right: 1px solid @border-color-base;
  border-bottom: 1px solid @border-color-base;
  border-bottom-left-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;
}
/deep/ .ant-collapse-borderless > .ant-collapse-item {
  border-bottom-width: 0;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 12px;
  transform: translateY(50%);
}
/deep/ .ant-collapse-content-box {
  padding: 0;
}
.heading-color {
  font-size: 20px;
  font-weight: 500;
}
.list-item {
  position: relative;
  padding: 24px 32px;
  border-bottom: 1px solid @border-color-base;
  &.last {
    border-bottom-width: 0;
  }
}
.list-item-icon {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 56px;
}
.header-icon {
  position: absolute;
  right: -84px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 56px;
}
</style>
